import React, { useRef } from 'react';
import {
  FAVORITE_FOLDER,
  TRASH_FOLDER,
  SHARED_ITEMS,
  COLLABORATED_ITEMS
} from '../../../utils/folders';
import {
  IconArrow,
  IconFavorite,
  IconFolder,
  IconFolderTrash,
  IconSharedFolder
} from '../HubIcons';

export const getFolderIcon = (folder: string) => {
  if (folder === TRASH_FOLDER) return <IconFolderTrash />;
  if (folder === FAVORITE_FOLDER) return <IconFavorite />;
  if (folder === SHARED_ITEMS || folder === COLLABORATED_ITEMS)
    return <IconSharedFolder />;
  return <IconFolder />;
};

const FolderButton = ({
  label,
  id,
  onClick,
  hasSubFolder,
  selected,
  level = 0,
  expanded,
  onToggleExpand,
  icon = <IconFolder />,
  isDroppable = true,
  onDrop,
  haveIcon = true,
  suffixIcon = null,
  spaceIndex = 14
}: {
  label: string;
  id: string;
  onClick: (id: string) => void;
  hasSubFolder?: boolean;
  selected?: boolean;
  level?: number;
  expanded?: boolean;
  onToggleExpand?: (id: string) => void;
  icon?: React.ReactNode;
  isDroppable?: boolean;
  onDrop?: (id: string, transferData: DataTransfer) => void;
  haveIcon?: boolean;
  suffixIcon?: React.ReactNode | null;
  spaceIndex?: number;
}) => {
  const containerRef = useRef<HTMLDivElement>();

  return (
    <div
      className={`FolderButton d-flex ${selected ? 'selected' : ''} ${
        expanded ? 'active' : ''
      }`}
      title={label}
      ref={containerRef}
      onDragOver={(e) => {
        e.preventDefault();
        if (!isDroppable) return;
        if (hasSubFolder && !expanded && onToggleExpand) {
          onToggleExpand(id);
        }
        containerRef.current?.classList?.add('drag-active');
      }}
      onDragLeave={() => {
        if (!isDroppable) return;
        containerRef.current?.classList?.remove('drag-active');
      }}
      onDrop={(e) => {
        if (!isDroppable) return;
        onDrop?.(id, e.dataTransfer);
        containerRef.current?.classList?.remove('drag-active');
      }}
    >
      <div className="spacer">
        {hasSubFolder && onToggleExpand && (
          <div className="expand-button" onClick={() => onToggleExpand(id)}>
            <IconArrow direction={expanded ? 'down' : 'right'} />
          </div>
        )}
      </div>

      <div
        onClick={() => {
          onClick(id);
        }}
        className="folder-label d-flex flex-grow-1 text-left"
      >
        {haveIcon && icon}
        <strong>{label}</strong>
        {suffixIcon}
      </div>
      <style jsx>{`
        .FolderButton {
          text-align: left;
          font-size: 13px;
          align-items: center;
          padding-left: ${spaceIndex + level * 14}px;
        }
        .FolderButton:hover {
          background-color: #f5f5f5;
        }
        .spacer {
          width: 15px;
        }
        .expand-button {
          cursor: pointer;
        }
        .expand-button :global(svg) {
          width: 15px;
          height: auto;
        }
        .folder-label {
          cursor: pointer;
          align-items: center;
          padding: 8px 0;
          flex-wrap: wrap;
        }
        .folder-label :global(svg) {
          width: 25px;
          height: auto;
          margin-right: 5px;
        }
        .folder-label strong {
          width: calc(100% - 30px);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
        }
        .selected,
        .selected:hover {
          background-color: #d8d8d8;
        }
        .drag-active {
          border: 1px solid #8f6599;
          background-color: rgba(143, 101, 153, 0.2);
        }
      `}</style>
    </div>
  );
};

export default FolderButton;
