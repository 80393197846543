import React from 'react';
import { logEvent } from '../../../../analytics';
import {
  IStorybook,
  IStorybookPage,
  IStoryBookPageType,
  SBMetadata
} from '../../../../interfaces';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import { isVideo } from '../../../../utils/file';
import { PageConfigContext } from '../../../clientSideServices/pageConfig';
import { getSBPageSettingString } from '../../../utils/storybook';
import ContentManager from '../ContentManager';
import { SBActionTarget } from '../SBActionHandler';

import { StoryBookContext } from '../SBContextContainer';
export type SBContentManagerTab = 'scene' | 'storybook' | 'file';

export interface ContentManagerItem {
  type: SBContentManagerTab;
  id: string;
}

export interface IFilesInUpload {
  file: File;
  name: string;
  key: string;
  metadata?: SBMetadata;
}

const SBContentManager = React.memo(() => {
  const { action, state, entitlement } =
    React.useContext(StoryBookContext);
  const storybook = state.storybook;
  const storybookPages = storybook?.pages?.map((page) => page) || [];
  const storybookId = storybook?.id;

  const contentViewer = state?.contentViewer;

  const handleAdd = (pages: IStorybookPage[]) => {
    const newPages = pages.map((page) => ({
      ...page,
      overlay: "",
      pageType:
        page.pageType ||
        ((isVideo(page.url) ? 'video' : 'image') as IStoryBookPageType),
      settings: getSBPageSettingString(
        page?.settings || '',
        contentViewer?.settings || ''
      )
    }));

    action.update(SBActionTarget.Storybook, {
      data: {
        pages: [...(state.storybook.pages || []), ...newPages]
      },
      append: true
    });
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      button: 'Add Page',
      pageUrl: newPages.map((page) => page.url).join(',')
    });
  };

  const handleSelectItems = (sb: IStorybook[]) => {
    handleAdd(sb.map((s) => s.pages[0]));
  };


  return (
    <>
      <ContentManager
        {...{
          context: PageConfigContext.STORYBOOK_DESIGNER,
          contentId: storybookId,
          onSelectItems: handleSelectItems,
          selectedItem: state?.contentViewer?.id,
          currentPages: storybookPages,
          autoAddNewItem: true,
          disabled: !entitlement?.allowEdit,
          inlineViewer: true,
          addAllButton: true,
        }}
      />
      <style jsx global>{`
        .ContentManagerViewer {
          border-radius: 10px;
          padding:20px;
        }
        .ContentManagerViewer .panel-header {
          padding: 3px 0;
          font-weight: bold;
          border-bottom: 1px solid #e1e1e1;
          height: 40px !important;
          font-size:14px;
        }
        .ContentManagerViewer .panel-content{
          height: calc(100% - 40px) !important;
        }
      `}</style>
    </>
  );
});

export default SBContentManager;
