import React from 'react';
import { logEvent } from '../../../analytics';
import { DID_CLICK_BUTTON } from '../../../utils/constants';
import SearchInput from './SearchInput';
import { isUserOnMobileOnly } from '../../../utils/deviceDetector';
import { Spinner } from 'react-bootstrap';
import { IconArrow } from './HubIcons';
const SearchBar = ({
  setKeywords,
  loading,
  selectMode,
  onCreate,
  createTitle = 'Create',
  placeHolder,
  title = 'Overview',
  keywords,
  createLoading,
  onBack,
  buttonId
}: {
  setKeywords: (keywords: string) => void;
  selectMode?: boolean;
  loading?: boolean;
  onCreate: () => void;
  placeHolder?: string;
  title?: string;
  createTitle?: string;
  keywords?: string;
  createLoading?: boolean;
  onBack?: () => void;
  buttonId?: string;
}) => {
  return (
    <div className="header-container">
      <h2 className={`${onBack ? 'child-page-title' : ''}`} onClick={onBack}>
        {onBack && (
          <span className="back-btn">
            <IconArrow direction="left" />
          </span>
        )}
        {title}
      </h2>
      <div className="action-container">
        {!createLoading && (
          <SearchInput
            onSearch={setKeywords}
            loading={loading}
            searchOnTyping={true}
            rounded={true}
            placeHolder={placeHolder}
            defaultKeyword={keywords || ''}
          />
        )}
        {!selectMode && !isUserOnMobileOnly() && (
          <button
            className="btn btn-round btn-dark btn-create ml-2"
            onClick={() => {
              onCreate();
              logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                button: createTitle
              });
            }}
            id={buttonId}
          >
            {createLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              createTitle
            )}
          </button>
        )}
      </div>
      <style jsx>{`
        h2 {
          margin: 0;
          display: flex;
          align-items: end;
        }

        .header-container {
          border-bottom: 1px solid #ccc;
          padding: 15px 20px;
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .action-container {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .back-btn {
          padding-bottom: 2px;
        }
        .child-page-title {
          cursor: pointer;
        }
        @media (max-width: 1400px) {
          .child-page-title .back-btn :global(svg) {
            width: 18px;
            height: 18px;
          }
        }
      `}</style>
    </div>
  );
};

export default SearchBar;
