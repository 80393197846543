import React from 'react';
import { Spinner } from 'react-bootstrap';
import { logClickButton } from '../../../analytics';
import SelectDropdown from './SelectDropdown';

const ButtonWithConfirmation = ({
  onClick,
  popupPosition = 'before',
  message = 'Are you sure you want to override?',
  label,
  shouldShowConfirmation,
  loading,
  confirmLabel = 'Yes, override my draft',
  className = 'btn-round btn-dark btn-sm',
  minWidth = '100px'
}: {
  onClick: () => void;
  shouldShowConfirmation: boolean;
  label: string;
  loading: boolean;
  popupPosition?: 'before' | 'after';
  message: string;
  confirmLabel?: string;
  className?: string;
  minWidth?: string;
}) => {
  const overrideConfirmation = (
    <SelectDropdown
      className={className}
      id="overrideButton"
      options={[
        {
          value: 'ok',
          label: (
            <div className="btn btn-sm btn-round btn-dark w-100">
              {confirmLabel}
            </div>
          ),
          onSelect: () => {
            onClick();
            logClickButton(confirmLabel);
          }
        },
        {
          value: 'cancel',
          label: (
            <div className="btn btn-sm btn-round btn-outline-dark w-100">
              Cancel
            </div>
          )
        }
      ]}
      label={loading ? <Spinner animation="border" size="sm" /> : label}
      popupWidth={240}
      hideArrow={true}
      childrenPosition={popupPosition}
    >
      <span>{message}</span>
      <style jsx global>{`
        button {
          minwidth: ${minWidth};
        }
      `}</style>
    </SelectDropdown>
  );

  const defaultButton = (
    <button
      className="btn btn-round btn-dark btn-sm btn-edit"
      onClick={() => {
        logClickButton(label);
        onClick();
      }}
    >
      {loading ? <Spinner animation="border" size="sm" /> : label}
      <style jsx>{`
        button {
          min-width: ${minWidth};
        }
      `}</style>
    </button>
  );

  return shouldShowConfirmation ? overrideConfirmation : defaultButton;
};

export default ButtonWithConfirmation;
