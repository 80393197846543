import React from 'react';
import { getTwoLetterFromName } from '../../../utils/string';

type Props = {
  url: string;
  name: string;
  size?: number;
  bgColor?: string;
  foregroundColor?: string;
  className?: string;
  fontSize?: string;
};

function UserAvatar({
  size = 70,
  name,
  bgColor = '#000',
  foregroundColor = '#fff',
  className = '',
  url,
  fontSize
}: Props) {
  const [isAvatarError, setIsAvatarError] = React.useState(false);

  return (
    <>
      <div className={`user-avatar ${className}`}>
        {url && !isAvatarError ? (
          <div className="avatar-cover">
            <img
              src={url}
              alt="Avatar photo"
              onError={() => setIsAvatarError(true)}
            />
          </div>
        ) : (
          <span>{getTwoLetterFromName(name)}</span>
        )}
      </div>
      <style jsx>{`
        .user-avatar {
          margin: 0;
          position: relative;
          overflow: hidden;
          width: ${size}px;
          min-width: ${size}px;
          height: ${size}px;
          user-select: none;
        }
        .user-avatar span {
          display: block;
          background: ${bgColor};
          color: ${foregroundColor};
          border-radius: 50%;
          font-weight: 500;
          font-size: ${fontSize ? fontSize : size > 70 ? '1.5rem' : '1.2rem'};
          line-height: ${size}px;
          text-align: center;
        }
        .user-avatar img {
          width: ${size}px;
          height: ${size}px;
          border-radius: 50%;
          object-fit: cover;
        }
      `}</style>
    </>
  );
}

export default UserAvatar;
