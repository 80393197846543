import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { SBContentManagerTab } from '.';
import { IStorybook, IStorybookPage } from '../../../../interfaces';
import { mapSceneToStorybook } from '../../../../mappers/storybook';
import {
  getFileNameFromUrl,
  isVideo,
  removeFileExtension
} from '../../../../utils/file';
import { getActivatedSceneById } from '../../../clientSideServices/library';
import { getActivatedStorybookById } from '../../../clientSideServices/storybook';
import SBPageThumbnail from '../Common/SBPageThumbnail';
import ContentItemActions from './ContentItemActions';

export const ConvertingThumbnail = ({ fileKey }: { fileKey: string }) => {
  return (
    <div className="converting-thumbnail">
      <div className="convert-item">
        <div className="converting-thumbnail-icon">
          <Spinner animation="border" size="sm" />
          <div>Converting</div>
        </div>
        <div className="converting-thumbnail-text">
          {getFileNameFromUrl(removeFileExtension(fileKey))}
        </div>
      </div>
      <style jsx>{`
        .converting-thumbnail {
          background: #f3f3f3;
          border-radius: 15px;
          overflow: hidden;
          font-size: 11px;
          border: 2px solid #fff;
          box-shadow: 0 0 5px #efefef;
          position: relative;
          height: 100%;
          text-align: center;
          display: flex;
          min-height: 100px;
          flex-direction: column;
          align-items: center;
          font-weight: normal;
        }
        .converting-thumbnail-text {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80%;
          margin: auto;
        }
        .convert-item {
          margin: auto;
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export const LoadingThumbnail = () => (
  <div className="LoadingThumbnail">
    <div>
      <Spinner animation="border" size="sm" />
    </div>
    <style jsx>{`
      .LoadingThumbnail {
        width: 100%;
        background: #efefef;
        border-radius: 5px;
        overflow: hidden;
        padding: 5px;
        height: 100%;
        display: flex;
        align-items: center;
      }
      .LoadingThumbnail > div {
        margin: auto;
      }
    `}</style>
  </div>
);

const ContentStorybook = ({
  id,
  onSelect,
  onView,
  active,
  onError,
  currentPages
}: {
  id: string;
  onSelect: (sb: IStorybook, view?: boolean) => void;
  onView: (sb: IStorybook, view?: boolean) => void;
  active?: boolean;
  onError?: (id: string) => void;
  currentPages?: string[];
}) => {
  const { isLoading, isError, data } = useQuery(`content-${id}`, () =>
    getActivatedStorybookById(id, true)
  );

  React.useEffect(() => {
    if (isError) {
      onError(id);
    }
  }, [isError]);

  if (isLoading) return <LoadingThumbnail />;

  if (data) {
    const selected =
      data.pages?.length === 1
        ? currentPages?.includes(data.pages[0].id)
        : false;

    return (
      <SBPageThumbnail
        title={data.title}
        url={data.pages[0]?.thumbnail || data.pages[0]?.url}
        contentUrl={data.pages?.[0]?.url}
        subtitle="Storybook"
        active={active}
        hoverText={`${data.title}${data.subtitle ? ` | ${data.subtitle}` : ''}`}
        selected={selected}
        disabled={selected}
        overlay={
          selected ? undefined : (
            <ContentItemActions
              onView={() => onView(data)}
              onAdd={data.pages?.length === 1 ? () => onSelect(data) : undefined}
            />
          )
        }
      />
    );
  }

  return null;
};

const ContentScene = ({
  id,
  onView,
  onSelect,
  active,
  onError,
  currentPages
}: {
  id: string;
  onView: (sb: IStorybook) => void;
  onSelect: (sb: IStorybook) => void;
  active?: boolean;
  onError?: (id: string) => void;
  currentPages?: string[];
}) => {
  const { isLoading, isError, data } = useQuery(`content-${id}`, () =>
    getActivatedSceneById(id)
  );

  React.useEffect(() => {
    if (isError) {
      onError(id);
    }
  }, [isError]);

  if (isLoading) return <LoadingThumbnail />;
  if (data) {
    const storybook = mapSceneToStorybook(data);
    const selected = currentPages?.includes(storybook.pages[0].id);
    return (
      <SBPageThumbnail
        title={storybook.title}
        url={storybook.pages[0]?.thumbnail || storybook.pages[0]?.url}
        contentUrl={storybook.pages?.[0]?.url}
        subtitle={'Scene'}
        active={active}
        allClickable={true}
        hoverText={`${storybook.title}${
          storybook.subtitle ? ` | ${storybook.subtitle}` : ''
        }`}
        selected={selected}
        disabled={selected}
        overlay={
          selected ? undefined : (
            <ContentItemActions
              onAdd={() => onSelect(storybook)}
              onView={() => onView(storybook)}
            />
          )
        }
      />
    );
  }
  return null;
};

const ContentFile = ({
  file,
  onView,
  onSelect,
  active,
  loading,
  onError,
  currentPages
}: {
  file: IStorybook;
  onView: (sb: IStorybook) => void;
  onSelect: (sb: IStorybook) => void;
  active?: boolean;
  loading?: boolean;
  onError?: () => void;
  currentPages?: string[];
}) => {
  React.useEffect(() => {
    if (!loading && !file) {
      onError();
    }
  }, [file, loading]);

  if (loading) return <LoadingThumbnail />;
  if (!file) return null;
  const subTitle =
    file.pages.length > 1
      ? 'Document'
      : isVideo(file.pages[0]?.url)
      ? 'Video'
      : 'Image';

  const selected =
    file.pages?.length === 1
      ? currentPages?.includes(file.pages[0].url)
      : false;

  return (
    <SBPageThumbnail
      title={file.title}
      url={file.pages[0]?.thumbnail || file.pages[0]?.url}
      contentUrl={file.pages?.[0]?.url}
      subtitle={subTitle}
      active={active}
      allClickable={true}
      hoverText={`${file.title}${file.subtitle ? ` | ${file.subtitle}` : ''}`}
      selected={selected}
      disabled={selected}
      overlay={
        selected ? undefined : (
          <ContentItemActions
            onAdd={file.pages.length === 1 ? () => onSelect(file) : undefined}
            onView={() => onView(file)}
          />
        )
      }
    />
  );
};

const ContentItem = ({
  id,
  type,
  filesContent,
  onError,
  onView,
  onSelect,
  selectedItem,
  loadingFileStatus,
  currentPages
}: {
  id: string;
  type: SBContentManagerTab;
  filesContent: IStorybook[];
  onView: (sb: IStorybook) => void;
  onSelect: (sb: IStorybook) => void;
  selectedItem?: string;
  onError?: (id: string) => void;
  loadingFileStatus?: boolean;
  currentPages?: IStorybookPage[];
}) => {
  return (
    <>
      {type === 'storybook' && (
        <ContentStorybook
          id={id}
          active={selectedItem === id}
          onView={onView}
          onSelect={onSelect}
          onError={onError}
          currentPages={currentPages?.map((p) => p.id)}
        />
      )}
      {type === 'scene' && (
        <ContentScene
          id={id}
          active={selectedItem === id}
          onView={onView}
          onSelect={onSelect}
          onError={onError}
          currentPages={currentPages?.map((p) => p.id)}
        />
      )}

      {type === 'file' && (
        <ContentFile
          file={filesContent.find((f) => f.id === id)}
          active={selectedItem === id}
          onView={onView}
          onSelect={onSelect}
          onError={() => onError(id)}
          loading={loadingFileStatus}
          currentPages={currentPages?.map((p) => p.url)}
        />
      )}
    </>
  );
};

export default ContentItem;
