import NextErrorComponent from 'next/error';
import React from 'react';
import * as Sentry from '@sentry/node';
import { assetBaseUrl } from '../config';

const ErrorMessage = ({redirectTo = null}) => {
  const icon404 = `${assetBaseUrl}/icons/404-v1.svg`;
  React.useEffect(() => {
    if (redirectTo) {
      setTimeout(() => {
        window.location.assign(redirectTo);
      }, 2000);
    }
  }, [redirectTo]);
  return (
    <>
      {
        <div className="ErrorMessage">
          <div className="child">
            <div>
              <img className="error-img" src={icon404}></img>
            </div>
            <div className="text-container">
              <h2>Sorry, something went wrong.</h2>
              <p className="message-text">
                The page you are looking for might have been removed or is
                temporarily unavailable.
              </p>
            </div>
          </div>
        </div>
      }
      <style jsx>{`
        .ErrorMessage {
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          min-height: 100vh;
        }
        .ErrorMessage .child {
          text-align: center;
        }
        .error-img {
          width: 15rem;
        }
        h2 {
          font-size: 2.8em;
        }
        .message-text {
          font-size: 1.25em;
        }
        .text-container {
          padding-top: 5rem;
          padding-bottom: 5rem;
        }
        @media (max-width: 769px) {
          .text-container {
            padding-top: 1rem;
            padding-bottom: 1rem;
          }
          h2 {
            font-size: 1.5em;
          }
          .message-text {
            font-size: 1em;
          }
          .error-img {
            width: 9rem;
          }
        }
      `}</style>
    </>
  );
};
const Error = ({ hasGetInitialPropsRun, err, redirectTo = null }) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    Sentry.captureException(err);
  }
  return <ErrorMessage redirectTo={redirectTo} />;
};

Error.getInitialProps = async ({ res, err, asPath }) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err
  });

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true;

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (res?.statusCode === 404) {
    // Opinionated: do not record an exception in Sentry for 404
    return { statusCode: 404 };
  }
  if (err) {
    Sentry.captureException(err);
    await Sentry.flush(2000);
    return errorInitialProps;
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  Sentry.captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`)
  );
  await Sentry.flush(2000);

  return errorInitialProps;
};

export default Error;
