import { isEmpty } from '@aws-amplify/core';
import { isEqual, uniq } from 'lodash';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FaPaintBrush } from 'react-icons/fa';
import {
  ILibraryScene,
  ILibrarySceneContent,
  LibrarySceneType
} from '../../../../interfaces';
import { TLanguage } from '../../../../mappers/polotno';
import { mapStorybookSettingsStringToObject } from '../../../../mappers/storybook';
import { getAvailableLanguageInScene } from '../../../utils/library';
import AddButton from '../../Common/AddButton';
import SBPageDetailEditor from '../../StoryBook/Common/SBPageDetailEditor';
import SBVisibilityTagsSetting from '../../StoryBook/Common/SBVisibilityTagsSetting';

const SLSceneDetails = ({
  onUpdate,
  scene,
  onOpenEditor,
  preview,
  togglePreview,
  context,
  onHide,
  isVideo
}: {
  onUpdate: (p: ILibraryScene) => void;
  scene: ILibraryScene;
  onOpenEditor?: () => void;
  preview?: {
    loading: boolean;
    isPreviewing: boolean;
  };
  togglePreview?: () => void;
  context?: LibrarySceneType;
  onHide?: () => void;
  isVideo?:boolean
}) => {
  const isTemplate = context === 'template';
  const handleUpdate = (data: ILibraryScene) => {
    onUpdate({
      ...scene,
      ...data
    });
  };

  const handleUpdateContent = (data: ILibrarySceneContent) => {
    handleUpdate({
      content: {
        ...scene.content,
        ...data
      }
    });
  };

  const existingScope = scene?.content?.visibilityScope || [];
  const existingTags = scene?.tags || [];

  const addTeam = (teamId: string) => {
    const visibilityScope = uniq([...existingScope, teamId]);
    if (!isEqual(visibilityScope, existingScope)) {
      onUpdate({
        ...scene,
        content: {
          ...scene.content,
          visibility: isEmpty(visibilityScope) ? 'private' : 'team',
          visibilityScope
        }
      });
    }
  };

  const removeTeam = (teamId: string) => {
    const visibilityScope = existingScope.filter((id) => id !== teamId);
    if (!isEqual(visibilityScope, existingScope)) {
      handleUpdateContent({
        visibility: isEmpty(visibilityScope) ? 'private' : 'team',
        visibilityScope: visibilityScope
      });
    }
  };

  const addTag = (tag: string) => {
    const tags = uniq([...existingTags, tag]);
    if (!isEqual(tags, existingTags)) {
      handleUpdate({ tags });
    }
  };

  const removeTag = (t: string) => {
    const tags = existingTags.filter((tag) => tag !== t);
    if (!isEqual(tags, existingTags)) {
      handleUpdate({ tags });
    }
  };


  const [activeLanguage, setActiveLanguage] = React.useState<TLanguage>('en');
  const [availableList, setAvailableList] = React.useState<TLanguage[]>(['en']);

  const settings = mapStorybookSettingsStringToObject(scene.content.settings) || {}

  React.useEffect(() => {
    const active = settings.language || 'en'
    setActiveLanguage(active)
  }, [settings]);

  React.useEffect(() => {
    const languageList = getAvailableLanguageInScene(scene);
    setAvailableList(languageList);
  }, [scene])

  const disableEdit = scene?.status === 'activated';

  const sceneDetails = (
    <div className="form-container">
      <div className="fieldset">
        <label>{isTemplate ? 'Template' : 'Scene'} Name</label>
        <input
          className="form-control form-control-sm sl-name"
          type="text"
          value={scene?.content?.name || ''}
          disabled={disableEdit}
          onChange={(e) => {
            handleUpdateContent({ name: e.target.value });
          }}
        />
      </div>
      <div className="fieldset">
        <label>Description</label>
        <textarea
          className="form-control form-control-sm sl-description"
          value={scene?.content?.description || ''}
          disabled={disableEdit}
          onChange={(e) => {
            handleUpdateContent({ description: e.target.value });
          }}
        />
      </div>
      {onOpenEditor && (
        <button
          className="btn btn-round btn-primary btn-sm btn-design w-100"
          onClick={() => onOpenEditor()}
          disabled={scene.status === 'activated'}
        >
          <FaPaintBrush /> Open In Scene Editor
        </button>
      )}

      <style jsx>{`
        .form-container {
          margin-bottom: ${isTemplate ? 0 : '20px'};
          padding-bottom: ${isTemplate ? 0 : '20px'};
          border-bottom: ${isTemplate ? 'none' : '1px solid #ccc'};
        }
        .fieldset {
          margin-top: 10px;
        }
        .form-container label {
          font-weight: 500;
        }
        textarea {
          height: 74px;
        }
        .btn-design {
          font-size: 12px;
          margin-top: 20px;
          border: 1px solid #005fc5 !important;
          line-height: 1.4;
        }
      `}</style>
    </div>
  );

  return (
    <div className="SLSceneDetails">
      <div className="d-flex flex-wrap align-items-center">
        <span className="panel-label">
          {isTemplate ? 'Template' : 'Scene'} Details
        </span>
        {onHide && <AddButton onClick={onHide} label="Hide Panel" />}
      </div>
      {sceneDetails}
      {!isTemplate && (
        <SBPageDetailEditor
          page={{
            id: scene.id,
            title: scene.content.title,
            subtitle: scene.content.subtitle,
            settings: scene.content.settings,
            url: scene.content.url,
            thumbnail: scene.content.thumbnail
          }}
          enlargeMode={true}
          onUpdate={(p) => {
            handleUpdateContent({
              settings: p.settings,
              title: p.title || '',
              subtitle: p.subtitle || ''
            });
          }}
          showVideoSettings={isVideo}
          settings={settings}
          allowEdit={scene?.status !== 'activated'}
        />
      )}
      {togglePreview && scene?.status !== 'activated' && (
        <div className="preview-btn">
          <button
            className="btn w-100 btn-sm btn-outline-dark btn-round"
            onClick={togglePreview}
          >
            {preview.loading ? (
              <Spinner animation="border" size="sm" />
            ) : preview.isPreviewing ? (
              'Close Preview'
            ) : (
              'Show Preview'
            )}
          </button>
        </div>
      )}
      <SBVisibilityTagsSetting
        teamIds={existingScope}
        tags={existingTags}
        addTeam={addTeam}
        removeTeam={removeTeam}
        addTag={addTag}
        removeTag={removeTag}
        canModify={!disableEdit}
        uniqueId={scene.id}
      />

      <style jsx>{`
        .d-flex {
          justify-content: space-between;
        }
        .preview-btn {
          margin-top: 20px;
        }
        .SLSceneDetails {
          padding: 20px 30px;
        }
        :global(.visibility-tags-setting) {
          border-top: 1px solid #ccc;
          margin-top: 20px;
        }
        :global(.visibility-tags-setting p) {
          font-weight: 500;
          margin-bottom: 5px !important;
        }
        :global(.duration-input span) {
          top: 5px !important;
        }
      `}</style>
    </div>
  );
};

export default SLSceneDetails;
