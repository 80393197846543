import React, { useState } from 'react';
import SBAnalyticsTabs, { ITab } from './SBAnalyticsTabs';
import dynamic from 'next/dynamic';
import { ITableColumn, SortTable } from '../../Common/Table';
import { cloneDeep } from 'lodash';
import { ApexOptions } from 'apexcharts';
import {
  formatDurationFromMiliSeconds,
  formattedNumber
} from '../../../../utils/number';

const Chart = dynamic(() => import('react-apexcharts'), { ssr: false });

const SBAnalyticsInteractionsDetails = ({ item }) => {
  const overviewTabs = ['eyeball time', 'product clicks', 'shares'];
  const [currentOverviewTab, setCurrentOverviewTab] = useState<ITab>({
    id: overviewTabs[0],
    label: overviewTabs[0]
  });

  const chartAreaOptions: ApexOptions = {
    chart: {
      type: 'area',
      toolbar: {
        show: false
      }
    },
    colors: ['#B63B3B', '#E89B55', '#6AC773', '#203DA5', '#9039B7', '#615E83'],
    stroke: {
      width: 1,
      curve: 'smooth'
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep'
      ]
    },
    yaxis: {
      opposite: true
    }
  };

  const chartAreaSeries = [
    {
      name: 'Singapore',
      data: [31, 40, 28, 51, 42, 109, 100]
    },
    {
      name: 'France',
      data: [11, 32, 45, 32, 34, 52, 41]
    },
    {
      name: 'Germany',
      data: [50, 8, 12, 18, 16, 30, 33]
    },
    {
      name: 'China',
      data: [43, 42, 23, 33, 67, 32, 32]
    },
    {
      name: 'Australia',
      data: [33, 65, 45, 332, 32, 32, 44]
    },
    {
      name: 'Indonesia',
      data: [55, 23, 25, 56, 45, 33, 41]
    }
  ];

  const dataColumns: ITableColumn[] = [
    {
      key: 'localisation',
      title: 'Localisation',
      align: 'left'
    },
    {
      key: 'targeted',
      title: 'Targeted',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'opened',
      title: 'Opened',
      align: 'center',
      custom: (value) => formattedNumber(value)
    },
    {
      key: 'watch_time',
      title: 'Watch Time',
      align: 'center',
      custom: (value) => formatDurationFromMiliSeconds(value)
    },
    {
      key: 'conversion',
      title: 'Conversion',
      align: 'center'
    }
  ];

  const fakeData = [
    {
      id: '1',
      localisation: 'Total',
      targeted: 22500,
      opened: 8500,
      watch_time: 800,
      conversion: '20%',
      styles: {
        color: '#615E83'
      },
      checked: true
    },
    {
      id: '2',
      localisation: 'Singapore',
      targeted: 1354,
      opened: 1102,
      watch_time: 140,
      conversion: '15%',
      styles: {
        color: '#B63B3B'
      },
      stylesColumns: [0]
    },
    {
      id: '3',
      localisation: 'France',
      targeted: 1354,
      opened: 1102,
      watch_time: 140,
      conversion: '15%',
      styles: {
        color: '#E89B55'
      },
      stylesColumns: [0]
    },
    {
      id: '4',
      localisation: 'Germany',
      targeted: 1354,
      opened: 1102,
      watch_time: 140,
      conversion: '15%',
      styles: {
        color: '#6AC773'
      },
      stylesColumns: [0]
    },
    {
      id: '5',
      localisation: 'China',
      targeted: 1354,
      opened: 1102,
      watch_time: 140,
      conversion: '15%',
      styles: {
        color: '#203DA5'
      },
      stylesColumns: [0]
    },
    {
      id: '6',
      localisation: 'Australia',
      targeted: 1354,
      opened: 1102,
      watch_time: 140,
      conversion: '15%',
      styles: {
        color: '#9039B7'
      },
      stylesColumns: [0]
    },
    {
      id: '7',
      localisation: 'Indonesia',
      targeted: 1354,
      opened: 1102,
      watch_time: 140,
      conversion: '15%',
      styles: {
        color: '#615E83'
      },
      stylesColumns: [0]
    }
  ];

  const [countriesData, setCountriesData] = useState(fakeData);

  const onRowCheck = (row) => {
    const index = countriesData.findIndex((r) => r.id === row.id);
    if (index !== -1) {
      const temp = cloneDeep(countriesData);
      temp[index].checked = !temp[index].checked;
      setCountriesData(temp);
    }
  };

  const chartColumnsOptions: ApexOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false
      }
    },
    colors: ['#502B5A'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '28px',
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -30,
      style: {
        colors: ['#615E83'],
        fontWeight: 400
      }
    },
    grid: {
      show: false
    },
    yaxis: {
      show: false
    },
    xaxis: {
      categories: [
        'Storybook 1',
        'Storybook 2',
        'Storybook 3',
        'Link',
        'Web Shared',
        'Email',
        'eCom Link'
      ],
      axisTicks: {
        show: false
      },
      axisBorder: {
        show: false
      }
    }
  };

  const chartColumnsSeries = [
    {
      name: 'Device Distribution',
      data: [56, 54, 48, 42, 34, 21, 20]
    }
  ];

  return (
    <>
      <div className="analytics-main">
        <div className="overview-linechart card-section">
          <SBAnalyticsTabs
            dot={true}
            onSelectTab={setCurrentOverviewTab}
            tabs={overviewTabs.map((t) => ({ id: t, label: t }))}
            active={currentOverviewTab}
          />
          <Chart
            options={chartAreaOptions}
            series={chartAreaSeries}
            type="area"
            height="350"
          />
        </div>
        <div className="overview-table card-section">
          <SortTable
            rowCheckbox
            onCheckboxClick={onRowCheck}
            columns={dataColumns}
            sorts={['targeted', 'opened', 'watch_time', 'conversion']}
            data={countriesData}
            onlyActiveSortBy
          />
        </div>
        <div className="overview-bar">
          <div className="card-section">
            <label>Share Sources</label>
            <Chart
              options={chartColumnsOptions}
              series={chartColumnsSeries}
              height="350"
              type="bar"
            />
          </div>
        </div>
      </div>

      <style jsx>{`
        .analytics-main {
          overflow: auto;
          max-height: calc(100vh - 200px);
        }
        :global(.overview-linechart .analytics-tab) {
          font-size: 14px !important;
          padding: 7px 10px !important;
          text-transform: capitalize;
        }
        :global(.overview-linechart .analytics-tabs) {
          border-bottom: none;
        }
        :global(.overview-linechart .active-tab) {
          color: rgba(0, 0, 0, 0.9);
        }
        :global(.overview-linechart .active-tab svg) {
          color: #4c2d5a;
        }
        .card-section {
          border-radius: 10px;
          background: #fff;
          box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
          padding: 20px;
          margin-bottom: 20px;
        }
        .card-section label {
          color: rgba(0, 0, 0, 0.5);
          font-size: 16px;
          font-weight: 400;
        }
      `}</style>
    </>
  );
};

export default SBAnalyticsInteractionsDetails;
