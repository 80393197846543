import {
  CollectionResponseWithTotalSimplePublicObjectForwardPaging,
  PublicObjectSearchRequest
} from '@hubspot/api-client/lib/codegen/crm/companies';
import httpClient from '../../../clientSideServices/http';
import { AxiosPromise } from 'axios';

type SearchHubSpotContactParams = PublicObjectSearchRequest & {
  spaceId: string;
};

export const searchContacts = (
  searchParams: SearchHubSpotContactParams
): AxiosPromise<CollectionResponseWithTotalSimplePublicObjectForwardPaging> => {
  return httpClient.post(`/api/hubspot/searchContacts`, searchParams);
};
