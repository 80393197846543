import { logEvent } from '../../../analytics';
import { DID_DELETE_FILE } from '../../../utils/constants';
import { deleteObjectFromS3, getObjectFromS3, uploadToS3 } from '../aws';
const pageEditorJsonBucket = 'inspify-config';

type PageContent = {
  id: string;
  type: 'storybook' | 'scene' | 'file';
};

export enum PageConfigContext {
  STORYBOOK_DESIGNER = 'storybook-designer',
  SCENE_DESIGNER = 'scene-designer',
  FEATURED_IMAGE_DESIGNER = 'featured-image-designer'
}

export interface HubPageConfig {
  id: string;
  context: PageConfigContext;
  content: PageContent[];
  createdBy?: string;
  modifiedBy?: string;
  createdAt?: string;
  modifiedAt?: string;
}

const mapHubPageConfigToPayload = ({
  config,
  userId
}: {
  config: HubPageConfig;
  userId: string;
}): HubPageConfig => {
  const { id, content, context, createdBy, createdAt } = config;
  return {
    id,
    context,
    content,
    createdBy: createdBy || userId,
    createdAt: createdAt || new Date().toISOString(),
    modifiedBy: createdBy ? userId : undefined,
    modifiedAt: createdAt ? new Date().toISOString() : undefined
  };
};

const generateHubPageConfigKey = (id: string) =>
  `hub-page-config/${id}/config.json`;

export const deleteHubPageConfigJson = ({
  id,
  onSuccess,
  onError
}: {
  id: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const bucket = pageEditorJsonBucket;
  const key = generateHubPageConfigKey(id);
  deleteObjectFromS3(bucket, key)
    .then(() => {
      logEvent(DID_DELETE_FILE, DID_DELETE_FILE, { bucket, key });
      onSuccess?.();
    })
    .catch(() => {
      onError?.();
    });
};

export const saveHubPageConfigJson = ({
  config,
  userId,
  onSuccess,
  onError
}: {
  config: HubPageConfig;
  userId: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const configPayload = mapHubPageConfigToPayload({ config, userId });
  const Key = generateHubPageConfigKey(config.id);
  const Bucket = pageEditorJsonBucket;
  uploadToS3({
    Body: Buffer.from(JSON.stringify(configPayload)),
    Bucket,
    Key,
    ContentType: 'application/json'
  })
    .then(() => {
      logEvent('DID_UPLOAD_CONFIG_FILE', 'DID_UPLOAD_CONFIG_FILE', {
        Key,
        Bucket,
        configPayload
      });
      onSuccess?.();
    })
    .catch((e) => {
      console.log('error uploading config file', e);
      logEvent('UPLOAD_CONFIG_FILE_FAILED', 'UPLOAD_CONFIG_FILE_FAILED', {
        Key,
        Bucket,
        configPayload
      });
      onError?.();
    });
};

export const getPageConfigJson = ({
  id,
  onSuccess,
  onError
}: {
  id: string;
  onSuccess: (json: HubPageConfig) => void;
  onError?: () => void;
}) => {
  getObjectFromS3(pageEditorJsonBucket, generateHubPageConfigKey(id))
    .then((data) => {
      onSuccess(JSON.parse(data.Body.toString()));
    })
    .catch(() => {
      onError?.();
    });
};
