import React from 'react';
import { IFolderKey } from '../../../../interfaces';
import {
  getFolderLabel,
  getFolderKeysByLevel,
  getSubFolderKeysByParent,
  isLastChildFolder,
  SHARED_ITEMS,
  SHARED_TEAM_TYPE
} from '../../../utils/folders';
import { IconFolder, IconSharedFolder } from '../HubIcons';
import FolderButton from './FolderButton';

const FolderTrees = ({
  folders,
  parent = { id: '', key: '/' },
  selectedFolder,
  onClickFolder,
  level = 1,
  expandedFolderKeys,
  toggleExpandFolderKey,
  isDroppable = true,
  onDrop,
  spaceIndex = 14
}: {
  folders: IFolderKey[];
  parent?: IFolderKey;
  selectedFolder?: string;
  onClickFolder: (id: string) => void;
  level?: number;
  expandedFolderKeys: string[];
  toggleExpandFolderKey: (id: string) => void;
  isDroppable?: boolean;
  onDrop?: (id: string, transferData: DataTransfer) => void;
  spaceIndex?: number;
}) => {
  const directSubFolder = getFolderKeysByLevel(level, folders);
  return (
    <div className={`FolderTrees`}>
      {directSubFolder?.length > 0 &&
        directSubFolder.map((folder, index) => {
          const subFolders = getSubFolderKeysByParent(folder.id, folders);
          const hasSubFolder = !isLastChildFolder(folder.id, folders);

          return (
            <div key={index}>
              <FolderButton
                label={getFolderLabel(folder.key)}
                id={folder.id}
                onClick={onClickFolder}
                hasSubFolder={hasSubFolder}
                selected={folder?.id === selectedFolder}
                level={level}
                expanded={expandedFolderKeys?.includes(folder?.id)}
                onToggleExpand={toggleExpandFolderKey}
                icon={
                  folder.key === SHARED_ITEMS ||
                  folder.settings?.type === SHARED_TEAM_TYPE ? (
                    <IconSharedFolder />
                  ) : (
                    <IconFolder />
                  )
                }
                isDroppable={isDroppable}
                onDrop={onDrop}
                spaceIndex={spaceIndex}
              />
              {hasSubFolder && (
                <FolderTrees
                  key={folder?.id}
                  folders={subFolders}
                  parent={folder}
                  selectedFolder={selectedFolder}
                  onClickFolder={onClickFolder}
                  level={level + 1}
                  expandedFolderKeys={expandedFolderKeys}
                  toggleExpandFolderKey={toggleExpandFolderKey}
                  onDrop={onDrop}
                  isDroppable={isDroppable}
                  spaceIndex={spaceIndex}
                />
              )}
            </div>
          );
        })}
      <style jsx>{`
        .FolderTrees {
          height: ${parent.key === '/' ? 'auto' : '0'};
          overflow: hidden;
          transition: all 0.3s ease;
        }
        :global(.active) + .FolderTrees {
          height: auto;
        }
      `}</style>
    </div>
  );
};

export default FolderTrees;
