import React from 'react';
import { logEvent } from '../../../analytics';

const DID_SELECT_ROLE_PREVIEW = 'DID_SELECT_ROLE_PREVIEW';
const RoleViewToggle = ({
  onToggleHost,
  asHost
}: {
  onToggleHost: (host: boolean) => void;
  asHost?: boolean;
}) => {
  const handleSelectRole = (hostRole: boolean) => {
    onToggleHost(hostRole);
    logEvent(DID_SELECT_ROLE_PREVIEW, DID_SELECT_ROLE_PREVIEW, {
      roleView: hostRole ? 'host' : 'client'
    });
  };
  return (
    <div className="btn-group">
      <button
        className={`btn  btn-round btn-sm ${
          asHost ? 'btn-secondary' : 'btn-light'
        }`}
        onClick={() => handleSelectRole(true)}
      >
        <strong>Host</strong>
      </button>

      <button
        className={`btn  btn-round btn-sm ${
          !asHost ? 'btn-secondary' : 'btn-light'
        }`}
        onClick={() => handleSelectRole(false)}
      >
        <strong>Client</strong>
      </button>

      <style jsx>{`
        .btn {
          width: 80px;
          border-color: #bbb !important;
          color: #666;
        }
        .btn-secondary {
          background: #bbb;
          color: #000;
        }
      `}</style>
    </div>
  );
};

export default RoleViewToggle;
