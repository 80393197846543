import React from 'react';
import PageEditor from './SBPageEditor';

import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay
} from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';
import { StoryBookContext } from '../SBContextContainer';
import { getSBPageSetting } from '../../../utils/storybook';
import { SBActionTarget } from '../SBActionHandler';
import SBPageThumbnail from '../Common/SBPageThumbnail';
import { IconAdd } from '../../Common/HubIcons';
import { TLanguage } from '../../../../mappers/polotno';

let saveTimeout;
const SBSortablePage = React.memo(({
  compactMode,
  onOpenEditor,
  onDuplicate,
}: {
  compactMode?: boolean;
  onOpenEditor?: (props: {
    sceneId?: string;
    allowEdit?: boolean;
    isImported?: boolean;
    activeLanguage?: TLanguage;
  }) => void;
  onDuplicate?: (props: { sceneId?: string; isImported?: boolean }) => void;
}) => {
  const { action, state, entitlement } = React.useContext(StoryBookContext);

  const selectedStorybook = state.storybook;
  const pages = selectedStorybook?.pages || [];
  const sensors = useSensors(useSensor(PointerSensor));
  const [activeDrag, setActiveDrag] = React.useState<{
    thumbnail: string;
    background?: string;
  }>(null);

  function handleDragEnd(event) {
    const { active, over } = event;
    if (!action || !over) return;
    const copy = [...pages];
    setActiveDrag(null);
    if (active.id !== over.id) {
      const oldIndex = copy.indexOf(copy.find((page) => page.id === active.id));
      const newIndex = copy.indexOf(copy.find((page) => page.id === over.id));
      action.update(SBActionTarget.Storybook, {
        data: {
          pages: arrayMove(copy, oldIndex, newIndex)
        },
        append: true
      });
    }
  }

  function handleDragStart(event) {
    const onDragPage = pages.find((page) => page.id === event.active.id);
    const { background } = getSBPageSetting(
      onDragPage.settings,
      selectedStorybook.settings
    );
    setActiveDrag({
      thumbnail: onDragPage.thumbnail,
      background
    });
  }

  React.useEffect(() => {
    if (compactMode) {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
      saveTimeout = setTimeout(() => {
        action.save(SBActionTarget.Storybook);
      }, 200);
    }
  }, [selectedStorybook]);

  return (
    <div className="panel-content d-flex">
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
      >
        {entitlement.allowEdit && !compactMode && (
          <div className="PageEditorContainer add-page d-flex flex-column">
            <button
              className="btn btn-add d-flex flex-column h-100 w-100"
              onClick={() => onOpenEditor({})}
            >
              <span className="m-auto">
                <IconAdd />
                <br />
                Design New Page
              </span>
            </button>
          </div>
        )}
        <SortableContext items={pages.map((page) => page.id)}>
          {pages.map((page, index) => (
            <PageEditor
              key={page.id}
              page={page}
              pageNumber={index + 1}
              onDelete={() =>
                action.delete(SBActionTarget.StorybookPage, index)
              }
              onUpdate={(update, doNotFlagChange) =>
                action.update(SBActionTarget.StorybookPage, {
                  index,
                  page: update,
                  doNotFlagChange
                })
              }
              onClick={() => action.openStorybookPage(index)}
              compactMode={compactMode}
              onOpenEditor={onOpenEditor}
              onDuplicate={onDuplicate}
              settings={getSBPageSetting(
                page.settings,
                selectedStorybook.settings
              )}
              allowEdit={entitlement.allowEdit}
            />
          ))}
        </SortableContext>
        <DragOverlay>
          {activeDrag && (
            <SBPageThumbnail
              thumbnailObjectFit="contain"
              thumbnailColor={activeDrag?.background}
              url={activeDrag.thumbnail}
            />
          )}
        </DragOverlay>
      </DndContext>
      <style jsx>{`
        .panel-content {
          flex-wrap: wrap;
          align-content: flex-start;
          padding: 10px;
        }
        .panel-content :global(.SBPageThumbnail),
        .panel-content :global(.SBPageThumbnail .media-container) {
          cursor: grabbing !important;
        }
        .panel-content :global(.PageEditorContainer) {
          width: ${compactMode ? '50%' : '100%'};
          background: #fff;
          border: 2px solid transparent;
          padding: ${compactMode ? '5px' : '10px'};
        }

        .panel-content :global(.PageEditor) {
          padding: ${compactMode ? '4px' : '10px'};
          border-radius: ${compactMode ? '5px' : '10px'};
        }

        .panel-content > :global(div.active) {
          border: 2px solid #000;
        }
        .panel-content :global(.PageEditorContainer) {
          margin-bottom: 10px;
          position: relative;
        }

        .panel-content :global(.PageEditorContainer:hover .PageEditor) {
          background: #fff;
          box-shadow: 0 0 10px #ccc;
        }
        .panel-content .btn-add {
          background: #efefef;
          border: 3px solid #efefef;
          border-radius: 10px;
          min-height: 230px;
        }
        .panel-content .add-page {
          padding: 30px;
        }
        .panel-content .btn-add:hover {
          background: #fff;
        }
        .panel-content .btn-add :global(svg) {
          width: 100px;
          height: auto;
        }
        @media (min-width: 768px) {
          .panel-content :global(.PageEditorContainer) {
            width: ${compactMode ? '16.6%' : '50%'};
          }
        }
        @media (min-width: 1000px) {
          .panel-content :global(.PageEditorContainer) {
            width: ${compactMode ? '14.2%' : '50%'};
          }
        }
        @media (min-width: 1440px) {
          .panel-content :global(.PageEditorContainer) {
            width: ${compactMode ? '12.5%' : '50%'};
          }
          .panel-content :global(.PageEditor) {
            padding: ${compactMode ? '10px 10px 0' : '10px'};
          }
        }
        @media (min-width: 1600px) {
          .panel-content :global(.PageEditorContainer) {
            width: ${compactMode ? '10.0%' : '50%'};
          }
          .panel-content :global(.PageEditor) {
            padding: ${compactMode ? '10px 10px 0' : '10px'};
          }
        }
      `}</style>
    </div>
  );
});

export default SBSortablePage;
