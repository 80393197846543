import { uniq } from 'lodash';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { logEvent } from '../../../../analytics';
import {
  IMainState,
  IStorybook,
  IStorybookVisibility
} from '../../../../interfaces';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import MyTeamsSelection from '../../Teams/MyTeamsSelection';
import SBPopupWrapper from '../Common/SBPopupWrapper';

const SBPublishPopup = ({
  storybook,
  onActivate,
  onClose,
  loading
}: {
  storybook: IStorybook;
  onActivate: ({
    visibility,
    visibilityScope
  }: {
    visibility: IStorybookVisibility;
    visibilityScope: string[];
  }) => void;
  onClose: () => void;
  loading: boolean;
}) => {
  const [sbCurrentState, setSBCurrentState] = React.useState({
    visibility: storybook.visibility,
    visibilityScope: storybook.visibilityScope
  });

  const selectedTeamIds =
    sbCurrentState.visibility === 'team'
      ? sbCurrentState.visibilityScope || []
      : [];

  const visibilityDisplays = {
    private: 'Myself',
    team: 'Team'
  };

  const user = useSelector((state: IMainState) => state.clientState.hub?.user);

  const updateCurrentState = (
    visibility: IStorybookVisibility,
    teamIds?: string[]
  ) => {
    const visibilityScope = (() => {
      switch (visibility) {
        case 'private':
          return [user?.id];
        case 'team':
          return teamIds || [];
        default:
          return [];
      }
    })();

    setSBCurrentState({
      visibility,
      visibilityScope
    });
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      button: `Visibility: ${visibility}`,
      visibilityScope,
      storybookId: storybook.id
    });
  };
  return (
    <SBPopupWrapper onClose={onClose} height="auto">
      <div className="popup-content-container">
        <h3 className="text-center">Activate Storybook</h3>
        <div className="fieldset">
          <label className="text-center">Visibility Settings</label>
          <div className="list-group">
            {Object.keys(visibilityDisplays).map((v) => (
              <span key={v} className="visibility-option">
                <input
                  type="checkbox"
                  name={v}
                  value={v}
                  id={v}
                  checked={sbCurrentState.visibility === v}
                  onChange={() => {
                    updateCurrentState(v as IStorybookVisibility);
                  }}
                />
                <label htmlFor={v}>{visibilityDisplays[v]}</label>
              </span>
            ))}
          </div>
        </div>
        {sbCurrentState.visibility === 'team' && (
          <MyTeamsSelection
            selectedTeams={selectedTeamIds}
            onSelectTeam={(teamId: string) => {
              updateCurrentState('team', uniq([...selectedTeamIds, teamId]));
            }}
            onDeselectTeam={(teamId: string) => {
              updateCurrentState(
                'team',
                selectedTeamIds.filter((id) => id !== teamId)
              );
            }}
          />
        )}
        <div className="buttons">
          <button
            className="btn btn-round btn-cancel"
            onClick={() => {
              onClose();
              logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                button: 'Cancel in popup'
              });
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-round btn-dark btn-publish"
            onClick={() => {
              onActivate(sbCurrentState);
              logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                button: 'Activate in popup'
              });
            }}
            disabled={
              sbCurrentState.visibility === 'team' &&
              selectedTeamIds.length === 0
            }
          >
            {loading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              'Activate'
            )}
          </button>
        </div>
      </div>

      <style jsx>{`
        :global(.PopupContainer .close-container) {
          display: none;
        }

        :global(.PopupContainer .content-container) {
          width: 700px !important;
          background: #000 !important;
          color: #fff;
        }
        .popup-content-container {
          display: flex;
          flex-direction: column;
          align-content: center;
          width: 100%;
          padding: 20px;
        }

        .popup-content-container h3 {
          font-size: 1.25rem;
          margin-bottom: 30px;
        }

        .buttons {
          margin-top: 10px;
          display: flex;
          justify-content: center;
        }

        .buttons .btn {
          margin: 20px 5px;
          padding-left: 20px;
          padding-right: 20px;
        }

        .popup-content-container .btn-cancel {
          border: 2px #fff solid;
          color: #fff;
        }

        .popup-content-container .btn-publish {
          background: #fff;
          color: #000;
        }

        .fieldset > label {
          display: block;
          margin: 0 20px;
          padding: 30px 0;
          border-top: 1px solid #fff;
        }

        .list-group {
          flex-direction: row;
          justify-content: space-around;
          width: 300px;
          margin: 0 auto;
        }

        .list-group input {
          cursor: pointer;
        }

        .list-group label {
          margin: 0;
          padding: 0 10px;
          font-weight: 300;
          transform: translateY(-2px);
        }

        :global(.selection input) {
          background: transparent;
          color: #fff !important;
        }

        :global(.teams-selection svg) {
          fill: #fff;
        }

        @media (max-height: 768px) {
          :global(.PopupContainer.SBPopupWrapper) {
            padding-top: 0 !important;
          }

          .popup-content-container h3 {
            margin-bottom: 10px;
          }

          .fieldset > label {
            padding: 15px 0;
          }

          .buttons {
            margin-top: 0;
          }
        }
      `}</style>
    </SBPopupWrapper>
  );
};

export default SBPublishPopup;
