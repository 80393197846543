import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IFilePageSettings,
  IMainState,
  IPlayerControl,
  MeetingRole
} from '../../interfaces';
import {
  actionRequestUpdatePlayerControl,
  actionSetActivePage,
  actionUpdatePlayerControl
} from '../../redux/actions';
import { isUserOnMobile } from '../../utils/deviceDetector';
import { useMeetingLocalAudio } from '../hooks/meeting';
import VideoViewer from './VideoViewer';
import { useSspState } from '../hooks/common';

const DocumentPageVideo = ({
  autoClose,
  url,
  viewOnly = true,
  playerControl = {},
  setting = {},
  setBuffering,
  buffering,
  silence,
  fullPage
}: {
  autoClose?: boolean;
  url: string;
  viewOnly?: boolean;
  playerControl?: IPlayerControl;
  setting?: IFilePageSettings;
  setBuffering: (buffering: boolean) => void;
  buffering: boolean;
  silence?: boolean;
  fullPage?: boolean;
}) => {
  const dispatch = useDispatch();
  const { videoLoop, autoplay, muteParticipant } = setting || {};
  const isInSSP = useSspState();
  const shouldMuteParticipant = muteParticipant ?? true;
  const { isMuted, restoreAudio, muteAudioAndRestoreLater, activeAudioOutput } =
    useMeetingLocalAudio();

  const [muteVideo, setMuteVideo] = React.useState(true);
  const [allowAutoMute, setAllowAutoMute] = React.useState(
    shouldMuteParticipant
  );
  const meeting = useSelector(
    (state: IMainState) => state.clientState?.meeting
  );
  const meetingState = meeting?.state;
  const isAdvisor = meeting?.localUser?.role === MeetingRole.ADVISOR;
  const [muteDueToError, setMuteDueToError] = React.useState(false);
  const handleUpdatePlayerState = (state: IPlayerControl) => {
    if (isAdvisor || isInSSP) dispatch(actionUpdatePlayerControl(state));
    else dispatch(actionRequestUpdatePlayerControl(state));
  };

  const shouldMuteAudio = !muteDueToError && muteVideo;
  React.useEffect(() => {
    if (
      playerControl?.play &&
      !isMuted &&
      !shouldMuteAudio &&
      allowAutoMute &&
      shouldMuteParticipant
    ) {
      muteAudioAndRestoreLater();
    }

    if (isMuted && (shouldMuteAudio || !playerControl?.play)) {
      restoreAudio();
    }
  }, [meetingState, playerControl?.play, shouldMuteAudio]);

  React.useEffect(() => {
    if (!isMuted && playerControl?.play) {
      setAllowAutoMute(false);
    }
  }, [isMuted]);

  React.useEffect(() => {
    setAllowAutoMute(shouldMuteParticipant);
    setBuffering(false);
  }, [url]);

  React.useEffect(() => {
    setMuteVideo(isUserOnMobile());
    setMuteDueToError(isUserOnMobile());
    return () => {
      restoreAudio();
    };
  }, []);

  const onMute = (mute: boolean, reason?: string) => {
    setMuteVideo(mute);
    if (reason === 'error') {
      setMuteDueToError(true);
    }
  };
  return (
    <VideoViewer
      key={url}
      url={url}
      viewOnly={viewOnly}
      shouldPlay={playerControl?.play}
      seekTo={playerControl?.seekTo}
      currentProgress={playerControl?.currentScene}
      mute={muteVideo || videoLoop}
      onMute={onMute}
      onControl={handleUpdatePlayerState}
      onEnded={() => {
        if (isMuted && shouldMuteParticipant) {
          restoreAudio();
        }
        if (isAdvisor && autoClose) {
          dispatch(actionSetActivePage(null));
        }
      }}
      loop={videoLoop}
      autoplay={autoplay}
      buffering={buffering}
      setBuffering={setBuffering}
      activeAudioOutput={activeAudioOutput}
      silence={silence}
      fullPage={fullPage}
    />
  );
};

export default DocumentPageVideo;
