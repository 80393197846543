import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { isAllowedForFileName } from '../../../../components/VirtualBoutique/Appointment/inputValidator';
import { IFolderContext, IFolderKey } from '../../../../interfaces';
import { actionHubAlertError } from '../../../../redux/actions';
import { updateKeysInFolderAsync } from '../../../../redux/advisorHubAsyncActions';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import {
  COLLABORATED_ITEMS,
  FAVORITE_FOLDER,
  getFolderLabel,
  getSubFolderKeysByParent,
  SHARED_ITEMS,
  SHARED_TEAM_TYPE,
  TRASH_FOLDER
} from '../../../utils/folders';
import PopupContainer from '../PopupContainer';
import FolderNavigation from './FolderNavigation';

const CreateFolderPopup = ({
  context,
  uuid,
  userId,
  onSuccess,
  folderToEdit = 'new',
  onClose,
  folders,
  activeFolderKey,
  expandedFolderKeys,
  hideRenameTo,
  hideMoveTo
}: {
  context: IFolderContext;
  uuid: string;
  userId: string;
  onSuccess?: (id: string) => void;
  folderToEdit?: string;
  onClose?: () => void;
  folders: IFolderKey[];
  activeFolderKey: string;
  expandedFolderKeys: string[];
  hideRenameTo?: boolean;
  hideMoveTo?: boolean;
}) => {
  const isNew = folderToEdit === 'new';
  const dispatch = useDispatch();

  const [selectedFolder, setSelectedFolder] = React.useState({
    activeFolderKey,
    expandedFolderKeys
  });
  const [value, setValue] = React.useState(
    isNew ? '' : getFolderLabel(folders.find((f) => f.id === folderToEdit)?.key)
  );
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const selectFolderKey = (id: string) => {
    const updatedExpandedFolderKeys =
      selectedFolder.expandedFolderKeys.includes(id)
        ? [...selectedFolder.expandedFolderKeys]
        : [...selectedFolder.expandedFolderKeys, id];

    setSelectedFolder({
      activeFolderKey: id,
      expandedFolderKeys: updatedExpandedFolderKeys
    });
  };

  const toggleExpandFolderKey = (id: string) => {
    const updatedExpandedFolderKeys =
      selectedFolder.expandedFolderKeys.includes(id)
        ? selectedFolder.expandedFolderKeys.filter((f) => f !== id)
        : [...selectedFolder.expandedFolderKeys, id];

    setSelectedFolder({
      ...selectedFolder,
      expandedFolderKeys: updatedExpandedFolderKeys
    });
  };

  const currentFolder = folders.find(
    (f) => f.id === selectedFolder.activeFolderKey
  ) || { id: '/', key: '/' };

  const preferredParent = [FAVORITE_FOLDER, TRASH_FOLDER].includes(
    currentFolder?.key
  )
    ? '/'
    : currentFolder?.key;

  const newKey = `${preferredParent}${value}/`;

  const handleNameChange = (val: string) => {
    setValue(val);
    if (isAllowedForFileName(val)) {
      setError('');
    } else {
      setError('Folder name is not allowed');
    }
  };

  const addFolder = () => {
    setLoading(true);

    dispatch(
      updateKeysInFolderAsync({
        keyId: folderToEdit,
        key: newKey,
        userId,
        uuid,
        context,
        operation: isNew ? 'add' : 'edit',
        onSuccess: () => {
          setLoading(false);
          onSuccess?.(newKey);
        },
        onFailed: () => {
          setLoading(false);
          dispatch(
            actionHubAlertError(
              'Something went wrong, please check your internet connection'
            )
          );
        }
      })
    );
  };

  const handleCreate = () => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button: 'Save' });
    if (error?.includes('Folder name is not allowed')) return;
    if (!value?.trim()) {
      setError('Folder name is required');
      setValue('');
    } else if (
      folders
        .map((folder) => folder.key)
        .some(
          (f: string) => f.toLowerCase().trim() === newKey.toLowerCase().trim()
        )
    ) {
      const currentKey = folders.find((f) => f.id === folderToEdit)?.key;
      if (newKey === currentKey) {
        onClose();
      } else if (hideRenameTo)
        dispatch(actionHubAlertError('Folder already exists'));
      else setError('Folder already exists');
    } else {
      setError('');
      addFolder();
    }
  };

  const formAddFolder = (
    <div className="formAddFolder">
      {!hideRenameTo && (
        <>
          <label>{isNew ? 'Folder Name:' : 'Rename to:'}</label>

          <input
            type="text"
            className={`form-control ssp-ignore ${error ? 'is-invalid' : ''}`}
            placeholder="Enter Folder Name"
            value={value || ''}
            onChange={(e) => handleNameChange(e.target.value)}
          />
          {error && <div className="error-text text-semibold">{error}</div>}
        </>
      )}

      <div className="action">
        <button
          className="btn btn-round btn-sm btn-outline-dark"
          onClick={() => {
            onClose();
            logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button: 'Cancel' });
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-round btn-sm btn-dark btn-save-folder"
          onClick={handleCreate}
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
        </button>
      </div>
      <style jsx>{`
        label {
          font-size: 12px;
          font-weight: 600;
          display: block;
          text-align: left;
          margin-top: 10px;
        }
        .error-text {
          height: 16px;
          font-size: 12px;
          color: red;
        }
        .icon-container {
          font-size: 12px;
          margin-bottom: 10px;
          border-radius: 0 0 5px 5px;
          text-align: left;
          padding: 2px 5px;
          background: #efefef;
        }
        .formAddFolder {
          width: 100%;
          text-align: center;
        }
        .form-control {
          margin-bottom: 2px;
        }
        .action {
          margin-top: 8px;
        }
        .btn {
          width: 100px;
          margin: 0 5px;
        }
        h3 {
          font-size: 1.25rem;
          margin-bottom: 0;
        }
      `}</style>
    </div>
  );

  const sharedOnly = currentFolder.key.startsWith(SHARED_ITEMS);
  const folderTrees = (
    <>
      <label>{isNew ? 'Select Parent:' : 'Move to:'}</label>
      <div className="folder-container">
        <FolderNavigation
          folders={folders}
          selectedFolder={selectedFolder.activeFolderKey}
          expandedFolderKeys={selectedFolder.expandedFolderKeys}
          onSelectFolderKey={selectFolderKey}
          toggleExpandFolderKey={toggleExpandFolderKey}
          hideOverview={sharedOnly && !isNew}
          excludeFolders={[
            ...(isNew
              ? [TRASH_FOLDER, FAVORITE_FOLDER, COLLABORATED_ITEMS]
              : [
                  COLLABORATED_ITEMS,
                  TRASH_FOLDER,
                  FAVORITE_FOLDER,
                  ...(sharedOnly ? [] : [SHARED_ITEMS]),
                  folderToEdit,
                  ...(getSubFolderKeysByParent(folderToEdit, folders).map(
                    (f) => f.id
                  ) || [])
                ]),
            ...(folders
              .filter((f) => f.settings?.type === SHARED_TEAM_TYPE)
              ?.map((f) => f.id) || [])
          ]}
        />
      </div>
      <style jsx>{`
        div {
          border: 1px solid #ccc;
          width: 100%;
          max-height: calc(100vh - 300px);
          min-height: 200px;
          overflow: auto;
          border-radius: 5px 5px 0 0;
          padding: 20px 0;
        }
        label {
          font-size: 12px;
          font-weight: 600;
          display: block;
          text-align: left;
          margin-top: 10px;
        }
      `}</style>
    </>
  );

  return (
    <PopupContainer maxWidth="350px">
      {!hideMoveTo && folderTrees}
      {formAddFolder}
    </PopupContainer>
  );
};

export default CreateFolderPopup;
