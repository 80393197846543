import { last } from 'lodash';
import React from 'react';
import {
  IMainState,
  IStorybook,
  MeetingLayoutMode,
  MeetingRole,
  StoryBookLayoutPage
} from '../../../interfaces';
import FileUploadProgressPanel from '../Common/FileUploader/FileUploadProgressPanel';
import { useDispatch, useSelector } from 'react-redux';
import { mapStorybookToDocument } from '../../../mappers/storybook';
import {
  actionRequestToBePresenter,
  actionSetActiveFile,
  actionSetActivePage,
  actionSetFileController,
  actionSetMeetingLayoutState,
  actionSetStorybook
} from '../../../redux/actions';
import Layout from '../Layout';
import SBDesignerPolotno from './Builder/SBBuilderPolotno';
import SBPopupWrapper from './Common/SBPopupWrapper';
import SBPreview from './Preview';
import { IStorybookLayoutMode, StoryBookContext } from './SBContextContainer';
import SBSearch from './Search';
import SBSorter from './Sorter';
import { wrapActionToBeMirroring } from '../../../utils/meeting';
import { getInitStorybookPageForDocumentViewer } from '../../utils/storybook';
import { useLocalUserMeetingEntitlement } from '../../../components/hooks/meeting';
import { useSspState } from '../../../components/hooks/common';
import { getActivatedStorybookById } from '../../clientSideServices/storybook';

export interface FolderSelectionState {
  selected: string;
  expanded: string[];
}

const StoryBook = React.memo(({
  onSelect,
  onClose,
  initPage,
  selectedStorybooks,
  layoutMode,
  brandId
}: {
  onSelect?: (storybook: string[]) => void;
  onClose?: () => void;
  initPage?: StoryBookLayoutPage;
  selectedStorybooks?: string[];
  layoutMode?: IStorybookLayoutMode;
  brandId?: string;
}) => {
  const { state, action, entitlement } = React.useContext(StoryBookContext);
  const pageIsReady = entitlement.shouldShowActivatedOnly !== undefined;
  const [_, isSourceOfMirrorAction] = useLocalUserMeetingEntitlement();
  const [selected, setSelected] = React.useState<string[]>(selectedStorybooks || []);
  const [keywords, setKeywords] = React.useState('');
  const [latestFolderKey, setLatestFolderKey] = React.useState(null);
  const currentPage = initPage || last(state.pageHistory);
  const isInSSP = useSspState();

  const handleSelect = (storybookId: string) => {
    if (selected.includes(storybookId)) {
      setSelected(selected.filter((id) => id !== storybookId));
    } else {
      setSelected([...selected, storybookId]);
    }
  };

  const handleClosePage = (myPage: StoryBookLayoutPage) => {
    if (initPage === myPage) {
      onClose();
    } else {
      action.navigate();
    }
  };

  const handleAddSelected = () => {
    onSelect(selected);
    onClose();
  };
  const isBrowseStorybookInMeeting = useSelector(
    (state: IMainState) => state.clientState.isBrowseStorybookInMeeting
  );
  const meeting = useSelector(
    (state: IMainState) => state.clientState?.meeting
  );
  const remoteUsers = meeting?.remoteUsers || {};

  const totalParticipants = React.useMemo(
    () =>
      Object.keys(remoteUsers).filter(
        (participantId) =>
          remoteUsers[participantId]?.shouldShowVideo &&
          remoteUsers[participantId]?.role !== MeetingRole.STUDIO
      ).length + 1,
    [remoteUsers]
  );
  const dispatch = useDispatch();
  const handlePresenterSelectStorybook = async (sb: IStorybook) => {
    if (!isBrowseStorybookInMeeting) {
      handleSelect(sb.id);
      return;
    }
    const participantId = meeting?.localUser?.participantId;
    if (totalParticipants > 3 && !meeting?.layout?.presenterIds?.length) {
      wrapActionToBeMirroring(
        dispatch,
        actionSetMeetingLayoutState(
          {
            mode: MeetingLayoutMode.NORMAL,
            presenterIds: [participantId]
          },
          true
        ),
        { to: '' }
      );
    }
    dispatch(actionSetActiveFile(sb.id));
    dispatch(actionSetFileController(participantId));
    dispatch(
      actionSetActivePage(getInitStorybookPageForDocumentViewer(sb))
    );

    onClose();

    try {
      const storybook = await getActivatedStorybookById(sb.id);
      dispatch(actionSetStorybook(mapStorybookToDocument(storybook)));
      dispatch(
        actionSetActivePage(getInitStorybookPageForDocumentViewer(storybook))
      );
    } catch (error) {
      dispatch(
        actionSetActivePage(null)
      );
    }
  };

  const handleParticipantSelectStorybook = async (sb: IStorybook) => {
    if (!isBrowseStorybookInMeeting) {
      handleSelect(sb.id);
      return;
    }
    try {
      const storybook = await getActivatedStorybookById(sb.id);
      const file = mapStorybookToDocument(storybook);
      dispatch(actionSetStorybook(file));
      dispatch(actionRequestToBePresenter(file));
      onClose();
    } catch (error) {
      onClose();
    }
  };

  const handleSelectStorybook =
    isSourceOfMirrorAction || isInSSP
      ? handlePresenterSelectStorybook
      : handleParticipantSelectStorybook;

  const onSelectProp = onSelect ? handleSelectStorybook : undefined;

  const getPage = () => {
    switch (currentPage) {
      case StoryBookLayoutPage.DESIGNER:
        return <SBDesignerPolotno />;
      case StoryBookLayoutPage.PREVIEW:
        return (
          <SBPreview
            onSelect={onSelectProp}
            selectedStorybooks={selected}
            onClose={() => handleClosePage(StoryBookLayoutPage.PREVIEW)}
            previewMode={layoutMode?.previewMode}
            storybook={state?.storybook}
          />
        );
      case StoryBookLayoutPage.SORTER:
        return <SBSorter />;
      default:
        return (
          <SBSearch
            onSelect={onSelectProp}
            onAddSelected={onSelect ? handleAddSelected : undefined}
            onClose={onClose}
            selectedStorybooks={selected}
            keywords={keywords}
            setKeywords={setKeywords}
            initFolderKey={latestFolderKey}
            setLatestFolderKey={setLatestFolderKey}
            brandId={brandId}
            isBrowseStorybookInMeeting={isBrowseStorybookInMeeting}
          />
        );
    }
  };

  React.useEffect(() => {
    action.setLayoutMode(
      layoutMode || {
        previewMode: 'default',
        popupMode: false,
        viewOnlyMode: false,
        selectMode: false
      }
    );
    return () => {
      action.resetState();
    };
  }, []);

  if (!pageIsReady) return null;
  return (
    <>
      {entitlement.popupMode ? (
        <SBPopupWrapper width="90%" height="85%" maxWidth="none">
          <div className="StoryBook Storybook-Popup h-100 w-100">
            {getPage()}
          </div>
        </SBPopupWrapper>
      ) : (
        <Layout showNav={false} theme="dark-light" className="StoryBook">
          {getPage()}
        </Layout>
      )}
      <FileUploadProgressPanel />
      <style jsx global>{`
        * {
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }

        input,
        textarea {
          -webkit-touch-callout: all;
          -webkit-user-select: all;
          -khtml-user-select: all;
          -moz-user-select: all;
          -ms-user-select: all;
          user-select: all;
        }
        .StoryBook h2 {
          font-size: 1.4em;
          margin-bottom: 0;
        }
        .StoryBook .form-control:disabled {
          background: #fff;
          border-color: #efefef;
        }
        .StoryBook input[type='color'] {
          padding: 0 !important;
        }

        .StoryBook .content-wrapper:hover {
          z-index: 2;
        }
        .Storybook-Popup {
          padding: 0 20px;
        }

        @media (max-width: 1400px) {
          .StoryBook h2 {
            font-size: 1em;
          }
        }
      `}</style>
    </>
  );
});

export default StoryBook;
