import React, { useEffect, useState } from 'react';
import {
  IStorybook,
  LoadingStatus,
  StoryBookLayoutPage
} from '../../../../interfaces';
import LoadingPlaceHolder from '../../Common/LoadingPlaceHolder';
import { StoryBookContext } from './../SBContextContainer';
import SBPreviewDefault from './SBPreviewDefault';
import SBPreviewDocumentViewer from './SBPreviewDocumentViewer';
import SBPreviewHeader from './SBPreviewHeader';
import { first, uniqBy } from 'lodash';
import { TDeviceView } from '../../Common/DeviceViewToggle';
import SBPreviewMicrosite from './SBPreviewMicrosite';
import { TLanguage } from '../../../../mappers/polotno';
import { mapBrowerLanguageToSBLanguage } from '../../../../utils/window';

export type SBPreviewType = 'default' | 'presentation' | 'microsite';

const SBPreview = ({
  onSelect,
  onClose,
  selectedStorybooks,
  previewMode,
  storybook,
  isForScene,
  actionLabel,
  hideInfo,
  hidePreviewList,
  title
}: {
  onSelect?: (storybook: IStorybook) => void;
  onClose?: () => void;
  selectedStorybooks?: string[];
  previewMode?: SBPreviewType;
  storybook?: IStorybook;
  isForScene?: boolean;
  actionLabel?: {
    ok: string;
    cancel: string;
  };
  hideInfo?: boolean;
  hidePreviewList?: boolean;
  title?: string;
}) => {
  const { state } = React.useContext(StoryBookContext);

  const [activateLanguage, setActiveLanguage] = useState<TLanguage>('en');
  const [availableList, setAvailableList] = useState<TLanguage[]>([]);
  const [browserLanguage, setBrowerLanguage] = useState<TLanguage>('en');
  useEffect(() => {
    setBrowerLanguage(mapBrowerLanguageToSBLanguage(navigator.language));
  }, []);
  useEffect(() => {
    let layers = [];
    storybook.pages.map((p) => {
      const layer = JSON.parse(p.settings)?.layer || {};
      const desktopLayers = Object.keys(layer).filter((key) =>
        key.includes('desktop')
      );
      if (desktopLayers.length) {
        const temp = [...layers];
        temp.push(
          ...desktopLayers.map((l) =>
            l === 'desktop' ? 'en' : l.replace('desktop-', '')
          )
        );
        layers = uniqBy(temp, (data) => data);
      }
    });
    setAvailableList(layers);
  }, [storybook.pages]);
  const showPreviewList: boolean =
    previewMode || first(state?.pageHistory) === StoryBookLayoutPage.DESIGNER
      ? true
      : false;
  const [previewType, setPreviewType] = React.useState<SBPreviewType>(
    previewMode || (showPreviewList ? 'default' : 'microsite')
  );

  const [deviceView, setDeviceView] = React.useState<TDeviceView>('desktop');

  const [asHost, setAsHost] = React.useState(true);

  React.useEffect(() => {
    const keydownListener = (e) => {
      if (e.key === 'Escape') {
        onClose?.();
      }
    };
    window.addEventListener('keydown', keydownListener);
    return () => {
      window.removeEventListener('keydown', keydownListener);
    };
  }, []);

  const loading = (
    <LoadingPlaceHolder loadingStatus={state.loadingStatus[storybook.id]}>
      <div className="loading-page text-center p-10">
        <div>
          <p>Failed to load Storybook</p>
          <button className="btn btn-sm btn-round btn-dark" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
      <style jsx>{`
        .loading-page {
          display: flex;
          align-items: center;
        }
        .loading-page > div {
          margin: auto;
        }
      `}</style>
    </LoadingPlaceHolder>
  );

  const emptyPage = (
    <div className="empty-page text-center p-10 h-100">
      <div>
        <p>This Storybook is empty.</p>
        <button className="btn btn-sm btn-round btn-dark" onClick={onClose}>
          Back
        </button>
      </div>
      <style jsx>{`
        .empty-page {
          display: flex;
          align-items: center;
        }
        .empty-page > div {
          margin: auto;
        }
      `}</style>
    </div>
  );

  const placeHolder =
    state.loadingStatus[storybook.id] !== LoadingStatus.LOADING
      ? emptyPage
      : loading;

  const preview = (
    <>
      {previewType === 'default' && (
        <SBPreviewDefault
          activateLanguage={activateLanguage}
          storybook={storybook}
          deviceView={deviceView}
        />
      )}
      {previewType === 'presentation' && (
        <SBPreviewDocumentViewer
          storybook={storybook}
          deviceView={deviceView}
          asHost={asHost}
          activateLanguage={browserLanguage}
        />
      )}
      {previewType === 'microsite' && (
        <SBPreviewMicrosite
          storybook={storybook}
          activateLanguage={activateLanguage}
          deviceView={deviceView}
        />
      )}
    </>
  );

  const previewList = hidePreviewList ? false : showPreviewList;

  return !storybook?.pages?.length ? (
    placeHolder
  ) : (
    <div className="SBPreview">
      <SBPreviewHeader
        activeLanguage={activateLanguage}
        availableList={availableList}
        onSelectLanguage={setActiveLanguage}
        previewType={previewType}
        setPreviewType={setPreviewType}
        onSelect={onSelect}
        onClose={onClose}
        selectedStorybooks={selectedStorybooks}
        storybook={storybook}
        sessionPreviewMode={!!previewMode}
        showPreviewList={previewList}
        isForScene={isForScene}
        actionLabel={actionLabel}
        hideInfo={hideInfo}
        deviceView={{ onSelectView: setDeviceView, activeView: deviceView }}
        hostView={{ onSelectView: setAsHost, activeView: asHost }}
        title={title}
      />
      <div className="content-container w-100">{preview}</div>
      <style jsx global>{`
        .StoryBook .content-wrapper {
          padding: 0 !important;
          background: #fff;
        }
        .SBPopupWrapper .content-container,
        .Storybook-Popup {
          // padding: 0 !important;
        }
      `}</style>
      <style jsx>{`
        .content-container {
          height: calc(100% - ${previewList ? 62 : 0}px);
        }
        .SBPreview {
          height: 100%;
          display: flex;
          flex-direction: column;
        }
      `}</style>
    </div>
  );
};

export default SBPreview;
