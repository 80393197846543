import React from 'react';
import { logEvent } from './../../analytics';
import { IMAGE_ERROR } from './../../utils/constants';
import { IFilePageSettings } from '../../interfaces';

const ImageViewer = ({
  url,
  onLoad,
  portraitContent,
  setting
}: {
  url: string;
  onLoad?: () => void;
  portraitContent?: boolean;
  setting?: IFilePageSettings;
}) => {
  const [canLoadPortrait, setCanLoadPortrait] = React.useState(true);
  const portraitUrl =
    setting?.portraitUrl || url.replace('scene.jpeg', 'scene_portrait.jpeg');
  const imageUrl = portraitContent && canLoadPortrait ? portraitUrl : url;

  const handleImageError = (e) => {
    logEvent(IMAGE_ERROR, IMAGE_ERROR, {
      imageUrl: url,
      error: `${e.type} load image`
    });
    if (portraitContent) {
      setCanLoadPortrait(false);
    }
  };

  return (
    <>
      <img
        src={imageUrl}
        className="doc img-document"
        onLoad={onLoad}
        onError={(e) => {
          handleImageError(e);
        }}
      />

      <style jsx>{`
        .img-document {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: top;
        }
      `}</style>
    </>
  );
};

export default ImageViewer;
