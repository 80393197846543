import React from 'react';
import { HubContext } from '../../HubContext';
import SBSearch from '../Search';
import { ContentManagerTabProps } from './ContentManagerPopup';

const ContentTabStorybook = ({
  selectedItems,
  toggleSelect,
  keywords,
  onPreview
}: ContentManagerTabProps) => {
  const { brandId } = React.useContext(HubContext);
  return (
    <SBSearch
      onSelect={toggleSelect}
      keywords={keywords}
      selectedStorybooks={selectedItems}
      onView={onPreview}
      initFolderKey="/"
      brandId={brandId}
    />
  );
};

export default ContentTabStorybook;
