import React from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

export default function LoaderButton({
  isLoading,
  className = '',
  disabled = false,
  ...props
}) {
  return (
    <>
      <Button
        disabled={disabled || isLoading}
        className={`LoaderButton ${className}`}
        {...props}
      >
        {isLoading ? (
          <Spinner className="spinner" size="sm" animation="border" />
        ) : (
          props.children
        )}
      </Button>
      <style jsx>{`
        .LoaderButton .spinner {
          margin-right: 7px;
          top: 2px;
        }
      `}</style>
    </>
  );
}
