import React from 'react';
import { logClickButton } from '../../../../analytics';
import { IFolderKey } from '../../../../interfaces';
import {
  COLLABORATED_ITEMS,
  FAVORITE_FOLDER,
  getFolderKeyPathName,
  getSubFolderKeysByParent,
  SHARED_ITEMS,
  TRASH_FOLDER
} from '../../../utils/folders';
import FolderButton, { getFolderIcon } from './FolderButton';
import FolderTrees from './FolderTrees';

export const FolderPath = ({ path }: { path: string }) => (
  <div className="folder-path">
    {getFolderIcon(path)}
    {getFolderKeyPathName(path)}
    <style jsx>{`
      .folder-path {
        font-size: 12px;
        letter-spacing: 0.5px;
        display: flex;
        align-items: end;
        margin-left: 10px;
        margin-bottom: 2px;
      }
      .folder-path :global(svg) {
        width: 16px;
        height: auto;
        margin-right: 3px;
      }
    `}</style>
  </div>
);

const FolderNavigation = ({
  onSelectFolderKey,
  selectedFolder = '/',
  folders,
  expandedFolderKeys = [],
  toggleExpandFolderKey,
  title = {},
  excludeFolders = [],
  onAddNewFolder,
  hideOverview,
  onDrop
}: {
  selectedFolder?: string;
  onSelectFolderKey: (id: string) => void;
  folders: IFolderKey[];
  expandedFolderKeys?: string[];
  toggleExpandFolderKey: (id: string) => void;
  title?: {
    mainTitle?: string;
    trashTitle?: string;
    favoritesTitle?: string;
    rootTitle?: string;
    sharedTitle?: string;
    collaboratedTitle?: string;
  };
  excludeFolders?: string[];
  onAddNewFolder?: () => void;
  hideOverview?: boolean;
  onDrop?: (fId: string, transferData: DataTransfer) => void;
}) => {
  const {
    mainTitle,
    trashTitle,
    favoritesTitle,
    rootTitle,
    sharedTitle,
    collaboratedTitle
  } = title;
  const handleSelectFolderKey = (id: string) => {
    onSelectFolderKey(id);
  };

  const overViewFolders = folders.filter(
    (f) =>
      ![
        TRASH_FOLDER,
        FAVORITE_FOLDER,
        SHARED_ITEMS,
        COLLABORATED_ITEMS
      ].includes(f.key)
  );

  const trashFolder = folders.find((f) => f.key === TRASH_FOLDER);
  const favoriteFolder = folders.find((f) => f.key === FAVORITE_FOLDER);
  const sharedFolder = folders.find((f) => f.key === SHARED_ITEMS);
  const collaboratedFolder = folders.find((f) => f.key === COLLABORATED_ITEMS);

  return (
    <div className="FolderNavigation">
      {mainTitle && <h4>{mainTitle}</h4>}
      {onAddNewFolder && (
        <div className="btn-container">
          <button
            className="btn btn-round btn-create-folder btn-sm btn-outline-dark"
            onClick={() => {
              onAddNewFolder();
              logClickButton('Create Folder');
            }}
          >
            Create New Folder
          </button>
        </div>
      )}
      {!excludeFolders.includes(TRASH_FOLDER) &&
        trashFolder &&
        onAddNewFolder && (
          <FolderButton
            label={trashTitle || 'Deleted'}
            id={trashFolder?.id}
            selected={selectedFolder === trashFolder?.id}
            onClick={() => handleSelectFolderKey(trashFolder?.id)}
            icon={getFolderIcon(trashFolder?.key)}
            isDroppable={false}
          />
        )}
      {!excludeFolders.includes(FAVORITE_FOLDER) && favoriteFolder && (
        <FolderButton
          label={favoritesTitle || 'Favorites'}
          id={favoriteFolder?.id}
          selected={selectedFolder === favoriteFolder?.id}
          onClick={() => handleSelectFolderKey(favoriteFolder?.id)}
          icon={getFolderIcon(favoriteFolder?.key)}
          isDroppable={false}
        />
      )}
      {!excludeFolders.includes(SHARED_ITEMS) && sharedFolder && (
        <>
          <FolderButton
            label={sharedTitle || 'Shared With Me'}
            id={sharedFolder?.id}
            selected={selectedFolder === sharedFolder?.id}
            onClick={() => handleSelectFolderKey(sharedFolder?.id)}
            icon={getFolderIcon(sharedFolder?.key)}
            isDroppable={false}
          />
          <FolderTrees
            folders={getSubFolderKeysByParent(sharedFolder.id, folders)?.filter(
              (f) => !excludeFolders.includes(f.id)
            )}
            level={2}
            selectedFolder={selectedFolder}
            onClickFolder={handleSelectFolderKey}
            expandedFolderKeys={expandedFolderKeys}
            toggleExpandFolderKey={toggleExpandFolderKey}
            isDroppable={false}
          />
        </>
      )}

      {!excludeFolders.includes(COLLABORATED_ITEMS) && collaboratedFolder && (
        <>
          <FolderButton
            label={collaboratedTitle || 'Collaborations'}
            id={collaboratedFolder?.id}
            selected={selectedFolder === collaboratedFolder?.id}
            onClick={() => handleSelectFolderKey(collaboratedFolder?.id)}
            icon={getFolderIcon(collaboratedFolder?.key)}
            isDroppable={false}
          />
          <FolderTrees
            folders={getSubFolderKeysByParent(
              collaboratedFolder.id,
              folders
            )?.filter((f) => !excludeFolders.includes(f.id))}
            level={2}
            selectedFolder={selectedFolder}
            onClickFolder={handleSelectFolderKey}
            expandedFolderKeys={expandedFolderKeys}
            toggleExpandFolderKey={toggleExpandFolderKey}
            isDroppable={false}
          />
        </>
      )}

      {!hideOverview && (
        <>
          <FolderButton
            label={rootTitle || 'Overview'}
            id="/"
            selected={selectedFolder === '/'}
            onClick={() => handleSelectFolderKey('/')}
            onDrop={onDrop}
          />
          <FolderTrees
            folders={overViewFolders.filter(
              (f) => !excludeFolders.includes(f.id)
            )}
            selectedFolder={selectedFolder}
            onClickFolder={handleSelectFolderKey}
            expandedFolderKeys={expandedFolderKeys}
            toggleExpandFolderKey={toggleExpandFolderKey}
            onDrop={onDrop}
          />
        </>
      )}

      <style jsx>{`
        h4 {
          font-size: 13px;
          font-weight: 600;
          padding: 20px 30px 5px;
        }
        .btn-container {
          padding: 10px 30px 15px;
        }
        .btn-container .btn {
          width: 100%;
        }
      `}</style>
    </div>
  );
};

export default FolderNavigation;
