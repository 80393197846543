import React from 'react';
import { logEvent } from '../../../../analytics';
import { IStorybook } from '../../../../interfaces';
import {
  DID_CLICK_BUTTON,
  DID_SHARE_STORYBOOK,
  SHARE_STORYBOOK_FAILED
} from '../../../../utils/constants';
import { getSimpleShortenedUrl } from '../../../../utils/shareUrl';
import { performNativeShare } from '../../../../utils/window';
import { getBrandStorybookHost } from '../../../../config/index';

const SBShareButton = ({
  storybook,
  onShare
}: {
  storybook: IStorybook;
  onShare: (arg: { url: string; message: string; id: string }) => void;
}) => {
  const logShare = (
    url: string,
    channel: string,
    message: string,
    storybookId: string
  ) => {
    logEvent(DID_SHARE_STORYBOOK, channel, {
      storybookId,
      shareUrl: url,
      message
    });
  };

  const performShare = (url: string, storybook: IStorybook) => {
    const onNativeShare = () => {
      logShare(url, 'native', storybook.title, storybook.id);
    };
    const onFailedNativeShare = () => {
      onShare({
        url,
        message: storybook.title,
        id: storybook.id
      });
    };
    performNativeShare(
      {
        url
      },
      onNativeShare,
      onFailedNativeShare
    );
  };

  const generateShareUrl = async (id: string, brandId: string) => {
    const host = getBrandStorybookHost(brandId);
    const storybookUrl = `${host}/storybook/${id}`;
    if (typeof getSimpleShortenedUrl === 'function') {
      return getSimpleShortenedUrl(storybookUrl, host)
        .then((url) => ({
          original: storybookUrl,
          shortened: url
        }))
        .catch(() => ({
          original: storybookUrl,
          shortened: ''
        }));
    } else {
      return {
        original: storybookUrl,
        shortened: ''
      };
    }
  };

  return (
    <div
      className="list-group-item list-group-item-action"
      id={`sb-${storybook.id}`}
      onClick={() => {
        logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
          button: 'Share Published Storybook',
          storybookId: storybook.id
        });
        generateShareUrl(storybook.id, storybook.brand)
          .then((url) => {
            performShare(url.shortened || url.original, storybook);
          })
          .catch((e) =>
            logEvent(SHARE_STORYBOOK_FAILED, SHARE_STORYBOOK_FAILED, {
              error: e
            })
          );
      }}
    >
      Share
    </div>
  );
};

export default SBShareButton;
