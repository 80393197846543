import React from 'react';
import ConfirmationPopup from '../../Common/ConfirmationPopup';

const SLDescriptionPopup = ({
  popupTitle,
  name,
  description,
  onClose,
  onSave,
  onUpdate,
  loading,
  isTemplate,
  okLabel = "Save",
  closeLabel = "Cancel"
}: {
  popupTitle?: string;
  name?: string;
  description?: string;
  onSave: () => void;
  onClose: () => void;
  onUpdate: (data: { name?: string; description?: string }) => void;
  loading?: boolean;
  isTemplate?: boolean;
  okLabel?: string;
  closeLabel?: string;
}) => {
  const [error, setError] = React.useState(false);

  const handleSave = () => {
    if (name?.trim()) {
      onSave();
    } else {
      setError(true);
    }
  };

  return (
    <ConfirmationPopup
      onOk={handleSave}
      okLabel={okLabel}
      closeLabel={closeLabel}
      onClose={onClose}
      title={popupTitle}
      loading={loading}
      zIndex={1000}
    >
      <div className="content">
        <div className="form-container text-left">
          <div className="fieldset">
            <label>{isTemplate ? 'Template' : 'Scene'} Name</label>
            <input
              className={`form-control form-control-sm ${
                error ? 'is-invalid' : ''
              }`}
              type="text"
              value={name || ''}
              onChange={(e) => {
                onUpdate({ name: e.target.value });
                setError(false);
              }}
            />
          </div>
          <div className="fieldset">
            <label>Description</label>
            <textarea
              className="form-control form-control-sm"
              value={description || ''}
              onChange={(e) => {
                onUpdate({ description: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .form-container {
          margin-bottom: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #ccc;
        }
        .fieldset {
          margin-top: 10px;
        }
        .form-container label {
          font-weight: 500;
        }
        textarea {
          height: 74px;
        }
        .content {
          margin-bottom: 30px;
        }
        .form-container > div {
          margin: 10px 0;
        }
        .error {
          height: 10px;
          color: red;
          font-size: 0.8em;
        }
      `}</style>
    </ConfirmationPopup>
  );
};

export default SLDescriptionPopup;
