import React from 'react';
import { Icon } from '@blueprintjs/core';
import { logEvent } from '../../../analytics';

export type TDeviceView = 'desktop' | 'mobile' | 'mobile-landscape' | 'compare';

const DID_SELECT_DEVICE_PREVIEW = 'DID_SELECT_DEVICE_PREVIEW';

export interface DeviceViewProps {
  onSelectView: (view: TDeviceView) => void;
  activeView: TDeviceView;
  disabledView?: TDeviceView[];
}

const DeviceViewToggle = ({
  onSelectView,
  activeView,
  disabledView
}: DeviceViewProps) => {
  const isDisabled = (view: TDeviceView) => disabledView?.includes(view);

  const handleSelectView = (view: TDeviceView) => {
    onSelectView(view);
    logEvent(DID_SELECT_DEVICE_PREVIEW, DID_SELECT_DEVICE_PREVIEW, {
      deviceView: view
    });
  };
  return (
    <div className="btn-group device-view-toggle">
      {!isDisabled('desktop') && (
        <button
          className={`btn btn-round btn-sm ${
            activeView === 'desktop' ? 'btn-secondary' : 'btn-light'
          }`}
          onClick={() => handleSelectView('desktop')}
        >
          <Icon icon="desktop" />
        </button>
      )}
      {!isDisabled('mobile') && (
        <button
          className={`btn  btn-round btn-sm ${
            activeView === 'mobile' ? 'btn-secondary' : 'btn-light'
          }`}
          onClick={() => handleSelectView('mobile')}
        >
          <Icon icon="mobile-phone" />
        </button>
      )}

      {!isDisabled('compare') && (
        <button
          className={`btn  btn-round btn-sm ${
            activeView === 'compare' ? 'btn-secondary' : 'btn-light'
          }`}
          onClick={() => handleSelectView('compare')}
        >
          <span>
            <Icon icon="desktop" />
            <Icon icon="mobile-phone" />
          </span>
        </button>
      )}

      <style jsx>{`
        .btn {
          width: 80px;
          border-color: #bbb !important;
          color: #666;
        }
        .btn-secondary {
          background: #bbb;
          color: #000;
        }
      `}</style>
    </div>
  );
};

export default DeviceViewToggle;
