import React from 'react';
import { logEvent } from '../../../analytics';
import { TLanguage } from '../../../mappers/polotno';

const DID_SELECT_LANGUAGE_PREVIEW = 'DID_SELECT_LANGUAGE_PREVIEW';

export interface LanguageSwitchProps {
  onSelectLanguage: (language: TLanguage) => void;
  activeLanguage: TLanguage;
  availableList: TLanguage[];
}

const LanguageSwitch = ({
  onSelectLanguage,
  activeLanguage,
  availableList
}: LanguageSwitchProps) => {
  const handleSelectLanguage = (language: TLanguage) => {
    onSelectLanguage(language);
    logEvent(DID_SELECT_LANGUAGE_PREVIEW, DID_SELECT_LANGUAGE_PREVIEW, {
      language
    });
  };

  return (
    <div className="btn-group language-switcher">
      <div className="language-list">
          {availableList.map((language) => (
            <div className={`language-item ${language === (activeLanguage || 'en') ? 'language-item--selected' : ''}`} key={language} onClick={() => handleSelectLanguage(language)}>
               <img src={`/asset/flag/${language}.svg`} />
              <span>{language}</span>
            </div>
          ))}
        </div>
      <style jsx>{`
         .language-list {
          display: flex;
        }
        .language-item {
          margin-right: 15px;
          display: flex;
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #FFF;
          cursor: pointer;
        }
        .language-item--selected {
          border-color: #D8D8D8;
          background: #F3F3F3;
        }
        .language-item:hover {
          border-color: #D8D8D8;
          background: #F3F3F3;
        }
        .language-item span{
          margin: auto;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 12px;
        }
        .language-item img {
          margin: auto;
          margin-right: 5px;
        }
      `}</style>
    </div>
  );
};

export default LanguageSwitch;
