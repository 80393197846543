import React from 'react';
import { Spinner } from 'react-bootstrap';
import { logClickButton } from '../../../../analytics';
import { useCollaborations } from '../../../../components/hooks/collaborator';
import { ILibraryScene } from '../../../../interfaces';
import { hasActiveVersion } from '../../../utils/library';
import { removeSceneFromLocalStorage } from '../../../utils/scene';
const SLThumbnailMenu = ({
  viewOnly,
  scene,
  onDelete,
  onClose,
  onShare,
  onEdit,
  onToggleFavorite,
  onAddToFolder,
  isFavorited,
  onClone,
  onPreview,
  isCollaboration,
  onReload,
  onInsight,
  onTransferOwnership
}: {
  viewOnly?: boolean;
  scene: ILibraryScene;
  onDelete: (id: string) => void;
  onClose: () => void;
  onShare?: (scene: ILibraryScene) => void;
  onEdit: (id: string) => void;
  onToggleFavorite: (id: string, add: boolean) => void;
  onAddToFolder: (id: string) => void;
  onInsight: (id: string) => void;
  isFavorited?: boolean;
  onClone?: (id: string) => void;
  onPreview?: (id: string) => void;
  isCollaboration?: boolean;
  onReload?: (fresh?: boolean, teamId?: string) => void;
  onTransferOwnership?: (scene: ILibraryScene) => void;
}) => {
  const { removeAllCollaborators } = useCollaborations({
    itemId: scene.id,
    context: 'scene',
    owner: scene.createdBy
  });

  const [loading, setLoading] = React.useState(false);

  const btnProps = {
    className: `list-group-item list-group-item-action`
  };

  const { availableVersions } = scene || {};
  const hasActivated = hasActiveVersion(availableVersions);
  const onClick = (button: string, closeAfterClick: boolean) => {
    logClickButton(button);
    closeAfterClick && onClose();
  };

  const handleDelete = () => {
    setLoading(true);
    const fallback = () => {
      setLoading(false);
      onDelete(scene.id);
      onClick('Delete', true);
      removeSceneFromLocalStorage(scene.id);
      isCollaboration && onReload(true);
    };
    removeAllCollaborators({
      itemId: scene.id,
      onSuccess: fallback,
      onFailed: fallback
    });
  };

  const handleEdit = () => {
    onEdit(scene.id);
    onClick('Edit', true);
  };

  const favoriteLabel = isFavorited
    ? 'Remove from Favorites'
    : 'Add to Favorites';

  const handleToggleFavorite = () => {
    onToggleFavorite(scene.id, !isFavorited);
    onClick(favoriteLabel, true);
  };

  const handleAddToFolder = () => {
    onAddToFolder(scene.id);
    onClick('Move to Folder', true);
  };
  const handleClone = () => {
    onClone?.(scene.id);
    onClick('Clone Scene', true);
  };

  return (
    <div className="control-menu text-left">
      {!viewOnly && (
        <button {...btnProps} onClick={handleToggleFavorite}>
          {favoriteLabel}
        </button>
      )}
      {(!viewOnly || isCollaboration) && (
        <button {...btnProps} onClick={handleEdit}>
          Edit
        </button>
      )}
      {onInsight && (
        <button {...btnProps} onClick={() => onInsight(scene.id)}>
          Insight
        </button>
      )}
      {onPreview && (
        <button {...btnProps} onClick={() => onPreview(scene.id)}>
          Preview
        </button>
      )}
      {!isCollaboration && (
        <>
          <button {...btnProps} onClick={handleClone}>
            Clone
          </button>
          <button {...btnProps} onClick={handleAddToFolder}>
            Move to Folder
          </button>
        </>
      )}
      {!viewOnly && (
        <button
          {...btnProps}
          onClick={() => {
            onTransferOwnership(scene);
            onClose();
          }}
        >
          Transfer Ownership
        </button>
      )}
      {hasActivated && onShare && (
        <button {...btnProps} onClick={() => onShare(scene)}>
          Share
        </button>
      )}

      {!viewOnly && (
        <button {...btnProps} onClick={handleDelete}>
          {loading ? <Spinner size="sm" animation="border" /> : 'Delete'}
        </button>
      )}
    </div>
  );
};

export default SLThumbnailMenu;
