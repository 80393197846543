import React from 'react';
import { FaTimes } from 'react-icons/fa';
import UserTeamSelector from '../../../../components/Common/UserTeamSelector';
import { isAllowedForTagName } from '../../../../components/VirtualBoutique/Appointment/inputValidator';
import { useAllTeams } from '../../hooks/teams';

interface ISBVisibilityTagsSetting {
  teamIds: string[];
  tags: string[];
  addTeam: (teamId: string) => void;
  removeTeam: (teamId: string) => void;
  addTag: (tag: string) => void;
  removeTag: (tag: string) => void;
  canModify?: boolean;
  uniqueId: string;
}

const SBVisibilityTagsSetting = ({
  teamIds,
  tags,
  addTeam,
  removeTeam,
  addTag,
  removeTag,
  canModify,
  uniqueId
}: ISBVisibilityTagsSetting) => {
  const [selectedTeamId, setSelectedTeamId] = React.useState('default');
  const [tagInput, setTagInput] = React.useState('');
  const { isLoading, data, error } = useAllTeams();
  React.useEffect(() => {
    setSelectedTeamId('default');
  }, [uniqueId]);
  const teamSelection = () => {
    if (isLoading) {
      return <p className="text-center">Loading...</p>;
    }
    if (error) {
      return (
        <p className="text-center">An error occurred when retrieving teams.</p>
      );
    }
    return (
      <div className="team-selection">
        <p>Teams</p>
        <UserTeamSelector
          placeHolder="Add Teams"
          emptyPlaceHolder="-"
          viewOnly={!canModify}
          allUsersTeams={(data || []).map((team) => ({
            id: team.id,
            name: team.name,
            context: 'team'
          }))}
          onSelect={(item) => addTeam(item.id)}
          onDelete={({ id }) => {
            removeTeam(id);
            if (id === selectedTeamId) {
              setSelectedTeamId('default');
            }
          }}
          usersTeams={
            !teamIds.length
              ? []
              : teamIds
                  .filter((id1) => (data || []).find((t) => t.id === id1))
                  .map((id2) => {
                    const team = (data || []).find((t) => t.id === id2);
                    return {
                      id: team.id,
                      name: team.name,
                      context: 'team'
                    };
                  })
          }
        />

        <style jsx>{`
          .team-selection {
            margin-top: 15px;
            font-size: 12px;
          }
          .team-selection > p {
            margin-bottom: 10px;
          }
          .team-selection :global(.SearchInput .form-control) {
            font-size: 12px !important;
          }
          select {
            font-size: 12px;
          }
          .labels {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .label {
            padding: 5px;
            background: #339a22;
            border-radius: 5px;
            color: #fff;
            font-size: 12px;
            font-weight: 600;
            margin-right: 5px;
            margin-bottom: 5px;
          }
          .label :global(svg) {
            height: 15px;
            width: 15px;
            transform: translateY(-1px);
          }
        `}</style>
      </div>
    );
  };
  return (
    <div className="visibility-tags-setting">
      {teamSelection()}
      <div className="list-selection">
        <p>Tags</p>
        <div className="tags-selection">
          {canModify && (
            <input
              type="text"
              className="form-control"
              placeholder="Add Tag"
              value={tagInput}
              onChange={(e) => {
                const value = e.target.value;
                if (isAllowedForTagName(value)) {
                  setTagInput(value);
                }
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  const isSame = tags.some(
                    (tag) =>
                      tag.toLowerCase().trim() ===
                      tagInput?.toLowerCase().trim()
                  );
                  if (!isSame && tagInput?.trim() != '') {
                    addTag(tagInput);
                  }
                  setTagInput('');
                }
              }}
            />
          )}
          <div className="labels">
            {!tags.length
              ? '-'
              : tags.map((t) => (
                  <span key={t} className="label">
                    {t}
                    {canModify && (
                      <>
                        {' '}
                        <FaTimes
                          onClick={() => {
                            removeTag(t);
                          }}
                        />
                      </>
                    )}
                  </span>
                ))}
          </div>
        </div>
      </div>
      <style jsx>{`
        .list-selection {
          margin-top: 15px;
          font-size: 12px;
        }
        .list-selection > p {
          margin-bottom: 10px;
        }
        .list-group {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          max-width: 220px;
        }
        .list-selection label {
          display: inline-block;
          margin-left: 5px;
          transform: translateY(-2px);
        }
        input {
          font-size: 12px;
        }
        .labels {
          margin-top: 10px;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }
        .label {
          padding: 5px;
          background: #8a8888;
          border-radius: 5px;
          color: #fff;
          font-size: 12px;
          font-weight: 600;
          margin-right: 5px;
          margin-bottom: 5px;
        }
        .label :global(svg) {
          height: 15px;
          width: 15px;
          transform: translateY(-1px);
        }

        .visibility-tags-setting :global(.text-center) {
          margin: 20px 0;
          font-size: 12px;
        }
      `}</style>
    </div>
  );
};

export default SBVisibilityTagsSetting;
