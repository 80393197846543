import React, { useContext, useEffect, useMemo } from 'react';
import { ITableColumn, SortTable } from '../../Common/Table';
import { StoryBookContext } from '../SBContextContainer';
import { tz } from 'moment-timezone';
import moment from 'moment';
import { formatDurationFromMiliSeconds } from '../../../../utils/number';
import { getThumbnailFromPage } from './helpers';
import ImageWithWebp from '../../Common/ImageWithWebp';

const ClientTimeZone = tz.guess();
const SBAnalyticsScenes = ({
  setSelectedItem,
  range,
  startDate,
  endDate,
  campaign
}) => {
  const { getSBInsightSceneOverviewData, state } = useContext(StoryBookContext);
  const insightScenes = state.storybookInsightSceneOverview.data?.scenes;
  const isLoading = state.storybookInsightSceneOverview?.loading === true;

  const storybook = state.storybook;
  useEffect(() => {
    const fromDate =
      range > 0
        ? moment().subtract(range, 'days').format('YYYY-MM-DD')
        : startDate;
    const toDate = range > 0 ? moment().format('YYYY-MM-DD') : endDate;
    getSBInsightSceneOverviewData({
      storybookId: state.storybook.id,
      startDate: fromDate,
      endDate: toDate,
      campaign: campaign,
      timeZone: ClientTimeZone
    });
  }, [range, startDate, endDate, storybook.id, campaign]);

  const topSceneColumns: ITableColumn[] = [
    {
      key: 'thumbnail',
      title: 'Scene Order',
      align: 'left',
      custom: (value, rowIndex) => {
        return (
          <div className="scenes_order">
            <span>{rowIndex + 1}</span>
            <ImageWithWebp src={value} />
          </div>
        );
      }
    },
    {
      key: 'uniqueViews',
      title: 'Viewers',
      align: 'center'
    },
    {
      key: 'totalViews',
      title: 'Views',
      align: 'center'
    },

    {
      key: 'averageEyeballTime',
      title: 'Average Time Spent',
      align: 'center',
      custom: (value) => formatDurationFromMiliSeconds(value)
    },
    {
      key: 'clicks',
      title: 'Clicks',
      align: 'center'
    },
    {
      key: 'shares',
      title: 'Share',
      align: 'center'
    }
  ];

  const insightScenesData = useMemo(() => {
    return storybook?.pages?.map((page) => {
      const data = insightScenes?.find((s) => s.scene === page.id);
      if (data)
        return {
          ...data,
          thumbnail: getThumbnailFromPage(page),
          name: page.name,
          key: page.id
        };
      else
        return {
          key: page.id,
          thumbnail: getThumbnailFromPage(page),
          name: page.name,
          clicks: 0,
          shares: 0,
          uniqueViews: 0,
          averageEyeballTime: 0,
          totalViews: 0
        };
    });
  }, [insightScenes, storybook.id]);

  const handleSelectRow = (row) => {
    setSelectedItem({ ...row, type: 'scene' });
  };

  return (
    <>
      <div className="analytics_scenes" data-isloading={isLoading}>
        <SortTable
          columns={topSceneColumns}
          sorts={[
            'uniqueViews',
            'totalViews',
            'averageEyeballTime',
            'clicks',
            'shares'
          ]}
          data={insightScenesData}
          onlyActiveSortBy
          onRowSelect={handleSelectRow}
        />
        <div className="overlay">
          <span className="spinner-border spinner-border-lg" />
        </div>
      </div>
      <style jsx>{`
        :global(.scenes_order img) {
          width: 120px;
          height: auto;
          border: 1px solid #ccc;
          margin-left: 30px;
        }
        .analytics_scenes[data-isloading='true'] {
          opacity: 0.5;
          pointer-events: none;
          position: relative;
        }

        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          padding-top: 30px;
          justify-content: center;
        }

        .analytics_scenes[data-isloading='true'] .overlay {
          display: flex;
        }

        :global(.analytics_scenes .sort-table td) {
          font-weight: 500;
          color: #252525;
        }
      `}</style>
    </>
  );
};

export default SBAnalyticsScenes;
