import React from 'react';
import { BsInfoCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { SBPreviewType } from '.';
import { logEvent } from '../../../../analytics';
import { IMainState, IStorybook } from '../../../../interfaces';
import { TLanguage } from '../../../../mappers/polotno';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import { isVideo } from '../../../../utils/file';
import DeviceViewToggle, {
  DeviceViewProps
} from '../../Common/DeviceViewToggle';
import LanguageSwitch from '../../Common/LanguageSwitch';
import RoleViewToggle from '../../Common/RoleViewToggle';
import SelectDropdown from '../../Common/SelectDropdown';
import SBSettings from '../Common/SBSettings';
import StorybookStats from '../Common/StorybookStats';

const SBPreviewHeader = ({
  activeLanguage,
  availableList,
  onSelectLanguage,
  previewType,
  setPreviewType,
  onSelect,
  onClose,
  selectedStorybooks,
  storybook,
  sessionPreviewMode,
  showPreviewList,
  isForScene,
  actionLabel,
  hideInfo,
  deviceView,
  hostView,
  title: titleProp
}: {
  activeLanguage: TLanguage,
  availableList: TLanguage[],
  onSelectLanguage: (l:TLanguage) => void,
  previewType: SBPreviewType;
  setPreviewType: (v: SBPreviewType) => void;
  onSelect?: (storybook: IStorybook) => void;
  onClose?: () => void;
  selectedStorybooks?: string[];
  storybook?: IStorybook;
  sessionPreviewMode?: boolean;
  showPreviewList: boolean;
  isForScene?: boolean;
  actionLabel?: {
    ok: string;
    cancel: string;
  };
  hideInfo?: boolean;
  deviceView?: DeviceViewProps;
  hostView?: {
    activeView: boolean;
    onSelectView: (v: boolean) => void;
  };
  title?:string;
}) => {
  const logClick = (button: string) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button });
  };

  const isBrowseStorybookInMeeting = useSelector(
    (state: IMainState) => state.clientState?.isBrowseStorybookInMeeting
  );
  const handleSelect = (label: string) => {
    onSelect(storybook);
    onClose();
    logClick(label);
  };
  const isSelected = selectedStorybooks?.includes(storybook?.id);

  const { title, subtitle, settings } = storybook;

  const infoButton = (
    <div className="info">
      <BsInfoCircleFill size="20" color={showPreviewList ? '#000' : '#fff'} />
      <div className="info-content shadow-box">
        <div className="inner">
          <div className="group">
            <p>
              <strong>{title || <i>Title not available</i>}</strong>
            </p>
            {subtitle && <p className="text-semibold">{subtitle}</p>}
          </div>
          <div className="group">
            <p className="small m-0">
              <StorybookStats storybook={storybook} />
            </p>
          </div>

          {storybook.settings && (
            <div className="group">
              <p className="small">
                <strong>{isForScene ? 'Scene' : 'Default'} Settings:</strong>
              </p>
              <SBSettings
                showVideoSettings={
                  !isForScene ? true : isVideo(storybook.pages[0].url)
                }
                setting={settings}
                label=""
              />
            </div>
          )}
        </div>
      </div>
      <style jsx>{`
        .info {
          position: relative;
          margin-right: 20px;
        }
        .info-content {
          background: #fff;
          position: absolute;
          top: 50%;
          padding: 5px;
          display: none;
          border-radius: 10px;
          transform: translateX(${showPreviewList ? 0 : -330}px);
        }
        .inner {
          width: 335px;
          max-height: 70vh;
          overflow: auto;
          padding: 15px;
        }
        p {
          margin: 3px 0;
          font-size: 0.9em;
        }
        .head {
          font-size: 0.7em;
          letter-spacing: 1px;
        }
        .info:hover .info-content {
          display: block;
        }
        .group {
          border-bottom: 1px solid #ccc;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        .group:last-child {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
        .small {
          font-size: 0.8em;
          margin-bottom: 10px;
        }
      `}</style>
    </div>
  );

  const selectLabel = isBrowseStorybookInMeeting
    ? 'Open in Meeting'
    : isSelected
    ? actionLabel?.cancel ||
      `Unselect This ${isForScene ? 'Scene' : 'Storybook'}`
    : actionLabel?.ok || `Select This ${isForScene ? 'Scene' : 'Storybook'}`;

  const previewFromSBHome = () => {
    return (
      <>
        <div className="previewFromSBHome">
          <div className="hidden">
            {onClose && (
              <div>
                <button
                  className="btn btn-sm btn-outline-light btn-back"
                  onClick={onClose}
                >
                  Back
                </button>
              </div>
            )}
            <div className="text-center">
              <DeviceViewToggle {...deviceView} disabledView={['compare']} />
            </div>
            <div className="text-right">
              <div className="text-left">{!hideInfo && infoButton}</div>
            </div>
          </div>
        </div>
        <style jsx>{`
          .btn-back {
            border: 2px solid rgba(255, 255, 255);
            border-radius: 100px;
            font-weight: bold;
          }
          .previewFromSBHome {
            position: fixed;
            height: 100px;
            width: 100%;
            z-index: 5;
          }
          .hidden {
            display: none;
          }

          .hidden > div {
            width: 200px;
          }
          .hidden > div:last-child > div {
            display: inline-block;
          }
          .previewFromSBHome:hover .hidden {
            background-image: linear-gradient(#0008, #0000);
            display: block;
            display: flex;
            justify-content: space-between;
            padding: 20px;
          }
        `}</style>
      </>
    );
  };

  return showPreviewList ? (
    <div className="header-container">
      <div>
        {!hideInfo && infoButton}
        {titleProp && <h2>{titleProp}</h2>}
      </div>

      <div className="actions">
        {activeLanguage && availableList.length ? (
          <div>
            <LanguageSwitch
              activeLanguage={activeLanguage}
              availableList={availableList}
              onSelectLanguage={onSelectLanguage}
            />
          </div>
        ) : null}
        <div>
          <DeviceViewToggle {...deviceView} disabledView={['compare']} />
        </div>
        {!isForScene && !isBrowseStorybookInMeeting && (
          <SelectDropdown
            id="SBPreview"
            options={
              sessionPreviewMode
                ? [
                    { value: 'default', label: 'Default Viewer' },
                    { value: 'presentation', label: 'Live Session Slide' }
                  ]
                : [
                    { value: 'default', label: 'Default Viewer' },
                    { value: 'presentation', label: 'Live Session Slide' },
                    { value: 'microsite', label: 'Microsite' }
                  ]
            }
            value={previewType}
            onSelect={(val) => setPreviewType(val as SBPreviewType)}
            className="btn-sm btn-round btn-outline-dark"
            popupWidth={200}
            label="Preview as: "
          />
        )}

        {deviceView && previewType === 'presentation' && hostView && (
          <div>
            <RoleViewToggle
              onToggleHost={hostView.onSelectView}
              asHost={hostView.activeView}
            />
          </div>
        )}

        {onSelect && (
          <button
            className={`btn btn-select-sb btn-sm btn-round ${
              isSelected ? 'btn-outline-dark' : 'btn-success'
            }`}
            onClick={() => handleSelect(selectLabel)}
          >
            {selectLabel}
          </button>
        )}

        {onClose && (
          <button
            className="btn btn-sm preview-close-btn btn-round btn-dark"
            onClick={() => {
              onClose();
              logClick('Close');
            }}
          >
            Close
          </button>
        )}
      </div>
      <style jsx>{`
        h3 {
          font-size: 1em;
          margin: 0;
        }
        .btn {
          margin-left: 10px;
        }
        .header-container {
          display: flex;
          justify-content: space-between;
          padding: 15px 20px;
          margin: 0;
          border-bottom: 1px solid #ccc;
          align-items: center;
          position: relative;
          z-index: 1102;
        }
        .header-container > div {
          display: flex;
          align-items: center;
        }
        .actions {
          justify-content: flex-end;
        }
        .actions > div {
          margin-right: 10px;
          margin-left: 10px;
        }
      `}</style>
    </div>
  ) : (
    previewFromSBHome()
  );
};

export default SBPreviewHeader;
