import React from 'react';
import { FaUserAlt } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import DocumentsViewer from '../../../../components/DocumentsViewer';
import { useFileView } from '../../../../components/hooks/fileViewer';
import { IStorybook } from '../../../../interfaces';
import { TLanguage } from '../../../../mappers/polotno';
import {
  mapStorybookToDocument
} from '../../../../mappers/storybook';
import { actionDidLoadFileList } from '../../../../redux/actions';
import { getInitStorybookPageForDocumentViewer } from '../../../utils/storybook';
import { TDeviceView } from '../../Common/DeviceViewToggle';
import PhoneMockup from '../../PhoneMockup';

const SBPreviewDocumentViewer = ({
  storybook,
  deviceView,
  asHost,
  activateLanguage
}: {
  storybook: IStorybook;
  deviceView?: TDeviceView;
  asHost?: boolean;
  activateLanguage?: TLanguage;
}) => {
  const portrait = deviceView === 'mobile';
  const dispatch = useDispatch();
  const fileViewerProps = useFileView();
  const content = storybook?.pages || [];

  const currrentIndex =
    fileViewerProps?.currentPage &&
    content.indexOf(
      content.find((page) => page.url === fileViewerProps.currentPage.url)
    );

  React.useEffect(() => {
    if (content.length > 0) {
      dispatch(
        actionDidLoadFileList({
          files: [mapStorybookToDocument(storybook)],
          activeFile: storybook.id,
          currentPage:getInitStorybookPageForDocumentViewer(storybook)
        })
      );
    }
  }, [content]);

  const documentViewer = (
    <div className={`SBPreviewDocumentViewer w-100 h-100 ${deviceView}`}>
      <div className="content">
        <DocumentsViewer
          {...fileViewerProps}
          portraitContent={portrait}
          initPage={currrentIndex}
          activateLanguage={activateLanguage}
        />
        <div className="participants">
          <div>
            <div className="participant self">
              <span>
                <FaUserAlt size={30} color="#999" />
              </span>
            </div>
            <div className="participant">
              <span>
                <FaUserAlt size={70} color="#999" />
              </span>
            </div>
            <div className="participant">
              <span>
                <FaUserAlt size={70} color="#999" />
              </span>
            </div>
          </div>
        </div>
      </div>
      <style jsx global>{`
        .SBDocumentViewer {
          position: absolute !important;
        }
        .SBDocumentViewer .mute-button {
          display: none;
        }
        .SBDocumentViewer .PageList {
          display: ${asHost ? 'block' : 'none'};
        }
      `}</style>
      <style jsx>{`
        .participants {
          display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background: #000;
        }

        .participant.self {
          display: none;
        }

        .mobile .participants {
          height: 115px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .mobile-landscape .participants {
          width: 144px;
          background: #000;
          height: 100%;
          display: block;
          display: flex;
        }

        .participants > div {
          margin: auto;
          display: flex;
        }
        .mobile-landscape .participants > div {
          display: block;
          margin-top: 0;
        }

        .participant {
          width: 150px;
          height: 103px;
          background: #666;
          margin: 0 3px;
          border-radius: 5px;
          display: flex;
          justify-content: center;
        }
        .mobile-landscape .participant {
          width: 124px;
          height: 74px;
          margin: 10px 0;
        }
        .mobile-landscape .participant.self {
          width: 68px;
          height: 40px;
          display: flex;
          margin-top: 15px;
          margin-bottom: 20px;
        }
        .participant span {
          margin: auto;
        }
        .mobile :global(.SBDocumentViewer) {
          bottom: 115px !important;
        }

        .mobile-landscape :global(.SBDocumentViewer) {
          left: 144px !important;
        }

        .content-container {
          width: 100%;
          background: #fff;
          display: flex;
          padding: 0;
        }
        .inner {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
        }
        .content {
          width: 100%;
          height: 100%;
          overflow: auto;
          background: #000;
          position: relative;
        }
      `}</style>
    </div>
  );

  return (
    <div className={`preview-container ${deviceView}`}>
      {deviceView === 'mobile' || deviceView === 'mobile-landscape' ? (
        <PhoneMockup landscape={deviceView === 'mobile-landscape'}>
          {documentViewer}
        </PhoneMockup>
      ) : (
        documentViewer
      )}
      <style jsx>{`
        .preview-container {
          width: 100%;
          height: 100%;
          display: flex;
        }
      `}</style>
    </div>
  );
};

export default SBPreviewDocumentViewer;
