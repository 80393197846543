import React from 'react';
import { mapSceneToStorybook } from '../../../../mappers/storybook';
import { getIdentityId } from '../../../../utils/identity';
import { COLLABORATED_ITEMS, TRASH_FOLDER } from '../../../utils/folders';
import { HubContext } from '../../HubContext';
import SLOverview from '../../SceneLibrary/Overview/SLOverview';
import { ContentManagerTabProps } from './ContentManagerPopup';

const ContentTabScene = ({
  selectedItems,
  toggleSelect,
  keywords,
  onPreview
}: ContentManagerTabProps) => {
  const { user } = React.useContext(HubContext);
  const userId = getIdentityId() || user?.id;

  return (
    <SLOverview
      onView={(scene) => onPreview(mapSceneToStorybook(scene))}
      onSelect={(scene) => toggleSelect(mapSceneToStorybook(scene))}
      userId={userId}
      keywords={keywords}
      selectedScenes={selectedItems}
      excludeFolders={[TRASH_FOLDER, COLLABORATED_ITEMS]}
    />
  );
};

export default ContentTabScene;
