import React from 'react';
import { Spinner } from 'react-bootstrap';
import { LoadingStatus } from '../../../interfaces';

const LoadingPlaceHolder = ({
  loadingStatus,
  children
}: {
  loadingStatus: LoadingStatus;
  children: React.ReactNode;
}) => {
  if (loadingStatus === LoadingStatus.LOADING) {
    return (
      <div className="w-100 text-center p-4">
        <Spinner animation="border" />
      </div>
    );
  }

  if (loadingStatus === LoadingStatus.FAILED) {
    return <div className="w-100 text-center p-4">{children}</div>;
  }

  return null;
};

export default LoadingPlaceHolder;
