const DocumentEmpty = () => (
  <div className="empty">
    <strong>This file doesn't contain pages or file type is not supported</strong>
    <style jsx>{`
      .empty {
        height: calc(100vh - 45px);
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    `}</style>
  </div>
);

export default DocumentEmpty;
