import React from 'react';
import { getValidHexColor } from '../../../utils/color';

const ColorInput = ({
  onChange,
  value = '',
  disabled,
  colorPlaceHolder,
  textPlaceHolder
}: {
  onChange: (value: string) => void;
  value?: string;
  disabled?: boolean;
  colorPlaceHolder?: string;
  textPlaceHolder?: string;
}) => {
  const [error, setError] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(value || '');
  const handleChangeColor = (newVal) => {
    setInputValue(newVal);
    onChange(newVal);
  };

  const handleChangeInput = (value) => {
    setInputValue(value);
    const val = value?.trim();
    const validHexColor = getValidHexColor(val, true) || '';
    setError(!!val && !validHexColor);
    if (validHexColor || !val) {
      onChange(validHexColor);
    }
  };

  const onBlur = () => {
    setInputValue(value || '');
    setError(false);
  };
  return (
    <div className="input-group">
      <input
        type="color"
        className={`form-control form-control-sm ssp-ignore ${
          !value ? 'no-color' : ''
        }`}
        value={value || '#ffffff'}
        onChange={(e) => handleChangeColor(e.target.value)}
        disabled={disabled}
      />
      <input
        type="text"
        className={`form-control form-control-sm ssp-ignore ${
          error ? 'is-invalid' : ''
        }`}
        value={inputValue || ''}
        placeholder={textPlaceHolder || 'Automatic'}
        onChange={(e) => handleChangeInput(e.target.value)}
        onBlur={onBlur}
        disabled={disabled}
        pattern="^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$"
        title={error ? 'Invalid color code. Only hex color code allowed.' : ''}
      />
      <style jsx>{`
        .input-group {
          background: ${!value ? colorPlaceHolder || '#efefef' : 'transparent'};
          border-radius: 0.5rem;
        }
        .form-control:first-child {
          width: 40px;
          max-width: 40px;
          flex: 0 auto;
        }
        .no-color {
          opacity: 0;
        }
      `}</style>
    </div>
  );
};

export default ColorInput;
