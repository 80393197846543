import React from 'react';
import { TLanguage } from '../../../../mappers/polotno';
import { getFileType } from '../../../../utils/file';
import { getLanguageJSON } from '../../../utils/storybook';
import SBThumbnailCover from './SBThumbnailCover';

interface Props {
  title?: string;
  subtitle?: string;
  url?: string;
  contentUrl?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  active?: boolean;
  selected?: boolean;
  allClickable?: boolean;
  thumbnailInfo?: string;
  thumbnailObjectFit?: 'cover' | 'contain';
  thumbnailColor?: string;
  videoCanPlay?: boolean;
  disabled?: boolean;
  hoverText?: string;
  portrait?: boolean;
  portraitUrl?: string;
  overlay?: React.ReactNode;
}

const SBPageThumbnail = ({
  title,
  subtitle,
  url,
  contentUrl,
  onClick,
  children,
  active,
  allClickable,
  thumbnailInfo,
  thumbnailColor,
  thumbnailObjectFit,
  selected,
  videoCanPlay,
  disabled,
  hoverText,
  portrait,
  portraitUrl,
  overlay
}: Props) => {

  const thumbnailUrl = url || contentUrl;
  const type = getFileType(contentUrl);
  const thumbnailObj = getLanguageJSON(thumbnailUrl);
  const language: TLanguage = 'en';
  const portraitObj = getLanguageJSON(portraitUrl);
  
  return (
    <div
      className={`SBPageThumbnail ${active ? 'active' : ''} ${
        disabled ? 'disabled' : ''
      }`}
      onClick={() => allClickable && onClick?.()}
      title={hoverText}
    >
      <SBThumbnailCover
        url={thumbnailObj[language]}
        portraitUrl={portraitObj[language]}
        onClick={() => !allClickable && onClick?.()}
        thumbnailInfo={thumbnailInfo}
        showDuration={true}
        backgroundColor={thumbnailColor}
        objectFit={thumbnailObjectFit}
        type={type}
        selected={selected}
        videoCanPlay={videoCanPlay}
        disabled={disabled}
        portrait={portrait}
        overlay={overlay}
      />
      <div className="description">
        {title && <div className="title text-semibold">{title}</div>}
        {subtitle && <div className="subtitle">{subtitle}</div>}
        {children}
      </div>
      <style jsx>{`
        .SBPageThumbnail {
          width: 100%;
          border: 2px solid transparent;
          border-radius: 5px;
          overflow: hidden;
          padding: 5px;
          font-size: 12px;
          cursor: ${allClickable ? 'pointer' : 'default'};
          pointer-events: ${disabled || selected || active ? 'none' : 'auto'};
        }
        .SBPageThumbnail:hover {
          box-shadow: {!selected && !disabled ? '0 0 10px #ccc' : 'none'};
        }

        .active {
          border-color: #000;
        }
        .subtitle {
          font-size: 0.9em;
        }
        .description {
          padding-top: 5px;
          opacity: ${selected ? '0.4' : '1'};
        }

        .title,
        .subtitle {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}</style>
    </div>
  );
};

export default SBPageThumbnail;
