import React, { useRef } from 'react';
import { Spinner } from 'react-bootstrap';
import { logClickButton, logEvent } from '../../../../analytics';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import { ILibraryScene } from '../../../../interfaces';
import {
  IconFavoriteFilled,
  IconFolderTrash,
  IconMenuMore
} from '../../Common/HubIcons';
import SBThumbnailCover from '../../StoryBook/Common/SBThumbnailCover';
import { getFileType } from '../../../../utils/file';
import { dateFormat } from '../../../../utils/clock';
import UserLabel from '../../Common/UserLabel';
import { getAvailableLanguageInScene, hasActiveVersion } from '../../../utils/library';
import { isEmpty } from 'lodash';
import { getLanguageJSON } from '../../../utils/storybook';
import { TLanguage } from '../../../../mappers/polotno';

const SLThumbnail = ({
  scene,
  activeLanguage = 'en',
  canModify,
  onDelete,
  onRestore,
  onSelect,
  onView,
  selected,
  children,
  loading,
  onClickMenu,
  isInTrash,
  isFavorited,
  deleteMark,
  dontShowAuthor,
  selectedScenes,
  selectScene,
  isDraggable=false
}: {
  scene: ILibraryScene;
  activeLanguage?: TLanguage;
  canModify?: boolean;
  onDelete?: (id: string) => void;
  onRestore: (id: string) => void;
  onSelect?: (scene: ILibraryScene) => void;
  selected?: boolean;
  children?: React.ReactNode;
  loading?: boolean;
  onClickMenu: () => void;
  isInTrash?: boolean;
  isFavorited?: boolean;
  deleteMark?: boolean;
  dontShowAuthor?: boolean;
  selectedScenes?: string[];
  selectScene:(scene: ILibraryScene) => void;
  onView:(id: string) => void;
  isDraggable?: boolean;
}) => {
  const { id, content, availableVersions } = scene || {};
  const { title, subtitle, visibility, name, description } = content || {};

  const preferredTitle = name || title || '';
  const preferredSubtitle = description || subtitle || '';
  const thumbnailObj = getLanguageJSON(content.thumbnail || content.url);
  const languages: TLanguage[] = getAvailableLanguageInScene(scene);
  const language: TLanguage = languages.includes(activeLanguage) ? activeLanguage : 'en';

  const containerRef = useRef<HTMLDivElement>(null);

  const logClick = (buttonName: string) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      button: buttonName,
      sceneId: id
    });
  };

  const hasActivated = hasActiveVersion(availableVersions);
  const externalScenes = selectedScenes?.filter((sId) => sId !== id);
  const isInSelectedList = selectedScenes?.some((sId) => id === sId);

  return (
    <div
      className={`SLThumbnail ${selected ? 'selected' : ''} ${
        deleteMark ? 'deleted' : ''
      }`}
      title={`${preferredTitle}${
        preferredSubtitle ? ' | ' + preferredSubtitle : ''
      }`}
      ref={containerRef}
      id={`scene-${id}`}
      draggable={isDraggable && !onSelect}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onDragStart={(e) => {
        containerRef.current?.classList?.add('drag-active');
        e.dataTransfer.setData(
          'sceneIds',
          isInSelectedList ? selectedScenes.join(',') : id
        );
        e.dataTransfer.dropEffect = 'move';

        if (isInSelectedList) {
          externalScenes.forEach((sId) => {
            document
              .getElementById(`scene-${sId}`)
              .classList.add('drag-active');
          });
        }
        const ghostElement = document.getElementById(`ghost-ele-${id}`);
        e.dataTransfer.setDragImage(ghostElement, 120, 20);
      }}
      onDragEnd={() => {
        containerRef.current?.classList?.remove('drag-active');
        if (isInSelectedList) {
          externalScenes.forEach((aId) => {
            document
              .getElementById(`scene-${aId}`)
              ?.classList?.remove('drag-active');
          });
        }
      }}
    >
      <div className="position-absolute">
        <div
          className="dragging-elements position-relative"
          id={`ghost-ele-${id}`}
        >
          <div className="dragging-elements-item dragging-element position-absolute shadow-sm">
            <div className="d-flex justify-content-center align-items-center">
              <span className="file-name">{scene?.content?.name}</span>
            </div>
            <span className="number">
              {isInSelectedList ? selectedScenes?.length || 1 : 1}
            </span>
          </div>
          {!isEmpty(externalScenes) && isInSelectedList && (
            <div className="dragging-elements-item additional-scene position-absolute"></div>
          )}
        </div>
      </div>
      <div className="media-container">
        <SBThumbnailCover
          type={getFileType(content.url)}
          url={thumbnailObj[language]}
          onClick={() => {
            onView(id);
            selectScene(scene);
          }}
        />
        {isFavorited && !isInTrash && (
          <div className="tag-label tag-favorite">
            <IconFavoriteFilled color="#fff" /> Favorite
          </div>
        )}
        {deleteMark && (
          <div className="delete-mark">
            <IconFolderTrash />
          </div>
        )}
        {!!availableVersions?.length &&
          (hasActivated ? (
            <div className="tag-label tag-activated">Activated</div>
          ) : (
            <div className="tag-label tag-draft">Draft</div>
          ))}

        {visibility === 'team' && (
          <div className="tag-label tag-visibility">Team</div>
        )}
        {visibility === 'private' && hasActivated && (
          <div className="tag-label tag-visibility">Private</div>
        )}
      </div>
      <div className="content-container">
        <div className="title">
          <strong>{preferredTitle}</strong>
        </div>
        <div className="subtitle">{preferredSubtitle}</div>

        {!dontShowAuthor && scene.createdBy && (
          <div className="subtitle author">
            By: <UserLabel id={scene.createdBy} />
          </div>
        )}

        <div className="subtitle created-at">
          Created: {dateFormat(scene?.createdAt, 'MMM dd, yyyy')}
        </div>
        <div className="subtitle">
          Modified: {dateFormat(scene?.modifiedAt, 'MMM dd, yyyy')}
        </div>
        {children}

        <div className="actions">
          <div>
            {onSelect && !isInTrash && (
              <button
                className={`btn sl-select btn-round btn-sm ${
                  selected ? 'btn-outline-dark' : 'btn-dark'
                }`}
                onClick={() => {
                  onSelect?.(scene);
                  logClick(selected ? 'Unselect' : 'Select');
                }}
              >
                {selected ? 'Unselect' : 'Select'}
              </button>
            )}

            {canModify && isInTrash && (
              <>
                <button
                  className={`btn sl-restore btn-round btn-sm btn-outline-dark`}
                  onClick={() => {
                    onRestore(id);
                    logClick('Restore');
                  }}
                >
                  Restore
                </button>
                <button
                  className={`btn sl-delete btn-round btn-sm btn-dark`}
                  onClick={() => {
                    onDelete(id);
                    logClick('Delete');
                  }}
                >
                  Delete
                </button>
              </>
            )}
          </div>

          {canModify && !isInTrash && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                onClickMenu();
                logClickButton('Scene Menu');
              }}
              className="sl-option"
              id={`menu-${id}`}
            >
              <IconMenuMore />
            </div>
          )}
        </div>
      </div>
      {loading && (
        <div className="loading">
          <Spinner animation="border" size="sm" />
        </div>
      )}
      <style jsx>{`
        .media-container {
          position: relative;
        }
        .sl-option :global(svg) {
          width: 35px;
          height: auto;
          cursor: pointer;
        }
        .SLThumbnail {
          background: #f3f3f3;
          border-radius: 15px;
          overflow: hidden;
          font-size: 13px;
          border: 2px solid #fff;
          box-shadow: 0 0 5px #efefef;
          position: relative;
        }
        .SLThumbnail.drag-active {
          opacity: 0.4;
        }
        .loading {
          position: absolute;
          bottom: 0;
          right: 0;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          pointer-events: none;
          background: rgba(255, 255, 255, 0.5);
        }
        .loading > :global(*) {
          margin: auto;
        }
        .SLThumbnail.selected {
          border: 2px solid #000;
        }
        .SLThumbnail:hover {
          box-shadow: 0 0 10px #ccc;
        }
        img {
          width: 100%;
          height: auto;
        }
        .btn {
          margin-right: 5px;
        }
        .content-container {
          padding: 14px;
        }
        .title {
          font-size: 1em;
          height: 20px;
        }
        .subtitle {
          font-size: 0.9em;
          height: 18px;
        }
        .title,
        .subtitle {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .actions {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
          border-top: 1px solid #e5e5e5;
          padding-top: 14px;
        }

        .delete-button {
          display: none;
          position: absolute;
          top: 5px;
          right: 5px;
          background: #fff;
          border-radius: 100px;
        }
        .SLThumbnail:hover .delete-button {
          display: block;
        }
        .sl-restore,
        .sl-delete {
          width: 80px;
          margin: 2px;
        }
        .tag-label {
          position: absolute;
          background: #000;
          border-radius: 4px;
          color: #fff;
          padding: 2px 5px;
          display: flex;
          align-items: center;
        }
        .tag-label :global(svg) {
          width: 17px;
          height: auto;
        }
        .tag-favorite {
          top: 5px;
          left: 5px;
        }
        .tag-activated,
        .tag-published,
        .tag-draft {
          top: 5px;
          right: 5px;
          background-color: #339a22;
        }
        .tag-draft {
          background-color: #666;
        }
        .tag-published {
          background-color: #22739a;
        }
        .tag-visibility {
          left: 0;
          bottom: 0;
          border-radius: 0 4px 0 0;
        }
        .delete-mark {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: 1;
          display: flex;
          align-items: center;
          background: rgba(255, 255, 255, 0.8);
          color: #fff;
          font-size: 2em;
          pointer-events: none;
        }
        .delete-mark :global(svg) {
          margin: auto;
          width: 40%;
          height: auto;
        }
        .deleted {
          opacity: 0.4;
        }
        .deleted:hover {
          opacity: 1;
        }
        .deleted:hover .delete-mark {
          display: none;
        }

        :global(.dragging-elements) {
          width: 249px;
          height: 60px;
          overflow: hidden;
          transform: translateX(-100%);
        }
        :global(.dragging-element) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: white;
          padding: 8px;
          border-radius: 10px;
          width: 100%;
          overflow: hidden;
          z-index: 2;
        }
        :global(.dragging-element .file-name) {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 140px;
          font-size: 10px;
          display: inline-block;
        }
        :global(.dragging-element .number) {
          background: #000000;
          padding: 4px;
          border-radius: 50%;
          color: #fff;
          width: 30px;
          height: 30px;
          text-align: center;
        }
        :global(.dragging-elements-item) {
          height: 46px;
        }
        :global(.additional-scene) {
          border-radius: 10px;
          background: white;
          transform: translateX(-50%);
          left: 50%;
          top: 6px;
          width: calc(100% - 8px);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.23);
        }
      `}</style>
    </div>
  );
};

export default SLThumbnail;
