import React from 'react';
import { ILibraryScene, IUser } from '../../../../interfaces';
import { getListUsers } from '../../../clientSideServices/user';
import { HubContext } from '../../HubContext';
import UserAvatar from '../../Common/UserAvatar';
import { RiFileTransferLine } from 'react-icons/ri';
import SearchInput from '../../Common/SearchInput';
import { Spinner } from 'react-bootstrap';
import LoaderButton from '../../Common/LoaderButton';
import SBPopupWrapper from '../../StoryBook/Common/SBPopupWrapper';
import { transferSceneOwnership } from '../../../clientSideServices/library';

function SLTransferOwnershipPopup({
  onClose,
  onSuccess,
  scene
}: {
  onClose: () => void;
  onSuccess: () => void;
  scene: ILibraryScene;
}) {
  const { storeId } = React.useContext(HubContext);
  const [users, setUsers] = React.useState<IUser[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState<IUser | null>(null);
  const [searchValue, setSearchValue] = React.useState('');
  const [isLoadingTransfer, setIsLoadingTransfer] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    getListUsers(storeId)
      .then((res) => {
        setUsers(res?.filter((user) => user.id !== scene.createdBy));
      })
      .catch(console.error)
      .finally(() => {
        setLoading(false);
      });
  }, [storeId]);

  const handleTransferOwnership = () => {
    const newOwnerId = selectedUser?.id;
    if (!newOwnerId) return;
    setIsLoadingTransfer(true);
    transferSceneOwnership(scene, newOwnerId)
      .then(() => {
        setSearchValue(null);
        onSuccess();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoadingTransfer(false);
      });
  };

  const filteredUsers = React.useMemo(() => {
    if (!searchValue) {
      return users;
    }
    return users.filter((user) => {
      return (
        user.full_name.toLowerCase().includes(searchValue.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
        user.alias?.toLowerCase().includes(searchValue.toLowerCase())
      );
    });
  }, [users, searchValue]);

  return (
    <SBPopupWrapper
      className="transfer-ownership-popup"
      onClose={onClose}
      maxHeight="100vh"
      height="700px"
      width="600px"
      maxWidth="98vw"
    >
      <div className="popup-wrapper">
        <div className="popup-header">
          <h5>Transfer Ownership - {scene?.content?.name}</h5>
        </div>
        <SearchInput
            placeHolder="Search users"
            onSearch={setSearchValue}
            searchOnTyping={true}
            rounded
            className="d-flex align-items-center"
          />
        <div className="popup-body">
          {loading && (
            <div className="d-flex justify-content-center align-items-center ">
              <Spinner animation="border" />
            </div>
          )}
          <ul className="list-group list-group-flush">
            {filteredUsers.map((user) => (
              <li
                key={user.id}
                className={`list-group-item ${
                  selectedUser?.id === user.id ? 'active' : ''
                }`}
              >
                <UserAvatar
                  name={user.alias}
                  url={user.avatar_picture}
                  size={40}
                  fontSize="12px"
                />
                <div className="user-metadata">
                  <span className="user-name">{user.alias}</span>
                  <span>
                    <i>{user.email}</i>
                  </span>
                </div>
                <div className="action">
                  <button
                    className="btn btn-dark btn-round btn-icon"
                    onClick={() => setSelectedUser(user)}
                  >
                    <RiFileTransferLine />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {selectedUser?.id && (
        <SBPopupWrapper
          className="confirmation-popup"
          width="320px"
          maxWidth="100vw"
        >
          <div className="confirm-body">
            <p className="text-center">
              Do you really want to transfer storybook <b>{scene?.content?.name}</b>{' '}
              to <b>{selectedUser.alias}</b>?
            </p>
            <div className="btn-group action-buttons text-center">
              <button
                className="btn btn-outline"
                onClick={() => setSelectedUser(null)}
              >
                No
              </button>
              <LoaderButton
                className="btn btn-dark"
                onClick={handleTransferOwnership}
                isLoading={isLoadingTransfer}
              >
                Transfer
              </LoaderButton>
            </div>
          </div>
        </SBPopupWrapper>
      )}
      <style jsx>{`
        .popup-wrapper {
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 20px 0;
          width: 100%;
          flex: 1;
        }
        .popup-header {
          width: 100%;
          border-bottom: 1px solid #ccc;
        }
        .popup-body {
          flex: 1;
          position: relative;
        }
        .popup-body .list-group {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          overflow-y: auto;
        }
        .btn-icon {
          width: 40px;
          height: 40px;
          padding: 0;
        }
        .action-buttons {
          display: flex;
          justify-content: center;
        }
        .action-buttons .btn {
          flex: 1;
        }
        .btn-dark {
          border: 1px solid #000 !important;
        }
        .list-group-item.active {
          background-color: rgba(0, 0, 0, 0.5);
        }
        .btn-outline {
          border-color: #ccc;
          color: #333;
        }
        .confirm-body p {
          font-size: 14px;
        }
        .list-group-item {
          display: flex;
          gap: 10px;
          padding: 10px;
          cursor: pointer;
          align-items: center;
        }
        .user-metadata {
          display: flex;
          flex-direction: column;
          gap: 5px;
          font-size: 12px;
          flex: 1;
        }
        .user-name {
          font-size: 14px;
          font-weight: 500;
        }
        ::global(.transfer-ownership-popup .content-container) {
          align-items: stretch !important;
        }
      `}</style>
    </SBPopupWrapper>
  );
}

export default SLTransferOwnershipPopup;
