import React from 'react';
import ConfirmationPopup from '../../Common/ConfirmationPopup';

const SBTitleEditor = ({
  popupTitle,
  title,
  subTitle,
  onClose,
  onSave
}: {
  popupTitle?:string,
  title?: string;
  subTitle?: string;
  onSave: (arg: { title?: string; subtitle: string }) => void;
  onClose: () => void;
}) => {
  const [newTitle, setNewTitle] = React.useState(title);
  const [newSubtitle, setNewSubtitle] = React.useState(subTitle);
  const [error, setError] = React.useState(false);

  const handleSave = () => {
    if (newTitle?.trim()) {
      onSave({ title: newTitle, subtitle: newSubtitle });
      onClose();
    } else {
      setError(true);
    }
  };

  return (
    <ConfirmationPopup
      onOk={handleSave}
      okLabel="Save"
      closeLabel="Cancel"
      onClose={onClose}
      title={popupTitle}
    >
      <div className="content">
        <div className="form-container text-left">
          <div>
            <span className="panel-label">Title</span>
            <input
              type="text"
              className={`form-control ssp-ignore ${error ? 'is-invalid':''}`}
              placeholder="Please enter Storybook Title"
              value={newTitle || ''}
              onChange={(e) => {
                setNewTitle(e.target.value)
                setError(false)
              }}
            />
          </div>
          <div className="flex-grow-1">
            <span className="panel-label">Subtitle</span>
            <input
              type="text"
              className="form-control ssp-ignore"
              placeholder="Please enter Storybook Subtitle"
              value={newSubtitle || ''}
              onChange={(e) => {
                setNewSubtitle(e.target.value)
              }}
            />
          </div>
        </div>
      </div>
      <style jsx>{`
        .content {
          margin-bottom: 30px;
        }
        .form-container > div {
          margin: 10px 0;
        }
        .error{
          height:10px;
          color:red;
          font-size:0.8em;
        }
      `}</style>
    </ConfirmationPopup>
  );
};

export default SBTitleEditor;
