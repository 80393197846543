import React from 'react';
import { IStorybook } from '../../../../interfaces';
import FormattedDate from '../../Common/FormattedDate';
import UserLabel from '../../Common/UserLabel';

const StorybookStats = ({ storybook = {} }: { storybook?: IStorybook }) => {
  const { createdAt, createdBy, modifiedAt } = storybook;
  return (
    <>
      <span>
        <UserLabel id={createdBy || ''} label="By: " />
      </span>
      <br />
      <span>
        <FormattedDate dateString={createdAt || ''} label="Created: " />
      </span>
      <br />
      <span>
        <FormattedDate dateString={modifiedAt || ''} label="Modified: " />
      </span>
    </>
  );
};

export default StorybookStats;
