import { Spinner } from 'react-bootstrap';
import { logEvent } from '../../../analytics';
import { LoadingStatus } from '../../../interfaces';
import { DID_CLICK_BUTTON } from '../../../utils/constants';
import {
  COLLABORATED_ITEMS,
  FAVORITE_FOLDER,
  isSpecialFolders,
  SHARED_ITEMS,
  SHARED_TEAM_ITEMS,
  SHARED_TEAM_TYPE,
  TRASH_FOLDER
} from '../../utils/folders';
import { FolderPath } from './Folder/FolderNavigation';
import { IconFavorite, IconFolderTrash } from './HubIcons';
import LoadingPlaceHolder from './LoadingPlaceHolder';

const OverviewPlaceHolder = ({
  hasNoSearchResult,
  hasNoItems,
  keywords,
  onCreate,
  loading,
  onReload,
  currentFolder,
  onDeleteFolder,
  canModify,
  isLastChild,
  context = 'item',
  teamFolder,
  createLoading
}: {
  hasNoSearchResult?: boolean;
  hasNoItems?: boolean;
  onCreate?: () => void;
  loading?: LoadingStatus;
  keywords?: string;
  onReload?: (arg: boolean) => void;
  currentFolder?: string;
  onDeleteFolder?: () => void;
  canModify?: boolean;
  isLastChild?: boolean;
  context?: string;
  teamFolder?: string;
  createLoading?: boolean;
}) => {
  const isSubFolder = !isSpecialFolders(currentFolder);
  const isTrashFolder = currentFolder === TRASH_FOLDER;
  const isFavoritesFolder = currentFolder === FAVORITE_FOLDER;
  const isRootSharedFolder =  currentFolder === '/SHARED_ITEMS/';
  const isSharedFolder = currentFolder.startsWith(SHARED_ITEMS);
  const isSharedSubFolder =
    isSharedFolder &&
    !currentFolder.startsWith(SHARED_TEAM_ITEMS) &&
    currentFolder.length > SHARED_ITEMS.length;
  const isCollaborationFolder = currentFolder.startsWith(COLLABORATED_ITEMS);
  const isNotTrashOrFavoritesFolder =
    !isTrashFolder && !isFavoritesFolder && !isSharedFolder;

  const isInSubFolder = !isTrashFolder && !isFavoritesFolder;

  const emptyLabel = () => {
    if (isSubFolder) {
      return 'Folder is empty.';
    }

    if (isTrashFolder) {
      return 'Your trash bin is clean!';
    }
    if (isFavoritesFolder) {
      return `There are no favorite ${context}s.`;
    }
    return `There are no ${context}s yet.`;
  };

  const notFoundLabel = () => {
    if (isTrashFolder) {
      return (
        <p>
          Deleted {context}s with keyword{' '}
          <strong>
            <i>{keywords}</i>
          </strong>{' '}
          not found.
        </p>
      );
    }
    if (isFavoritesFolder) {
      return (
        <p>
          Favorites {context}s with keyword{' '}
          <strong>
            <i>{keywords}</i>
          </strong>{' '}
          not found.
        </p>
      );
    }
    return (
      <p>
        {context}s with keyword{' '}
        <strong>
          <i>{keywords}</i>
        </strong>{' '}
        not found.
      </p>
    );
  };
  const emptyIcon = () => {
    if (isTrashFolder) {
      return <IconFolderTrash />;
    }
    if (isFavoritesFolder) {
      return <IconFavorite />;
    }
    return <img src="/asset/Flipping-Book.gif" alt="No results" />;
  };
  
  return (
    <>
      {hasNoSearchResult && loading !== LoadingStatus.LOADING && (
        <div className="no-results no-search-found text-center w-100 h-100 d-flex">
          <div className="empty-image">{emptyIcon()}</div>
          {isInSubFolder && <FolderPath path={currentFolder} />}

          {notFoundLabel()}
        </div>
      )}

      {!isRootSharedFolder && hasNoItems && (isLastChild || isTrashFolder || isFavoritesFolder) && (
        <div className="no-results empty-item text-center w-100 h-100 d-flex">
          <div className="empty-image">{emptyIcon()}</div>
          <p>{emptyLabel()}</p>
          {canModify &&
            onCreate &&
            isNotTrashOrFavoritesFolder &&
            !isCollaborationFolder && (
              <button
                className="btn btn-create-first btn-round btn-dark"
                onClick={() => {
                  logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                    button: `Create First ${context}`
                  });
                  onCreate();
                }}
              >
                {createLoading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  `Create ${context}`
                )}
              </button>
            )}
          {canModify &&
            isSubFolder &&
            onDeleteFolder &&
            isLastChild &&
            teamFolder !== SHARED_TEAM_TYPE &&
            (isNotTrashOrFavoritesFolder || isSharedSubFolder) &&
            !isCollaborationFolder && (
              <button
                className="btn btn-delete-folder btn-round btn-outline-dark"
                onClick={() => {
                  onDeleteFolder();
                  logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
                    button: 'Delete This Folder'
                  });
                }}
              >
                Delete This Folder
              </button>
            )}
        </div>
      )}

      <LoadingPlaceHolder loadingStatus={loading}>
        <p>Oops, something wrong happen.</p>
        {onReload && (
          <button
            className="btn btn-sm btn-outline-dark"
            onClick={() => onReload(false)}
          >
            Try Again
          </button>
        )}
      </LoadingPlaceHolder>
      <style jsx>{`
        .loading-container {
          text-align: center;
          padding: 40px;
          width: 100%;
        }
        .no-results {
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .no-results :global(.folder-path) {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 30px;
          align-items: center;
        }

        .empty-image {
          width: 70%;
          max-width: 250px;
        }
        .empty-image :global(img),
        .empty-image :global(svg) {
          width: 100%;
          height: auto;
        }
        .btn {
          width: 200px;
          margin-bottom: 10px;
        }
      `}</style>
    </>
  );
};

export default OverviewPlaceHolder;
