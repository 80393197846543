import React from 'react';
import SBSettings from './SBSettings';
import SelectDropdown from '../../Common/SelectDropdown';
import { mapStorybookSettingsObjectToString } from './../../../../mappers/storybook';
import {
  ILibraryScene,
  IStorybookPage,
  IStorybookSettings
} from '../../../../interfaces';
import { logClickButton } from '../../../../analytics';
import SBExportPagePopup from './SBExportPagePopup';
import { IconDuplicate, IconPencil, IconSettings } from '../../Common/HubIcons';
import SBExportSceneButton from './SBExportSceneButton';
import { getCollaborators } from '../../../clientSideServices/collaboration';
import { getIdentityId } from '../../../../utils/identity';
import { BsInfoCircleFill } from 'react-icons/bs';
import UserLabel from '../../Common/UserLabel';

const SBPageDetailEditor = ({
  enlargeMode,
  compactMode,
  pageNumber,
  children,
  onUpdate,
  showVideoSettings,
  allowEdit,
  page,
  pageData,
  sceneData,
  settings,
  title,
  onOpenEditor,
  onDuplicate,
  exportButton
}: {
  enlargeMode?: boolean;
  compactMode?: boolean;
  pageNumber?: number | string;
  children?: React.ReactNode;
  onUpdate: (page: IStorybookPage, doNotFlagChange?: boolean) => void;
  showVideoSettings?: boolean;
  allowEdit?: boolean;
  page: IStorybookPage;
  pageData?: IStorybookPage;
  sceneData?: ILibraryScene;
  settings: IStorybookSettings;
  title?: string;
  onOpenEditor?: (props: {
    sceneId?: string;
    allowEdit?: boolean;
    isImported?: boolean;
  }) => void;
  onDuplicate?: (props: { sceneId?: string; isImported?: boolean }) => void;
  exportButton?: boolean;
}) => {
  const [exportPagePopup, setExportPagePopup] =
    React.useState<IStorybookPage>(null);

  const userId = getIdentityId();

  const pageSettings = (
    <SBSettings
      setting={mapStorybookSettingsObjectToString(settings)}
      onUpdate={
        allowEdit
          ? (settings) => {
              onUpdate({ ...page, settings });
            }
          : undefined
      }
      showVideoSettings={showVideoSettings}
      label={!enlargeMode ? 'Scene Settings' : ''}
    >
      {exportButton && sceneData && (
        <div className="info">
          <div style={{ fontSize: '11px', color: '#666' }}>
            <BsInfoCircleFill /> {sceneData?.content?.name}
            <br />
            by <UserLabel id={sceneData?.createdBy} />
          </div>
          <style jsx>{`
            .info {
              margin-top: 20px;
              padding: 20px 0 10px;
              border-top: 1px solid #ccc;
              text-align: center;
            }
          `}</style>
        </div>
      )}
    </SBSettings>
  );

  const pageSettingsPopup = (
    <SelectDropdown
      className="btn btn-sm"
      id={`pageSettings-${pageNumber}`}
      showCloseButton={true}
      label={
        <IconSettings
          style={{ width: '30px', height: 'auto' }}
          onClick={() => {
            logClickButton('Show Setting');
          }}
        />
      }
      popupWidth={280}
      popupXPosition="left"
      hideArrow={true}
    >
      <div className="inner">{pageSettings}</div>
    </SelectDropdown>
  );

  const formContainer = (
    <div className="form-container">
      <div className="fieldset">
        <label>Title</label>
        <input
          className="form-control form-control-sm"
          type="text"
          value={page?.title || ''}
          disabled={!allowEdit}
          onChange={(e) => {
            onUpdate({ ...page, title: e.target.value });
          }}
        />
      </div>
      <div className="fieldset">
        <label>Subtitle</label>
        <input
          className="form-control form-control-sm"
          type="text"
          value={page?.subtitle || ''}
          disabled={!allowEdit}
          onChange={(e) => {
            onUpdate({ ...page, subtitle: e.target.value });
          }}
        />
      </div>
      {enlargeMode && pageSettings}
    </div>
  );

  return (
    <>
      <div className={`SBPageDetailEditor ${enlargeMode ? 'enlargeMode' : ''}`}>
        {title && <span className="panel-label">{title}</span>}
        {!enlargeMode && (
          <div className="action">
            <span style={{ width: '60px' }}>{pageNumber}</span>
            <div className="d-flex justify-content-between">
              <div>
                {allowEdit && (
                  <>
                    {onOpenEditor && pageData.overlay && (
                      <span
                        className="editor-button"
                        title="Edit Page"
                        onClick={async () => {
                          const { data: collabData } = await getCollaborators(
                            'scene',
                            page?.id
                          );
                          const isCollaborator = (
                            collabData?.users || []
                          )?.includes(userId);
                          const isOwner =
                            sceneData && sceneData.createdBy === userId;
                          onOpenEditor({
                            sceneId: page?.id,
                            allowEdit:
                              isCollaborator || isOwner || !!pageData?.overlay,
                            isImported: !!pageData.overlay
                          });
                        }}
                      >
                        <IconPencil />
                      </span>
                    )}

                    {onDuplicate && pageData.overlay && (
                      <span
                        className="editor-button"
                        title="Clone Page"
                        onClick={() =>
                          onDuplicate({
                            sceneId: page?.id,
                            isImported: !page?.overlay
                          })
                        }
                      >
                        <IconDuplicate />
                      </span>
                    )}

                    {exportButton && !exportPagePopup && !compactMode && (
                      <span
                        className="editor-button"
                        title="Add Scene to Library"
                      >
                        <SBExportSceneButton
                          scene={sceneData}
                          onClick={() => {
                            setExportPagePopup(pageData);
                          }}
                        />
                      </span>
                    )}
                  </>
                )}
                {allowEdit && (
                  <span className="editor-button" title="Settings">
                    {pageSettingsPopup}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}

        {!compactMode && formContainer}
        {children}
        <style jsx>{`
          .editor-button {
            cursor: pointer;
            margin-left: 5px;
          }
          .editor-button > :global(.btn) {
            padding: 0 !important;
          }
          .editor-button > :global(.btn svg) {
            margin-left: 0 !important;
          }
          .panel-label {
            margin-bottom: 15px;
            display: block;
          }
          .action {
            margin: 5px 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .action span:last-child {
            margin-right: -5px;
          }
          .SBPageDetailEditor :global(.form-control),
          .SBPageDetailEditor :global(.duration-input) {
            flex: 1;
          }
          .SBPageDetailEditor :global(.form-container .fieldset) {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 10px;
          }

          .SBPageDetailEditor :global(.form-container .video-settings) {
            margin-top: 25px;
            padding-top: 20px;
            border-top: 1px solid #ccc;
          }

          .SBPageDetailEditor :global(.form-container label) {
            width: 60px;
            margin: 0;
          }

          .enlargeMode :global(.form-container label) {
            width: 100%;
            margin-bottom: 5px;
            font-weight: 500;
          }

          .SBPageDetailEditor :global(.pageSettings label) {
            width: 100%;
          }
          input:disabled {
            background: inherit;
            border-color: inherit;
          }
          .language {
            cursor: pointer;
            border-radius: 5px;
            border: 1px solid #fff;
            padding: 5px;
          }
          .language:hover {
            border-color: #d8d8d8;
            background: #f3f3f3;
          }
          .language span {
            font-weight: 500;
            font-size: 12px;
            margin: auto;
            margin-right: 0 !important;
          }
          .language img {
            margin: auto;
          }
          .language-selector {
            width: 54px;
          }
        `}</style>
        <style jsx global>{`
          .ins-select-indicator {
            display: none;
          }
          .ins-select-control > div > div .ins-select-control label {
            display: none;
          }
          .ins-select-control label {
            display: none;
          }
          .language-selector img {
            width: 20px;
            height: 20px;
          }
          .language-option {
            padding: 0;
          }
          .language-selector span {
            font-size: 12px;
            margin-left: 0 !important;
          }
          .SBPageDetailEditor .remove-icon {
            display: none !important;
          }
          .SBPageDetailEditor .language-option {
            padding: 0;
          }
        `}</style>
      </div>
      {exportPagePopup && (
        <SBExportPagePopup
          page={exportPagePopup}
          onClose={(updatedPage) => {
            if (updatedPage) {
              onUpdate(updatedPage, true);
            }
            setExportPagePopup(null);
          }}
        />
      )}
    </>
  );
};

export default SBPageDetailEditor;
