import React from 'react';
import { Spinner } from 'react-bootstrap';
import { FiUsers } from 'react-icons/fi';
import {
  useCollaborationPopup,
  useCollaborations
} from '../../../../components/hooks/collaborator';
import { useLoadingStatus } from '../../../../components/hooks/loading';
import { ICollaboratorContext } from '../../../clientSideServices/collaboration';

const CollaboratorButton = ({
  itemId,
  context,
  owner
}: {
  itemId: string;
  context: ICollaboratorContext;
  owner: string;
}) => {
  const { openPopup, collaborationPopup } = useCollaborationPopup();
  const { isLoading } = useLoadingStatus();
  const { collaborators, loadCollaboratorsById } = useCollaborations({
    itemId,
    context,
    owner
  });

  const loading = isLoading(`usersTeams-${itemId}`);

  React.useEffect(() => {
    if (!collaborationPopup) {
      loadCollaboratorsById({ id: itemId });
    }
  }, [collaborationPopup]);

  if (!itemId || !context || !owner) return null;
  return (
    <button
      className="btn btn-sm btn-outline-dark btn-collaborator btn-round"
      onClick={() => openPopup(itemId, context, owner)}
    >
      {loading && <Spinner animation="border" size="sm" />}
      {!loading && (
        <span className="label-button">
          <FiUsers />
          {collaborators.length ? (
            <span className="text-medium">{collaborators.length}</span>
          ) : null}
        </span>
      )}
      <style jsx>{`
        .label-button {
          display: flex;
          align-items: center;
        }
        .label-button > span{
            margin-left: 0.3rem;
        }
        button{
            min-height:31px;
        }
      `}</style>
    </button>
  );
};

export default CollaboratorButton;
