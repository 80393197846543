import React from 'react';
import { searchContacts } from '../../../clientSideServices/contacts';
import { FaLink, FaUserAlt } from 'react-icons/fa';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { SimplePublicObject } from '@hubspot/api-client/lib/codegen/crm/companies';
import { IStorybook } from '../../../../interfaces';
import { BsX } from 'react-icons/bs';
import { logEvent } from '../../../../analytics';
import {
  DID_SHARE_STORYBOOK,
  SHARE_STORYBOOK_FAILED
} from '../../../../utils/constants';
import {
  getBrandStorybookHost,
  inspifyBrandId,
  inspifySpaceId
} from '../../../../config';
import { getSimpleShortenedUrl } from '../../../../utils/shareUrl';
import { getCurrentHost, performNativeShare } from '../../../../utils/window';
import SimpleShareModal from '../../../../components/Sharing/SimpleShareModal';
import ToolTipWrapper from '../../../../components/Common/ToolTipWrapper';
import { debounce } from 'lodash';
import { HubContext } from '../../HubContext';
import { StatsValueType } from '../Analytics/helpers';
const defaultShareState = {
  url: '',
  show: false,
  cId: '',
  loading: false
};

function ShareAsContact({ storybook }: { storybook: IStorybook }) {
  const [search, setSearch] = React.useState('');
  const [after, setAfter] = React.useState<string>();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [contacts, setContacts] = React.useState<SimplePublicObject[]>([]);
  const [copied, setCopied] = React.useState(false);
  const [shareModal, setShareModal] = React.useState(defaultShareState);
  const [connection, setConnection] = React.useState(false);
  const [testConnection, setTestConnection] = React.useState(true);
  const { brandId, storeId } = React.useContext(HubContext);

  const onLoad = (loadMore = false, keyword?: string) => {
    if (!loadMore) {
      setContacts([]);
    }
    setLoading(true);
    searchContacts({
      limit: 25,
      sorts: [],
      query: keyword || search,
      properties: [
        'firstname',
        'lastname',
        'email',
        'jobtitle',
        'photo',
        'company'
      ],
      after: loadMore && after ? +after : undefined,
      filterGroups: [],
      spaceId
    })
      .then((res) => {
        const data = res.data;
        const results = data.results;
        if (loadMore) {
          setContacts([...contacts, ...results]);
        } else {
          setContacts(results);
        }
        setAfter(data.paging?.next?.after);
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const spaceId = React.useMemo(() => {
    if (brandId === inspifyBrandId) {
      return inspifySpaceId;
    } else return storeId;
  }, [brandId, storeId]);
  React.useEffect(() => {
    if (!spaceId) return;
    setTestConnection(true);
    searchContacts({
      limit: 25,
      sorts: [],
      query: 'test',
      properties: [],
      after: undefined,
      filterGroups: [],
      spaceId
    })
      .then(() => {
        setConnection(true);
      })
      .catch(() => {
        setConnection(false);
      })
      .finally(() => {
        setTestConnection(false);
      });
  }, [spaceId]);

  const contactRef = useInfiniteScroll<HTMLDivElement>({
    loading,
    hasNextPage: after !== undefined,
    onLoadMore: () => onLoad(true),
    scrollContainer: 'parent',
    threshold: 100,
    checkInterval: 100
  });

  const logShare = (channel: string, url: string) => {
    const sbUrl = `${getCurrentHost()}/storybook/${storybook.id}`;

    logEvent(DID_SHARE_STORYBOOK, channel, {
      storybookId: storybook.id,
      type: 'campaign',
      url
    });
    logEvent(
      StatsValueType.TARGET,
      StatsValueType.TARGET,
      {
        targetIdentityId: shareModal.cId,
        channel,
        url: sbUrl,
        country: 'SG',
        uuid: storybook.id
      },
      true
    );
    setCopied(true);
  };

  const generateShareUrl = async (id: string, brandId: string, ref: string) => {
    const host = getBrandStorybookHost(brandId);
    const originalUrl = new URL(`${host}/storybook/${id}`);
    originalUrl.searchParams.append('utm_source', 'direct');
    originalUrl.searchParams.append('ref', ref);
    const oUrl = originalUrl.toString();
    return getSimpleShortenedUrl(oUrl, host)
      .then((url) => ({
        original: oUrl,
        shortened: url
      }))
      .catch(() => ({
        original: oUrl,
        shortened: ''
      }));
  };

  const share = (url) => {
    return performNativeShare(
      {
        url: url.shortened
      },
      () => logShare('native', url),
      () => {
        setShareModal((prev) => ({
          ...prev,
          show: true,
          url: url.shortened,
          loading: false
        }));
      }
    );
  };

  const getFullName = (contact: SimplePublicObject) => {
    return `${contact.properties?.firstname || ''} ${
      contact.properties?.lastname || ''
    }`?.trim();
  };

  const handleGetSharableLink = (contact: SimplePublicObject) => {
    setShareModal({
      url: '',
      show: false,
      cId: contact.id,
      loading: true
    });
    generateShareUrl(storybook.id, storybook.brand, contact.id)
      .then(share)
      .catch((e) =>
        logEvent(SHARE_STORYBOOK_FAILED, SHARE_STORYBOOK_FAILED, {
          error: e
        })
      );
  };

  const onClear = () => {
    setSearch('');
    setContacts([]);
    setAfter(undefined);
  };

  const debounceSearch = React.useCallback(
    debounce((value) => onLoad(false, value), 500),
    []
  );

  function handleInputOnchange(e) {
    const { value } = e.target;
    setSearch(e.target.value);
    debounceSearch(value);
  }

  React.useEffect(() => {
    let timeoutId = null;
    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [copied]);

  if (error) {
    console.log(error);
  }

  if (testConnection) {
    return (
      <div className="w-100 d-flex justify-content-center align-items-center">
        <span className="spinner-border spinner-border-sm" />
      </div>
    );
  }
  if (!connection)
    return (
      <h6 className="p-3 text-center">
        No connection to hubspot, please provide hubspot api key on Account
        setting
      </h6>
    );

  return (
    <div className="ShareAsContact w-100">
      <span className="label">Search Contact</span>
      <div className="search">
        <div className="input-container">
          <input
            type="text"
            className="form-control form-control"
            value={search}
            placeholder="Search..."
            onChange={handleInputOnchange}
          />
          {!!search && (
            <button className="btn btn-sm" onClick={onClear}>
              <BsX size={20} />
            </button>
          )}
        </div>
      </div>
      {contacts && (
        <div className="contacts">
          {contacts.map((contact) => {
            return (
              <div
                id={`menu-${contact.id}`}
                className="contact row"
                key={contact.id}
              >
                <div className="avt-wrapper col-2">
                  <div className="avatar">
                    <FaUserAlt />
                  </div>
                </div>
                <div className={`name-tag col-4`}>
                  <div className="name">{getFullName(contact)}</div>
                  <div
                    className="jobtitle"
                    title={contact.properties?.jobtitle}
                  >
                    {contact.properties?.jobtitle || '- -'}
                  </div>
                  <div className="email-mobile">
                    {contact.properties?.email}
                  </div>
                </div>

                <div className="email col-4">{contact.properties?.email}</div>
                <div className="action col-2">
                  <button
                    className="btn btn-rounded btn-dark"
                    onClick={() => handleGetSharableLink(contact)}
                  >
                    <span className="icon">
                      {((shareModal?.cId === contact.id &&
                        !shareModal?.loading) ||
                        shareModal?.cId !== contact.id) && <FaLink size={16} />}
                      {shareModal?.cId === contact.id && shareModal.loading && (
                        <span className="spinner-border spinner-border-sm" />
                      )}
                    </span>
                  </button>
                </div>
                {shareModal?.cId === contact.id && shareModal?.show && (
                  <ToolTipWrapper
                    onClose={() => setShareModal(null)}
                    parentId={`menu-${shareModal.cId}`}
                    width={250}
                    xPosition="left"
                  >
                    <div className="control-menu text-left">
                      <SimpleShareModal
                        onClose={() => {
                          setShareModal(defaultShareState);
                        }}
                        url={shareModal?.url}
                        show={shareModal?.show}
                        message={storybook.title}
                        title="Share to Contact"
                        darkMode={true}
                        urlOnly={true}
                        type="menu"
                        onShare={logShare}
                      />
                    </div>
                  </ToolTipWrapper>
                )}
              </div>
            );
          })}
          {(loading || !!after) && (
            <div
              ref={contactRef}
              className="w-100 d-flex justify-content-center"
            >
              <span className="spinner-border spinner-border-sm" />
            </div>
          )}
        </div>
      )}
      <div className="alert">
        <div className="alert alert-success">Copied to clipboard</div>
      </div>
      <style jsx>{`
        .ShareAsContact {
          flex: 1;
          width: 100%;
          display: flex;
          flex-direction: column;
        }
        .label {
          font-size: 12px;
          font-weight: 500;
        }
        .alert {
          font-size: 12px;
          white-space: nowrap;
          background: transparent;
          border: none;
          color: #5da956;
          display: flex;
          justify-content: center;
          font-weight: bold;
          opacity: ${copied ? 1 : 0};
        }
        .name-tag *,
        .email {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .name-tag .email-mobile {
          display: none;
        }
        .action,
        .avt-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .btn-rounded {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          padding: 0;
        }
        .search {
          padding: 10px 0;
          display: flex;
          justify-content: space-between;
          align-items: stretch;
        }
        .input-container {
          flex: 1;
          position: relative;
        }
        .input-container .btn {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
          background-color: transparent;
          border: none;
          color: #000;
        }
        .search .btn {
          width: 38px;
          height: 38px;
        }
        .avatar {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-color: #ccc;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }
        .jobtitle {
          font-size: 12px;
        }
        .name {
          font-size: 12px;
        }
        .contacts {
          flex: 1;
          overflow-y: auto;
          overflow-x: hidden;
        }
        .contact:nth-child(odd) {
          background-color: #f1f1f1;
        }
        .contact {
          justify-content: space-between;
          padding: 13px 0;
        }
        .name {
          font-weight: bold;
        }
        .email {
          font-size: 12px;
        }
      `}</style>
    </div>
  );
}

export default ShareAsContact;
