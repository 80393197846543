import React from 'react';
import { Spinner } from 'react-bootstrap';
import PopupContainer from '../../Common/PopupContainer';

const SBSceneVersionPopup = ({
  title,
  onOverride,
  onLoadScene,
  onClose,
  children,
  loading,
  disableCancel,
  zIndex=10
}: {
  title?: string;
  onOverride?: () => void;
  onLoadScene?: () => void;
  onClose: () => void;
  children?: React.ReactNode;
  loading?: boolean;
  disableCancel?: boolean;
  zIndex?: number;
}) => {

  return (
    <PopupContainer maxWidth="470px" zIndex={zIndex}>
      <div className="w-100 text-center p-3">
        <h3 className='warning-text'>Warning!</h3>
        {title && <h3>{title}</h3>}
        {children}
        <div className="button-container ">
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              {!disableCancel && (
                <button
                  className="btn btn-sm btn-round btn-outline-dark btn-reject"
                  onClick={() => {
                    onClose();
                  }}
                >
                  Cancel
                </button>
              )}
               {onLoadScene && (
                <button
                  className="btn btn-sm btn-round btn-dark btn-confirm"
                  onClick={() => {
                    onLoadScene();
                  }}
                >
                    Load to Editor
                </button>
              )}
              {onOverride && (
                <button
                  className="btn btn-sm btn-round btn-dark btn-confirm"
                  onClick={() => {
                    onOverride();
                  }}
                >
                    Override Scene
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <style jsx>{`
        .warning-text {
          color: #ff9966;
        }
        .button-container {
          margin-top: 15px;
        }
        .btn {
          min-width: 100px;
          margin: 0 5px;
        }
        .text-center :global(h3) {
          font-size: 1.25rem;
        }
      `}</style>
    </PopupContainer>
  );
};

export default SBSceneVersionPopup;
