import React from 'react';

const PasscodeInput = ({
  onChange,
  buttonRef,
  darkMode,
  size = 'lg'
}: {
  onChange: (passcode: string) => void;
  buttonRef?: any;
  darkMode?: boolean;
  size?: 'sm' | 'lg';
}) => {
  const [passcode, setPasscode] = React.useState(['', '', '', '', '']);
  const inputRef = [
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null)
  ];

  React.useEffect(() => {
    inputRef[0]?.current?.focus();
  }, []);

  React.useEffect(() => {
    onChange(passcode.join(''));
  }, [passcode]);

  return (
    <div className="group d-flex">
      {[0, 1, 2, 3, 4].map((i) => (
        <input
          key={i}
          className="form-control"
          type="number"
          value={passcode[i] || ''}
          ref={inputRef[i]}
          onFocus={() => {
            inputRef[i]?.current?.select();
          }}
          onChange={(e) => {
            const value = e.target.value?.trim();
            if (value.length < 2) {
              const copy = [...passcode];
              copy[i] = value;
              setPasscode(copy);
            }
            if (value) {
              if (i < 4) {
                inputRef[i + 1]?.current?.focus();
              } else {
                buttonRef?.current?.focus();
              }
            }
          }}
          onKeyDown={(e) => {
            if (!e.key.match(/[0-9]/) && e.keyCode !== 8) {
              e.preventDefault();
            }
            if (e.keyCode === 8 && i !== 0) {
              const copy = [...passcode];
              copy[i] = '';
              setPasscode(copy);
              inputRef[i - 1]?.current?.focus();
            }
          }}
        />
      ))}
      <style jsx>{`
        .form-control {
          color: ${darkMode ? '#fff' : '#000'};
          background: ${darkMode ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
          border: 2px solid ${darkMode ? '#fff' : '#000'};
          margin: ${size === 'sm' ? '3px' : '5px'};
          width: 100%;
          text-align: center;
          font-weight: 600;
          font-size: ${size === 'sm' ? '1em' : '2em'};
          height: calc(2em + 0.75rem + 2px);
          border-radius: 0;
        }
        .group {
          align-items: center;
          margin: 0 auto 20px;
          max-width: 450px;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        input[type='number'] {
          -moz-appearance: textfield;
        }
      `}</style>
    </div>
  );
};

export default PasscodeInput;
