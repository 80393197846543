import React from 'react';
import { useDispatch } from 'react-redux';
import { logClickButton } from '../../../../analytics';
import { actionHubAlertError } from '../../../../redux/actions';

const FolderMenu = ({
  onDelete,
  canDelete,
  onRename,
  onMoveToFolder,
  onClose
}: {
  onDelete?: () => void;
  canDelete?: boolean;
  onRename?: () => void;
  onClose?: () => void;
  onMoveToFolder?: () => void;
}) => {
  const dispatch = useDispatch();
  const btnProps = {
    className: `list-group-item list-group-item-action`
  };

  const handleMove = () => {
    onMoveToFolder();
    logClickButton('Move Folder');
    onClose();
  };

  const handleRename = () => {
    onRename();
    logClickButton('Rename FOlder');
    onClose();
  };

  const handleDelete = () => {
    if (!canDelete) {
      dispatch(
        actionHubAlertError(
          'Folder is not empty! You cannot delete this folder.'
        )
      );
    } else {
      onDelete();
    }
    logClickButton('Delete Folder');
    onClose();
  };

  return (
    <div className="control-menu text-left">
      {onMoveToFolder && (
        <button {...btnProps} onClick={handleMove}>
          Move
        </button>
      )}
      {onRename && (
        <button {...btnProps} onClick={handleRename}>
          Rename
        </button>
      )}
      {onDelete && (
        <button {...btnProps} onClick={handleDelete}>
          Delete
        </button>
      )}
    </div>
  );
};

export default FolderMenu;
