import { IMainState, ISceneState } from './../../interfaces/index';
import { uniqBy, orderBy } from 'lodash';
import { logEvent } from './../../analytics/index';
import React from 'react';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import {
  activateScene as activateSceneService,
  deleteLibraryScene,
  getActivatedSceneById,
  getLibrarySceneById,
  saveEditorJson,
  saveLibraryScene,
  searchScene,
  updateSceneAttributes
} from '../../advisorHub/clientSideServices/library';
import { useUserTeams } from '../../advisorHub/components/hooks/teams';
import {
  ILibraryScene,
  ITagsVisibilityPayload,
  LibrarySceneType,
  LoadingStatus
} from '../../interfaces';
import { useLoadingStatus } from './loading';
import {
  DELETING_SCENE,
  DELETING_SCENE_FAILED,
  DELETING_SCENE_SUCCESSFUL,
  LOADING_SCENES,
  LOADING_SCENES_FAILED,
  LOADING_SCENES_SUCCESSFUL,
  LOADING_SCENE,
  LOADING_SCENE_FAILED,
  LOADING_SCENE_SUCCESSFUL,
  ACTIVATING_SCENE,
  ACTIVATING_SCENE_FAILED,
  ACTIVATING_SCENE_SUCCESSFUL,
  SAVING_SCENE,
  SAVING_SCENE_SUCCESSFUL,
  SAVING_SCENE_FAILED,
  UPDATING_SCENE_ATTRIBUTES,
  UPDATING_SCENE_ATTRIBUTES_FAILED,
  UPDATING_SCENE_ATTRIBUTES_SUCCESSFUL
} from '../../advisorHub/utils/analyticsEvents/library';
import { mapSceneForClone, mapSceneToPayload } from '../../mappers/library';
import {
  CLONING,
  CLONING_FAILED,
  CLONING_SUCCESSFUL
} from '../../utils/constants';
import { generateV4UUID } from '../../utils/identityGenerator';
import { useDispatch, useSelector } from 'react-redux';
import { addUserInfoAsync } from '../../redux/advisorHubAsyncActions';
import { SHARED_TEAM_FOLDER_ID } from '../../advisorHub/utils/folders';
import { getCollaborationsByUser } from '../../advisorHub/clientSideServices/collaboration';
import { actionUpdateScene } from '../../redux/actions';
import { removeUnusedFontsInScene } from '../../utils/polotno';

export const useLibrarySearch = ({
  userId,
  filterScope,
  excludeScope,
  keywords,
  activatedOnly,
  includeSharedToMe,
  initScenes,
  excludeMine,
  context,
  collaboration
}: {
  keywords: string;
  userId: string;
  filterScope?: string[];
  excludeScope?: string[];
  activatedOnly?: boolean;
  includeSharedToMe?: boolean;
  initScenes?: ILibraryScene[];
  context?: LibrarySceneType;
  excludeMine?: boolean;
  collaboration?: boolean;
}) => {
  const dispatch = useDispatch();
  const [hasMoreResult, setHasMoreResult] = React.useState(true);
  const [scenes, setScenes] = React.useState<ILibraryScene[]>([]);

  const [collaboratedScenes, setCollaboratedScenes] = React.useState<
    ILibraryScene[]
  >([]);
  const preferredScenes = collaboration ? collaboratedScenes : scenes;
  const preferredSetScenes = collaboration ? setCollaboratedScenes : setScenes;
  const [loading, setLoading] = React.useState(LoadingStatus.INITIAL);
  const [startIndex, setStartIndex] = React.useState(0);
  const [activeTeamId, setActiveTeamId] = React.useState<string>(undefined);
  const { isSuccess, data } = useUserTeams();

  const updateStatus = (status: LoadingStatus, event: string, payload: any) => {
    setLoading(status);
    logEvent(event, event, payload);
  };

  const loadCollaborationScenes = () => {
    const query = { keywords, collaboration: true };
    updateStatus(LoadingStatus.LOADING, LOADING_SCENES, query);
    getCollaborationsByUser({ userId, context: 'scene' })
      .then((res) => {
        updateStatus(LoadingStatus.LOADED, LOADING_SCENES_SUCCESSFUL, query);
        dispatch(addUserInfoAsync(res.map((r) => r.createdBy)));
        setCollaboratedScenes(res);
      })
      .catch((e) => {
        updateStatus(LoadingStatus.FAILED, LOADING_SCENES_FAILED, {
          ...query,
          error: e
        });
      });
  };

  const loadScenes = (fresh: boolean, teamId?: string) => {
    if (!userId) return;

    if (collaboration) {
      loadCollaborationScenes();
      return;
    }

    const _startIndex = fresh ? 0 : startIndex;

    const query = {
      userId,
      keywords,
      startIndex: _startIndex,
      activatedOnly,
      teamIds: includeSharedToMe
        ? teamId &&
          (teamId !== SHARED_TEAM_FOLDER_ID ||
            (teamId === SHARED_TEAM_FOLDER_ID && keywords?.length === 0))
          ? [teamId]
          : data?.map((team) => team.id)
        : undefined,
      excludeMine,
      context
    };

    updateStatus(LoadingStatus.LOADING, LOADING_SCENES, query);

    searchScene(query)
      .then((resScenes) => {
        setScenes(
          fresh
            ? keywords || includeSharedToMe
              ? resScenes
              : [...(initScenes || []), ...resScenes]
            : [...scenes, ...resScenes]
        );
        setHasMoreResult(resScenes.length > 0);
        setStartIndex((fresh ? 0 : startIndex) + (resScenes?.length || 0));
        updateStatus(LoadingStatus.LOADED, LOADING_SCENES_SUCCESSFUL, query);
        dispatch(
          addUserInfoAsync(resScenes.map((scene) => scene.createdBy || ''))
        );
      })
      .catch((e) => {
        updateStatus(LoadingStatus.FAILED, LOADING_SCENES_FAILED, {
          ...query,
          error: e
        });
      });
  };

  const infiniteRef: React.MutableRefObject<any> = useInfiniteScroll({
    loading: loading === LoadingStatus.LOADING,
    hasNextPage: hasMoreResult,
    onLoadMore: () => {
      loadScenes(false, activeTeamId);
    }
  });

  const filteredCommonScenes =
    (filterScope || excludeScope) && !activeTeamId
      ? scenes?.filter((s) =>
          excludeScope && !filterScope
            ? !excludeScope.includes(s.id)
            : excludeScope && filterScope
            ? filterScope.includes(s.id) && !excludeScope.includes(s.id)
            : filterScope.includes(s.id)
        )
      : scenes;

  const filteredCollaboratedScenes = collaboratedScenes?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().includes(keywords.toLowerCase()) &&
      item.type === context
  );

  const filteredScenes = collaboration
    ? orderBy(
        filteredCollaboratedScenes,
        (item) => new Date(item.modifiedAt),
        'desc'
      )
    : filteredCommonScenes;

  const hasNoScenes =
    keywords.length === 0 &&
    filteredScenes?.length === 0 &&
    loading === LoadingStatus.LOADED;

  const hasNoSearchResult =
    keywords.length > 0 &&
    filteredScenes?.length === 0 &&
    loading === LoadingStatus.LOADED;

  const updateSceneInList = (scene: ILibraryScene) => {
    const index = preferredScenes.findIndex((s) => s.id === scene.id);
    if (index > -1) {
      preferredSetScenes([
        ...preferredScenes.slice(0, index),
        scene,
        ...preferredScenes.slice(index + 1)
      ]);
    }
  };

  const deleteScenesInList = (ids: string[]) => {
    const newScenes = preferredScenes.filter((s) => !ids.includes(s.id));
    preferredSetScenes(newScenes);
  };

  const addSceneToList = (scene: ILibraryScene, afterId?: string) => {
    const index = preferredScenes.findIndex((s) => s.id === afterId);
    if (index > -1) {
      preferredSetScenes([
        ...preferredScenes.slice(0, index + 1),
        scene,
        ...preferredScenes.slice(index + 1)
      ]);
    } else {
      preferredSetScenes([...preferredScenes, scene]);
    }
  };
  return {
    scenes: uniqBy(filteredScenes, 'id'),
    loading,
    hasNoScenes,
    hasNoSearchResult,
    hasMoreResult,
    loadScenes: (fresh: boolean, teamId?: string) => {
      loadScenes(fresh, teamId);
      setActiveTeamId(teamId);
    },
    infiniteRef: collaboration ? null : infiniteRef,
    useLibrarySearchReady: includeSharedToMe ? isSuccess : true,
    updateSceneInList,
    deleteScenesInList,
    addSceneToList,
    setScenes: preferredSetScenes
  };
};

export const useLibraryAction = ({
  userId,
  context
}: {
  userId: string;
  context: LibrarySceneType;
}) => {
  const { setLoadingStatus } = useLoadingStatus();

  const updateStatus = (
    status: LoadingStatus,
    event: string,
    payload: any,
    key: string
  ) => {
    setLoadingStatus(key, status);
    logEvent(event, event, payload);
  };

  const removeScenes = ({
    ids,
    onSuccess,
    onError
  }: {
    ids: string[];
    onSuccess?: (ids: string[]) => void;
    onError?: (ids: string[]) => void;
  }) => {
    const updateRemoveSceneStatus = (
      status: LoadingStatus,
      event: string,
      error?: any
    ) => {
      updateStatus(status, event, { ids, error }, 'deleting-scenes');
    };
    updateRemoveSceneStatus(LoadingStatus.LOADING, DELETING_SCENE);
    Promise.all(ids.map((id) => deleteLibraryScene(id)))
      .then(() => {
        updateRemoveSceneStatus(
          LoadingStatus.LOADED,
          DELETING_SCENE_SUCCESSFUL
        );
        onSuccess?.(ids);
      })
      .catch((e) => {
        updateRemoveSceneStatus(
          LoadingStatus.LOADING,
          DELETING_SCENE_FAILED,
          e
        );
        onError?.(ids);
      });
  };

  const getSceneById = ({
    id,
    version,
    onSuccess,
    onError,
    preventUpdateStatus = false
  }: {
    id: string;
    previousVersion?: number;
    version?: number;
    onSuccess: (scene: ILibraryScene) => void;
    onError?: (id: string, version?: number) => void;
    preventUpdateStatus?: boolean;
  }) => {
    const updateGetSceneByIdStatus = (
      status: LoadingStatus,
      event: string,
      error?: any
    ) => {
      updateStatus(status, event, { id, version, error }, id);
    };

    !preventUpdateStatus &&
      updateGetSceneByIdStatus(LoadingStatus.LOADING, LOADING_SCENE);

    getLibrarySceneById(id, version)
      .then((res) => {
        onSuccess(res);
        !preventUpdateStatus &&
          updateGetSceneByIdStatus(
            LoadingStatus.LOADED,
            LOADING_SCENE_SUCCESSFUL
          );
      })
      .catch((e) => {
        console.log(e);
        onError?.(id, version);
        !preventUpdateStatus &&
          updateGetSceneByIdStatus(
            LoadingStatus.FAILED,
            LOADING_SCENE_FAILED,
            e
          );
      });
  };

  const getActiveSceneById = ({
    id,
    onSuccess,
    onError
  }: {
    id: string;
    onSuccess: (scene: ILibraryScene) => void;
    onError?: (id: string, version?: number) => void;
  }) => {
    const updateGetActiveSceneByIdStatus = (
      status: LoadingStatus,
      event: string,
      error?: any
    ) => {
      updateStatus(status, event, { id, error }, id);
    };

    updateGetActiveSceneByIdStatus(LoadingStatus.LOADING, LOADING_SCENE);

    getActivatedSceneById(id)
      .then((res) => {
        onSuccess(res);
        updateGetActiveSceneByIdStatus(
          LoadingStatus.LOADED,
          LOADING_SCENE_SUCCESSFUL
        );
      })
      .catch((e) => {
        onError?.(id);
        updateGetActiveSceneByIdStatus(
          LoadingStatus.FAILED,
          LOADING_SCENE_FAILED,
          e
        );
      });
  };

  const activateScene = ({
    id,
    onSuccess,
    onError,
    dontReloadAfterSave,
    delay = 1
  }: {
    id: string;
    onSuccess?: (scene: ILibraryScene) => void;
    onError?: (id, error?: any) => void;
    dontReloadAfterSave?: boolean;
    delay?: number;
  }) => {
    const updateActivateStatus = (status: LoadingStatus, event: string) => {
      updateStatus(status, event, { id, userId }, `activating-${id}`);
    };
    updateActivateStatus(LoadingStatus.LOADING, ACTIVATING_SCENE);
    setTimeout(
      () =>
        activateSceneService(id, userId)
          .then(() => {
            const completed = (res: ILibraryScene) => {
              updateActivateStatus(
                LoadingStatus.LOADED,
                ACTIVATING_SCENE_SUCCESSFUL
              );
              onSuccess?.(res);
            };
            if (dontReloadAfterSave) {
              completed({ id });
            } else {
              getSceneById({
                id,
                onSuccess: (res) => {
                  completed(res);
                }
              });
            }
          })
          .catch((e) => {
            onError?.(id, e);
            updateActivateStatus(LoadingStatus.FAILED, ACTIVATING_SCENE_FAILED);
          }),
      delay
    );
  };

  const saveScene = ({
    scene,
    onSuccess,
    onError,
    dontReloadAfterSave
  }: {
    scene: ILibraryScene;
    onSuccess?: (scene: ILibraryScene) => void;
    onError?: (scene: ILibraryScene, error?: any) => void;
    dontReloadAfterSave?: boolean;
  }) => {
    const payload = mapSceneToPayload({ scene, userId, context });

    const updateSaveStatus = (
      status: LoadingStatus,
      event: string,
      extraPayload?: any
    ) => {
      const evtPayload = {
        ...payload,
        ...(extraPayload || {}),
        content: undefined
      };
      updateStatus(status, event, evtPayload, `saving-${scene.id}`);
    };

    updateSaveStatus(LoadingStatus.LOADING, SAVING_SCENE);

    saveLibraryScene(payload)
      .then(() => {
        const onSuccessFull = (res: ILibraryScene) => {
          updateSaveStatus(LoadingStatus.LOADED, SAVING_SCENE_SUCCESSFUL);
          onSuccess?.(res);
        };

        const getScene = () =>
          getSceneById({
            id: scene.id,
            onSuccess: onSuccessFull
          });

        const handleCompleted = dontReloadAfterSave
          ? () => onSuccessFull(scene)
          : getScene;

        if (scene.editor && !scene.content.overlay) {
          saveEditorJson({
            id: scene.id,
            version:
              scene.status !== 'draft'
                ? (scene.version || 0) + 1
                : scene?.version || 1,
            json: scene.editor,
            onSuccess: handleCompleted,
            onError: handleCompleted
          });
        } else {
          handleCompleted();
        }
      })
      .catch((e) => {
        onError?.(scene, e);
        updateSaveStatus(LoadingStatus.FAILED, SAVING_SCENE_FAILED, {
          error: e
        });
      });
  };

  const editScene = ({
    id,
    version,
    onSuccess,
    onError
  }: {
    id: string;
    version: number;
    onSuccess?: (scene: ILibraryScene) => void;
    onError?: (scene: ILibraryScene, error?: any) => void;
  }) => {
    const updateSaveStatus = (status: LoadingStatus, event: string) => {
      updateStatus(status, event, { id }, `saving-${id}`);
    };

    updateSaveStatus(LoadingStatus.LOADING, LOADING_SCENE);

    const handleSuccess = (payload: ILibraryScene) => {
      updateSaveStatus(LoadingStatus.LOADED, LOADING_SCENE);
      onSuccess?.(payload);
    };

    getSceneById({
      id,
      version,
      onSuccess: (res) => {
        const payload = mapSceneToPayload({
          scene: { ...res, status: 'draft' },
          userId,
          context
        });
        saveLibraryScene(payload)
          .then(() => {
            getSceneById({
              id,
              onSuccess: (res2) => {
                if (res.editor && !res.content.overlay) {
                  saveEditorJson({
                    id,
                    version: res2.version,
                    json: res.editor,
                    onSuccess: () =>
                      handleSuccess({ ...res2, editor: res.editor }),
                    onError: () => onError?.(res, 'Error saving editor json')
                  });
                } else {
                  handleSuccess(res2);
                }
              }
            });
          })
          .catch((e) => onError?.(res, e));
      }
    });
  };

  const cloneScene = ({
    id,
    name,
    onSuccess,
    onError
  }: {
    id: string;
    name: string;
    onSuccess?: (scene) => void;
    onError?: (id: string, version?: number) => void;
  }) => {
    const updateCloneStatus = (
      status: LoadingStatus,
      event: string,
      extraPayload?: any
    ) => {
      updateStatus(
        status,
        event,
        { type: 'scene', id, name, ...(extraPayload || {}) },
        'cloning-scene'
      );
    };

    const cloneError = (e: any) => {
      updateCloneStatus(LoadingStatus.FAILED, CLONING_FAILED, { error: e });
    };

    const saveClonedScene = (scene: ILibraryScene) => {
      const newScene = mapSceneForClone(scene, name);
      saveScene({
        scene: removeUnusedFontsInScene(newScene),
        onSuccess: (res) => {
          updateCloneStatus(LoadingStatus.LOADED, CLONING_SUCCESSFUL);
          onSuccess?.(res);
        }
      });
    };

    updateCloneStatus(LoadingStatus.LOADING, CLONING);

    getSceneById({
      id,
      onSuccess: (scene) => {
        saveClonedScene(scene);
      },
      onError: (id) => {
        cloneError('error when getting scene');
        onError(id);
      }
    });
  };

  const updateTagsVisibility = ({
    data,
    onSuccess,
    onError
  }: {
    data: ITagsVisibilityPayload;
    onSuccess?: () => void;
    onError?: () => void;
  }) => {
    const updateSaveStatus = (
      status: LoadingStatus,
      event: string,
      extraPayload?: any
    ) => {
      updateStatus(
        status,
        event,
        { ...data, ...(extraPayload || {}) },
        data.id
      );
    };

    updateSaveStatus(LoadingStatus.LOADING, UPDATING_SCENE_ATTRIBUTES);

    updateSceneAttributes(data)
      .then(() => {
        onSuccess?.();
        updateSaveStatus(
          LoadingStatus.LOADED,
          UPDATING_SCENE_ATTRIBUTES_SUCCESSFUL
        );
      })
      .catch((e) => {
        onError?.();
        updateSaveStatus(
          LoadingStatus.FAILED,
          UPDATING_SCENE_ATTRIBUTES_FAILED,
          {
            error: e
          }
        );
      });
  };

  const createScene = ({
    type,
    scene,
    onSuccess,
    onError
  }: {
    type: `video` | 'image' | 'layer';
    scene?: ILibraryScene;
    onSuccess: (scene: ILibraryScene) => void;
    onError: (scene: ILibraryScene, error: any) => void;
  }) => {
    saveScene({
      scene: {
        id: generateV4UUID(),
        content: {
          ...scene?.content,
          settings: JSON.stringify({ settingJSONVersion: 1, type })
        }
      },
      onSuccess,
      onError
    });
  };

  return {
    editScene,
    createScene,
    removeScenes,
    getSceneById,
    activateScene,
    saveScene,
    updateTagsVisibility,
    cloneScene,
    getActiveSceneById
  };
};

export const useSceneState = (): {
  scene?: ILibraryScene;
  dataHasChanged?: boolean;
  updateSceneState: (state?: ISceneState) => void;
  setScene: (state?: ILibraryScene) => void;
  setDataHasChanged: (state?: boolean) => void;
} => {
  const dispatch = useDispatch();

  const state = useSelector(
    (state: IMainState) => state.clientState.hub?.scene || {}
  );

  const updateSceneState = (payload: ISceneState) => {
    dispatch(actionUpdateScene(payload));
  };

  const setScene = (s?: ILibraryScene) => {
    updateSceneState({
      ...state,
      currentScene: s
    });
  };

  const setDataHasChanged = (s?: boolean) => {
    updateSceneState({
      ...state,
      dataHasChanged: s
    });
  };

  return {
    scene: state.currentScene,
    dataHasChanged: state.dataHasChanged,
    updateSceneState,
    setScene,
    setDataHasChanged
  };
};
