import { partition } from 'lodash';
import React from 'react';
import { MdAddCircleOutline, MdRemoveCircleOutline } from 'react-icons/md';
import { useUserTeams } from '../hooks/teams';

const MyTeamsSelection = ({
  selectedTeams,
  onSelectTeam,
  onDeselectTeam
}: {
  selectedTeams: string[];
  onSelectTeam: (teamId: string) => void;
  onDeselectTeam: (teamId: string) => void;
}) => {
  const [keywords, setKeywords] = React.useState('');

  const { isLoading, data, error } = useUserTeams();
  const [selected, unselected] = partition(data || [], (t) =>
    selectedTeams.includes(t.id)
  );

  const unselectedTeams = unselected
    .filter((t) => t.name.toLowerCase().includes(keywords.toLowerCase()))
    .map((t) => (
      <li
        key={t.id}
        onClick={() => {
          onSelectTeam(t.id);
        }}
      >
        {t.name} <MdAddCircleOutline />
      </li>
    ));

  const renderAvailableTeams = () => {
    if (isLoading) {
      return 'Loading your teams.';
    }
    if (error) {
      return 'An error occurred, please try again later';
    }
    return unselectedTeams.length ? unselectedTeams : 'No team available.';
  };

  return (
    <>
      <div className="teams-selection">
        <div className="selection">
          <p>Share to specific team(s)</p>
          <ul className="candidates selected">
            {selected.map((t) => (
              <li
                key={t.id}
                onClick={() => {
                  onDeselectTeam(t.id);
                }}
              >
                {t.name} <MdRemoveCircleOutline />
              </li>
            ))}
          </ul>
          <div className="search">
            <input
              type="text"
              className="form-control"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setKeywords(e.target.value);
              }}
            />
          </div>
        </div>
        <ul className="candidates unselected">{renderAvailableTeams()}</ul>
      </div>
      <style jsx>{`
        .teams-selection {
          margin-top: 20px;
        }

        .selection,
        .unselected {
          margin: 0 40px;
        }

        .candidates.selected {
          margin-left: 20px;
        }

        .search input {
          background: transparent;
          color: #fff;
        }

        .selection {
          margin-bottom: 20px;
        }

        .selection p {
          margin-bottom: 5px;
        }

        .selection .search {
          display: flex;
        }

        .selection .btn {
          background: #fff;
          color: #000;
          margin-left: 20px;
          padding-left: 20px;
          padding-right: 20px;
        }

        .candidates.selected {
          max-height: 120px;
          overflow-y: scroll;
        }

        .candidates.unselected {
          max-height: 300px;
          overflow-y: scroll;
        }

        .teams-selection :global(svg) {
          float: right;
        }

        .teams-selection ul {
          padding: 0;
          list-style: none;
        }

        :global(.teams-selection li) {
          cursor: pointer;
          margin-bottom: 5px;
        }

        @media (max-height: 768px) {
          .candidates.unselected {
            max-height: 150px;
          }
        }
      `}</style>
    </>
  );
};

export default MyTeamsSelection;
