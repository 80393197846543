import React from 'react';
import { logEvent, logKeyPress } from '../../../../analytics';
import EyeballTimeLogger from '../../../../components/EyeballTimeLogger';
import {
  IStorybook,
  IStorybookPage,
  PageContentView
} from '../../../../interfaces';
import { TLanguage } from '../../../../mappers/polotno';
import { DID_SELECT_PAGE, DID_VIEW_CONTENT } from '../../../../utils/constants';
import { isVideo } from '../../../../utils/file';
import { scrollElementIntoViewById } from '../../../../utils/window';
import {
  getContentUrl,
  getLanguaImageFromJSON,
  getSBPageSetting
} from '../../../utils/storybook';
import { TDeviceView } from '../../Common/DeviceViewToggle';
import PhoneMockup from '../../PhoneMockup';
import SBPageThumbnail from '../Common/SBPageThumbnail';
import SBPreviewDefaultPage from './SBPreviewDefaultPage';

const SBPreviewDefault = ({
  storybook,
  deviceView,
  activateLanguage
}: {
  storybook: IStorybook;
  deviceView: TDeviceView;
  activateLanguage?: TLanguage;
}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const isPortrait = deviceView === 'mobile';
  const updateIndex = (index: number) => {
    scrollElementIntoViewById(`page-${index}`);
    setActiveIndex(index);
    const newPage = storybook?.pages[index];
    const language = 'en';
    const url = getLanguaImageFromJSON(newPage?.url, language);
    logEvent(DID_SELECT_PAGE, DID_SELECT_PAGE, {
      id: storybook.id,
      title: storybook.title,
      contentUrl: url,
      contentTitle: newPage?.title,
      contentSubtitle: newPage?.subtitle,
      pageNumber: index + 1
    });
  };

  const pageThumbnail = (index: number, item: IStorybookPage) => {
    const { thumbnail, portraitThumbnail, url, background } = getContentUrl(
      item,
      item.settings,
      activateLanguage
    );

    return (
      <div className="pageThumbnail" key={`page-${index}`}>
        <div className="order">
          <strong>{index + 1}</strong>
        </div>
        <div className="page-container">
          <SBPageThumbnail
            title={item.title}
            subtitle={item.subtitle}
            url={thumbnail}
            portraitUrl={portraitThumbnail}
            active={index === activeIndex}
            onClick={() => updateIndex(index)}
            contentUrl={item.url}
            portrait={isPortrait}
            thumbnailObjectFit={isVideo(url) ? 'cover' : 'contain'}
            thumbnailColor={background}
          />
        </div>
        <style jsx>{`
          .pageThumbnail {
            display: flex;
            flex-wrap: wrap;
            font-size: 13px;
            margin-bottom: 5px;
          }
          .order {
            width: 20px;
            padding-top: 5px;
          }
          .pageThumbnail .page-container {
            width: calc(100% - 20px);
          }
          .pageThumbnail :global(.portrait.media-container) {
            width: 100%;
            height: 0;
            padding-top: 170%;
          }
        `}</style>
      </div>
    );
  };
  const selectedPage = storybook?.pages[activeIndex];
  const contentLength = storybook?.pages.length;

  const hasMultiplePages = storybook?.pages.length > 1;

  const handleKeyDown = React.useCallback(
    (e) => {
      e.preventDefault();
      e = e || window.event;
      if (e.keyCode == '38') {
        updateIndex(activeIndex > 0 ? activeIndex - 1 : 0);
        logKeyPress('38');
      }
      if (e.keyCode == '40') {
        updateIndex(
          activeIndex < contentLength - 1 ? activeIndex + 1 : contentLength - 1
        );
        logKeyPress('40');
      }
    },
    [activeIndex]
  );

  const previewPage = (
    <SBPreviewDefaultPage
      page={selectedPage}
      setting={getSBPageSetting(
        selectedPage?.settings || '',
        storybook?.settings || ''
      )}
      portrait={isPortrait}
      activateLanguage={activateLanguage}
    />
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  React.useEffect(() => {
    logEvent(DID_VIEW_CONTENT, DID_VIEW_CONTENT, {
      contentType: PageContentView.STORYBOOK_VIEWER,
      id: storybook.id,
      title: storybook.title,
      viewerType: 'default'
    });
  }, []);

  React.useEffect(() => {
    logEvent(DID_VIEW_CONTENT, DID_VIEW_CONTENT, {
      contentType: PageContentView.STORYBOOK_VIEWER,
      id: storybook.id,
      title: storybook.title,
      viewerType: 'default',
      organisationId: storybook.brand,
      uuid: selectedPage.id,
      pageId: selectedPage.id
    });
  }, [selectedPage]);

  return (
    <EyeballTimeLogger
      tracker={{
        view: PageContentView.STORYBOOK_VIEWER,
        id: storybook.id,
        title: storybook.title
      }}
    >
      <div className={`SBPreviewDefault d-flex ${deviceView}`}>
        {hasMultiplePages && (
          <div className="pages">
            {storybook?.pages.map((item, index) => pageThumbnail(index, item))}
          </div>
        )}

        <EyeballTimeLogger
          key={selectedPage?.url}
          tracker={{
            view: PageContentView.CONTENT_PAGE,
            id: storybook.id,
            title: storybook.title,
            contentUrl: getLanguaImageFromJSON(selectedPage?.url, 'en'),
            contentTitle: selectedPage?.title,
            contentSubtitle: selectedPage?.subtitle,
            pageNumber: activeIndex + 1
          }}
        >
          {null}
        </EyeballTimeLogger>
        {deviceView === 'mobile' || deviceView === 'mobile-landscape' ? (
          <PhoneMockup landscape={deviceView === 'mobile-landscape'}>
            {previewPage}
          </PhoneMockup>
        ) : (
          previewPage
        )}
      </div>
      <style jsx>{`
        .preview-container {
          width: 100%;
          height: 100%;
        }
        .SBPreviewDefault {
          overflow: hidden;
          width: 100%;
          height: 100%;
          margin: auto;
        }

        .pages {
          height: 100%;
          width: 250px;
          background: #fff;
          border-right: 1px solid #ccc;
          padding: 30px;
          overflow-y: auto;
        }
      `}</style>
    </EyeballTimeLogger>
  );
};

export default SBPreviewDefault;
