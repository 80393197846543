import React from 'react';
import { logEvent } from '../../../../analytics';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import SBEnlargedPage from '../PageDesigner/SBEnlargedPage';
import { StoryBookContext } from '../SBContextContainer';
import SBSorterContainer from './SBSorterContainer';

const SBSorter = () => {
  const { action, selectedStorybookPage } = React.useContext(StoryBookContext);
  return (
    <div className="SBSorter d-flex h-100 flex-column">
      <div className="sorter-header">
        <h2>Create Storybook</h2>
        <button
          className="btn btn-round btn-dark btn-sm btn-close-page"
          onClick={() => {
            action.navigate();
            logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
              button: 'Close Sorting Page'
            });
          }}
        >
          Close Sorting
        </button>
      </div>
      <div className="sorter-container d-flex h-100">
        <div className="flex-grow-1 d-flex col-sort">
          <SBSorterContainer />
        </div>

        {selectedStorybookPage && (
          <div className="col-enlarge d-flex">
            <div className="h-100 w-100">
              <SBEnlargedPage />
            </div>
          </div>
        )}
      </div>

      <style jsx global>{`
        .StoryBook .content-wrapper {
          padding: 0 20px 20px !important;
          background: #f6f6f6;
        }
      `}</style>

      <style jsx>{`
        .col-sort {
          padding-right: ${selectedStorybookPage ? '15px' : '0'};
          width: ${selectedStorybookPage ? '50%' : '100%'};
        }
        .col-enlarge {
          width: 50%;
          max-width: 1000px;
          padding-left: 15px;
        }
        .sorter-header {
          display: flex;
          justify-content: space-between;
          padding: 20px 0;
        }
        .sorter-container {
          overflow: hidden;
        }
        .sorter-container > div > :global(div) {
          background: #fff;
          border-radius: 10px;
          padding: 5px 20px 20px;
        }
        .SBSorter :global(.panel-label) {
          font-weight: bold;
          font-size: 14px;
        }
        .SBSorter :global(label) {
          font-weight: bold;
          font-size: 12px;
        }
        .SBSorter :global(.panel-header) {
          border-bottom: 1px solid #ccc;
          padding: 10px 0;
        }
        .SBSorter :global(.panel-content) {
          height: calc(100% - 52px);
          overflow: auto;
        }
        .SBSorter :global(.panel-header .form-control) {
          margin-top: 7px;
        }
        .SBSorter :global(.col-side) {
          width: 370px;
          min-width: 370px;
        }
      `}</style>
    </div>
  );
};

export default SBSorter;
