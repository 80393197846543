import { orderBy, partial, uniqBy } from 'lodash';
import React from 'react';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';
import { useDispatch } from 'react-redux';
import { getCollaborationsByUser } from '../../advisorHub/clientSideServices/collaboration';
import { useUserTeams } from '../../advisorHub/components/hooks/teams';
import { SBActionTarget } from '../../advisorHub/components/StoryBook/SBActionHandler';
import { StoryBookContext } from '../../advisorHub/components/StoryBook/SBContextContainer';
import { SHARED_TEAM_FOLDER_ID } from '../../advisorHub/utils/folders';
import { sortStorybooks } from '../../advisorHub/utils/storybook';
import { logEvent } from '../../analytics';
import { IStorybook, LoadingStatus } from '../../interfaces';
import { mapResponseToStorybook } from '../../mappers/storybook';
import { addUserInfoAsync } from '../../redux/advisorHubAsyncActions';
import {
  LOADING_STORYBOOKS,
  LOADING_STORYBOOKS_FAILED,
  LOADING_STORYBOOKS_SUCCESSFUL
} from '../../utils/constants';

export const useStorybookSearch = ({
  includeSharedToMe,
  activatedOnly,
  excludeMine,
  keywords,
  filterScope,
  excludeScope,
  sharedWithMe,
  userId,
  brandId,
  collaboration
}: {
  includeSharedToMe: boolean;
  activatedOnly?: boolean;
  excludeMine?: boolean;
  sharedWithMe?: boolean;
  keywords: string;
  filterScope?: string[];
  excludeScope?: string[];
  userId?: string;
  brandId?: string;
  collaboration?: boolean;
}) => {
  const dispatch = useDispatch();
  const { action, state } = React.useContext(StoryBookContext);
  const [storybooks, setStorybooks] = React.useState<IStorybook[]>([]);
  const [collaborationLoading, setCollaborationLoading] =
    React.useState<LoadingStatus>(LoadingStatus.INITIAL);
  const [collaborationStorybooks, setCollaborationStorybooks] =
    React.useState<IStorybook[]>(undefined);
  const [startIndex, setStartIndex] = React.useState(0);
  const [hasMoreResult, setHasMoreResult] = React.useState(true);
  const [activeTeamId, setActiveTeamId] = React.useState<string>(undefined);
  const { isSuccess, data } = useUserTeams(userId, brandId);
  const loading = collaboration
    ? collaborationLoading
    : state?.loadingStatus?.['any'];

  const filteredStorybooks =
    (filterScope || excludeScope) && !activeTeamId
      ? storybooks?.filter((s) =>
          excludeScope && !filterScope
            ? !excludeScope.includes(s.id)
            : excludeScope && filterScope
            ? filterScope.includes(s.id) && !excludeScope.includes(s.id)
            : filterScope.includes(s.id)
        )
      : storybooks;

  const filteredCollaborationStorybooks = collaborationStorybooks?.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().includes(keywords.toLowerCase())
  );

  const filteredStorybooks4SharedWithMe = filteredStorybooks?.filter(
    (s) => s.visibility === 'team' && s.visibilityScope.length
  );

  const preferredStorybooks = sharedWithMe
    ? filteredStorybooks4SharedWithMe
    : collaboration
    ? filteredCollaborationStorybooks
    : filteredStorybooks;

  const hasNoStorybook =
    keywords.length === 0 &&
    preferredStorybooks?.length === 0 &&
    loading !== LoadingStatus.FAILED;

  const hasNoSearchResult =
    keywords.length > 0 &&
    preferredStorybooks?.length === 0 &&
    loading === LoadingStatus.LOADED;

  const infiniteRef: React.MutableRefObject<any> = useInfiniteScroll({
    loading: loading === LoadingStatus.LOADING,
    hasNextPage: hasMoreResult,
    onLoadMore: () => {
      loadStorybooks(false, activeTeamId);
    }
  });

  const updateCollaborationStatus = (
    status: LoadingStatus,
    event: string,
    payload: any
  ) => {
    setCollaborationLoading(status);
    logEvent(event, event, payload);
  };

  const loadCollaborationStorybooks = () => {
    const query = { keywords, collaboration: true };
    updateCollaborationStatus(LoadingStatus.LOADING, LOADING_STORYBOOKS, query);
    getCollaborationsByUser({ userId, context: 'storybook' })
      .then((res) => {
        updateCollaborationStatus(
          LoadingStatus.LOADED,
          LOADING_STORYBOOKS_SUCCESSFUL,
          query
        );
        const mappedResult = res?.map(mapResponseToStorybook);
        dispatch(addUserInfoAsync(res.map((r) => r.createdBy)));
        setCollaborationStorybooks(
          orderBy(mappedResult, (item) => new Date(item.modifiedAt), 'desc')
        );
      })
      .catch((e) => {
        updateCollaborationStatus(
          LoadingStatus.FAILED,
          LOADING_STORYBOOKS_FAILED,
          {
            ...query,
            error: e
          }
        );
      });
  };

  const loadStorybooks = (fresh: boolean, teamId?: string) => {
    if (collaboration) {
      loadCollaborationStorybooks();
      return;
    }
    if (!action?.read) {
      return;
    }
    const performSearch = partial(
      action.read,
      activatedOnly
        ? SBActionTarget.ActivatedStorybooks
        : SBActionTarget.Storybooks
    );
    const _startIndex = fresh ? 0 : startIndex;
    performSearch(
      {
        startIndex: _startIndex,
        keywords,
        excludeMine,
        teamIds: includeSharedToMe
          ? teamId &&
            (teamId !== SHARED_TEAM_FOLDER_ID ||
              (teamId === SHARED_TEAM_FOLDER_ID && keywords?.length === 0))
            ? [teamId]
            : data?.map((team) => team.id)
          : undefined
      },
      (result: { storybooks: IStorybook[]; totalCount: number }) => {
        const searchResult = result.storybooks;
        const uniqueStorybooks = uniqBy(
          sortStorybooks([
            ...(searchResult || []),
            ...(fresh ? [] : storybooks || [])
          ]),
          'id'
        );
        setHasMoreResult(searchResult.length > 0);
        setStorybooks(sortStorybooks(uniqueStorybooks));
        setStartIndex((fresh ? 0 : startIndex) + (searchResult?.length || 0));
      }
    );
  };

  return {
    storybooks: preferredStorybooks,
    allStorybookIds: (preferredStorybooks || []).map((s) => s.id),
    hasMoreResult: collaboration ? false : hasMoreResult,
    infiniteRef: collaboration ? undefined : infiniteRef,
    hasNoStorybook,
    hasNoSearchResult,
    loading,
    useStorybookSearchReady: includeSharedToMe ? isSuccess : true,
    loadStorybooks: (fresh: boolean, teamId?: string) => {
      loadStorybooks(fresh, teamId);
      setActiveTeamId(teamId);
    },
    allStoryBooks: collaboration ? collaborationStorybooks : storybooks,
    setStorybooks: collaboration ? setCollaborationStorybooks : setStorybooks
  };
};
export type ISBAnalyticsSortType = 'increase' | 'decrease';
export type ISBAnalyticsSortBy = 'views' | 'avgDurationInMs';
export const useStorybookAnalyticsSort = (
  type: ISBAnalyticsSortType = 'decrease',
  by: ISBAnalyticsSortBy = 'views'
) => {
  const [sortBy, setSortBy] = React.useState<ISBAnalyticsSortBy>(by);
  const [sortType, setSortType] = React.useState<ISBAnalyticsSortType>(type);
  const sort = (by: ISBAnalyticsSortBy) => {
    let type: ISBAnalyticsSortType = 'decrease';
    if (sortBy === by) {
      type = sortType === 'decrease' ? 'increase' : 'decrease';
    } else {
      setSortBy(by);
    }
    setSortType(type);
    return { type, by };
  };
  return {
    sortType,
    sortBy,
    sort
  };
};
