import React from 'react';
import { Spinner } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../analytics';
import { IPlayerControl } from '../../interfaces';
import { actionUpdateLocalPlayerControlCurrentScene } from '../../redux/actions';
import {
  DID_CLICK_MUTE_VIDEO,
  DID_CLICK_PLAY_VIDEO,
  DID_CLICK_UNMUTE_VIDEO,
  DID_SEEK_VIDEO,
  VIDEO_ERROR,
  VIDEO_PAUSED,
  VIDEO_PLAYED,
  WILL_SEEK_VIDEO
} from '../../utils/constants';
import { handleRetryPlayVideo, setAudioOutputDevice } from '../../utils/video';
import CSRComponentWrapper from '../CSRComponentWrapper';
import { useUpdateVideoUrlForIOSInMeeting } from '../hooks/meeting';

interface PlayerProgressInfo {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

const VideoViewer = ({
  url,
  viewOnly,
  shouldPlay = false,
  seekTo = 0,
  currentProgress = 0,
  onControl,
  mute,
  onMute,
  onEnded,
  loop = false,
  autoplay,
  buffering,
  setBuffering,
  activeAudioOutput,
  silence,
  fullPage
}: {
  url: string;
  viewOnly: boolean;
  shouldPlay?: boolean;
  seekTo?: number;
  currentProgress?: number;
  onControl?: (state: IPlayerControl) => void;
  mute?: boolean;
  onMute?: (mute: boolean, reason?: string) => void;
  onEnded?: () => void;
  loop?: boolean;
  autoplay?: boolean;
  buffering: boolean;
  setBuffering: (buffering: boolean) => void;
  activeAudioOutput?: string;
  silence?: boolean;
  fullPage?: boolean;
}) => {
  const [playing, setPlaying] = React.useState(false);
  const [didStart, setDidStart] = React.useState(false);
  const [retryCount, setRetryCount] = React.useState(0);
  const playerRef = React.useRef<ReactPlayer>();
  const dispatch = useDispatch();
  const hiresUrl = url.replace(`/index.m3u8`, `/index_high.m3u8`);
  const preferredUrl = loop ? hiresUrl : url;
  const updatedUrl = useUpdateVideoUrlForIOSInMeeting(preferredUrl);

  const updateState = (state: IPlayerControl) => {
    onControl?.(state);
  };

  const logVideoEvent = (
    event: string,
    payload: object = {},
    sceneAt?: any
  ) => {
    logEvent(event, event, {
      video: url,
      seekToSeconds: sceneAt,
      ...payload
    });
  };

  const handlePlayVideo = () => {
    console.log('MEDIA PLAYER: play video');
    setPlaying(true);
    if (!viewOnly) {
      updateState({ play: true });
    }
    logVideoEvent(VIDEO_PLAYED);
  };

  const handlePauseVideo = () => {
    console.log('MEDIA PLAYER: pause video');
    if (!viewOnly) {
      updateState({ play: false });
    }
    logVideoEvent(VIDEO_PAUSED);
  };

  const handleProgress = (info: PlayerProgressInfo) => {
    setBuffering(false);
    if (!viewOnly) {
      dispatch(actionUpdateLocalPlayerControlCurrentScene(info.playedSeconds));
    }
  };

  const handleSeekTo = (second: number) => {
    console.log('MEDIA PLAYER: Streaming jump to scene ', second);
    if (!viewOnly) {
      updateState({ seekTo: second });
    }
    logVideoEvent(DID_SEEK_VIDEO, {}, second);
  };

  const onParticipantVideoReady = () => {
    if (currentProgress) {
      playerRef?.current?.seekTo(currentProgress, 'seconds');
    }
    setPlaying(shouldPlay);
  };

  const onAdvisorVideoReady = () => {
    const isOkayToPlay = autoplay ?? true;
    setPlaying(isOkayToPlay);
    updateState({ play: isOkayToPlay });
  };

  const handleDuration = (duration: number) => {
    console.log('Streaming duration:', duration);
    viewOnly ? onParticipantVideoReady() : onAdvisorVideoReady();
  };

  const handleError = (e, data, hlsInstance, hlsGlobal) => {
    console.log('video error', e, data);

    setPlaying(false);
    setBuffering(false);

    if (e !== 'hlsError') {
      onMute?.(true, 'error');
    }
    if (retryCount < 20) {
      const currentRetry = retryCount + 1;
      setTimeout(() => {
        setRetryCount(currentRetry);
        handleRetryPlayVideo({
          error: e,
          data,
          hlsInstance,
          hlsGlobal,
          videoUrl: url,
          retryCount: currentRetry,
          onRetry: () => {
            setPlaying(true);
          }
        });
      }, 100);
    }
    logEvent(VIDEO_ERROR, VIDEO_ERROR, { error: e, details: data });
  };

  const getRecoveryButtons = () => {
    if (!shouldPlay || silence) return null;
    if (!playing) {
      return (
        <div className="mute-button">
          <button
            className="btn"
            onClick={() => {
              setPlaying(true);
              logEvent(DID_CLICK_PLAY_VIDEO, DID_CLICK_PLAY_VIDEO);
            }}
          >
            PLAY
          </button>
        </div>
      );
    }
    if (loop) return null;
    return (
      <div className="mute-button">
        {mute ? (
          <button
            className="btn"
            onClick={() => {
              onMute(false);
              setPlaying(true);
              logEvent(DID_CLICK_UNMUTE_VIDEO, DID_CLICK_UNMUTE_VIDEO);
            }}
          >
            UNMUTE SOUND
          </button>
        ) : (
          <button
            className="btn btn-mute"
            onClick={() => {
              onMute(true);
              setPlaying(true);
              logEvent(DID_CLICK_MUTE_VIDEO, DID_CLICK_MUTE_VIDEO);
            }}
          >
            MUTE SOUND
          </button>
        )}
      </div>
    );
  };

  React.useEffect(() => {
    if (viewOnly && seekTo) {
      console.log(`seekingTo ${seekTo} seconds`);
      logVideoEvent(WILL_SEEK_VIDEO, {}, seekTo);
      playerRef?.current?.seekTo(seekTo, 'seconds');
    }
  }, [seekTo, viewOnly]);

  React.useEffect(() => {
    if (viewOnly) {
      console.log(`setting playing to ${shouldPlay}`);
      setPlaying(shouldPlay);
    }
  }, [shouldPlay, viewOnly]);

  React.useEffect(() => {
    if (didStart && activeAudioOutput && playerRef?.current) {
      const videoElement = playerRef?.current?.getInternalPlayer();
      setAudioOutputDevice(videoElement, activeAudioOutput);
    }
  }, [activeAudioOutput, didStart, playerRef?.current]);
  return (
    <div className="doc video-document">
      <CSRComponentWrapper>
        <ReactPlayer
          ref={playerRef}
          width="100%"
          height="100%"
          onStart={() => setDidStart(true)}
          url={updatedUrl}
          muted={mute}
          controls={!viewOnly}
          autoPlay
          playsinline
          volume={1}
          playing={playing}
          onBuffer={() => setBuffering(true)}
          onBufferEnd={() => setBuffering(false)}
          onPlay={handlePlayVideo}
          onPause={handlePauseVideo}
          onProgress={handleProgress}
          onSeek={handleSeekTo}
          onDuration={handleDuration}
          onError={handleError}
          onEnded={onEnded}
          loop={loop}
        />
      </CSRComponentWrapper>
      {buffering && (
        <div className="loading-placeholder">
          <Spinner size="sm" animation="border" />
        </div>
      )}
      {getRecoveryButtons()}

      <style jsx>{`
        .video-document {
          position: relative;
          width: 100%;
          height: 100%;
        }
        .loading-placeholder {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
        }
        .video-document :global(video) {
          max-height: 100%;
          width: 100%;
          margin: auto;
          object-fit: ${fullPage ? 'cover' : 'contain'};
          object-position: top;
        }
        .video-document > :global(div:first-child) {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
        }
        .video-document :global(.mute-button) {
          position: absolute;
          top: ${viewOnly ? 'auto' : '10px'};
          bottom: ${viewOnly ? '60px' : 'auto'};
          right: ${viewOnly ? '20px' : '168px'};
        }

        :global(.vbssp .mute-button) {
          right: ${viewOnly ? '20px' : '196px'};
        }

        .video-document :global(.mute-button .btn) {
          outline: none !important;
          box-shadow: none !important;
          color: #fff;
          border-radius: ${viewOnly ? 0 : '.3rem'};
          background: ${viewOnly
            ? 'rgba(37, 37, 37, 0.5)'
            : 'rgba(0, 0, 0, 0.4)'};
          border: 1px solid #fff;
          font-size: ${viewOnly ? '20px' : '15px'};
          letter-spacing: 2px;
        }

        :global(.vbssp .mute-button .btn) {
          font-size: ${viewOnly ? '20px' : '29px'};
        }
      `}</style>
    </div>
  );
};

export default VideoViewer;
