import React, { useEffect } from 'react';
import DeleteButton from '../../Common/DeleteButton';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { isVideo } from '../../../../utils/file';
import VideoPlayerInline from '../Common/VideoPlayerInline';
import { IconPlay } from '../../Common/HubIcons';
import PageLabel from '../Common/PageLabel';
import { IStorybookPage, IStorybookSettings } from '../../../../interfaces';
import SBPageDetailEditor from '../Common/SBPageDetailEditor';
import SBPreview from '../Preview';
import { useLoadingStatus } from '../../../../components/hooks/loading';
import { Spinner } from 'react-bootstrap';
import { getActivatedSceneById } from '../../../clientSideServices/library';
import { TLanguage } from '../../../../mappers/polotno';
import { getLanguageJSON } from '../../../utils/storybook';
import ImageWithWebp from '../../Common/ImageWithWebp';

const PageEditor = React.memo(({
  page,
  pageNumber,
  onDelete,
  onUpdate,
  onClick,
  compactMode,
  active,
  enlargeMode,
  allowEdit,
  settings = {},
  children,
  onOpenEditor,
  onDuplicate,
}: {
  page: IStorybookPage;
  pageNumber: number | string;
  onDelete?: () => void;
  onUpdate: (page: IStorybookPage, doNotFlagChange?: boolean) => void;
  onClick?: () => void;
  compactMode?: boolean;
  active?: boolean;
  enlargeMode?: boolean;
  allowEdit?: boolean;
  settings: IStorybookSettings;
  children?: React.ReactNode;
  onOpenEditor?: (props: {
    sceneId?: string;
    allowEdit?: boolean;
    isImported?: boolean;
    activeLanguage?: TLanguage;
  }) => void;
  onDuplicate?: (props: { sceneId?: string; isImported?: boolean }) => void;
}) => {
  const { color, background, text } = settings;
  const { isLoading } = useLoadingStatus();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging
  } = useSortable({ id: page?.id });

  const [sceneData, setSceneData] = React.useState<IStorybookPage | null>(null);
  const [pageData, setPageData] = React.useState<IStorybookPage | null>(page);
  const [activeLanguage, setActiveLanguage] = React.useState<TLanguage>('en');

  React.useEffect(() => {
    const active = settings.language || 'en'
    setActiveLanguage(active)
  }, [settings]);


  useEffect(() => {
    if(page?.id && page?.pageType === 'scene') {
      getActivatedSceneById(page?.id).then(scene => {
        const scenePage = {...page, overlay: scene.content?.overlay, thumbnail: scene.content?.thumbnail, url: scene.content?.url}
        setSceneData(scene)
        setPageData(scenePage)
      })
    }
  }, [page?.id])

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1 : undefined,
    opacity: isDragging ? 0 : 1
  };

  const isVideoContent = isVideo(pageData?.url);

  const dragOverlay = !enlargeMode && allowEdit && (
    <>
      <div
        className={`btn-drag drag1 ${
          isVideoContent && !compactMode ? 'hasControls' : ''
        }`}
        {...attributes}
        {...listeners}
      />

      <div
        className={`btn-drag drag2 ${
          isVideoContent && !compactMode ? 'hasControls' : ''
        }`}
        {...attributes}
        {...listeners}
      />

      <div
        className={`btn-drag drag3 ${
          isVideoContent && !compactMode ? 'hasControls' : ''
        }`}
        {...attributes}
        {...listeners}
      />

      <style jsx>{`
        .btn-drag {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          box-shadow: none;
          outline: none;
        }
        .hasControls {
          bottom: 60px;
          right: auto;
          width: calc(50% - 50px);
        }
        .hasControls.drag2 {
          right: 0;
          left: auto;
        }
        .hasControls.drag3 {
          bottom: auto;
          width: 100%;
          right: 0;
          height: calc(50% - 50px);
        }
        .btn-drag,
        .btn-drag:focus,
        .btn-drag:active {
          cursor: grab;
        }
      `}</style>
    </>
  );

  const urls = getLanguageJSON(pageData?.url);
  const thumbnails = getLanguageJSON(pageData?.thumbnail);
  const url = urls[activeLanguage] || urls['en'];
  const thumbnail = thumbnails[activeLanguage] || thumbnails['en'];

  const thumbnailUrl = enlargeMode
    ? url
    : !compactMode && isVideoContent
    ? url
    : thumbnail || url;

  const isVideoThumbnail = isVideo(thumbnailUrl);

  return (
    <div
      className={`PageEditorContainer ${enlargeMode ? 'w-100' : ''} ${
        active ? 'active' : ''
      }`}
      ref={setNodeRef}
      style={style}
    >
      <div
        className={`PageEditor ${enlargeMode ? 'enlargeMode' : 'compactMode'}`}
      >
        <div
          className="media-container shadow-box"
          style={{ backgroundColor: background || '#fff' }}
        >
          {enlargeMode ? (
            <SBPreview
              storybook={{ pages: [pageData] }}
              isForScene={true}
              hideInfo={true}
              previewMode="microsite"
            />
          ) : (
            <>
              {isVideoThumbnail ? (
                <VideoPlayerInline
                  url={thumbnailUrl || ''}
                  controls={!compactMode}
                  controlsButton={false}
                />
              ) : (
                thumbnailUrl && <ImageWithWebp className='webp-image-thumbnail' src={thumbnailUrl || ''} />
              )}
              {isVideoContent && compactMode && <IconPlay />}
              {!compactMode && (pageData?.title || pageData?.subtitle) && (
                <div className="label-container">
                  <PageLabel
                    title={pageData?.title}
                    subtitle={pageData?.subtitle}
                    background={color}
                    textColor={text}
                  />
                </div>
              )}
              {dragOverlay}
            </>
          )}

          {isLoading(`loading-scene-${pageData?.id}`) && (
            <div className="loading">
              <div>
                <Spinner animation="border" size="sm" />
              </div>
            </div>
          )}
        </div>

        <div className="content-container">
          <SBPageDetailEditor
            enlargeMode={enlargeMode}
            compactMode={compactMode}
            pageNumber={pageNumber}
            onUpdate={onUpdate}
            showVideoSettings={isVideoContent}
            allowEdit={allowEdit}
            page={page}
            pageData={pageData}
            sceneData={sceneData}
            settings={settings}
            title={enlargeMode ? 'Scene Details' : ''}
            onOpenEditor={onOpenEditor}
            onDuplicate={onDuplicate}
            exportButton={true}
          >
            {children}
          </SBPageDetailEditor>
        </div>

        {!enlargeMode && (
          <button
            className="btn btn-view text-semibold btn-sm"
            onClick={() => onClick?.()}
          >
            View
          </button>
        )}
        {onDelete && allowEdit && (
          <div className="delete-container">
            <DeleteButton
              onClick={onDelete}
              xPosition="right"
              yPosition="top"
              indent="0"
              noConfirmation={true}
              confirmation={`Are you sure want to remove Page ${pageNumber}`}
            />
          </div>
        )}

        <style jsx>{`
          :global(.panel-content + .SBPopupWrapper .content-container) {
            padding: 15px !important;
          }
          :global(
              .panel-content
                + .SBPopupWrapper
                .header-container
                + .content-container
            ) {
            padding: 0 !important;
          }

          :global(.webp-image-thumbnail) {
            position: absolute;
            top: 50%;
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: translateY(-50%);
          }

          .media-container {
            width: 100%;
            border-radius: 5px;
            position: relative;
          }

          .media-container :global(video) {
            // border-radius: 5px;
          }

          .compactMode .media-container {
            overflow: hidden;
            height: 0;
            padding-top: 56.3%;
            background-image: url('/asset/loading-square.gif');
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 40px auto;
          }

          .enlargeMode .media-container {
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
          }

          .media-container :global(.action-group) {
            opacity: 0;
          }
          .media-container:hover :global(.action-group) {
            opacity: 1;
          }

          .btn-view {
            position: absolute;
            z-index: 2;
            padding-top: 0;
            padding-bottom: 0;
            top: ${compactMode ? '0' : '5px'};
            left: ${compactMode ? '0' : '5px'};
            box-shadow: 0 0 3px rgb(0 0 0 / 30%);
            background: #fff;
            border-radius: 3px;
            height: 27px;
            display: none;
          }
          .delete-container {
            position: absolute;
            top: ${compactMode ? '0' : '5px'};
            right: ${compactMode ? '0' : '5px'};
            background: #fff;
            border-radius: 3px;
            box-shadow: 0 0 3px rgb(0 0 0 / 30%);
            display: none;
            z-index: 2;
          }
          .PageEditor:hover .btn-view,
          .PageEditor:hover .delete-container {
            display: block;
          }
          .enlargeMode .action {
            margin-bottom: 20px;
          }

          .enlargeMode.PageEditor {
            display: flex;
            flex-wrap: wrap;
            height: 100%;
          }

          :global(.enlargeMode .webp-image-thumbnail) {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          .enlargeMode .content-container {
            width: 30%;
            padding: 30px;
            position: relative;
            border-left: 1px solid #efefef;
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
          }

          .label-container {
            position: absolute;
            pointer-events: none;
            left: 0;
            bottom: 0;
          }
          .media-container:hover .label-container {
            display: ${isVideoContent ? 'none' : 'block'};
          }

          .loading {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.4);
            color: #fff;
            z-index: 2;
            display: flex;
            align-items: center;
          }
          .loading > div {
            margin: auto;
          }
        `}</style>
      </div>
    </div>
  );
});

export default PageEditor;
