import React from 'react';
import AddButton from '../../Common/AddButton';
import SBPageThumbnail from '../Common/SBPageThumbnail';

import { IStorybook, IStorybookPage } from '../../../../interfaces';
import ContentItemActions from './ContentItemActions';
import { IconArrow } from '../../Common/HubIcons';
import { logClickButton } from '../../../../analytics';

const ContentManagerViewer = ({
  onSelect,
  onView,
  content,
  onClose,
  currentPages = [],
  onAddAll
}: {
  onSelect?: (item: IStorybook) => void;
  onView?: (item: IStorybook) => void;
  content: IStorybook;
  onClose: () => void;
  currentPages?: IStorybookPage[];
  onAddAll?: (item: IStorybook[]) => void;
}) => {
  const [opening, setOpening] = React.useState(false);
  const selectedIds = currentPages.map((page) => page.id);
  React.useEffect(() => {
    setOpening(true);
  }, []);
  const viewerContent = content?.pages || [];
  const unselectedPage = viewerContent.filter(
    (page) => selectedIds.indexOf(page.id) === -1
  );
  return (
    <div
      className={`ContentManagerViewer h-100 w-100 ${opening ? 'opening' : ''}`}
    >
      <div className="panel-header">
        <div className="d-flex" style={{ alignItems: 'center' }}>
          <span
            className="file-title"
            title={content?.title}
            onClick={() => {
              setOpening(false);
              setTimeout(() => {
                onClose();
              }, 200);
            }}
          >
            <IconArrow direction="left" />
            {content?.title}
          </span>
          {onAddAll && (
            <AddButton
              label="Add All"
              disabled={unselectedPage.length === 0}
              onClick={() => {
                onAddAll(
                  unselectedPage.map((page) => ({ ...content, pages: [page] }))
                );
                logClickButton('Add All');
              }}
            />
          )}
        </div>
      </div>

      <div className="panel-content d-flex">
        {viewerContent.map((page, index) => {
          const selected = selectedIds.includes(page.id);
          const sb = { ...content, pages: [page] };
          return (
            <div key={page?.url + index}>
              <SBPageThumbnail
                url={page?.thumbnail}
                contentUrl={page?.url}
                selected={selected}
                overlay={
                  selected ? undefined : (
                    <ContentItemActions
                      onAdd={() => onSelect(sb)}
                      onView={() => onView(sb)}
                    />
                  )
                }
              >
                <div className="d-flex action">
                  <span>{index + 1}</span>
                </div>
              </SBPageThumbnail>
            </div>
          );
        })}
      </div>

      <style jsx>{`
        .ContentManagerViewer {
          position: absolute;
          left: 100%;
          top: 0;
          width: 100%;
          bottom: 0;
          transition: all 0.2s ease-in-out;
          background: #fff;
          z-index: 2;
        }
        .ContentManagerViewer.opening {
          left: 0;
        }

        .panel-header .d-flex {
          justify-content: space-between;
        }
        .panel-header .d-flex:first-child {
          margin-bottom: 30px;
        }

        .divider {
          color: #ccc;
        }
        .panel-content {
          flex-wrap: wrap;
          align-content: flex-start;
        }
        .panel-content > div {
          width: ${viewerContent?.length === 1 ? '100%' : '50%'};
          padding: 8px;
          margin: 0;
          position: relative;
        }
        .panel-header :global(.btn) {
          min-width: 70px;
        }
        .action {
          justify-content: space-between;
          font-weight: 500;
          align-items: center;
          width: 100%;
        }
        .selected :global(.SBPageThumbnail) {
          pointer-events: none;
        }
        .selected .action :global(.btn) {
          opacity: 0;
        }

        .file-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 70%;
        }
        .file-title {
          cursor: pointer;
        }
        .file-title :global(svg) {
          width: 20px;
          height: auto;
          margin-top: -2px;
        }
        @media (max-width: 1400px) {
          .panel-content > div {
            width: 100%;
          }
        }
      `}</style>
    </div>
  );
};

export default ContentManagerViewer;
