import { isEmpty, uniq } from 'lodash';
import React from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { logEvent } from '../../../../analytics';
import { useUploadFile } from '../../../../components/hooks/fileUpload';
import { ILibraryScene, LoadingStatus } from '../../../../interfaces';
import { actionHubAlertError } from '../../../../redux/actions';
import { DID_DELETE_FILE } from '../../../../utils/constants';

import { deleteObjectFromS3 } from '../../../clientSideServices/aws';
import { getLibrarySceneFile } from '../../../clientSideServices/library';
import DropInfo from '../../Common/FileUploader/DropInfo';
import FileUpload from '../../Common/FileUploader/FileUpload';
import UploadItems, { FileItem } from './UploadItems';

export const FETCH_EDITOR_FILES = 'FETCH_EDITOR_FILES';

export interface IFilesInUpload {
  file: File;
  name: string;
  key: string;
}

const filePath = 'content/uploaded/libraries';
const bucket = 'inspify-data';

const UploadContainer = ({
  onSelectFile,
  selectedFile,
  context,
  acceptTypes = 'image/jpeg, image/png, application/pdf, .svg, video/mp4, video/quicktime',
  autoOpenUpload = false
}: {
  onSelectFile: (scene: FileItem) => void;
  selectedFile: string[];
  context: string;
  acceptTypes?: string;
  autoOpenUpload?: boolean;
}) => {

  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(LoadingStatus.INITIAL);
  const [showUpload, setShowUpload] = React.useState(autoOpenUpload);
  const [uploadedFiles, setUploadedFiles] = React.useState<ILibraryScene[]>([]);

  const [files, setFiles] = React.useState<File[]>([]);

  const { addFilesToUpload, onFileConverted, unconvertedFiles } =
    useUploadFile();

  const handleUpload = (fileList: { file: File; name: string }[]) => {
    addFilesToUpload(
      fileList.map((file) => ({
        ...file,
        key: `${filePath}/${context}/${new Date().toISOString()} ${file.name}`,
        bucket
      }))
    );
  };

  const deleteFile = (id: string) => {
    const currentScene = uploadedFiles.find((file) => file.id === id);
    const scenesInSameFile = uploadedFiles.filter(
      (f) => f.content.settings === currentScene.content.settings
    );

    const hasMultiplePages = scenesInSameFile.length > 1;

    if (hasMultiplePages) {
      const updatedData = uploadedFiles.filter((f) => f.id !== id);
      setUploadedFiles(updatedData);
    } else {
      deleteObjectFromS3(bucket, currentScene.content.settings).then(() => {
        setUploadedFiles(uploadedFiles.filter((f) => f.id !== currentScene.id));
        logEvent(DID_DELETE_FILE, DID_DELETE_FILE, {
          bucket,
          key: currentScene.content.settings
        });
      });
    }
  };


  const getData = (silentLoad?: boolean) => {
    if (!context) return;
    !silentLoad && setLoading(LoadingStatus.LOADING);

    logEvent(FETCH_EDITOR_FILES, FETCH_EDITOR_FILES, {
      context,
      status: 'Loading'
    });

    getLibrarySceneFile({ sceneId: context })
      .then((res) => {
        setUploadedFiles(res);
        const uploadedSceneUniqueKey = uniq(res.map((r) => r.content.settings));

        onFileConverted(uploadedSceneUniqueKey);
        !silentLoad && setLoading(LoadingStatus.LOADED);
        logEvent(FETCH_EDITOR_FILES, FETCH_EDITOR_FILES, {
          context,
          status: 'Successful'
        });
      })
      .catch((e) => {
        logEvent(FETCH_EDITOR_FILES, FETCH_EDITOR_FILES, {
          context,
          status: 'Error',
          error: e
        });
        !silentLoad && setLoading(LoadingStatus.LOADED);
      });
  };

  React.useEffect(() => {
    let fetching;
    if (unconvertedFiles.length > 0) {
      fetching = setInterval(() => {
        getData(true);
      }, 5000);
    } else {
      fetching && clearInterval(fetching);
    }
    return () => {
      clearInterval(fetching);
    };
  }, [unconvertedFiles]);

  React.useEffect(() => {
    if (context) {
      getData(true);
    }
  }, [context]);

  const content = (
    <>
      <div
        className={`panel-header ${
          uploadedFiles.length > 0 || unconvertedFiles?.length > 0
            ? 'bordered'
            : ''
        }`}
      >
        <div className="info-container d-flex flex-column">
          <div className="info">
            <DropInfo
              isDragActive={false}
              acceptedTypes={acceptTypes}
              onClick={() => setShowUpload(true)}
            />
          </div>
        </div>
      </div>

      <div className="panel-content flex-grow-1 d-flex">
        <UploadItems
          loading={loading}
          items={uploadedFiles}
          onSelectFile={onSelectFile}
          selectedFile={selectedFile}
          unconvertedFiles={unconvertedFiles}
          onReload={getData}
          onDelete={deleteFile}
        />
      </div>

      <style jsx>{`
        .add-container {
          padding: 10px 20px;
          text-align: center;
          border-bottom: 1px solid #efefef;
        }
        .panel-header {
          padding: 20px;
        }
        .panel-header.bordered {
          border-bottom: 1px solid #ccc;
        }
        .panel-header .d-flex {
          justify-content: space-between;
        }
        .panel-content {
          padding: 10px;
          flex-wrap: wrap;
          align-content: flex-start;
          overflow: auto;
        }
        .inner {
          margin: auto;
        }
        .info {
          text-align: center;
          margin: auto;
          display: flex;
          align-items: center;
          padding: 10px 10px 0;
          border: 3px dashed #ddd;
          background: #efefef;
          border-radius: 10px;
        }
        .info-container {
          height: 100%;
          padding: 10px 0;
        }
        .info :global(p) {
          font-size: 0.8em !important;
        }
        .info :global(.small) {
          font-size: 0.7em !important;
        }
      `}</style>
    </>
  );

  const dropInfo = (
    <div className="info">
      <DropInfo isDragActive={true} acceptedTypes={acceptTypes} />
      <style jsx>{`
        .inner {
          margin: auto;
        }
        .info {
          text-align: center;
          width: 100%;
          margin: auto;
          height: 100%;
          display: flex;
          align-items: center;
          padding: 20px;
          border: 3px dashed #28a746;
          background: #edfff2;
          border-radius: 0;
        }
        .info :global(p) {
          font-size: 0.9em !important;
        }
        .info :global(.small) {
          font-size: 0.8em !important;
        }
      `}</style>
    </div>
  );

  const uploadComponent = (
    <>
      <FileUpload
        onClose={() => {
          setShowUpload(false);
          setFiles([]);
        }}
        onUpload={handleUpload}
        files={files}
        showDialog={!files?.length}
        multipleUpload={true}
        type="inline"
        onDeleteFile={(index) => setFiles(files.filter((_, i) => i !== index))}
        onUpdateFileList={setFiles}
        rejectDuplicates={true}
        acceptTypes={acceptTypes}
      />
      <style jsx>{`
        :global(.head-before-upload) {
          display: none !important;
        }
        .UploadContainer :global(p) {
          font-size: 0.9em !important;
        }
        .UploadContainer :global(.small) {
          font-size: 0.8em !important;
        }
        .UploadContainer :global(.item-container) {
          width: 100% !important;
        }
        :global(.item-container) {
          width: 100% !important;
        }
        :global(.info) {
          padding: 20px;
        }
      `}</style>
    </>
  );

  const dropArea = (
    <Dropzone
      noClick
      onDrop={(f, fileRejection) => {
        if (fileRejection && !isEmpty(fileRejection)) {
          const err = fileRejection[0].errors?.[0];
          if(err) dispatch(actionHubAlertError(`Error: ${err.message}`));
        }
        setFiles(f);
        setShowUpload(true);
      }}
      accept={acceptTypes}
      multiple={true}
    >
      {({ getRootProps, isDragActive }) => (
        <div
          className={`dropzone ${isDragActive ? 'drag-active' : ''}`}
          {...getRootProps({ className: 'dropzone' })}
        >
          <div className="content d-flex flex-column h-100">{content}</div>
          <div className="drop-area h-100">{dropInfo}</div>
          <style jsx>{`
            .dropzone {
              width: 100%;
              height: 100%;
              padding: 0;
            }
            .drag-active .content {
              display: none !important;
            }
            .drag-active {
              padding: 0 !important;
            }
            .drop-area {
              display: none;
            }
            .drag-active .drop-area {
              display: block;
            }
          `}</style>
        </div>
      )}
    </Dropzone>
  );

  return (
    <div
      className={`UploadContainer bp5-focus-disabled ${
        showUpload ? 'show-upload' : ''
      }`}
    >
      {showUpload && uploadComponent}
      <div className="inner w-100 h-100 d-flex flex-column">{dropArea}</div>
      <style jsx>{`
        .UploadContainer {
          padding: 0 !important;
          height: calc(100% + 20px);
          margin: -10px -10px 0;
          overflow: hidden;
        }
        :global(.dropzone) {
          border-radius: 0 !important;
        }
      `}</style>
    </div>
  );
};

export default UploadContainer;
