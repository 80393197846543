import React from 'react';
import { logClickButton } from '../../../../analytics';
import { ILibraryScene } from '../../../../interfaces';
import { IconExport } from '../../Common/HubIcons';

export const SBExportSceneButton = ({
  scene,
  onClick
}: {
  scene: ILibraryScene;
  onClick?: () => void;
}) => {
  return (
    <>
      {!scene && (
        <span
          onClick={() => {
            onClick();
            logClickButton('Add to Scene Library');
          }}
        >
          <IconExport />
        </span>
      )}
    </>
  );
};
export default SBExportSceneButton;
