import { LoadingStatus } from './../../interfaces/index';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FAVORITE_FOLDER,
  getElementsInFolderKey,
  getKeyIdFromElementId,
  isInCollaboratedItemsFolder,
  isInSharedItemsFolder,
  SHARED_ITEMS,
  SHARED_TEAM_FOLDER_ID,
  SHARED_TEAM_ITEMS,
  SHARED_TEAM_TYPE,
  TRASH_FOLDER
} from '../../advisorHub/utils/folders';

import {
  DID_COPY_ELEMENT_TO_FOLDER_KEY,
  DID_DELETE_ELEMENT_FROM_FOLDER_KEY,
  DID_EXPAND_FOLDER_KEY,
  DID_MOVE_ELEMENT_TO_FOLDER_KEY,
  DID_SELECT_FOLDER_KEY
} from '../../advisorHub/utils/analyticsEvents/folder';

import {
  IFolder,
  IFolderContext,
  IFolderKey,
  IMainState
} from '../../interfaces';
import {
  actionFolderCurrentContext,
  actionHubAlertError,
  actionSelectFolderKey,
  actionUpdateElementsInFolderKeys
} from '../../redux/actions';
import {
  loadFolderInfoAsync,
  updateKeysInFolderAsync
} from '../../redux/advisorHubAsyncActions';
import {
  copyElementToFolder,
  deleteElementInFolder,
  moveElementToFolder
} from '../../advisorHub/clientSideServices/folders';
import { flattenDeep, keyBy, mapValues } from 'lodash';
import { logEvent } from '../../analytics';
import { useUserTeams } from '../../advisorHub/components/hooks/teams';

export interface FolderHookValue {
  folderKeys: IFolderKey[];
  activeFolderKey: string;
  expandedFolderKeys: string[];
  selectFolderKey: (id: string) => void;
  toggleExpandFolderKey: (id: string) => void;
  moveElementToKey: (arg: {
    elementId: string;
    toKeyId: string;
    callback?: () => void;
  }) => void;
  moveMultipleElementsToKey: (arg: {
    elementIds: string[];
    toKeyId: string;
    callback?: () => void;
  }) => void;
  elementsInKey: string[];
  elementsNotInKey?: string[];
  elementsNotInTrash?: string[];
  loading?: { [key: string]: LoadingStatus };
  trashList?: string[];
  favoritesList?: string[];
  addToFavorites: (elementId: string, callback?: () => void) => void;
  removeFromFavorites: (elementId: string, callback?: () => void) => void;
  addToTrash: (elementId: string, callback?: () => void) => void;
  removeFromTrash: (elementId: string[], callback?: () => void) => void;
  activeFolderKeyLabel: string;
  searchScope?: string[];
  isInTrashKey?: boolean;
  isInSharedKey?: boolean;
  isInCollaboratedKey?: boolean;
  isInFavoritesKey?: boolean;
  elementsInFolderKeys?: {
    [key: string]: string[];
  };
}

export const useFolder = ({
  userId,
  brandId,
  context,
  uuid
}: {
  uuid: string;
  userId: string;
  brandId?: string;
  context: IFolderContext;
}): FolderHookValue => {
  const dispatch = useDispatch();
  const folderState = useSelector(
    (state: IMainState) => state.clientState.hub?.folders || {}
  );

  const [folderLoading, setFolderLoading] = React.useState<{
    [key: string]: LoadingStatus;
  }>({});

  const folders: IFolder[] = folderState?.[context as IFolderContext] || [];

  const activeFolderKey = folderState?.activeFolderKey || '/';
  const currentFolder = folders?.filter((f) => f.uuid === userId)[0] || {};

  const elementsInFolderKeys = folderState?.elementsInFolderKeys || {};
  const expandedFolderKeys = folderState?.expandedFolderKeys || [];

  const selectFolderKey = (id: string) => {
    const updatedExpandedFolderKeys = expandedFolderKeys.includes(id)
      ? [...expandedFolderKeys]
      : [...expandedFolderKeys, id];

    logEvent(DID_SELECT_FOLDER_KEY, DID_SELECT_FOLDER_KEY, {
      folderId: currentFolder?.id,
      keyId: id
    });

    dispatch(
      actionSelectFolderKey({
        activeFolderKey: id,
        expandedFolderKeys: updatedExpandedFolderKeys
      })
    );
  };

  const toggleExpandFolderKey = (id: string) => {
    const shouldClose = expandedFolderKeys.includes(id);
    const updatedExpandedFolderKeys = shouldClose
      ? expandedFolderKeys.filter((f) => f !== id)
      : [...expandedFolderKeys, id];

    logEvent(DID_EXPAND_FOLDER_KEY, DID_EXPAND_FOLDER_KEY, {
      folderId: currentFolder?.id,
      keyId: id,
      expand: !shouldClose
    });

    dispatch(
      actionSelectFolderKey({ expandedFolderKeys: updatedExpandedFolderKeys })
    );
  };
  const { data } = useUserTeams(userId, brandId);
  const teamFolders: IFolderKey[] = [
    {
      id: SHARED_TEAM_FOLDER_ID,
      key: SHARED_TEAM_ITEMS,
      settings: { type: SHARED_TEAM_TYPE }
    },
    ...(data?.map((t) => ({
      id: t.id,
      key: SHARED_TEAM_ITEMS + t.name + '/',
      settings: { type: SHARED_TEAM_TYPE }
    })) || [])
  ];

  const folderKeys = [...(currentFolder?.keys || []), ...teamFolders];
  const trashKey = folderKeys.find((f) => f.key === TRASH_FOLDER);
  const favoritesKey = folderKeys.find((f) => f.key === FAVORITE_FOLDER);
  const sharedKey = folderKeys.find((f) => f.key === SHARED_ITEMS);

  const trashList = elementsInFolderKeys[trashKey?.id] || [];
  const favoritesList = elementsInFolderKeys[favoritesKey?.id] || [];

  const addToFavorites = (elementId: string, callback?: () => void) => {
    copyElementToKey({ elementId, toKeyId: favoritesKey?.id, callback });
  };

  const removeFromFavorites = (elementId: string, callback?: () => void) => {
    deleteElementFromKey({ elementId, keyId: favoritesKey?.id, callback });
  };

  const addToTrash = (elementId: string, callback?: () => void) => {
    moveElementToKey({
      elementId,
      toKeyId: trashKey?.id,
      callback: () => {
        callback?.();
        if (favoritesList.includes(elementId)) {
          removeFromFavorites(elementId);
        }
      }
    });
  };

  const removeFromTrash = (elementIds: string[], callback?: () => void) => {
    if (elementIds?.length > 1) {
      updateLoadingStatus('clearTrash', LoadingStatus.LOADING);
      Promise.all(
        elementIds.map((elementId) =>
          deleteElementInFolder({
            elementId,
            keyId: trashKey?.id,
            folderId: currentFolder?.id,
            elementType: context
          })
        )
      )
        .then(() => {
          callback?.();
          dispatch(
            actionUpdateElementsInFolderKeys({
              [trashKey?.id]: []
            })
          );
          updateLoadingStatus('clearTrash', LoadingStatus.LOADED);
        })
        .catch(() => {
          updateLoadingStatus('clearTrash', LoadingStatus.FAILED);
        });
    } else {
      deleteElementFromKey({
        elementId: elementIds[0],
        keyId: trashKey?.id,
        callback
      });
    }
  };

  const updateLoadingStatus = (id: string, status: LoadingStatus) => {
    setFolderLoading({ ...folderLoading, [id]: status });
    if (status === LoadingStatus.FAILED) {
      dispatch(
        actionHubAlertError(
          'Something went wrong, please check your internet connection'
        )
      );
    }
  };

  const updateMultipleLoadingStatus = (sceneStatus) => {

    const statusObj = mapValues(keyBy(sceneStatus, 'id'), 'status')
    setFolderLoading({ ...folderLoading, ...statusObj });
    if (sceneStatus.some(s => s === LoadingStatus.FAILED)) {
      dispatch(
        actionHubAlertError(
          'Something went wrong, please check your internet connection'
        )
      );
    }
  };

  const moveMultipleElementsToKey = ({
    elementIds,
    toKeyId,
    callback
  }: {
    elementIds: string[];
    toKeyId: string;
    callback?: () => void;
  }) => {

    const validElementIds = elementIds.filter(elementId => !elementsInFolderKeys[toKeyId]?.includes(elementId))

    if (validElementIds.length === 0) return;
    const fromKeyId = getKeyIdFromElementId(validElementIds[0], elementsInFolderKeys, [
      trashKey.id,
      favoritesKey.id
    ], activeFolderKey);
    const isToBaseShareId =
      folderKeys?.find((f) => f.id === toKeyId)?.key === SHARED_ITEMS;
    
      if (fromKeyId === toKeyId) return;

    const promiseArr = []

    updateMultipleLoadingStatus(validElementIds.map(id => ({id, status: LoadingStatus.LOADING})))

    validElementIds.map(elementId => {
      if (fromKeyId && fromKeyId !== '/' && toKeyId !== '/' && !isToBaseShareId) {
        promiseArr.push(moveElementToFolder({
          elementId,
          fromKeyId,
          toKeyId,
          fromFolderId: currentFolder?.id,
          toFolderId: currentFolder?.id,
          elementType: context
        }))
      }
      else if (
        fromKeyId &&
        fromKeyId !== '/' &&
        (toKeyId === '/' || (toKeyId !== '/' && isToBaseShareId))
      ) {
        promiseArr.push(deleteElementInFolder({
          elementId,
          keyId: fromKeyId,
          folderId: currentFolder?.id,
          elementType: context
        }))
      }
      else {
        promiseArr.push(copyElementToFolder({
          elementId,
          toKeyId,
          toFolderId: currentFolder?.id,
          elementType: context
        }))
        }
      })

    Promise.all(promiseArr).then(() => {
      updateMultipleLoadingStatus(validElementIds.map(id => ({id, status: LoadingStatus.LOADED})))
      if (fromKeyId && fromKeyId !== '/' && toKeyId !== '/' && !isToBaseShareId) {
        dispatch(
          actionUpdateElementsInFolderKeys({
            [fromKeyId]:
              elementsInFolderKeys[fromKeyId]?.filter(
                (e) => !validElementIds.includes(e)
              ) || [],
            [toKeyId]: [...(elementsInFolderKeys[toKeyId] || []), ...validElementIds]
          })
        );
        logEvent(
          DID_MOVE_ELEMENT_TO_FOLDER_KEY,
          DID_MOVE_ELEMENT_TO_FOLDER_KEY,
          {
            folderId: currentFolder?.id,
            elementId:elementIds[0],
            fromKeyId,
            toKeyId
          }
        );
        callback?.();
      }
      else if (
        fromKeyId &&
        fromKeyId !== '/' &&
        (toKeyId === '/' || (toKeyId !== '/' && isToBaseShareId))
      ) {
         dispatch(
          actionUpdateElementsInFolderKeys({
            [fromKeyId]:
              elementsInFolderKeys[fromKeyId]?.filter((el) => !validElementIds.includes(el)) ||
              []
          })
        );
        logEvent(
          DID_DELETE_ELEMENT_FROM_FOLDER_KEY,
          DID_DELETE_ELEMENT_FROM_FOLDER_KEY,
          {
            folderId: currentFolder?.id,
            elementId: elementIds[0],
            fromKeyId
          }
        );
        callback?.();
      }
      else {
         dispatch(
          actionUpdateElementsInFolderKeys({
            [toKeyId]: [...(elementsInFolderKeys[toKeyId] || []), ...validElementIds]
          })
        );
        logEvent(
          DID_COPY_ELEMENT_TO_FOLDER_KEY,
          DID_COPY_ELEMENT_TO_FOLDER_KEY,
          {
            folderId: currentFolder?.id,
            elementId: elementIds[0],
            toKeyId
          }
        );
        callback?.();
      }
    }).catch(() => {
      updateMultipleLoadingStatus(validElementIds.map(id => ({id, status: LoadingStatus.FAILED})))

    })
  };

  const moveElementToKey = ({
    elementId,
    toKeyId,
    callback
  }: {
    elementId: string;
    toKeyId: string;
    callback?: () => void;
  }) => {
    const fromKeyId = getKeyIdFromElementId(elementId, elementsInFolderKeys, [
      trashKey.id,
      favoritesKey.id
    ], activeFolderKey);
    const isToBaseShareId =
      folderKeys?.find((f) => f.id === toKeyId)?.key === SHARED_ITEMS;
    if (
      fromKeyId === toKeyId ||
      elementsInFolderKeys[toKeyId]?.includes(elementId)
    ) {
      return;
    }

    if (fromKeyId && fromKeyId !== '/' && toKeyId !== '/' && !isToBaseShareId) {
      updateLoadingStatus(elementId, LoadingStatus.LOADING);

      moveElementToFolder({
        elementId,
        fromKeyId,
        toKeyId,
        fromFolderId: currentFolder?.id,
        toFolderId: currentFolder?.id,
        elementType: context
      })
        .then(() => {
          updateLoadingStatus(elementId, LoadingStatus.LOADED);

          dispatch(
            actionUpdateElementsInFolderKeys({
              [fromKeyId]:
                elementsInFolderKeys[fromKeyId]?.filter(
                  (e) => e !== elementId
                ) || [],
              [toKeyId]: [...(elementsInFolderKeys[toKeyId] || []), elementId]
            })
          );
          logEvent(
            DID_MOVE_ELEMENT_TO_FOLDER_KEY,
            DID_MOVE_ELEMENT_TO_FOLDER_KEY,
            {
              folderId: currentFolder?.id,
              elementId,
              fromKeyId,
              toKeyId
            }
          );
          callback?.();
        })
        .catch(() => updateLoadingStatus(elementId, LoadingStatus.FAILED));

      return;
    }

    if (
      fromKeyId &&
      fromKeyId !== '/' &&
      (toKeyId === '/' || (toKeyId !== '/' && isToBaseShareId))
    ) {
      deleteElementFromKey({ elementId, keyId: fromKeyId, callback });
      return;
    }

    copyElementToKey({ elementId, toKeyId, callback });
  };

  const deleteElementFromKey = ({
    elementId,
    keyId,
    callback
  }: {
    elementId: string;
    keyId: string;
    callback?: () => void;
  }) => {
    updateLoadingStatus(elementId, LoadingStatus.LOADING);

    deleteElementInFolder({
      elementId,
      keyId,
      folderId: currentFolder?.id,
      elementType: context
    })
      .then(() => {
        updateLoadingStatus(elementId, LoadingStatus.LOADED);
        dispatch(
          actionUpdateElementsInFolderKeys({
            [keyId]:
              elementsInFolderKeys[keyId]?.filter((el) => el !== elementId) ||
              []
          })
        );
        logEvent(
          DID_DELETE_ELEMENT_FROM_FOLDER_KEY,
          DID_DELETE_ELEMENT_FROM_FOLDER_KEY,
          {
            folderId: currentFolder?.id,
            elementId,
            fromKeyId: keyId
          }
        );
        callback?.();
      })
      .catch(() => updateLoadingStatus(elementId, LoadingStatus.FAILED));
  };

  const copyElementToKey = ({
    elementId,
    toKeyId,
    callback
  }: {
    elementId: string;
    toKeyId: string;
    callback?: () => void;
  }) => {
    updateLoadingStatus(elementId, LoadingStatus.LOADING);

    if (elementsInFolderKeys[toKeyId]?.includes(elementId)) {
      return;
    }

    copyElementToFolder({
      elementId,
      toKeyId,
      toFolderId: currentFolder?.id,
      elementType: context
    })
      .then(() => {
        updateLoadingStatus(elementId, LoadingStatus.LOADED);
        callback?.();
        dispatch(
          actionUpdateElementsInFolderKeys({
            [toKeyId]: [...(elementsInFolderKeys[toKeyId] || []), elementId]
          })
        );
        logEvent(
          DID_COPY_ELEMENT_TO_FOLDER_KEY,
          DID_COPY_ELEMENT_TO_FOLDER_KEY,
          {
            folderId: currentFolder?.id,
            elementId,
            toKeyId
          }
        );
      })
      .catch(() => {
        updateLoadingStatus(elementId, LoadingStatus.FAILED);
      });
  };

  const elementsNotInKey =
    activeFolderKey === '/' ||
    activeFolderKey === sharedKey?.id ||
    folderKeys.find((f) => f.id === activeFolderKey)?.settings?.type === SHARED_TEAM_TYPE
      ? flattenDeep(
          Object.keys(elementsInFolderKeys || {})
            .map((key) => {
              if (key !== favoritesKey?.id) {
                return elementsInFolderKeys[key];
              }
            })
            .filter((e) => e)
        )
      : undefined;

  const allElementsInKey = elementsInFolderKeys[activeFolderKey] || [];

  const elementsInKey =
    activeFolderKey === '/' ||
    activeFolderKey === sharedKey?.id ||
    folderKeys.find((f) => f.id === activeFolderKey)?.settings?.type === SHARED_TEAM_TYPE
      ? undefined
      : activeFolderKey === trashKey?.id
      ? allElementsInKey
      : allElementsInKey.filter((e) => !trashList?.includes(e));

  const elementsNotInTrash = allElementsInKey.filter((e) => !trashList?.includes(e))
  
  const searchScope = getElementsInFolderKey(
    activeFolderKey,
    folderKeys,
    elementsInFolderKeys
  );

  React.useEffect(() => {
    if (uuid && userId && context && !folders?.length) {
      dispatch(loadFolderInfoAsync(uuid, context, userId));
    }

    if (folders.length && (!trashKey || !favoritesKey)) {
      const setupFavoritesTrashFolder = (
        keyType: 'favorites' | 'trash',
        onSuccess?: () => void
      ) => {
        dispatch(
          updateKeysInFolderAsync({
            key: keyType === 'favorites' ? FAVORITE_FOLDER : TRASH_FOLDER,
            userId,
            uuid,
            context,
            operation: 'add',
            onSuccess
          })
        );
      };
      if (!trashKey && !favoritesKey) {
        setupFavoritesTrashFolder('favorites', () =>
          setupFavoritesTrashFolder('trash')
        );
      } else if (!favoritesKey) {
        setupFavoritesTrashFolder('favorites');
      } else if (!trashKey) {
        setupFavoritesTrashFolder('trash');
      }
    }
    if (folderState.currentContext !== context) {
      dispatch(actionFolderCurrentContext({ currentContext: context }));
      selectFolderKey?.('/');
    }
  }, [uuid, userId, context, folders?.length]);

  return {
    folderKeys,
    activeFolderKey: folderKeys.some((key) => key.id === activeFolderKey)
      ? activeFolderKey
      : '/',
    activeFolderKeyLabel: folderKeys.find((f) => f.id === activeFolderKey)?.key,
    selectFolderKey,
    toggleExpandFolderKey,
    expandedFolderKeys,
    elementsNotInKey,
    elementsNotInTrash,
    moveElementToKey,
    moveMultipleElementsToKey,
    elementsInKey,
    loading: folderLoading,
    trashList,
    favoritesList,
    addToFavorites,
    removeFromFavorites,
    addToTrash,
    removeFromTrash,
    searchScope,
    isInTrashKey: trashKey?.id === activeFolderKey,
    isInFavoritesKey: favoritesKey?.id === activeFolderKey,
    isInSharedKey: isInSharedItemsFolder(activeFolderKey, folderKeys),
    isInCollaboratedKey: isInCollaboratedItemsFolder(activeFolderKey, folderKeys),
    elementsInFolderKeys
  };
};
