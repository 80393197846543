import React, { useState } from 'react';
import { logClickButton } from '../../../../analytics';
import { IFolderKey } from '../../../../interfaces';
import {
  FAVORITE_FOLDER,
  getFolderKeyPathName,
  getFolderLabel,
  getSubFolderKeysByParent,
  isLastChildFolder,
  SHARED_ITEMS
} from '../../../utils/folders';
import FolderButton, { getFolderIcon } from './FolderButton';
import { BsChevronDown } from 'react-icons/bs';
import ToolTipWrapper from '../../../../components/Common/ToolTipWrapper';
import FolderTrees from './FolderTrees';

export const FolderPath = ({ path }: { path: string }) => (
  <div className="folder-path">
    {getFolderIcon(path)}
    {getFolderKeyPathName(path)}
    <style jsx>{`
      .folder-path {
        font-size: 12px;
        letter-spacing: 0.5px;
        display: flex;
        align-items: end;
        margin-left: 10px;
        margin-bottom: 2px;
      }
      .folder-path :global(svg) {
        width: 16px;
        height: auto;
        margin-right: 3px;
      }
    `}</style>
  </div>
);

const FolderNavigationMobile = ({
  onSelectFolderKey,
  selectedFolder = '/',
  folders,
  expandedFolderKeys = [],
  toggleExpandFolderKey,
  title = {},
  excludeFolders = [],
  onAddNewFolder,
  hideOverview,
  onDrop,
  creatable = false
}: {
  selectedFolder?: string;
  onSelectFolderKey: (id: string) => void;
  folders: IFolderKey[];
  expandedFolderKeys?: string[];
  toggleExpandFolderKey: (id: string) => void;
  title?: {
    mainTitle?: string;
    trashTitle?: string;
    favoritesTitle?: string;
    rootTitle?: string;
    sharedTitle?: string;
    collaboratedTitle?: string;
  };
  excludeFolders?: string[];
  onAddNewFolder?: () => void;
  hideOverview?: boolean;
  onDrop?: (fId: string, transferData: DataTransfer) => void;
  creatable?: boolean;
}) => {
  const { mainTitle, favoritesTitle, rootTitle, sharedTitle } = title;
  const handleSelectFolderKey = (id: string) => {
    onSelectFolderKey(id);
  };

  const [folderTreePopup, setFolderTreePopup] = useState(null);
  const overViewFolders = folders.filter(
    (f) => ![FAVORITE_FOLDER, SHARED_ITEMS].includes(f.key)
  );

  const favoriteFolder = folders.find((f) => f.key === FAVORITE_FOLDER);
  const sharedFolder = folders.find((f) => f.key === SHARED_ITEMS);
  const [selectedRoot, setSelectedRoot] = useState(favoriteFolder?.id);
  const hasSharedSubFolder = !isLastChildFolder(sharedFolder?.id, folders);
  const hasOverviewSubFolder = !isLastChildFolder('/', folders);

  return (
    <>
      <div className="FolderNavigationMobile">
        <div className="FolderNavigation">
          {mainTitle && <h4>{mainTitle}</h4>}
          {creatable && onAddNewFolder && (
            <div className="btn-container">
              <button
                className="btn btn-round btn-create-folder btn-sm btn-outline-dark"
                onClick={() => {
                  onAddNewFolder();
                  logClickButton('Create Folder');
                }}
              >
                Create New Folder
              </button>
            </div>
          )}

          <div className="sb-nav">
            {!excludeFolders.includes(FAVORITE_FOLDER) && favoriteFolder && (
              <FolderButton
                label={favoritesTitle || 'Favorites'}
                id={favoriteFolder?.id}
                selected={selectedFolder === favoriteFolder?.id}
                onClick={() => {
                  if (selectedRoot !== favoriteFolder?.id) {
                    setSelectedRoot(favoriteFolder?.id);
                  }
                  handleSelectFolderKey(favoriteFolder?.id);
                }}
                icon={getFolderIcon(favoriteFolder?.key)}
                isDroppable={false}
                haveIcon={false}
              />
            )}
            {!hideOverview && (
              <FolderButton
                label={rootTitle || 'Overview'}
                id="/"
                selected={selectedFolder === '/' || selectedRoot === '/'}
                onClick={() => {
                  if (selectedRoot !== '/') {
                    handleSelectFolderKey('/');
                    setSelectedRoot('/');
                  }
                  setFolderTreePopup({
                    key: '/',
                    folder: overViewFolders,
                    level: 1
                  });
                }}
                onDrop={onDrop}
                haveIcon={false}
                suffixIcon={
                  hasOverviewSubFolder ? (
                    <BsChevronDown width={10} className="folder-down" />
                  ) : null
                }
              />
            )}
            {!excludeFolders.includes(SHARED_ITEMS) && sharedFolder && (
              <>
                <FolderButton
                  label={sharedTitle || 'Shared With Me'}
                  id={sharedFolder?.id}
                  selected={
                    selectedFolder === sharedFolder?.id ||
                    selectedRoot === sharedFolder?.id
                  }
                  onClick={() => {
                    if (selectedRoot !== sharedFolder?.id) {
                      handleSelectFolderKey(sharedFolder?.id);
                      setSelectedRoot(sharedFolder?.id);
                    }
                    setFolderTreePopup({
                      ...sharedFolder,
                      level: 2,
                      folder: getSubFolderKeysByParent(
                        sharedFolder.id,
                        folders
                      )?.filter((f) => !excludeFolders.includes(f.id))
                    });
                  }}
                  icon={getFolderIcon(sharedFolder?.key)}
                  isDroppable={false}
                  haveIcon={false}
                  suffixIcon={
                    hasSharedSubFolder ? (
                      <BsChevronDown width={10} className="folder-down" />
                    ) : null
                  }
                />
              </>
            )}
          </div>
        </div>
        {folderTreePopup && (
          <ToolTipWrapper
            onClose={() => setFolderTreePopup(null)}
            width={300}
            xPosition="center"
          >
            <div className="folderTree-popup">
              <label>{getFolderLabel(folderTreePopup.key) || rootTitle}</label>
              <div className="menuTree">
                <FolderTrees
                  folders={folderTreePopup.folder?.filter(
                    (f) => !excludeFolders.includes(f.id)
                  )}
                  selectedFolder={selectedFolder}
                  onClickFolder={(key) => {
                    handleSelectFolderKey(key);
                    setFolderTreePopup(null);
                  }}
                  expandedFolderKeys={expandedFolderKeys}
                  toggleExpandFolderKey={toggleExpandFolderKey}
                  onDrop={onDrop}
                  spaceIndex={0}
                  level={folderTreePopup.level}
                />
              </div>
            </div>
          </ToolTipWrapper>
        )}
      </div>

      <style jsx>{`
        h4 {
          font-size: 13px;
          font-weight: 600;
          padding: 20px 30px 5px;
        }
        .btn-container {
          padding: 10px 30px 15px;
        }
        .btn-container .btn {
          width: 100%;
        }
        .FolderNavigationMobile {
          padding: 0px 15px;
          margin-bottom: 5px;
        }
        .FolderNavigationMobile .sb-nav {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: 5px;
        }
        .menuTree {
          height: calc(65vh - 70px);
          overflow: auto;
        }
      `}</style>
      <style global jsx>{`
        .FolderNavigationMobile .FolderNavigation .FolderButton {
          border-radius: 20px;
          color: #979797;
          background: #fff;
          border: 1px solid #979797;
          padding: 0 5px;
          height: 35px;
        }
        .FolderNavigationMobile .FolderNavigation .FolderButton .spacer {
          display: none;
        }
        .FolderNavigationMobile
          .FolderNavigation
          .FolderButton
          .folder-label
          strong {
          font-weight: 500 !important;
          text-align: center !important;
          font-size: 11px;
          width: auto;
          margin: auto;
        }
        .sb-nav .selected,
        .sb-nav .selected:hover {
          background: #8f6599 !important;
          border-color: #8f6599 !important;
          color: #fff !important;
        }
        .folder-down {
          width: 12px !important;
          margin-right: 0 !important;
        }
        .FolderNavigationMobile #ToolTipWrapper {
          width: 90vw !important;
          background: #fff;
          border-radius: 2.1875rem;
          border: 1px solid #a8a8a8;
          transform: translate(-50%, 140px) !important;
          top: 0 !important;
          height: 65vh;
        }
        .FolderNavigationMobile #ToolTipWrapper .close-container .btn {
          background: transparent;
          color: #000;
          font-size: 35px;
        }
        .FolderNavigationMobile #ToolTipWrapper .close-container {
          right: 10px;
          top: 10px;
        }
        .folderTree-popup {
          padding: 14px;
          padding-left: 0;
        }
        .folderTree-popup .folder-label svg {
          display: none;
        }
        .folderTree-popup .FolderButton {
          background: transparent !important;
        }

        .folderTree-popup .FolderButton .spacer {
          margin-right: 5px;
          width: 20px;
        }
        .folderTree-popup .folder-label {
          padding: 5px 0;
        }
        .folderTree-popup .expand-button svg {
          width: 18px;
        }
        .folderTree-popup label {
          color: #303030;
          font-size: 14px;
          font-weight: 400;
          padding-left: 14px;
          text-transform: uppercase;
        }
        .folderTree-popup .folder-label strong {
          font-weight: 400 !important;
          font-size: 14px;
        }
        .folderTree-popup .selected strong {
          font-weight: 600 !important;
        }
      `}</style>
    </>
  );
};

export default FolderNavigationMobile;
