import React from 'react';
import { Spinner } from 'react-bootstrap';
import { logEvent } from '../../../../analytics';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import PasscodeInput from './PasscodeInput';
import SBPopupWrapper from './SBPopupWrapper';

const PasscodeForm = ({
  onBack,
  onSave
}: {
  onBack: () => void;
  onSave: (passcode: string) => void;
}) => {
  const [pin, setPin] = React.useState('');
  const [error, setError] = React.useState('');
  const logClick = (buttonName: string) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button: buttonName });
  };
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const handleSave = () => {
    if (pin.length < 5) {
      setError('Passcode must be 5 characters long');
      return;
    }
    onSave(pin);
    onBack();
  };

  return (
    <>
      {error ? (
        <span className="error text-semibold">{error}</span>
      ) : (
        <span>Enter 5 digits passcode</span>
      )}
      <div className="fieldset">
        <PasscodeInput
          onChange={(code) => {
            setPin(code);
            setError(null);
          }}
          buttonRef={buttonRef}
          size="sm"
        />
      </div>
      <div className="fieldset action-wrapper">
        <button
          className="btn btn-sm btn-round btn-outline-dark"
          onClick={(e) => {
            e?.stopPropagation();
            onBack();
            logClick('Back');
          }}
        >
          Back
        </button>
        <button
          className="btn btn-sm btn-round btn-dark ml-2"
          onClick={(e) => {
            e?.stopPropagation();
            handleSave();
            logClick('Save');
          }}
          ref={buttonRef}
        >
          Save
        </button>
      </div>
      <style jsx>{`
        .action-wrapper .fieldset {
          display: flex;
          justify-content: space-between;
        }
        .action-wrapper .btn {
          width: 48%;
        }
        .btn-eye {
          position: absolute;
          right: 0;
          top: 4px;
        }
        .error {
          color: red;
          font-size: 0.8em;
        }
      `}</style>
    </>
  );
};

const PasscodePopup = ({
  onSubmit,
  onClose,
  loading,
  title,
  hasPasscode,
  editMode
}: {
  onSubmit: (code: string) => void;
  onClose?: () => void;
  title?: string;
  loading?: boolean;
  hasPasscode?: boolean;
  editMode?: boolean;
}) => {
  const [pin, setPin] = React.useState(hasPasscode ? 'any' : '');
  const [showForm, setShowForm] = React.useState(false);
  const [hidePublish, setHidePublish] = React.useState(editMode);
  const logClick = (buttonName: string) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button: buttonName });
  };

  const changePasscodeButton = (
    <>
      <div className="fieldset">
        <button
          className="btn btn-sm btn-round btn-outline-dark w-100 passcode-remove"
          onClick={(e) => {
            e?.stopPropagation();
            setPin('');
            setHidePublish(false);
            logClick('Remove Passcode');
          }}
        >
          Remove Passcode
        </button>
      </div>
      <div className="fieldset">
        <button
          className="btn btn-sm btn-round btn-outline-dark w-100 passcode-change"
          onClick={(e) => {
            e?.stopPropagation();
            setShowForm(true);
            logClick('Change Passcode');
          }}
        >
          Change Passcode
        </button>
      </div>
    </>
  );

  const addPasscodeButton = (
    <div className="fieldset">
      <button
        className="btn btn-sm btn-round btn-outline-dark w-100 passcode-add"
        onClick={(e) => {
          e?.stopPropagation();
          setShowForm(true);
          logClick('Add Passcode');
        }}
      >
        Add Passcode
      </button>
    </div>
  );

  const actionPublishButton = !hidePublish && (
    <div className="fieldset">
      <button
        className="btn btn-sm btn-round btn-outline-dark publish-cancel"
        onClick={(e) => {
          e?.stopPropagation();
          onClose();
          logClick('Cancel');
        }}
      >
        Cancel
      </button>
      <button
        className="btn btn-sm btn-round btn-dark ml-2 publish-submit"
        onClick={(e) => {
          e?.stopPropagation();
          onSubmit(pin);
          logClick('Publish');
        }}
      >
        {loading ? <Spinner animation="border" size="sm" /> : 'Publish'}
      </button>
      <style jsx>{`
        .fieldset {
          display: flex;
          justify-content: space-between;
        }
        .btn {
          width: 48%;
        }
      `}</style>
    </div>
  );

  const editElement = (
    <>
      {pin ? changePasscodeButton : addPasscodeButton}
      {actionPublishButton}
    </>
  );

  return (
    <SBPopupWrapper onClose={onClose} width="320px">
      <div className="PasscodePopup text-center w-100">
        {title && <h4 className="text-semibold">{title}</h4>}

        <div className="form-container">
          {showForm ? (
            <PasscodeForm
              onBack={() => setShowForm(false)}
              onSave={(p) => {
                setPin(p);
                setHidePublish(false);
              }}
            />
          ) : (
            editElement
          )}
        </div>
        <style jsx>{`
          .PasscodePopup {
            padding: 20px 30px;
          }
          h4 {
            font-size: 1.2em;
          }
          .form-container {
            margin-top: 20px;
          }
          .PasscodePopup :global(.fieldset) {
            margin-top: 10px;
            position: relative;
          }
        `}</style>
      </div>
    </SBPopupWrapper>
  );
};

export default PasscodePopup;
