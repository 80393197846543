import React from 'react';
import { IStorybookPage, IStorybookSettings } from '../../../../interfaces';
import { TLanguage } from '../../../../mappers/polotno';
import { isVideo } from '../../../../utils/file';
import { getStorybookPageType } from '../../../utils/storybook';
import PageLabel from '../Common/PageLabel';
import {
  SBImagePage,
  SBLayerPage,
  SBVideoPage
} from '../Mirosite/SBMicrositePageType';

const SBPreviewDefaultPage = ({
  page,
  setting,
  portrait,
  activateLanguage
}: {
  page: IStorybookPage;
  setting?: IStorybookSettings;
  portrait?: boolean;
  activateLanguage?: TLanguage;
}) => {
  const { color, duration, background, text } = setting;
  const isVideoPage = isVideo(page?.url);

  const pageType = getStorybookPageType(page, setting);

  return (
    <div
      className={`content ${portrait ? 'portrait' : ''}`}
      style={{ background: background || '#fff' }}
    >
      {pageType === 'video' && (
        <SBVideoPage
          portrait={portrait}
          page={page}
          setting={setting}
          canPlay={true}
        />
      )}

      {pageType === 'image' && (
        <SBImagePage page={page} portrait={portrait} setting={setting} />
      )}

      {pageType === 'layer' && (
        <SBLayerPage
          portrait={portrait}
          page={page}
          setting={setting}
          handleError={() => null}
          canPlay={true}
          activateLanguage={activateLanguage}
        />
      )}

      {(page?.title || page?.subtitle) && (
        <div className="label-container">
          <PageLabel
            key={page?.title}
            title={page?.title}
            subtitle={page?.subtitle}
            background={color}
            duration={duration}
            textColor={text}
          />
        </div>
      )}
      <style jsx>{`
        .label-container {
          position: absolute;
          pointer-events: none;
          left: 0;
          bottom: 0;
        }
        .content {
          width: 100%;
          height: 100%;
          overflow: auto;
          position: relative;
        }
        .content:hover .label-container {
          display: ${isVideoPage ? 'none' : 'block'};
        }
        .content img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center center;
        }
        .content.portrait :global(video) {
          // height: ${setting.portraitUrl ? '100%' : 'auto'} !important;
          object-fit: cover !important;
        }
        @media (orientation: portrait) {
          .content :global(video) {
            height: ${setting.portraitUrl ? '100%' : 'auto'} !important;
            object-fit: cover !important;
          }
        }
      `}</style>
    </div>
  );
};

export default SBPreviewDefaultPage;
