import React from 'react';
import { logEvent } from '../../../../analytics';
import { IStorybook } from '../../../../interfaces';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import { StorybookAccessType } from '../../../utils/storybook';
import { SBActionTarget } from '../SBActionHandler';
import { StoryBookContext } from '../SBContextContainer';

const SBChangePasscode = ({
  storybook,
  onSettingPasscode
}: {
  storybook: IStorybook;
  onSettingPasscode: (type: StorybookAccessType) => void;
}) => {
  const { action } = React.useContext(StoryBookContext);

  const handleClick = () => {
    action.read(
      SBActionTarget.PublishedStorybook,
      { id: storybook.id },
      (res) => {
        onSettingPasscode(res.status);
      }
    );
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
      button: 'Passcode Setting'
    });
  };

  return (
    <button
      className="list-group-item list-group-item-action"
      onClick={handleClick}
    >
      Passcode Setting
    </button>
  );
};
export default SBChangePasscode;
