import React from 'react';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import {
  BsFillCaretLeftFill,
  BsFillCaretRightFill,
  BsPlayFill
} from 'react-icons/bs';
import { logClickButton, logEvent, logKeyPress } from '../../analytics';
import { DID_SELECT_PAGE } from '../../utils/constants';
import { mapBrowerLanguageToSBLanguage, scrollElementIntoViewById } from '../../utils/window';
import { useDispatch } from 'react-redux';
import {
  actionParticipantSetActivePage,
  actionSetActivePage,
  actionUpdateHiddenPages
} from '../../redux/actions';
import { isImage, isVideo, getFileType } from '../../utils/file';
import { first, last, xor } from 'lodash';
import { FileType } from '../../interfaces';
import { meetingColor } from '../../utils/meeting';
import { mapStorybookSettingStringToDocumentSettings } from '../../mappers/storybook';
import { useLocalUserMeetingEntitlement } from '../hooks/meeting';
import { getLanguaImageFromJSON } from '../../advisorHub/utils/storybook';

let scrollTimeout;

const ThumbnailHeading = ({
  hidden,
  title,
  onToggleHide,
  onSelect
}: {
  onToggleHide: () => void;
  onSelect: () => void;
  title: string;
  hidden: boolean;
}) => (
  <div className="thumbnailHeading">
    <span onClick={onSelect}>{title}</span>
    <button className="btn btn-sm btn-hide-page" onClick={onToggleHide}>
      {hidden ? <FiEyeOff color="#ccc" /> : <FiEye color="#ccc" />}
    </button>
    <style jsx>{`
      .thumbnailHeading {
        font-size: 0.8em;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 10px;
        color: #ccc;
        font-weight: normal;
      }
      span {
        cursor: pointer;
      }
    `}</style>
  </div>
);

const Thumbnail = ({
  url,
  onSelect,
  title,
  active,
  hidden,
  onToggleHide,
  id,
  type
}: {
  url: string;
  onSelect: () => void;
  title: string;
  active: boolean;
  hidden: boolean;
  onToggleHide: () => void;
  id: string;
  type: FileType;
}) => {
  const content = () => {
    if (isVideo(url)) {
      return (
        <button className="btn media-container media-icon" onClick={onSelect}>
          <BsPlayFill color="#000" className="with-shadow" />
          <strong className="hidden-text">HIDDEN</strong>
        </button>
      );
    }
    return (
      <button className="btn media-container media-image" onClick={onSelect}>
        <img src={url} alt="title" />
        {type === FileType.VIDEO && (
          <BsPlayFill color="fff" className="with-shadow" />
        )}
        <strong className="hidden-text">HIDDEN</strong>
      </button>
    );
  };

  return (
    <div
      key={id}
      className={`thumbnail thumbnail-image ${active ? 'active' : ''} ${
        hidden ? 'hidden' : ''
      }`}
      id={id}
    >
      <ThumbnailHeading
        title={title}
        onToggleHide={onToggleHide}
        hidden={hidden}
        onSelect={onSelect}
      />
      {content()}
    </div>
  );
};

const PageList = ({
  fileId,
  fileTitle,
  pages,
  pagesSetting,
  thumbnails,
  hiddenPages = [],
  silences,
  initPage = 0
}: {
  fileId: string;
  fileTitle: string;
  pages: string[];
  pagesSetting: string[];
  thumbnails: string[];
  hiddenPages?: number[];
  silences?: boolean[];
  initPage?: number;
}) => {
  const dispatch = useDispatch();
  const [activePage, setActivePage] = React.useState(0);
  const totalHiddenSlides = hiddenPages.length;
  const visiblePages = xor(
    thumbnails.map((_, i) => i),
    hiddenPages
  );

  const currentVisiblePageIndex = visiblePages.indexOf(activePage);

  const [_, isSourceOfMirrorAction] = useLocalUserMeetingEntitlement();
  const handleAutoScroll = (newPage: number) => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
    scrollTimeout = setTimeout(() => {
      scrollElementIntoViewById(`slide${newPage}`);
      scrollTimeout = null;
    }, 10);
  };

  const updateActivePage = (newPage: number) => {
    setActivePage(newPage);
    handleAutoScroll(newPage);

    const nextImages = pages.filter(
      (url, i) => i > newPage && isImage(url) && visiblePages.includes(i)
    );
    const prevImages = pages.filter(
      (url, i) => i < newPage && isImage(url) && visiblePages.includes(i)
    );
    const payload = {
      id: fileId,
      file: fileTitle,
      url: pages[newPage],
      setting: pagesSetting[newPage],
      silence: silences[newPage]
    };
    if (!isSourceOfMirrorAction) {
      dispatch(
        actionParticipantSetActivePage(
          payload,
          first(nextImages),
          last(prevImages)
        )
      );
    }
    dispatch(actionSetActivePage(payload, first(nextImages), last(prevImages)));
    logEvent(DID_SELECT_PAGE, DID_SELECT_PAGE, {
      pageNumber: newPage + 1,
      pageId: mapStorybookSettingStringToDocumentSettings(
        pagesSetting[newPage],
        pages[newPage]
      ).pageId,
      id: fileId,
      title: fileTitle
    });
  };

  const toggleHidePage = (index: number) => {
    if (thumbnails.length - totalHiddenSlides === 1 && activePage === index)
      return;
    dispatch(
      actionUpdateHiddenPages(
        hiddenPages.includes(index)
          ? hiddenPages.filter((hiddenPage) => hiddenPage !== index)
          : [...hiddenPages, index]
      )
    );

    if (activePage === index) {
      const nextPage = visiblePages[visiblePages.indexOf(activePage) + 1];
      updateActivePage(
        nextPage ? nextPage : visiblePages[visiblePages.indexOf(activePage) - 1]
      );
    }
  };

  const handleNext = () => {
    if (currentVisiblePageIndex === visiblePages.length - 1) return;
    const newPage = visiblePages[currentVisiblePageIndex + 1];

    updateActivePage(newPage);
  };
  const handlePrev = () => {
    if (currentVisiblePageIndex === 0) return;
    const newPage = visiblePages[currentVisiblePageIndex - 1];
    updateActivePage(newPage);
  };
  const handleKeyDown = React.useCallback(
    (e) => {
      e = e || window.event;
      if (e.keyCode == '37') {
        //left arrow key
        e.preventDefault();
        handlePrev();
        logKeyPress('37');
      }
      if (e.keyCode == '39') {
        //left arrow key
        e.preventDefault();
        handleNext();
        logKeyPress('39');
      }
    },
    [activePage, visiblePages]
  );

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  React.useEffect(() => {
    updateActivePage(initPage);
  }, [pages]);

  const navigation = (
    <div className="navigation text-center">
      <button
        className="btn btn-lg btn-prev"
        disabled={currentVisiblePageIndex === 0}
        onClick={() => {
          logClickButton('Previous');
          handlePrev();
        }}
      >
        <BsFillCaretLeftFill color="#fff" />
      </button>
      <span>
        Slide {currentVisiblePageIndex + 1} of {visiblePages.length}
        {!!totalHiddenSlides && ` visible slides`}
      </span>{' '}
      <button
        className="btn btn-lg btn-next"
        disabled={currentVisiblePageIndex === visiblePages.length - 1}
        onClick={() => {
          logClickButton('Next');
          handleNext();
        }}
      >
        <BsFillCaretRightFill color="#fff" />
      </button>
      <style jsx>{`
        .navigation {
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 5px;
        }

        .btn :global(svg) {
          margin-top: -4px;
        }

        :global(.vbssp) .btn :global(svg) {
          width: 30px;
          height: auto;
        }
      `}</style>
    </div>
  );

  return (
    <div className="PageList">
      {navigation}
      <div className="thumbnail-container">
        {thumbnails.map((thumbnail, i) => {
          const language = mapBrowerLanguageToSBLanguage(navigator.language);
          const url = getLanguaImageFromJSON(thumbnail, language);

          return (
            <Thumbnail
              key={i}
              url={url}
              onSelect={() => updateActivePage(i)}
              onToggleHide={() => toggleHidePage(i)}
              title={`${i + 1}`}
              active={activePage === i}
              hidden={hiddenPages.includes(i)}
              id={`slide${i}`}
              type={getFileType(pages[i])}
            />
          );
        })}
      </div>

      <style jsx>{`
        .PageList {
          flex: 0 0 auto;
          border-top: 1px solid #444;
          height: 200px;
          position: relative;
        }

        .PageList :global(.btn) {
          outline: none !important;
          box-shadow: none !important;
        }

        .thumbnail-container {
          white-space: nowrap;
          width: 100%;
          overflow-y: auto;
          display: block;
          padding-left: calc(50% - 75px);
          padding-right: calc(50% - 75px);
        }

        @-moz-document url-prefix() {
          .thumbnail-container :global(.thumbnail):last-child {
            margin-right: calc(50vw - 75px);
          }
        }
        .PageList :global(.thumbnail) {
          text-align: center;
          display: inline-block;
          min-width: 140px;
          border: 1px solid #333;
          margin: 10px;
          overflow: hidden;
          border-radius: 4px;
          background: #fff;
          vertical-align: top;
        }
        .PageList :global(.media-container svg) {
          width: 30%;
          height: auto;
          margin: auto;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0.8;
        }

        .PageList :global(.thumbnailHeading) {
          background: #000;
        }

        .PageList :global(.thumbnailHeading .btn) {
          visibility: hidden;
        }
        .PageList :global(.hidden .thumbnailHeading .btn),
        .PageList :global(.thumbnail:hover .thumbnailHeading .btn) {
          visibility: visible;
        }

        .PageList :global(.thumbnail.active),
        .PageList :global(.thumbnail.active:hover) {
          border: 1px solid ${meetingColor.success};
          box-shadow: 0 0 0 4px ${meetingColor.success};
        }

        .PageList :global(.thumbnail.hidden:hover) {
          border: 1px solid #666;
        }

        .PageList :global(.thumbnail.hidden) {
          border: 1px solid #000;
          opacity: 0.5;
        }

        .PageList :global(.thumbnail.hidden:hover .thumbnailHeading .btn) {
          transform: scale(1.6);
        }

        .PageList :global(.thumbnail.hidden .media-container),
        .PageList :global(.thumbnail.hidden .thumbnailHeading span) {
          cursor: not-allowed;
          pointer-events: none;
        }

        .PageList :global(.thumbnail img) {
          width: auto;
          height: 80px;
        }
        .PageList :global(.thumbnail img) {
          margin: auto;
          pointer-events: none;
        }
        .PageList :global(.thumbnail .media-container) {
          display: flex;
          align-items: center;
          width: 100%;
          height: 80px;
          padding: 0;
          border: none;
          cursor: pointer;
          position: relative;
          outline: none !important;
          box-shadow: none !important;
        }
        .PageList :global(.hidden-text) {
          display: none;
        }
        .PageList :global(.hidden .hidden-text) {
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: #000;
          color: #fff;
          opacity: 0.7;
          padding: 5px;
          border-radius: 4px;
        }
        .show-mobile {
          display: none;
        }
      `}</style>
    </div>
  );
};

export default PageList;
