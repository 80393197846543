import React from 'react';
import { Spinner } from 'react-bootstrap';
import { logEvent } from '../../../analytics';
import { DID_CLICK_BUTTON, DID_VIEW_CONTENT } from '../../../utils/constants';
import PopupContainer from './PopupContainer';

const ConfirmationPopup = ({
  title,
  onOk,
  okLabel = 'OK',
  closeLabel = 'Cancel',
  onClose,
  children,
  loading,
  disableCancel,
  zIndex
}: {
  title?: string;
  onOk?: () => void;
  okLabel?: string;
  closeLabel?: string;
  onClose: () => void;
  children?: React.ReactNode;
  loading?: boolean;
  disableCancel?: boolean;
  zIndex?: number;
}) => {
  const logClick = (button: string) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button });
  };

  React.useEffect(() => {
    logEvent(DID_VIEW_CONTENT, DID_VIEW_CONTENT, {
      content: 'Confirmation Popup',
      popupTitle: title,
      okLabel,
      closeLabel
    });
  }, []);

  return (
    <PopupContainer maxWidth="400px" zIndex={zIndex}>
      <div className="w-100 text-center p-3">
        {title && <h3>{title}</h3>}
        {children}
        <div className="button-container ">
          {loading ? (
            <Spinner animation="border" size="sm" />
          ) : (
            <>
              {!disableCancel && (
                <button
                  className="btn btn-sm btn-round btn-outline-dark btn-reject"
                  onClick={() => {
                    logClick(closeLabel);
                    onClose();
                  }}
                >
                  {closeLabel}
                </button>
              )}
              {onOk && (
                <button
                  className="btn btn-sm btn-round btn-dark btn-confirm"
                  onClick={() => {
                    onOk();
                    logClick(okLabel);
                  }}
                >
                  {okLabel}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <style jsx>{`
        .button-container {
          margin-top: 15px;
        }
        .btn {
          min-width: 100px;
          margin: 0 5px;
        }
        .text-center :global(h3) {
          font-size: 1.25rem;
        }
      `}</style>
    </PopupContainer>
  );
};

export default ConfirmationPopup;
