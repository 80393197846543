import React from 'react';
import { Spinner } from 'react-bootstrap';
import { logEvent } from '../../../../analytics';
import { IFolderKey, ILibraryScene } from '../../../../interfaces';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import { FAVORITE_FOLDER, TRASH_FOLDER, SHARED_ITEMS } from '../../../utils/folders';
import FolderNavigation from '../../Common/Folder/FolderNavigation';
import PopupContainer from '../../Common/PopupContainer';

const SLClonePopup = ({
  folders,
  activeFolderKey,
  expandedFolderKeys,
  onClose,
  loading,
  scene,
  onClone
}: {
  folders: IFolderKey[];
  activeFolderKey: string;
  expandedFolderKeys: string[];
  onClose?: () => void;
  loading?: boolean;
  scene: ILibraryScene;
  onClone: ({
    id,
    name,
    folder,
    openAfterClone
  }: {
    id: string;
    name: string;
    folder: string;
    openAfterClone?: boolean;
  }) => void;
}) => {
  const [selectedFolder, setSelectedFolder] = React.useState({
    activeFolderKey,
    expandedFolderKeys
  });

  const [sceneName, setSceneName] = React.useState(
    scene.content.name ? scene.content.name + ' (copy)' : ''
  );

  const [openEditor, setOpenEditor] = React.useState(true);

  const selectFolderKey = (id: string) => {
    const updatedExpandedFolderKeys =
      selectedFolder.expandedFolderKeys.includes(id)
        ? [...selectedFolder.expandedFolderKeys]
        : [...selectedFolder.expandedFolderKeys, id];

    setSelectedFolder({
      activeFolderKey: id,
      expandedFolderKeys: updatedExpandedFolderKeys
    });
  };

  const toggleExpandFolderKey = (id: string) => {
    const updatedExpandedFolderKeys =
      selectedFolder.expandedFolderKeys.includes(id)
        ? selectedFolder.expandedFolderKeys.filter((f) => f !== id)
        : [...selectedFolder.expandedFolderKeys, id];

    setSelectedFolder({
      ...selectedFolder,
      expandedFolderKeys: updatedExpandedFolderKeys
    });
  };

  const error = !sceneName.trim();

  const handleClone = () => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, 'Clone');
    if (!error) {
      onClone({
        id: scene.id,
        name: sceneName,
        folder: selectedFolder.activeFolderKey,
        openAfterClone: openEditor
      });
    }
  };

  const folderTrees = (
    <div className="folder-container">
      <FolderNavigation
        folders={folders}
        selectedFolder={selectedFolder.activeFolderKey}
        expandedFolderKeys={selectedFolder.expandedFolderKeys}
        onSelectFolderKey={selectFolderKey}
        toggleExpandFolderKey={toggleExpandFolderKey}
        excludeFolders={[TRASH_FOLDER, FAVORITE_FOLDER, SHARED_ITEMS]}
      />
      <style jsx>{`
        div {
          border: 1px solid #ccc;
          width: 100%;
          max-height: calc(100vh - 300px);
          min-height: 200px;
          overflow: auto;
          border-radius: 5px 5px 0 0;
          padding: 20px 0;
        }
      `}</style>
    </div>
  );

  return (
    <PopupContainer maxWidth="350px">
      <label className="strongLabel">Scene Name:</label>
      <input
        type="text"
        className={`form-control ssp-ignore ${error ? 'is-invalid' : ''}`}
        placeholder="Enter Scene Name"
        value={sceneName}
        onChange={(e) => setSceneName(e.target.value)}
      />

      <label className="strongLabel">Clone To Folder:</label>
      {folderTrees}
      <div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value=""
          id="flexCheckChecked"
          checked={openEditor}
          onChange={() => setOpenEditor(!openEditor)}
        />
        <label className="form-check-label" htmlFor="flexCheckChecked">
          Open editor after clone
        </label>
      </div>
      </div>
      <div className="action d-flex">
        <button
          className="btn btn-round btn-sm btn-outline-dark"
          onClick={() => {
            onClose();
            logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button: 'Cancel' });
          }}
        >
          Cancel
        </button>
        <button
          className="btn btn-round btn-sm btn-dark btn-save-folder"
          onClick={handleClone}
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Clone'}
        </button>
      </div>
      <style jsx>{`
        label {
          font-size: 12px;
          font-weight: 600;

          text-align: left;
        }
        .strongLabel {
          display: block;
          margin-top: 10px;
        }
        .action {
          margin-top: 8px;
          width: 100%;
        }
        .form-check {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .btn {
          width: calc(100% / 2 - 5px);
          margin: 0 5px;
        }
      `}</style>
    </PopupContainer>
  );
};

export default SLClonePopup;
