import React from 'react';
import { Spinner } from 'react-bootstrap';
import { ContentManagerItem, SBContentManagerTab } from '.';
import { IStorybook } from '../../../../interfaces';
import PopupContainer from '../../Common/PopupContainer';
import SearchInput from '../../Common/SearchInput';
import SBPreview from '../Preview';
import { StoryBookContext } from '../SBContextContainer';
import ContentTabScene from './ContentTabScene';
import ContentTabStorybook from './ContentTabStorybook';

const tabs: {
  label: string;
  tab: SBContentManagerTab;
}[] = [
  { label: 'Scenes', tab: 'scene' },
  { label: 'Storybooks', tab: 'storybook' }
];

export interface ContentManagerTabProps {
  selectedItems: string[];
  toggleSelect: (storybook: IStorybook) => void;
  keywords: string;
  onPreview: (storybook: IStorybook) => void;
}

const PopupHeader = ({
  tabs,
  activeTab,
  setKeywords,
  keywords,
  setActiveTab
}) => (
  <div className="popup-header-container d-flex">
    <div className="popup-tabs d-flex">
      {tabs.map((tab) => (
        <div
          className={`tab ${activeTab === tab.tab ? 'active text-medium' : ''}`}
          key={tab.tab}
          onClick={() => {
            setActiveTab(tab.tab);
          }}
        >
          {tab.label}
        </div>
      ))}
    </div>
    <div className="search-container">
      <SearchInput
        onSearch={setKeywords}
        searchOnTyping={true}
        rounded={true}
        placeHolder="Search"
        defaultKeyword={keywords || ''}
      />
    </div>

    <style jsx>{`
      .popup-header-container {
        justify-content: space-between;
        align-items: center;
      }
      .popup-tabs {
        align-items: center;
      }
      .popup-tabs > div {
        margin-right: 40px;
      }
      .tab {
        font-size: 24px;
        color: #a8a8a8;

        cursor: pointer;
      }

      .divider {
        height: 20px;
        border-left: 1px solid #d8d8d8;
      }
      .active {
        font-weight: 500;
      }
      .active,
      .tab:hover {
        color: #000;
      }
    `}</style>
  </div>
);

const PopupFooter = ({ onClose, onConfirm, isConfirming, legend }) => (
  <>
    <div className="legend text-semibold">{legend}</div>
    <div className="actions">
      <button className="btn btn-round btn-outline-dark" onClick={onClose}>
        Cancel
      </button>
      <button
        className="btn btn-round btn-dark ml-2 ok-button"
        onClick={onConfirm}
        disabled={isConfirming}
      >
        Confirm
        {isConfirming && (
          <Spinner animation="border" size="sm" style={{ marginLeft: '5px' }} />
        )}
      </button>
    </div>
    <style jsx>
      {`
        .legend {
          font-size: 0.9em;
        }
      `}
    </style>
  </>
);

interface ContentManagerItemPreview {
  type: SBContentManagerTab;
  content: IStorybook;
}

const ContentManagerPopup = ({
  activeTab,
  setActiveTab,
  currentContentItems,
  onConfirm,
  autoClose = true,
  isConfirming,
  keywords = '',
  setKeywords
}: {
  activeTab: SBContentManagerTab;
  setActiveTab: (tab?: SBContentManagerTab) => void;
  currentContentItems: ContentManagerItem[];
  onConfirm: (contentItems: ContentManagerItem[]) => void;
  autoClose?: boolean;
  isConfirming?: boolean;
  keywords?: string;
  setKeywords: (keywords?: string) => void;
}) => {
  const { action } = React.useContext(StoryBookContext);
  const [selectedItems, setSelectedItems] = React.useState(currentContentItems);
  const [mounted, setMounted] = React.useState(false);

  const [preview, setPreview] = React.useState<ContentManagerItemPreview>(null);

  const handleToggleSelect = (item: IStorybook, type: SBContentManagerTab) => {
    const id = item.id;
    if (selectedItems.some((item) => item.id === id)) {
      setSelectedItems(selectedItems.filter((item) => item.id !== id));
    } else {
      setSelectedItems([
        { payload: item, type, id: item.id },
        ...selectedItems
      ]);
    }
  };

  const getSelectedItemsByType = (type: SBContentManagerTab) =>
    selectedItems.filter((item) => item.type === type).map((item) => item.id);

  const selectedStorybooks = getSelectedItemsByType('storybook');
  const selectedScenes = getSelectedItemsByType('scene');

  const legend = `Selected: Scene ${selectedScenes.length} | Storybook ${selectedStorybooks.length}`;

  React.useEffect(() => {
    action.setLayoutMode({
      selectMode: true
    });
    setMounted(true);
    return () => {
      action.setLayoutMode({
        selectMode: false
      });
      setMounted(false);
    };
  }, []);

  if (!mounted) return null;

  return (
    <PopupContainer maxWidth="2400px" width="95%" zIndex={100000}>
      {!preview && (
        <div className="popup-section popup-header w-100">
          <PopupHeader
            {...{ tabs, activeTab, setActiveTab, keywords, setKeywords }}
          />
        </div>
      )}
      <div className="popup-section  popup-body w-100">
        {preview ? (
          <SBPreview
            storybook={preview.content}
            onClose={() => setPreview(null)}
            selectedStorybooks={selectedItems.map((item) => item.id)}
            onSelect={(item) => handleToggleSelect(item, preview.type)}
            isForScene={
              preview.type === 'scene' ||
              (preview.type === 'file' && preview.content?.pages?.length === 1)
            }
            hideInfo={preview.type === 'file'}
            actionLabel={{ ok: 'Select', cancel: 'Unselect' }}
          />
        ) : (
          <>
            {activeTab === 'scene' && (
              <ContentTabScene
                selectedItems={selectedScenes}
                toggleSelect={(item) => handleToggleSelect(item, 'scene')}
                keywords={keywords}
                onPreview={(item) =>
                  setPreview({ content: item, type: 'scene' })
                }
              />
            )}
            {activeTab === 'storybook' && (
              <ContentTabStorybook
                selectedItems={selectedStorybooks}
                toggleSelect={(item) => handleToggleSelect(item, 'storybook')}
                keywords={keywords}
                onPreview={(item) =>
                  setPreview({ content: item, type: 'storybook' })
                }
              />
            )}

          </>
        )}
      </div>
      {!preview && (
        <div className="popup-section  popup-footer d-flex w-100">
          <PopupFooter
            onClose={() => setActiveTab(undefined)}
            onConfirm={() => {
              onConfirm(selectedItems);
              if (autoClose) {
                setActiveTab(undefined);
              }
            }}
            isConfirming={isConfirming}
            legend={legend}
          />
        </div>
      )}
      <style global jsx>
        {`
          .SBSearch .header-container,
          .SBSearch .action-footer {
            display: none !important;
          }
          .PopupContainer > .content-container {
            padding: 0 !important;
            overflow: hidden;
          }
          #polonto-root > div > div + div .bp5-navbar,
          #polonto-root .polotno-panel-container + div + div {
            opacity: 0;
            pointer-events: none;
          }
        `}
      </style>
      <style jsx>{`
        .popup-footer {
          justify-content: space-between;
          align-items: center;
          border-top: 1px solid #d8d8d8;
          padding: 15px 20px;
        }
        .popup-header {
          padding: 20px 20px 15px;
          border-bottom: 1px solid #d8d8d8;
        }
        .popup-body {
          height: calc(100vh - ${preview ? 80 : 223}px);
        }
      `}</style>
    </PopupContainer>
  );
};

export default ContentManagerPopup;
