import React from 'react';
import PageList from './PageList';
import DocumentEmpty from './DocumentEmpty';
import DocumentPage from './DocumentPage';

import { FileViewerProps } from '../hooks/fileViewer';
import { FileViewerLayout, PageContentView } from '../../interfaces';
import { zIndex } from '../../config';
import { mapStorybookSettingStringToDocumentSettings } from '../../mappers/storybook';
import EyeballTimeLogger from '../EyeballTimeLogger';
import { logEvent } from '../../analytics';
import { DID_OPEN_DOCUMENT_VIEWER } from '../../utils/constants';
import { isVideo } from '../../utils/file';
import { Spinner } from 'react-bootstrap';
import { getLanguaImageFromJSON } from '../../advisorHub/utils/storybook';

const DocumentsViewer = ({
  playerControl,
  layoutMode,
  hiddenPages,
  pages,
  pagesSetting,
  currentPage,
  prevUrl,
  nextUrl,
  thumbnails,
  viewOnly,
  className = '',
  children,
  silences,
  initPage,
  portraitContent,
  canControl,
  activateLanguage
}: FileViewerProps) => {
  const isSinglePage = pages.length === 1;

  const layoutClass = () => {
    switch (layoutMode) {
      case FileViewerLayout.SIDE:
        return 'document-side';
      case FileViewerLayout.PRESENTATION:
        return 'document-presentation';
      case FileViewerLayout.FULLSCREEN:
        return 'document-fullscreen';
      case FileViewerLayout.MINIMIZE:
        return 'document-minimize';
      default:
        return '';
    }
  };

  React.useEffect(() => {
    if (currentPage) {
      logEvent(DID_OPEN_DOCUMENT_VIEWER, DID_OPEN_DOCUMENT_VIEWER, {
        id: currentPage.id,
        title: currentPage.file
      });
    }
  }, [currentPage]);

  if (!currentPage) {
    return <DocumentEmpty />;
  }

  const pageSetting = mapStorybookSettingStringToDocumentSettings(
    currentPage.setting,
    currentPage.id
  );

  const documentViewer = (
    <div className={`SBDocumentViewer ${layoutClass()} ${className}`}>
      {currentPage.url ? (
        <>
          <EyeballTimeLogger
            key={currentPage.url}
            tracker={{
              view: PageContentView.CONTENT_PAGE,
              id: currentPage.id,
              title: currentPage.file,
              contentUrl: getLanguaImageFromJSON(currentPage?.url, 'en'),
              contentTitle: pageSetting.title,
              contentSubtitle: pageSetting.subtitle,
              pageNumber: pages?.indexOf(currentPage.url) + 1,
              pageId: pageSetting.pageId,
              portrait: portraitContent
            }}
          >
            {null}
          </EyeballTimeLogger>
          <DocumentPage
            url={currentPage.url}
            preloadUrls={[prevUrl, nextUrl].filter((pl) => !!pl)}
            viewOnly={viewOnly}
            playerControl={playerControl}
            setting={pageSetting}
            autoClose={isSinglePage && isVideo(currentPage.url)}
            silence={currentPage.silence}
            portraitContent={portraitContent}
            id={currentPage.id}
            activateLanguage={activateLanguage}
          />

          {!isSinglePage &&
            !viewOnly &&
            thumbnails?.length &&
            pages?.length && (
              <PageList
                fileId={currentPage.id}
                fileTitle={currentPage.file}
                pages={pages}
                pagesSetting={pagesSetting}
                hiddenPages={hiddenPages}
                thumbnails={thumbnails}
                silences={silences}
                initPage={initPage}
              />
            )}
          {children}
        </>
      ) : (
        <div className="loading">
          <Spinner variant="light" animation="border" />
        </div>
      )}
      <style jsx global>{`
        .content-wrapper {
          padding-top: 0 !important;
          overflow: hidden !important;
        }
        .spinner-border {
          position: absolute;
          top: 50%;
        }
      `}</style>
      <style jsx>{`
        .loading {
          height: 100%;
          width: 100%;
        }
        .SBDocumentViewer {
          transform: scale(1);
          position: fixed;
          bottom: 0;
          right: 0;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          background: #000;
          z-index: ${zIndex.documentViewer};
        }

        :global(.btn-restore) {
          position: fixed;
          bottom: 0;
          right: 0;
          top: 0;
          left: 0;
          background: #fff;
          opacity: 0.02;
        }
        .document-fullscreen {
          left: 0 !important;
          bottom: 0 !important;
          right: 0 !important;
          z-index: ${zIndex.meetingVideosWrapper + 1};
        }
        :global(.popup-page-opened) .SBDocumentViewer {
          z-index: ${zIndex.popupPage - 1};
        }
        .document-minimize {
          transform: scale(0.08);
          opacity: ${canControl || !viewOnly ? 0.5 : 0} !important;
          transform-origin: 50% 0;
          z-index: ${zIndex.documentViewer};
          border: 10px solid #fff;
          border-top: 0;
          transition: all 0.3s ease-in-out;
        }
        .document-minimize:hover {
          opacity: 1 !important;
          cursor: pointer;
        }

        .SBDocumentViewer.entering {
          opacity: 0;
        }
        .SBDocumentViewer.entered {
          opacity: 1;
        }
        .SBDocumentViewer.exiting {
          opacity: 0;
        }
        .SBDocumentViewer.exited {
          opacity: 0;
        }
      `}</style>
    </div>
  );

  return (
    <EyeballTimeLogger
      tracker={{
        view: PageContentView.DOCUMENT_VIEWER,
        id: currentPage.id,
        title: currentPage.file
      }}
    >
      {documentViewer}
    </EyeballTimeLogger>
  );
};

export default DocumentsViewer;
