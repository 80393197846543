import React, { useEffect } from 'react';
import ToolTipWrapper from '../../../../components/Common/ToolTipWrapper';
import { useFolder } from '../../../../components/hooks/folder';
import {
  useLibraryAction,
  useLibrarySearch
} from '../../../../components/hooks/library';
import { useLoadingStatus } from '../../../../components/hooks/loading';
import {
  ILibraryScene,
  LibrarySceneType,
  LoadingStatus
} from '../../../../interfaces';
import {
  getFolderLabel,
  getKeyIdFromElementId,
  getParentFolderKey,
  hasUserSubFolder,
  isLastChildFolder,
  SHARED_ITEMS,
  SHARED_TEAM_TYPE,
  TRASH_FOLDER
} from '../../../utils/folders';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import AddToFolderPopup from '../../Common/Folder/AddToFolderPopup';
import CreateFolderPopup from '../../Common/Folder/CreateFolderPopup';
import FolderNavigation from '../../Common/Folder/FolderNavigation';
import SearchBar from '../../Common/SearchBar';
import FolderList from '../../Common/Folder/FolderList';
import SLThumbnailMenu from '../Common/SLThumbnailMenu';
import SLSearchItems from './SLSearchItems';
import OverviewPlaceHolder from '../../Common/OverviewPlaceHolder';
import { useDispatch } from 'react-redux';
import { updateKeysInFolderAsync } from '../../../../redux/advisorHubAsyncActions';
import SLMetaData from '../MetaData/SLMetaData';
import { IconOutlineArrow } from '../../Common/HubIcons';
import { logClickButton } from '../../../../analytics';
import { actionHubAlertInfo } from '../../../../redux/actions';
import SLClonePopup from './SLClonePopup';
import FolderMenu from '../../Common/Folder/FolderMenu';
import { mapSceneToStorybook } from '../../../../mappers/storybook';
import SLCreateSceneMenu from './SLCreateSceneMenu';
import PagePreviewPopup from '../../StoryBook/Common/PagePreviewPopup';
import hotkeys from 'hotkeys-js';
import { filter, first, isEmpty, max, min, reverse } from 'lodash';
import SLSharePopup from '../Common/SLSharePopup';
import SLDescriptionPopup from '../Builder/SLDescriptionPopup';
import SLTransferOwnershipPopup from '../Common/SLTransferOwnershipPopup';

type MenuType = 'scene' | 'folder';
type ThumbnailMenuPayload<T extends MenuType> = T extends 'scene'
  ? ILibraryScene
  : string;
type ThumbnailMenu = {
  type: MenuType;
  id: string;
  payload?: ThumbnailMenuPayload<MenuType>;
  canDelete?: boolean;
  viewOnly?: boolean;
};

const SLOverview = ({
  userId,
  onOpenBuilder,
  onOpenInsight,
  initScenes,
  keywords,
  setKeywords,
  onSelect,
  selectedScenes,
  onView,
  excludeFolders = [],
  context,
  onUpdateInitScenes
}: {
  userId: string;
  onOpenBuilder?: (scene?: ILibraryScene) => void;
  onOpenInsight?: (scene: ILibraryScene) => void;
  initScenes?: ILibraryScene[];
  keywords?: string;
  setKeywords?: (keywords: string) => void;
  onSelect?: (scene: ILibraryScene) => void;
  selectedScenes?: string[];
  onView?: (scene: ILibraryScene) => void;
  excludeFolders?: string[];
  context?: LibrarySceneType;
  onUpdateInitScenes?: (ids: string[]) => void;
}) => {
  const dispatch = useDispatch();
  const isTemplate = context === 'template';
  const folderContext = isTemplate ? 'scenetemplate' : 'library';
  const {
    folderKeys,
    activeFolderKey,
    selectFolderKey,
    toggleExpandFolderKey,
    expandedFolderKeys,
    moveElementToKey,
    moveMultipleElementsToKey,
    elementsInKey,
    elementsNotInKey,
    elementsNotInTrash,
    loading: loadingFolder,
    trashList,
    favoritesList,
    addToFavorites,
    removeFromFavorites,
    addToTrash,
    removeFromTrash,
    activeFolderKeyLabel,
    searchScope,
    isInTrashKey,
    isInFavoritesKey,
    elementsInFolderKeys,
    isInSharedKey,
    isInCollaboratedKey
  } = useFolder({
    uuid: userId,
    userId,
    context: folderContext
  });

  hotkeys.setScope(`scene-overview`);

  const isLastChild =
    isLastChildFolder(activeFolderKey, folderKeys) ||
    !hasUserSubFolder(folderKeys);

  const isInFolder = activeFolderKey && activeFolderKey !== '/';

  const { isLoading, setLoadingStatus } = useLoadingStatus();

  const [showFolderCol, setShowFolderCol] = React.useState(true);

  const [thumbnailMenu, setThumbnailMenu] = React.useState<ThumbnailMenu>(null);
  const [editFolderPopup, setEditFolderPopup] = React.useState<{
    id: string;
    hideRename?: boolean;
    hideMoveTo?: boolean;
  }>(null);
  const [addToFolder, setAddToFolder] = React.useState<{
    id: string;
    sharedOnly?: boolean;
  }>(null);
  const [deleteConfirmation, setDeleteConfirmation] = React.useState<string[]>(
    []
  );
  const [selectedScene, setSelectedScene] = React.useState<ILibraryScene>(null);
  const [selectedSceneArr, setSelectedSceneArr] = React.useState<string[]>([]);
  const [isMultipleSelectionEnabled, setIsMultipleSelectionEnabled] =
    React.useState<boolean>(false);
  const [multipleSelectTrigger, setMultipleSelectTrigger] =
    React.useState<string>(undefined);

  const [clonePopup, setClonePopup] = React.useState<ILibraryScene>(null);
  const [loadingCreate, setLoadingCreate] = React.useState(false);
  const [loadingCreateLayer, setLoadingCreateLayer] = React.useState(false);
  const [createMenu, setCreateMenu] = React.useState(false);
  const [sharingScene, setSharingScene] = React.useState<ILibraryScene | null>(
    null
  );

  const [openDescriptionPopup, setOpenDescriptionPopup] =
    React.useState<boolean>(false);
  const [currentScene, setCurrentScene] = React.useState<ILibraryScene | null>(
    null
  );
  const [transferOwnershipScene, setTransferOwnershipScene] =
    React.useState<ILibraryScene | null>(null);

  const filterScope = !keywords
    ? elementsInKey
    : isInFolder && activeFolderKeyLabel !== SHARED_ITEMS
    ? searchScope
    : undefined;

  const trashKey = folderKeys.find((f) => f.key === TRASH_FOLDER)?.id;

  const excludeScope = keywords
    ? activeFolderKey === trashKey
      ? elementsNotInTrash
      : trashList
    : elementsNotInKey;

  const {
    scenes,
    loading,
    hasNoScenes,
    hasNoSearchResult,
    hasMoreResult,
    loadScenes,
    infiniteRef,
    useLibrarySearchReady,
    updateSceneInList,
    deleteScenesInList,
    addSceneToList,
    setScenes
  } = useLibrarySearch({
    userId,
    keywords,
    filterScope,
    excludeScope,
    initScenes,
    excludeMine: isInSharedKey,
    activatedOnly: isInSharedKey || !!onSelect,
    includeSharedToMe: isInSharedKey,
    context,
    collaboration: isInCollaboratedKey
  });

  const activeFolderType = folderKeys.find((f) => f.id === activeFolderKey)
    ?.settings?.type;
  const isInSpecialFolder =
    isInTrashKey || isInFavoritesKey || isInSharedKey || isInCollaboratedKey;
  const {
    removeScenes,
    getSceneById,
    updateTagsVisibility,
    cloneScene,
    createScene
  } = useLibraryAction({ userId, context });

  const handleSelectFolderKey = (keyId: string) => {
    selectFolderKey(keyId);
    if (isInSharedKey) {
      const folder = folderKeys.find((f) => f.id === keyId);
      if (folder?.settings?.type?.includes(SHARED_TEAM_TYPE)) {
        loadScenes(true, keyId);
      } else if (folder?.key?.includes(SHARED_ITEMS)) {
        loadScenes(true);
      }
    }
  };

  const handleUpdateMetaData = (scene: ILibraryScene) => {
    const currentScene = { ...selectedScene };
    setSelectedScene(scene);
    updateTagsVisibility({
      data: {
        id: scene.id,
        tags: scene.tags,
        visibility: scene.content.visibility,
        visibilityScope: scene.content.visibilityScope,
        createdBy: scene.createdBy
      },
      onSuccess: () => {
        updateSceneInList(scene);
        setSelectedScene(scene);
      },
      onError: () => setSelectedScene(currentScene)
    });
  };

  const onDelete = (ids: string[]) => {
    setLoadingStatus('deleting-scenes', LoadingStatus.LOADING);
    removeFromTrash(ids, () => {
      removeScenes({
        ids,
        onSuccess: () => {
          setDeleteConfirmation([]);
          deleteScenesInList(ids);
          setSelectedScene(null);
          onUpdateInitScenes(ids);
        }
      });
    });
  };

  const onDeleteFolder = (idToDelete?: string) => {
    const id = idToDelete || activeFolderKey;
    dispatch(
      updateKeysInFolderAsync({
        key: folderKeys.find((f) => f.id === id)?.key,
        keyId: id,
        userId,
        uuid: userId,
        context: folderContext,
        operation: 'remove',
        onSuccess: () => {
          selectFolderKey(getParentFolderKey(id, folderKeys)?.id || '/');
        }
      })
    );
  };

  const handleEdit = (id: string) => {
    if (onOpenBuilder) {
      getSceneById({
        id,
        onSuccess: onOpenBuilder
      });
    }
  };

  const handleInsight = (id: string) => {
    if (onOpenInsight) {
      getSceneById({
        id,
        onSuccess: (scene) => onOpenInsight(scene)
      });
    }
  };

  const handleOpenClonePopup = (id: string) => {
    getSceneById({
      id,
      onSuccess: (scene) => setClonePopup(scene)
    });
  };
  const handleView = (id: string) => {
    getSceneById({
      id,
      onSuccess: onView || setSelectedScene
    });
  };

  const handleCreateMenu = () => {
    if (context === 'template') {
      handleCreate('layer');
    } else {
      setCreateMenu(true);
    }
  };

  const handleCreate = (type: 'video' | 'image' | 'layer') => {
    if (loadingCreate) return;
    if (type !== 'layer') {
      setLoadingCreate(true);
    }
    if (isInSpecialFolder) {
      selectFolderKey('/');
    }

    if (type === 'layer') {
      setOpenDescriptionPopup(true);
      return;
    }

    createScene({
      type,
      onSuccess: (scene) => {
        if (activeFolderKey !== '/' && !isInSpecialFolder) {
          moveElementToKey({
            elementId: scene.id,
            toKeyId: activeFolderKey
          });
        }
        onOpenBuilder(scene);
        setLoadingCreate(false);
      },
      onError: () => {
        setLoadingCreate(false);
      }
    });
  };

  const handleClone = ({
    id,
    name,
    folder,
    openAfterClone
  }: {
    id: string;
    name: string;
    folder: string;
    openAfterClone?: boolean;
  }) => {
    cloneScene({
      id,
      name,
      onSuccess: (scene) => {
        if (openAfterClone) {
          onOpenBuilder(scene);
        } else {
          addSceneToList(scene, id);
        }
        setClonePopup(null);
        if (folder !== '/') {
          moveElementToKey({
            elementId: scene.id,
            toKeyId: folder
          });
        }
        selectFolderKey(folder);
      }
    });
  };

  const restoreDeleteAll = isInTrashKey && scenes?.length > 1 && (
    <div className="delete-all">
      <button
        className="btn btn-sm btn-round btn-outline-dark"
        onClick={() => removeFromTrash(scenes.map((s) => s.id))}
      >
        Restore All
      </button>
      <button
        className="btn btn-sm btn-round btn-dark"
        onClick={() => setDeleteConfirmation(scenes.map((s) => s.id))}
      >
        Delete All
      </button>
      <style jsx>{`
        .delete-all {
          background: #efefef;
          padding: 10px;
        }
        .btn {
          margin: 0 2px;
          width: 100px;
        }
      `}</style>
    </div>
  );

  React.useEffect(() => {
    setSelectedScene(null);
    if (userId && useLibrarySearchReady) {
      const folder = folderKeys.find((f) => f.id === activeFolderKey);
      if (folder?.settings?.type?.includes(SHARED_TEAM_TYPE)) {
        loadScenes(true, activeFolderKey);
      } else {
        loadScenes(true);
      }
    }
  }, [userId, keywords, useLibrarySearchReady, context, isInSharedKey]);

  React.useEffect(() => {
    if (setScenes) {
      setScenes([]);
    }
  }, [isInSharedKey]);

  React.useEffect(() => {
    setSelectedScene(null);
    setSelectedSceneArr([]);
    if (isInCollaboratedKey) {
      loadScenes(true);
    }
  }, [activeFolderKey]);

  const [previewScene, setPreviewScene] = React.useState<ILibraryScene>(null);
  const pageViewer = previewScene && (
    <PagePreviewPopup
      storybook={mapSceneToStorybook(previewScene)}
      onClose={() => setPreviewScene(null)}
    />
  );

  const onDropToItem = async (fId, transferData) => {
    if (activeFolderKey.includes(fId)) {
      return;
    }

    const droppedSceneIds = transferData.getData('sceneIds').split(',');

    const folderPath =
      folderKeys?.find((folder) => folder?.id === fId)?.key || '/';
    if (activeFolderType === SHARED_TEAM_TYPE) {
      if (
        elementsInFolderKeys[fId].includes(droppedSceneIds[0]) ||
        (folderKeys.find((f) => f.id === fId).key === SHARED_ITEMS &&
          !getKeyIdFromElementId(droppedSceneIds[0], elementsInFolderKeys))
      ) {
        dispatch(
          actionHubAlertInfo(
            `Move to Shared with me/${folderPath.replace(SHARED_ITEMS, '')}`
          )
        );
        return;
      }
    }

    moveMultipleElementsToKey({
      elementIds: droppedSceneIds,
      toKeyId: fId,
      callback: () => {
        if (folderPath.startsWith(SHARED_ITEMS)) {
          dispatch(
            actionHubAlertInfo(
              `Move to Shared with me/${folderPath.replace(SHARED_ITEMS, '')}`
            )
          );
        } else {
          dispatch(actionHubAlertInfo(`Move to Overview${folderPath}`));
        }
        handleSelectFolderKey(fId);
      }
    });
  };

  const onMoveToFolder = (newFolderId, scene, callbackFunc) => {
    const folderPath =
      folderKeys?.find((folder) => folder?.id === newFolderId)?.key || '/';
    if (activeFolderType === SHARED_TEAM_TYPE) {
      if (
        elementsInFolderKeys[newFolderId].includes(scene.id) ||
        (folderKeys.find((f) => f.id === newFolderId).key === SHARED_ITEMS &&
          !getKeyIdFromElementId(scene.id, elementsInFolderKeys))
      ) {
        dispatch(
          actionHubAlertInfo(
            `Move to Shared with me/${folderPath.replace(SHARED_ITEMS, '')}`
          )
        );
        callbackFunc && callbackFunc();
        return;
      }
    }
    moveElementToKey({
      elementId: scene.id,
      toKeyId: newFolderId,
      callback: () => {
        if (folderPath.startsWith(SHARED_ITEMS)) {
          dispatch(
            actionHubAlertInfo(
              `Move to Shared with me/${folderPath.replace(SHARED_ITEMS, '')}`
            )
          );
        } else {
          dispatch(actionHubAlertInfo(`Move to Overview${folderPath}`));
        }
        callbackFunc && callbackFunc();
      }
    });
  };

  useEffect(() => {
    const onDisableMultipleSelect = (e) => {
      if (e.type === 'contextmenu') {
        if (
          (e.ctrlKey || e.metaKey || e.shiftKey) &&
          isMultipleSelectionEnabled
        ) {
          setIsMultipleSelectionEnabled(false);
        }
      } else if (e.type === 'blur') {
        if (isMultipleSelectionEnabled) {
          setIsMultipleSelectionEnabled(false);
        }
      }
    };

    window.addEventListener('contextmenu', onDisableMultipleSelect);
    window.addEventListener('blur', onDisableMultipleSelect);

    hotkeys(
      '*',
      { keyup: true, keydown: true, scope: `scene-overview` },
      (e) => {
        if (isInSpecialFolder || !!onSelect) return;
        if (e.type === 'keydown') {
          if (e.ctrlKey || e.key === 'Meta' || e.key === 'Shift') {
            setIsMultipleSelectionEnabled(true);
            setMultipleSelectTrigger(e.key);
          }
        }
        if (e.type === 'keyup') {
          if (e.key === 'Control' || e.key === 'Meta' || e.key === 'Shift') {
            setIsMultipleSelectionEnabled(false);
            setMultipleSelectTrigger(undefined);
          }
        }
      }
    );

    return () => {
      hotkeys.unbind('ctrl+a,cmd+a');
      hotkeys.unbind('*');
      window.removeEventListener('contextmenu', onDisableMultipleSelect);
      window.removeEventListener('blur', onDisableMultipleSelect);
    };
  }, [isInSpecialFolder]);

  hotkeys('ctrl+a,cmd+a', `scene-overview`, (e) => {
    if (isInSpecialFolder || !!onSelect) return;
    e.preventDefault();
    setSelectedSceneArr(scenes.map((s) => s.id));
  });

  const selectScene = (scene: ILibraryScene, forceAdd?: boolean) => {
    if (!isMultipleSelectionEnabled) {
      setSelectedSceneArr([scene.id]);
      handleView(scene.id);
    } else {
      let newSelectedSceneArr = [...selectedSceneArr];

      if (multipleSelectTrigger === 'Shift') {
        const firstSelectedIndex = isEmpty(selectedSceneArr)
          ? 0
          : scenes.findIndex((s) => s.id === first(selectedSceneArr));
        const lastSelectedIndex = scenes.findIndex((s) => s.id === scene.id);
        const smallerIndex = min([firstSelectedIndex, lastSelectedIndex]);
        const biggerIndex = max([firstSelectedIndex, lastSelectedIndex]);
        let newSelectedSceneIds = scenes
          ?.slice(smallerIndex, biggerIndex + 1)
          ?.map((s) => s.id);
        if (
          firstSelectedIndex > lastSelectedIndex ||
          isEmpty(selectedSceneArr)
        ) {
          newSelectedSceneIds = reverse(newSelectedSceneIds);
        }
        newSelectedSceneArr = newSelectedSceneIds;
      } else if (selectedSceneArr.includes(scene.id)) {
        newSelectedSceneArr = forceAdd
          ? selectedSceneArr.filter((id) => id !== scene.id).concat([scene.id])
          : selectedSceneArr.filter((k) => k !== scene.id);
      } else newSelectedSceneArr = [...selectedSceneArr, scene.id];
      setSelectedSceneArr(newSelectedSceneArr);
      if (newSelectedSceneArr.length === 1) {
        handleView(newSelectedSceneArr[0]);
      }
    }
  };

  const deselectScenes = (ids?: string[]) => {
    let nextSelectedScenes = [];
    if (ids) {
      nextSelectedScenes = selectedSceneArr?.filter((id) => !ids.includes(id));
    }
    setSelectedSceneArr(nextSelectedScenes);
    setSelectedScene(null);
  };

  const onUpdateScene = async (scene: ILibraryScene) => {
    setCurrentScene(scene);
  };

  return (
    <div className="SBSearch" onDragOver={(e) => e.preventDefault()}>
      <SearchBar
        onCreate={onSelect ? undefined : handleCreateMenu}
        setKeywords={setKeywords}
        placeHolder={
          isInFolder
            ? `Search in ${getFolderLabel(activeFolderKeyLabel)} folder`
            : 'Search'
        }
        loading={loading === LoadingStatus.LOADING}
        title={isTemplate ? 'Scene Templates' : 'Scenes'}
        createTitle={`Create ${isTemplate ? 'Template' : 'Scene'}`}
        keywords={keywords}
        createLoading={loadingCreate}
        buttonId="create-scene"
      />
      <div className="col-container">
        <div className={`content-col side-col${showFolderCol ? '' : '-hide'}`}>
          {showFolderCol && (
            <FolderNavigation
              folders={folderKeys}
              selectedFolder={activeFolderKey}
              onSelectFolderKey={handleSelectFolderKey}
              expandedFolderKeys={expandedFolderKeys}
              toggleExpandFolderKey={toggleExpandFolderKey}
              title={{
                mainTitle: 'Overview',
                trashTitle: isTemplate ? 'Deleted Templates' : 'Deleted Scenes',
                rootTitle: isTemplate ? 'My Templates' : 'My Scenes'
              }}
              onAddNewFolder={
                onSelect ? undefined : () => setEditFolderPopup({ id: 'new' })
              }
              excludeFolders={excludeFolders}
              onDrop={onDropToItem}
            />
          )}
          {showFolderCol ? (
            <div
              className="hide-folder"
              onClick={() => {
                setShowFolderCol(!showFolderCol);
                logClickButton('Hide folder');
              }}
            >
              <IconOutlineArrow direction="left" />
            </div>
          ) : (
            <div
              className="show-folder"
              onClick={() => {
                setShowFolderCol(!showFolderCol);
                logClickButton('Show folder');
              }}
            >
              <IconOutlineArrow direction="right" />
            </div>
          )}
        </div>

        <div
          className="content-col main-col flex-grow-1"
          onClick={() => deselectScenes()}
        >
          {restoreDeleteAll}
          <div className="content-container">
            <div className="inner-content-container" ref={infiniteRef}>
              {!keywords && (
                <FolderList
                  folderKeys={folderKeys}
                  activeFolderKey={activeFolderKey}
                  elementsInFolderKeys={elementsInFolderKeys}
                  onSelectFolderKey={handleSelectFolderKey}
                  selectMode={!!onSelect}
                  onClickMenu={(id, canDelete) =>
                    setThumbnailMenu({
                      type: 'folder',
                      id,
                      canDelete
                    })
                  }
                  onDrop={onDropToItem}
                />
              )}

              {folderKeys.length > 0 && (
                <>
                  <SLSearchItems
                    userId={userId}
                    scenes={scenes}
                    onDelete={(id) => setDeleteConfirmation([id])}
                    onView={handleView}
                    onClickMenu={(sl, viewOnly) => {
                      setThumbnailMenu({
                        type: 'scene',
                        id: sl.id,
                        payload: sl,
                        viewOnly
                      });
                    }}
                    trashList={trashList}
                    onRestore={(id) => removeFromTrash([id])}
                    favoriteList={favoritesList}
                    searchMode={!!keywords}
                    isInTrashKey={isInTrashKey}
                    selectedScenes={selectedScenes}
                    selectedSceneArr={selectedSceneArr}
                    onSelect={onSelect}
                    selectScene={selectScene}
                    loadingFolder={loadingFolder}
                    isDraggable={!isInSpecialFolder}
                  />
                  <OverviewPlaceHolder
                    hasNoSearchResult={hasNoSearchResult}
                    hasNoItems={hasMoreResult ? false : hasNoScenes}
                    keywords={keywords}
                    onCreate={() => handleCreate('layer')}
                    loading={
                      isInFolder && !isInSharedKey
                        ? loadingFolder[activeFolderKey]
                        : loading
                    }
                    canModify={!onSelect}
                    currentFolder={
                      activeFolderKey === '/'
                        ? activeFolderKey
                        : activeFolderKeyLabel
                    }
                    onDeleteFolder={onDeleteFolder}
                    isLastChild={isLastChild}
                    onReload={loadScenes}
                    context={isTemplate ? 'Template' : 'Scene'}
                    createLoading={loadingCreate}
                    teamFolder={
                      folderKeys.find((f) => f.id === activeFolderKey)?.settings
                        ?.type
                    }
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {selectedScene?.id && selectedSceneArr.length <= 1 && !onSelect && (
          <div className="content-col side-col">
            <SLMetaData
              scene={selectedScene}
              updateScene={handleUpdateMetaData}
              onToggleFavorite={(id, add) => {
                if (add) {
                  addToFavorites(id);
                } else {
                  removeFromFavorites(id);
                }
              }}
              canModify={!isInTrashKey && selectedScene.createdBy === userId}
              isFavorited={favoritesList?.includes(selectedScene.id)}
              onClose={() => {
                setSelectedScene(null);
                logClickButton('close scene detail');
              }}
            />
          </div>
        )}
      </div>

      {thumbnailMenu && (
        <ToolTipWrapper
          onClose={() => setThumbnailMenu(null)}
          parentId={`menu-${thumbnailMenu.id}`}
          width={thumbnailMenu.type === 'folder' ? 150 : 250}
          xPosition="left"
        >
          {thumbnailMenu.type === 'scene' ? (
            <SLThumbnailMenu
              isCollaboration={isInCollaboratedKey}
              viewOnly={thumbnailMenu.viewOnly}
              scene={thumbnailMenu.payload as ILibraryScene}
              onClone={handleOpenClonePopup}
              onDelete={(id: string) => {
                addToTrash(id);
                setSelectedScene(null);
              }}
              onInsight={handleInsight}
              onEdit={handleEdit}
              onClose={() => setThumbnailMenu(null)}
              onToggleFavorite={(id: string, add: boolean) => {
                if (add) {
                  addToFavorites(id);
                } else {
                  removeFromFavorites(id);
                }
              }}
              onAddToFolder={(id) =>
                setAddToFolder({
                  id,
                  sharedOnly:
                    (thumbnailMenu.payload as ILibraryScene).createdBy !==
                    userId
                })
              }
              isFavorited={favoritesList?.includes(thumbnailMenu.id)}
              onPreview={
                (thumbnailMenu.payload as ILibraryScene)?.content?.thumbnail
                  ? (id) => {
                      if (id) {
                        getSceneById({ id, onSuccess: setPreviewScene });
                        setThumbnailMenu(null);
                      }
                    }
                  : undefined
              }
              onReload={loadScenes}
              onShare={(scene) => {
                setSharingScene(scene);
                setThumbnailMenu(null);
              }}
              onTransferOwnership={(scene) => {
                setTransferOwnershipScene(scene);
              }}
            />
          ) : (
            <FolderMenu
              canDelete={thumbnailMenu.canDelete}
              onDelete={() => onDeleteFolder(thumbnailMenu.id)}
              onClose={() => setThumbnailMenu(null)}
              onRename={() =>
                setEditFolderPopup({ id: thumbnailMenu.id, hideMoveTo: true })
              }
              onMoveToFolder={() =>
                setEditFolderPopup({ id: thumbnailMenu.id, hideRename: true })
              }
            />
          )}
        </ToolTipWrapper>
      )}

      {createMenu && (
        <SLCreateSceneMenu
          onClose={() => setCreateMenu(null)}
          onSelectMenu={handleCreate}
        />
      )}

      {addToFolder && (
        <AddToFolderPopup
          folders={folderKeys}
          activeFolderKey={activeFolderKey}
          expandedFolderKeys={expandedFolderKeys}
          onClose={() => setAddToFolder(null)}
          loading={loadingFolder?.[addToFolder.id] === LoadingStatus.LOADING}
          onMoveTo={(target) =>
            onMoveToFolder(target, addToFolder, () => setAddToFolder(null))
          }
          isTeamFolder={activeFolderType === SHARED_TEAM_TYPE}
          onAddNewFolder={() => setEditFolderPopup({ id: 'new' })}
        />
      )}

      {clonePopup && (
        <SLClonePopup
          scene={clonePopup}
          folders={folderKeys}
          activeFolderKey={activeFolderKey}
          expandedFolderKeys={expandedFolderKeys}
          onClose={() => setClonePopup(null)}
          loading={isLoading('cloning-scene')}
          onClone={handleClone}
        />
      )}

      {editFolderPopup && (
        <CreateFolderPopup
          context={folderContext}
          uuid={userId}
          userId={userId}
          folderToEdit={editFolderPopup.id}
          onSuccess={() => {
            setEditFolderPopup(null);
          }}
          onClose={() => setEditFolderPopup(null)}
          folders={folderKeys}
          activeFolderKey={activeFolderKey}
          expandedFolderKeys={expandedFolderKeys}
          hideRenameTo={editFolderPopup.hideRename}
          hideMoveTo={editFolderPopup.hideMoveTo}
        />
      )}

      {!!deleteConfirmation?.length && (
        <ConfirmationPopup
          title={`Are you sure want to delete ${
            deleteConfirmation.length > 1 ? 'all these Scenes' : 'this Scene'
          } permanently?`}
          okLabel="Delete"
          onOk={() => {
            onDelete(deleteConfirmation);
          }}
          onClose={() => setDeleteConfirmation([])}
          loading={isLoading('deleting-scenes')}
        >
          <p />
        </ConfirmationPopup>
      )}
      {!!sharingScene?.id && (
        <SLSharePopup
          onClose={() => setSharingScene(null)}
          scene={sharingScene}
        />
      )}

      {openDescriptionPopup && (
        <SLDescriptionPopup
          popupTitle={`Please enter ${
            isTemplate ? 'Template' : 'Scene'
          } Details`}
          name={currentScene?.content?.name || ''}
          description={currentScene?.content?.description || ''}
          onClose={() => {
            setCurrentScene(null);
            setOpenDescriptionPopup(false);
          }}
          okLabel={'Save'}
          onUpdate={(data) =>
            onUpdateScene({
              ...currentScene,
              content: { ...currentScene?.content, ...data }
            })
          }
          loading={loadingCreateLayer}
          onSave={() => {
            setLoadingCreateLayer(true);
            createScene({
              type: 'layer',
              scene: currentScene,
              onSuccess: (scene) => {
                if (activeFolderKey !== '/' && !isInSpecialFolder) {
                  moveElementToKey({
                    elementId: scene.id,
                    toKeyId: activeFolderKey
                  });
                }
                setCurrentScene(scene);
                onOpenBuilder(scene);
                setLoadingCreateLayer(false);
              },
              onError: () => {
                setLoadingCreateLayer(false);
              }
            });
          }}
        />
      )}
      {!!transferOwnershipScene?.id && (
        <SLTransferOwnershipPopup
          onClose={() => setTransferOwnershipScene(null)}
          scene={transferOwnershipScene}
          onSuccess={() => {
            setScenes(
              filter(scenes, (scene) => {
                return scene.id !== transferOwnershipScene.id;
              })
            );
            // loadStorybooks(true);
            setTransferOwnershipScene(null);
            dispatch(actionHubAlertInfo('Transfer ownership successfully'));
          }}
        />
      )}
      {pageViewer}
      <style jsx global>{`
        .SceneLibrary .content-wrapper {
          padding: 0 !important;
          background: #fff;
        }
        .thumbnail-container {
          width: 50%;
          padding: 10px;
        }
        .thumbnail-container :global(.media-container) {
          border: none !important;
          border-radius: 0;
          border-bottom: 1px solid #ccc;
        }
        @media (min-width: 1200px) {
          .thumbnail-container {
            width: 33%;
          }
        }

        @media (min-width: 1600px) {
          .thumbnail-container {
            width: 25%;
          }
        }

        @media (min-width: 2000px) {
          .thumbnail-container {
            width: 20%;
          }
        }

        @media (min-width: 2000px) {
          .thumbnail-container {
            width: 16.666%;
          }
        }
      `}</style>
      <style jsx>{`
        .SBSearch {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-content: stretch;
        }
        .col-container {
          height: 100%;
          overflow: hidden;
          display: flex;
        }
        .content-col {
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
        }
        .main-col {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-content: baseline;
          position: relative;
        }

        .side-col {
          width: 300px;
          min-width: 300px;
          transition: min-width 0.1s;
          position: relative;
        }
        .show-folder {
          padding: 8px;
          opacity: 0.5;
          cursor: pointer;
        }
        .hide-folder {
          position: absolute;
          top: 14px;
          right: 29px;
          opacity: 0.5;
          cursor: pointer;
        }
        .side-col-hide {
          min-width: 35px;
          transition: min-width 0.1s;
          border-right: 1px solid #ccc;
        }

        .side-col:first-child {
          border-right: 1px solid #ccc;
        }
        .side-col:last-child {
          border-left: 1px solid #ccc;
        }
        .content-container {
          overflow-y: scroll;
          width: 100%;
          padding: 20px;
        }
        .inner-content-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: baseline;
        }

        .ok-button {
          min-width: 150px;
        }
        .action-footer {
          padding-top: 15px;
          border-top: 1px solid #ccc;
          text-align: right;
        }
      `}</style>
    </div>
  );
};

export default SLOverview;
