import React from 'react';
import { IStorybook } from '../../../../interfaces';
import { TLanguage } from '../../../../mappers/polotno';
import { TDeviceView } from '../../Common/DeviceViewToggle';
import PhoneMockup from '../../PhoneMockup';
import SBMicrosite from '../Mirosite/SBMicrosite';

const SBPreviewMicrosite = ({
  storybook,
  deviceView,
  activateLanguage
}: {
  storybook: IStorybook;
  deviceView: TDeviceView;
  activateLanguage?: TLanguage;
}) => {
  const [currentPage, setCurrentPage] = React.useState(0);
  const microsite = (
    <SBMicrosite activateLanguage={activateLanguage} storybook={storybook} portrait={deviceView === 'mobile'} initialPage={currentPage} onPageChange={setCurrentPage} />
  );

  const isMobile = deviceView === 'mobile' || deviceView === 'mobile-landscape';
  return (
    <div className={`preview-container ${deviceView}`}>
      {isMobile ? (
        <PhoneMockup landscape={deviceView === 'mobile-landscape'}>
          {microsite}
        </PhoneMockup>
      ) : (
        microsite
      )}

      <style jsx>{`
        .preview-container {
          width: 100%;
          height: 100%;
          display: flex;
        }
        .preview-container.mobile-landscape :global(.arrow) {
          width: 50px !important;
        }
        .preview-container.mobile-landscape :global(.prev) {
          left: 10px !important;
        }
        .preview-container.mobile-landscape :global(.next) {
          right: 10px !important;
        }
      `}</style>
    </div>
  );
};

export default SBPreviewMicrosite;
