import React from 'react';
import { logClickButton } from '../../../analytics';
import ConfirmationPopup from '../Common/ConfirmationPopup';
import { TDeviceView } from '../Common/DeviceViewToggle';

const PolotnoSaveWarning = ({
  onOk,
  onClose,
  missingItem,
  context,
  okLabel = 'Confirm'
}: {
  onOk: () => void;
  okLabel?: string;
  onClose: () => void;
  context: string;
  missingItem: TDeviceView;
}) => {
  const canSave = missingItem === 'mobile';
  const label = canSave ? okLabel : 'OK';

  const warningMessages = {
    mobile: (
      <p>
        You are about to {context} your Scene
        <br />
        <strong>without a Portrait Version</strong>.
      </p>
    ),
    desktop: (
      <p>
        You are about to {context} your Scene.
        <br />
        <strong>Landscape Version cannot be blank</strong>.
      </p>
    ),
    compare: (
      <p>
        You are about to {context} your Scene.
        <br />
        <strong>Scene cannot be blank</strong>.
      </p>
    )
  };

  const popupMessage = warningMessages[missingItem];

  const handleOk = () => {
    (canSave ? onOk : onClose)();
    logClickButton(label);
  };
  const handleCancel = () => {
    onClose();
    logClickButton('Cancel');
  };

  return (
    <>
      <ConfirmationPopup
        onOk={handleOk}
        okLabel={label}
        closeLabel="Cancel"
        disableCancel={!canSave}
        onClose={handleCancel}
        zIndex={1000}
      >
        {popupMessage}
      </ConfirmationPopup>
      <style jsx global>{`
        .PopupContainer .btn-confirm {
          width: ${canSave ? '200px !important' : '100px'};
        }
      `}</style>
    </>
  );
};

export default PolotnoSaveWarning;
