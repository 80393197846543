import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { IFolderKey } from '../../../../interfaces';
import { actionHubAlertError } from '../../../../redux/actions';
import {
  COLLABORATED_ITEMS,
  FAVORITE_FOLDER,
  isInSharedItemsFolder,
  SHARED_ITEMS,
  TRASH_FOLDER
} from '../../../utils/folders';
import PopupContainer from '../PopupContainer';
import FolderNavigation from './FolderNavigation';

const AddToFolderPopup = ({
  folders,
  activeFolderKey,
  expandedFolderKeys,
  onClose,
  onMoveTo,
  loading,
  onAddNewFolder,
  isTeamFolder
}: {
  folders: IFolderKey[];
  activeFolderKey: string;
  expandedFolderKeys: string[];
  onClose?: () => void;
  onMoveTo?: (targetFolder: string) => void;
  loading?: boolean;
  onAddNewFolder?: (arg: {
    hideRename?: boolean;
    hideMoveTo?: boolean;
  }) => void;
  isTeamFolder?: boolean;
}) => {
  const dispatch = useDispatch();
  const [selectedFolder, setSelectedFolder] = React.useState({
    activeFolderKey,
    expandedFolderKeys
  });

  const selectFolderKey = (id: string) => {
    const updatedExpandedFolderKeys =
      selectedFolder.expandedFolderKeys.includes(id)
        ? [...selectedFolder.expandedFolderKeys]
        : [...selectedFolder.expandedFolderKeys, id];

    setSelectedFolder({
      activeFolderKey: id,
      expandedFolderKeys: updatedExpandedFolderKeys
    });
  };

  const toggleExpandFolderKey = (id: string) => {
    const updatedExpandedFolderKeys =
      selectedFolder.expandedFolderKeys.includes(id)
        ? selectedFolder.expandedFolderKeys.filter((f) => f !== id)
        : [...selectedFolder.expandedFolderKeys, id];

    setSelectedFolder({
      ...selectedFolder,
      expandedFolderKeys: updatedExpandedFolderKeys
    });
  };
  React.useEffect(() => {
    const sharedId = folders.find((f) => f.key === SHARED_ITEMS)?.id;
    if (sharedId && isTeamFolder) selectFolderKey(sharedId);
  }, []);
  const sharedOnly = isInSharedItemsFolder(activeFolderKey, folders);

  const formAddFolder = (
    <div className="formAddFolder">
      <div className="action">
        <button
          className="btn btn-round btn-sm btn-outline-dark"
          onClick={onClose}
        >
          Cancel
        </button>
        {onAddNewFolder && (
          <button
            className="btn btn-round btn-sm btn-outline-dark"
            onClick={() =>
              onAddNewFolder({ hideRename: true, hideMoveTo: true })
            }
          >
            New Folder
          </button>
        )}
        <button
          className="btn btn-round btn-sm btn-dark"
          onClick={() => {
            if (activeFolderKey !== selectedFolder.activeFolderKey) {
              onMoveTo(selectedFolder.activeFolderKey);
            } else {
              dispatch(
                actionHubAlertError('You can not move to the same folder.')
              );
            }
          }}
        >
          {loading ? <Spinner animation="border" size="sm" /> : 'Move'}
        </button>
      </div>
      <style jsx>{`
        .formAddFolder {
          width: 100%;
          text-align: center;
        }

        .action {
          margin-top: 8px;
        }
        .btn {
          width: 100px;
          margin: 0 3px;
        }
      `}</style>
    </div>
  );
  const folderTrees = (
    <div className="folder-container">
      <FolderNavigation
        folders={folders}
        selectedFolder={selectedFolder.activeFolderKey}
        expandedFolderKeys={selectedFolder.expandedFolderKeys}
        onSelectFolderKey={selectFolderKey}
        toggleExpandFolderKey={toggleExpandFolderKey}
        excludeFolders={[
          TRASH_FOLDER,
          FAVORITE_FOLDER,
          COLLABORATED_ITEMS,
          ...(sharedOnly ? [] : [SHARED_ITEMS]),
          ...(folders
            .filter((f) => f.settings?.type === 'team')
            ?.map((f) => f.id) || [])
        ]}
        hideOverview={sharedOnly}
      />
      <style jsx>{`
        div {
          border: 1px solid #ccc;
          width: 100%;
          max-height: calc(100vh - 300px);
          min-height: 200px;
          overflow: auto;
          border-radius: 5px 5px 0 0;
          padding: 20px 0;
        }
      `}</style>
    </div>
  );

  return (
    <PopupContainer maxWidth="350px">
      {folderTrees}
      {formAddFolder}
    </PopupContainer>
  );
};

export default AddToFolderPopup;
