import React, { useContext, useEffect, useMemo } from 'react';
import { ITableColumn, SortTable } from '../../Common/Table';
import { StoryBookContext } from '../SBContextContainer';
import moment from 'moment';
import { tz } from 'moment-timezone';
import { IStorybookPage } from '../../../../interfaces';
import { interactionStyles } from './helpers';
import ImageWithWebp from '../../Common/ImageWithWebp';
const ClientTimeZone = tz.guess();

const getThumbnailFromPage = (page: IStorybookPage): string => {
  try {
    return Object.values(JSON.parse(page.thumbnail))[0] as string;
  } catch (error) {
    return page?.thumbnail;
  }
};
const SBAnalyticsInteractions = ({
  setSelectedItem,
  // setListItemTab,
  range,
  startDate,
  endDate,
  campaign
}) => {
  const { state, getSbInsightInteractionData } = useContext(StoryBookContext);
  const storybook = state.storybook;
  const interactions = state.storybookInteractions.data?.interactions || [];
  useEffect(() => {
    const fromDate =
      range > 0
        ? moment().subtract(range, 'days').format('YYYY-MM-DD')
        : startDate;
    const toDate = range > 0 ? moment().format('YYYY-MM-DD') : endDate;
    getSbInsightInteractionData({
      storybookId: storybook.id,
      startDate: fromDate,
      endDate: toDate,
      campaign: campaign,
      timeZone: ClientTimeZone
    });
  }, [storybook, startDate, endDate, range, campaign]);

  const interactionData = useMemo(() => {
    const data = interactions.map((item) => {
      const page = storybook.pages.find((page) => page.id === item.sceneId);
      const title = page?.name;
      const thumbnail = getThumbnailFromPage(page);
      return {
        ...item,
        title,
        thumbnail,
        address: {
          type: item.type,
          address: item.title
        },
        sceneId: item.sceneId,
        sceneName: title
      };
    });
    return data;
  }, [interactions]);

  const topInteractionColumns: ITableColumn[] = [
    {
      key: 'sceneId',
      title: 'Scene Order',
      align: 'left',
      custom: (value, rowIndex) => {
        const page = storybook.pages.find((page) => page.id === value);
        return (
          <div className="scenes_order">
            <span>{rowIndex + 1}</span>
            <ImageWithWebp src={getThumbnailFromPage(page)} />
          </div>
        );
      }
    },
    {
      key: 'title',
      title: 'Scene Name',
      align: 'left'
    },
    {
      key: 'address',
      title: 'Interaction',
      align: 'left',
      custom: (value) => {
        const vType = value.type?.toLocaleLowerCase?.();
        const color = interactionStyles[vType]?.color || 'black';
        return (
          <div className="custom-interaction">
            <div className="value">
              <span>{value.address}</span>
              <span className="type" style={{ color }}>
                {vType}
              </span>
            </div>
          </div>
        );
      }
    },
    {
      key: 'totalClicks',
      title: 'Clicks',
      align: 'center'
    },
    {
      key: 'userCount',
      title: 'Viewers',
      align: 'center'
    }
  ];

  const handleSelectRow = (row) => {
    // move to scene details page for now!!
    setSelectedItem({
      scene: row.sceneId,
      name: row.sceneName,
      type: 'scene'
    });

    // setSelectedItem({
    //   scene: row.title,
    //   name: row.title,
    //   type: 'interaction'
    // });

    // const listItems = interactions.map((item) => {
    //   const page = storybook.pages.find((page) => page.id === item.sceneId);
    //   const thumbnail = getThumbnailFromPage(page);
    //   return {
    //     thumbnail,
    //     scene: item.title,
    //     name: item.title,
    //     id: item.sceneId,
    //     type: 'interaction'
    //   };
    // });
    // setListItemTab(listItems);
  };

  return (
    <>
      <div
        className="analytics_scenes"
        data-isloading={state.storybookInteractions?.loading === true}
      >
        <SortTable
          columns={topInteractionColumns}
          sorts={['order', 'totalClicks', 'userCount']}
          data={interactionData}
          onRowSelect={handleSelectRow}
          onlyActiveSortBy
          noData={{
            text: `No Interactions Found`,
            align: 'center',
            verticalAlign: 'middle'
          }}
        />
        <div className="overlay">
          <span className="spinner-border spinner-border-lg" />
        </div>
      </div>
      <style jsx>{`
        :global(.type-container) {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
        }
        :global(.badge-icon) {
          background: purple;
          width: 12px;
          height: 12px;
          border-radius: 100%;
        }
        :global(.scenes_order img) {
          width: 120px;
          height: auto;
          border: 1px solid #ccc;
          margin-left: 30px;
        }
        :global(.analytics_scenes .sort-table td) {
          font-weight: 500;
          color: #252525;
        }
        .analytics_scenes[data-isloading='true'] {
          opacity: 0.5;
          pointer-events: none;
          position: relative;
        }

        .overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          display: none;
          padding-top: 30px;
          justify-content: center;
        }

        .analytics_scenes[data-isloading='true'] .overlay {
          display: flex;
        }
      `}</style>
    </>
  );
};

export default SBAnalyticsInteractions;
