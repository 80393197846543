import React from 'react';
import { IStorybook } from '../../../../interfaces';
import PopupContainer from '../../Common/PopupContainer';
import SBPreview from '../Preview';

const PagePreviewPopup = ({
  onClose,
  storybook,
  onSelect
}: {
  onClose: () => void;
  storybook: IStorybook;
  onSelect?: (s: IStorybook) => void;
}) => {
  return (
    <PopupContainer maxWidth="2400px" width="95%">
      <div className="popup-section  popup-body w-100">
        <SBPreview
          storybook={storybook}
          onClose={onClose}
          isForScene={true}
          onSelect={onSelect}
          previewMode="microsite"
        />
      </div>
      <style jsx global>
        {`
          .popup-body {
            height: calc(100vh - 80px);
          }
          #polonto-root > div > div + div > .bp5-navbar ,
          .polotno-side-panel > div + div + div + div{
            display: none !important;
          }
          
        `}
      </style>
    </PopupContainer>
  );
};

export default PagePreviewPopup;
