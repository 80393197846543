import React from 'react';
import { IFilePageSettings, IPlayerControl } from '../../interfaces';
import { isVideo } from '../../utils/file';
import ImageViewer from './ImageViewer';
import PageLabel from '../../advisorHub/components/StoryBook/Common/PageLabel';
import DocumentPageVideo from './DocumentPageVideo';
import { useMeetingLocalAudio } from '../hooks/meeting';
import { hasVideoLayer } from '../../advisorHub/utils/storybook';
import { SBLayerPage } from '../../advisorHub/components/StoryBook/Mirosite/SBMicrositePageType';
import { TLanguage } from '../../mappers/polotno';

const DocumentPage = ({
  url,
  viewOnly = false,
  playerControl,
  preloadUrls,
  setting,
  autoClose,
  silence,
  portraitContent,
  id,
  activateLanguage
}: {
  url: string;
  viewOnly?: boolean;
  playerControl?: IPlayerControl;
  preloadUrls?: string[];
  setting?: IFilePageSettings;
  autoClose?: boolean;
  silence?: boolean;
  portraitContent?: boolean;
  id?: string;
  activateLanguage?: TLanguage;
}) => {
  const { restoreAudio } = useMeetingLocalAudio();
  const { title, subtitle, color, duration, background, text } = setting || {};

  const isVideoPage = isVideo(url);
  const isLayerPage = !!setting?.layer;

  const containsVideo =
    isVideoPage ||
    hasVideoLayer(setting, portraitContent ? 'mobile' : 'desktop');

  const [buffering, setBuffering] = React.useState(false);

  React.useEffect(() => {
    if (!containsVideo) {
      restoreAudio();
    }
  }, [containsVideo]);

  const getContent = () => {
    if (isVideoPage) {
      return (
        <DocumentPageVideo
          autoClose={autoClose}
          url={portraitContent ? setting?.portraitUrl || url : url}
          viewOnly={viewOnly}
          playerControl={playerControl}
          setting={setting}
          setBuffering={setBuffering}
          buffering={buffering}
          silence={silence}
          fullPage={portraitContent && !!setting?.portraitUrl}
        />
      );
    }

    if (isLayerPage)
      return (
        <SBLayerPage
          page={{
            id: setting?.pageId || '',
            url,
            title,
            subtitle
          }}
          setting={setting}
          canPlay={true}
          portrait={portraitContent}
          handleError={() => null}
          id={id}
          activateLanguage={activateLanguage}
        />
      );
    return (
      <ImageViewer
        url={url}
        setting={setting}
        portraitContent={portraitContent}
      />
    );
  };

  return (
    <div className="DocumentPage text-center" key={url}>
      {getContent()}
      {(title || subtitle) && (
        <div className="label-container">
          <PageLabel
            key={url}
            title={title}
            subtitle={subtitle}
            duration={duration}
            background={color}
            textColor={text}
          />
        </div>
      )}
      {!buffering &&
        !!preloadUrls?.length &&
        preloadUrls?.map((image, idx) => (
          <React.Fragment key={idx}>
            <img
              key={`${idx}-${image}`}
              loading="lazy"
              src={image}
              className="img-preload"
            />
            {image?.endsWith('scene.jpeg') && (
              <img
                key={`${idx}-${image}-portrait`}
                loading="lazy"
                src={image.replace('scene.jpeg', 'scene_portrait.jpeg')}
                className="img-preload"
              />
            )}
          </React.Fragment>
        ))}

      <style jsx>{`
        .DocumentPage {
          flex-basis: 0;
          flex-grow: 1;
          overflow: hidden;
          position: relative;
          background: ${background || 'transparent'};
        }
        .label-container {
          position: absolute;
          left: 0;
          bottom: 0;
        }
        .DocumentPage:hover .label-container {
          display: ${isVideoPage && !viewOnly ? 'none' : 'block'};
        }
        .img-preload {
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          height: 1px;
        }
      `}</style>
    </div>
  );
};

export default DocumentPage;
