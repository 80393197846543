import React from 'react';
import SelectDropdown from './SelectDropdown';

const VersionsDropdown = ({
  versions,
  value,
  onSelect
}: {
  versions: { value: number; label: string }[];
  value: string | number;
  onSelect: (value: string | number) => void;
}) => {
  return (
    <SelectDropdown
      className="btn-round btn-outline-dark btn-sm"
      id="SBVersion"
      value={value}
      options={versions}
      onSelect={onSelect}
      label="Version: "
      popupWidth={200}
    />
  );
};

export default VersionsDropdown;
