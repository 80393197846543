import React from 'react';
import { IconAdd, IconAddCircle } from './HubIcons';

const AddButton = ({
  darkMode,
  label,
  onClick,
  disabled,
  asText
}: {
  darkMode?: boolean;
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
  asText?: boolean;
}) =>
  asText ? (
    <button className="btn btn-sm" onClick={onClick} disabled={disabled}>
      {label ? (
        <>
          <IconAdd />
          <strong>{label}</strong>
        </>
      ) : (
        <IconAddCircle />
      )}
      <style jsx>{`
        .btn {
          padding: 0;
        }
      `}</style>
    </button>
  ) : (
    <button className={`btn btn-sm ${darkMode ? 'btn-dark' : 'btn-outline-dark'} btn-round`} onClick={onClick} disabled={disabled}>
      {label}
      <style jsx>{`
        .btn {
          padding: 1px 5px;
        }
      `}</style>
    </button>
  );
export default AddButton;
