import React from 'react';
import { logEvent } from '../../../analytics';
import { DID_CLICK_BUTTON } from '../../../utils/constants';
import { Icon } from '@blueprintjs/core';

const UndoRedoButton = ({
  onUndo,
  onRedo,
  disableUndo,
  disableRedo
}: {
  onUndo?: () => void;
  onRedo?: () => void;
  disableUndo?: boolean;
  disableRedo?: boolean;
}) => {
  return (
    <div className="btn-group">
      <button
        className="btn btn-sm text-semibold btn-undo"
        onClick={() => {
          onUndo();
          logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button: 'undo' });
        }}
        disabled={disableUndo}
      >
        <Icon icon="undo" />
      </button>

      <button
        className="btn btn-sm text-semibold btn-redo"
        onClick={() => {
          onRedo();
          logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button: 'redo' });
        }}
        disabled={disableRedo}
      >
        <Icon icon="redo" />
      </button>
      <style jsx>{`
        .btn:disabled {
          cursor: not-allowed;
          opacity: 0.4;
        }
      `}</style>
    </div>
  );
};

export default UndoRedoButton;
