import React from 'react';
import { ILibraryScene } from '../../../../interfaces';
import { Nav, Tab } from 'react-bootstrap';
import {
  ShareAsCampaignIcon,
  ShareAsContactIcon,
  ShareAsLinkIcon
} from '../../Common/Icons';
import { HubContext } from '../../HubContext';
import { getBrandStorybookHost } from '../../../../config';
import { BsX } from 'react-icons/bs';
import { getSimpleShortenedUrl } from '../../../../utils/shareUrl';
import { isUserOnMobile } from '../../../../utils/deviceDetector';
import PopupContainer from '../../Common/PopupContainer';
import SLShareAsCampaign from './SLShareAsCampaign';
import SLShareAsContact from './SLShareAsContact';
import SLShareAsGeneral from './SLShareAsGeneral';
import {
  getFeaturedImageForScene,
  getPublicThumbnailForScene
} from '../../../../mappers/storybook';
import { copyS3Object } from '../../../clientSideServices/aws';

function SLSharePopup({
  scene,
  onClose,
  className = ''
}: {
  scene: ILibraryScene;
  onClose: () => void;
  className?: string;
}) {
  const [activeKey, setActiveKey] = React.useState(
    !isUserOnMobile() ? 'general' : 'campaign'
  );
  const { brandId } = React.useContext(HubContext);

  const [generalShareUrl, setGeneralShareUrl] = React.useState('');

  const publicThumbnail = React.useCallback(() => {
    const thumbnailUrl = getFeaturedImageForScene(scene);
    if (!thumbnailUrl) return;
    const copySource = thumbnailUrl.replace(
      'https://assets.inspify.com',
      'inspify-assets'
    );
    const destination = getPublicThumbnailForScene(scene.id);
    return copyS3Object({
      Bucket: destination.bucket,
      CopySource: copySource,
      Key: destination.key,
      ACL: 'public-read'
    });
  }, [brandId, scene.id]);

  React.useEffect(() => {
    const hostUrl = getBrandStorybookHost(brandId);
    const url = new URL(`${hostUrl}/scene/${scene.id}`);
    publicThumbnail()
      .then(() => getSimpleShortenedUrl(url.toString(), hostUrl))
      .then((shortenUrl) => {
        setGeneralShareUrl(shortenUrl);
      })
      .catch(() => {
        setGeneralShareUrl(url.toString());
      });
  }, [scene.id, brandId]);

  const shareTabs = React.useMemo(() => {
    const isMobile = isUserOnMobile();
    const defaultTabs = [
      {
        key: 'general',
        icon: <ShareAsLinkIcon />,
        content: <SLShareAsGeneral url={generalShareUrl} />,
        title: 'Share',
        hide: isMobile
      },
      {
        key: 'campaign',
        icon: <ShareAsCampaignIcon />,
        content: <SLShareAsCampaign scene={scene} />,
        title: 'Share as Campaign',
        hide: false
      },
      {
        key: 'contact',
        icon: <ShareAsContactIcon />,
        content: <SLShareAsContact scene={scene} />,
        title: 'Share to Contact',
        hide: false
      }
    ];
    return defaultTabs.filter((tab) => !tab.hide);
  }, [brandId, generalShareUrl]);

  return (
    <PopupContainer className="SharePopup" onClose={onClose} maxHeight="100vh">
      <div className={`tab-container relative ${className}`}>
        <Tab.Container id="share-tab" activeKey={activeKey}>
          <Nav variant="pills" className="nav-bar">
            {shareTabs.map((tab) => {
              return (
                <Nav.Item key={tab.key}>
                  <Nav.Link
                    onClick={() => setActiveKey(tab.key)}
                    eventKey={tab.key}
                  >
                    {tab.icon}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
          </Nav>
          <Tab.Content className="w-full p-4 content flex-1">
            <div className="title">
              <h4>{shareTabs.find((tab) => tab.key === activeKey).title}</h4>
            </div>
            {shareTabs.map((tab) => {
              return (
                <Tab.Pane key={tab.key} eventKey={tab.key} className="tab-pane">
                  {tab.content}
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </Tab.Container>
        <button className="btn btn-close" onClick={onClose}>
          <BsX size={20} />
        </button>
      </div>

      <style jsx>{`
        ::global(.SharePopup .content-container) {
          width: 750px !important;
          max-width: 100vw !important;
          max-height: 100vh;
          overflow: hidden;
        }
        ::global(.flex-1) {
          flex: 1;
        }
        hr {
          border-color: #e1cccc;
        }
        .btn.btn-close {
          position: absolute;
          top: 10px;
          right: 10px;
          background-color: transparent;
          border: none;
          color: black;
        }
        h4 {
          padding: 0 20px;
        }
        .tab-container {
          display: flex;
          flex-direction: row;
          width: 100%;
          flex: 1;
          width: 100%;
          height: 500px;
          max-height: 95vh;
          max-width: 95vw;
          margin: -15px;
        }

        @media (max-width: 568px) {
          .tab-container {
            flex-direction: column;
            height: 100%;
          }
          .tab-container ::global(.nav-bar) {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
          }
          .tab-container ::global(.nav-content) {
            flex: 1;
          }
        }
        .title {
          text-align: left;
          margin-bottom: 15px;
        }
        .title h4 {
          font-weight: bold;
        }
        .title {
          border-bottom: 1px solid #e1cccc;
        }
        ::global(.tab-container .nav-bar) {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: stretch;
          align-items: center;
        }
        .tab-container ::global(.tab-pane) {
          padding: 0 20px;
        }
        .tab-container ::global(#share-tab-tabpane-contact) {
          height: 100%;
          flex: 1;
          display: flex;
        }
        .tab-container ::global(.nav-bar) {
          border-bottom: none;
          background-color: #e2e2e2 !important;
        }
        .tab-container ::global(.nav-link:focus) {
          outline: none !important;
        }
        .tab-container ::global(.nav-item) {
          height: 33.3333333%;
        }
        .tab-container ::global(.nav-link) {
          color: #000 !important;
          border-color: transparent !important;
          padding: 10px !important;
          min-width: 100px;
          width: 100%;
          border-radius: 0;
          max-height: 350px;
          overflow-y: auto;
          height: 100%;
          align-items: center;
          justify-content: center;
          display: flex;
        }
        .tab-container ::global(.nav-link.active) {
          background-color: #fff !important;
        }
        .tab-container ::global(.content) {
          display: flex;
          flex-direction: column;
        }
      `}</style>
    </PopupContainer>
  );
}

export default SLSharePopup;
