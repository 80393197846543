import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logClickButton, logEvent } from '../../../../analytics';
import ToolTipWrapper from '../../../../components/Common/ToolTipWrapper';
import { useFolder } from '../../../../components/hooks/folder';
import { useStorybookSearch } from '../../../../components/hooks/storybook';
import SimpleShareModal from '../../../../components/Sharing/SimpleShareModal';
import {
  IStorybook,
  LoadingStatus,
  StoryBookLayoutPage
} from '../../../../interfaces';
import { updateKeysInFolderAsync } from '../../../../redux/advisorHubAsyncActions';
import { DID_SHARE_STORYBOOK } from '../../../../utils/constants';
import { getIdentityId } from '../../../../utils/identity';
import {
  COLLABORATED_ITEMS,
  getFolderLabel,
  getKeyIdFromElementId,
  getParentFolderKey,
  hasUserSubFolder,
  isLastChildFolder,
  SHARED_ITEMS,
  SHARED_TEAM_TYPE,
  TRASH_FOLDER
} from '../../../utils/folders';
import {
  getLanguaImageFromJSON,
  StorybookAccessType
} from '../../../utils/storybook';
import CreateFolderPopup from '../../Common/Folder/CreateFolderPopup';
import FolderNavigation from '../../Common/Folder/FolderNavigation';
import PasscodePopup from '../Common/PasscodePopup';
import { SBActionTarget } from '../SBActionHandler';
import { StoryBookContext } from '../SBContextContainer';
import SBSearchItems from './SBSearchItems';
import SBThumbnailMenu from '../Common/SBThumbnailMenu';
import StorybookMetaData from '../MetaData/StorybookMetaData';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import AddToFolderPopup from '../../Common/Folder/AddToFolderPopup';
import { generateV4UUID } from '../../../../utils/identityGenerator';
import SBAnalytics from '../Analytics';
import FolderList from '../../Common/Folder/FolderList';
import SearchBar from '../../Common/SearchBar';
import OverviewPlaceHolder from '../../Common/OverviewPlaceHolder';
import { filter, first, indexOf, isEmpty, max, min, reverse } from 'lodash';
import { IconClose, IconOutlineArrow } from '../../Common/HubIcons';
import { actionHubAlertInfo } from '../../../../redux/actions';
import FolderMenu from '../../Common/Folder/FolderMenu';
import hotkeys from 'hotkeys-js';
import { getActivatedStorybookById } from '../../../clientSideServices/storybook';
import { HubContext } from '../../HubContext';
import SBSharePopup from '../Common/SBSharePopup';
import { isUserOnMobile } from '../../../../utils/deviceDetector';
import FolderNavigationMobile from '../../Common/Folder/FolderNavigationMobile';
import { Modal } from 'react-bootstrap';
import { storyAssetBaseUrl } from '../../../../config';
import SBShareButton from '../Common/SBShareButton';
import SBPresentButton from '../Common/SBPresentButton';
import SBTransferOwnershipPopup from '../Common/SBTransferOwnershipPopup';

type MenuType = 'storybook' | 'folder';
type ThumbnailMenuPayload<T extends MenuType> = T extends 'storybook'
  ? IStorybook
  : string;
type ThumbnailMenu = {
  type: MenuType;
  id: string;
  payload?: ThumbnailMenuPayload<MenuType>;
  canDelete?: boolean;
  viewOnly?: boolean;
};

const SBSearch = ({
  onSelect,
  onClose,
  onAddSelected,
  selectedStorybooks,
  keywords,
  setKeywords,
  onView,
  initFolderKey,
  setLatestFolderKey,
  brandId,
  isBrowseStorybookInMeeting
}: {
  onSelect?: (storybook: IStorybook) => void;
  onClose?: () => void;
  selectedStorybooks?: string[];
  onAddSelected?: () => void;
  keywords?: string;
  setKeywords?: (keywords: string) => void;
  onView?: (storybook: IStorybook) => void;
  initFolderKey?: string;
  setLatestFolderKey?: (folderKey: string) => void;
  brandId: string;
  isBrowseStorybookInMeeting?: boolean;
}) => {
  hotkeys.setScope(`storybook-overview`);

  const dispatch = useDispatch();
  const userId = getIdentityId() || 'user?.id';
  const { brandSetup } = useContext(HubContext) || {};

  const [showFolderCol, setShowFolderCol] = React.useState(true);
  const [editFolderPopup, setEditFolderPopup] = React.useState<{
    id: string;
    hideRename?: boolean;
    hideMoveTo?: boolean;
  }>(null);
  const [addToFolder, setAddToFolder] = React.useState('');
  const [deleteConfirmation, setDeleteConfirmation] = React.useState<string[]>(
    []
  );
  const [activeStorybook, setActiveStorybook] =
    React.useState<IStorybook>(null);
  const [isOnMobile, setIsOnMobile] = React.useState<boolean>(false);
  const { action, state, entitlement } = React.useContext(StoryBookContext);
  const [transferOwnershipSb, setTransferOwnershipSb] =
    React.useState<IStorybook>(null);
  React.useEffect(() => {
    setIsOnMobile(isUserOnMobile());
  }, []);
  const {
    folderKeys,
    activeFolderKey,
    selectFolderKey,
    toggleExpandFolderKey,
    expandedFolderKeys,
    moveElementToKey,
    moveMultipleElementsToKey,
    elementsInKey,
    elementsNotInKey,
    elementsNotInTrash,
    loading: loadingFolder,
    trashList,
    favoritesList,
    addToFavorites,
    removeFromFavorites,
    addToTrash,
    removeFromTrash,
    activeFolderKeyLabel,
    searchScope,
    isInTrashKey,
    isInFavoritesKey,
    elementsInFolderKeys,
    isInSharedKey,
    isInCollaboratedKey
  } = useFolder({
    uuid: userId,
    userId,
    brandId: brandSetup?.parentBrandId || brandId || brandSetup?.id,
    context: 'storybook'
  });

  const onSelectFolder = (keyId) => {
    selectFolderKey(keyId);
    const folder = folderKeys.find((f) => f.id === keyId);
    if (folder?.settings?.type === SHARED_TEAM_TYPE) {
      loadStorybooks(true, keyId);
    } else if (folder?.key?.includes(SHARED_ITEMS)) {
      loadStorybooks(true);
    }
    if (isInsight) setIsInsight(false);
  };

  const isLastChild =
    isLastChildFolder(activeFolderKey, folderKeys) ||
    !hasUserSubFolder(folderKeys);

  const isInFolder = activeFolderKey && activeFolderKey !== '/';
  const activeFolderType = folderKeys.find((f) => f.id === activeFolderKey)
    ?.settings?.type;
  const filterScope = !keywords
    ? elementsInKey
    : isInFolder && activeFolderKeyLabel !== SHARED_ITEMS
    ? searchScope
    : undefined;

  const trashKey = folderKeys.find((f) => f.key === TRASH_FOLDER)?.id;

  const excludeScope = keywords
    ? activeFolderKey === trashKey
      ? elementsNotInTrash
      : trashList
    : elementsNotInKey;

  const {
    storybooks,
    hasMoreResult,
    infiniteRef,
    hasNoStorybook,
    hasNoSearchResult,
    loading,
    useStorybookSearchReady,
    loadStorybooks,
    allStoryBooks,
    setStorybooks
  } = useStorybookSearch({
    includeSharedToMe: isInSharedKey,
    activatedOnly: !!entitlement?.shouldShowActivatedOnly || isInSharedKey,
    keywords,
    filterScope,
    excludeScope,
    userId,
    brandId: brandSetup?.parentBrandId || brandId || brandSetup?.id,
    excludeMine: isInSharedKey,
    sharedWithMe: isInSharedKey,
    collaboration: isInCollaboratedKey
  });

  const isInSpecialFolder =
    isInTrashKey || isInFavoritesKey || isInSharedKey || isInCollaboratedKey;
  const [shareModal, setShareModal] = React.useState<{
    url: string;
    message: string;
    id: string;
  }>(null);

  const [thumbnailMenu, setThumbnailMenu] = React.useState(null);
  const [passcodeSetting, setPasscodeSetting] = React.useState<{
    type?: StorybookAccessType;
    storybook: IStorybook;
    editMode?: boolean;
  }>(null);

  const [shareWithOptions, setShareWithOptions] =
    React.useState<IStorybook>(null);

  const [isInsight, setIsInsight] = React.useState(false);
  const [selectedStoryBookArr, setSelectedStorybookArr] = React.useState<
    string[]
  >([]);
  const [isMultipleSelectionEnabled, setIsMultipleSelectionEnabled] =
    React.useState<boolean>(false);
  const [multipleSelectTrigger, setMultipleSelectTrigger] =
    React.useState<string>(undefined);

  const onEdit = (id: string) => {
    setLatestFolderKey?.(activeFolderKey);
    action.navigate(StoryBookLayoutPage.DESIGNER, true, {
      id
    });
  };

  const handleView = (id: string) => {
    if (onView) {
      action.read(SBActionTarget.Storybook, { id, silent: true }, onView);
      return;
    }
    if (onSelect) {
      onPreview(id);
    } else {
      action.read(
        SBActionTarget.Storybook,
        { id, silent: true },
        setActiveStorybook
      );
    }
  };

  const onPreview = (id: string) => {
    action.navigate(StoryBookLayoutPage.PREVIEW, true, {
      id
    });
  };

  const onInsight = async (storybook: IStorybook, fallback) => {
    try {
      const sb = await getActivatedStorybookById(storybook.id);
      action.update(SBActionTarget.Storybook, { data: sb });
      action.resetStorybookOverview();
      fallback();
      setIsInsight(true);
    } catch (error) {
      console.log(error);
    }
  };

  const onDelete = (ids: string[]) => {
    removeFromTrash(ids, () => {
      ids.forEach((id, index) =>
        action.delete(SBActionTarget.Storybook, id, () => {
          if (index === ids.length - 1) {
            setStorybooks(
              filter(allStoryBooks, (storybook) => {
                return indexOf(ids, storybook.id) === -1;
              })
            );
          }
        })
      );
    });
    action?.setStorybook?.({});
  };

  const onTrash = (id: string) => {
    addToTrash(id);
    action?.setStorybook?.({});
  };

  const onRestore = (ids: string[]) => {
    removeFromTrash(ids);
  };

  const onToggleFavorite = (id: string, add: boolean) => {
    if (add) {
      addToFavorites(id);
    } else {
      removeFromFavorites(id);
    }
  };

  const onCreate = () => {
    const storybookId = generateV4UUID();

    if (isInSpecialFolder) {
      selectFolderKey('/');
    }
    setLatestFolderKey?.(isInSpecialFolder ? '/' : activeFolderKey);
    action.navigate(StoryBookLayoutPage.DESIGNER, true, {
      newPage: true,
      initData: {
        id: storybookId,
        brand: brandSetup?.parentBrandId || brandId || brandSetup?.id
      }
    });

    if (!isInSpecialFolder && activeFolderKey !== '/') {
      moveElementToKey({
        elementId: storybookId,
        toKeyId: activeFolderKey
      });
    }
  };

  const onDeleteFolder = (idToDelete?: string) => {
    const id = idToDelete || activeFolderKey;
    dispatch(
      updateKeysInFolderAsync({
        key: folderKeys.find((f) => f.id === id)?.key,
        keyId: id,
        userId,
        uuid: userId,
        context: 'storybook',
        operation: 'remove',
        onSuccess: () => {
          selectFolderKey(getParentFolderKey(id, folderKeys)?.id || '/');
        }
      })
    );
  };

  const handlePublish = (passcode?: string) => {
    action.save(
      SBActionTarget.PublishedStorybook,
      () => {
        setPasscodeSetting(null);
        loadStorybooks(true);
      },
      { storybook: passcodeSetting.storybook, passcode: passcode || undefined }
    );
  };
  const filteredFolderKeys = folderKeys;

  React.useEffect(() => {
    if (isInsight) {
      setIsInsight(false);
    }
    setActiveStorybook(null);
    if (useStorybookSearchReady) {
      const folder = folderKeys.find((f) => f.id === activeFolderKey);
      if (folder?.settings?.type === SHARED_TEAM_TYPE) {
        loadStorybooks(true, activeFolderKey);
      } else {
        loadStorybooks(true);
      }
    }
  }, [keywords, useStorybookSearchReady, isInSharedKey]);

  React.useEffect(() => {
    if (setStorybooks) {
      setStorybooks([]);
    }
  }, [isInSharedKey]);

  React.useEffect(() => {
    setActiveStorybook(null);
    setSelectedStorybookArr([]);
    if (isInCollaboratedKey) {
      loadStorybooks(true);
    }
  }, [activeFolderKey]);

  React.useEffect(() => {
    if (initFolderKey) {
      selectFolderKey(initFolderKey);
    }
  }, [initFolderKey]);

  const onBack = () => {
    action.update(action.update(SBActionTarget.Storybook, { data: {} }));
    setIsInsight(false);
  };

  const restoreDeleteAll = isInTrashKey && storybooks?.length > 1 && (
    <div className="delete-all">
      <button
        className="btn btn-sm btn-round btn-outline-dark"
        onClick={() => onRestore(storybooks.map((s) => s.id))}
      >
        Restore All
      </button>
      <button
        className="btn btn-sm btn-round btn-dark"
        onClick={() => setDeleteConfirmation(storybooks.map((s) => s.id))}
      >
        Delete All
      </button>
      <style jsx>{`
        .delete-all {
          background: #efefef;
          padding: 10px;
        }
        .btn {
          margin: 0 2px;
          width: 100px;
        }
      `}</style>
    </div>
  );

  const onDropToItem = async (fId, transferData) => {
    if (activeFolderKey.includes(fId)) {
      return;
    }

    const droppedStorybookIds = transferData.getData('storybookIds').split(',');

    const folderPath =
      filteredFolderKeys?.find((folder) => folder?.id === fId)?.key || '/';
    if (activeFolderType === SHARED_TEAM_TYPE) {
      if (elementsInFolderKeys[fId].includes(addToFolder)) {
        setAddToFolder(null);
        dispatch(
          actionHubAlertInfo(
            `Move to Shared with me/${folderPath.replace(SHARED_ITEMS, '')}`
          )
        );
        return;
      }
      if (
        folderKeys.find((f) => f.id === fId).key === SHARED_ITEMS &&
        !getKeyIdFromElementId(addToFolder, elementsInFolderKeys)
      ) {
        setAddToFolder(null);
        dispatch(
          actionHubAlertInfo(
            `Move to Shared with me/${folderPath.replace(SHARED_ITEMS, '')}`
          )
        );
        return;
      }
    }

    moveMultipleElementsToKey({
      elementIds: droppedStorybookIds,
      toKeyId: fId,
      callback: () => {
        if (folderPath.startsWith(SHARED_ITEMS)) {
          dispatch(
            actionHubAlertInfo(
              `Move to Shared with me/${folderPath.replace(SHARED_ITEMS, '')}`
            )
          );
        } else {
          dispatch(actionHubAlertInfo(`Move to Overview${folderPath}`));
        }
        onSelectFolder(fId);
      }
    });
  };

  const selectStorybook = (storybook: IStorybook, forceAdd?: boolean) => {
    if (!isMultipleSelectionEnabled) {
      setSelectedStorybookArr([storybook.id]);
      setActiveStorybook(storybook);
      handleView(storybook.id);
    } else {
      let newSelectedStorybookArr = [...selectedStoryBookArr];
      if (multipleSelectTrigger === 'Shift') {
        const firstSelectedIndex = isEmpty(selectedStoryBookArr)
          ? 0
          : storybooks.findIndex((s) => s.id === first(selectedStoryBookArr));
        const lastSelectedIndex = storybooks.findIndex(
          (s) => s.id === storybook.id
        );
        const smallerIndex = min([firstSelectedIndex, lastSelectedIndex]);
        const biggerIndex = max([firstSelectedIndex, lastSelectedIndex]);
        let newSelectedStorybookIds = storybooks
          ?.slice(smallerIndex, biggerIndex + 1)
          ?.map((s) => s.id);
        if (
          firstSelectedIndex > lastSelectedIndex ||
          isEmpty(selectedStoryBookArr)
        ) {
          newSelectedStorybookIds = reverse(newSelectedStorybookIds);
        }
        newSelectedStorybookArr = newSelectedStorybookIds;
      } else if (selectedStoryBookArr.includes(storybook.id)) {
        newSelectedStorybookArr = forceAdd
          ? selectedStoryBookArr
              .filter((id) => id !== storybook.id)
              .concat([storybook.id])
          : selectedStoryBookArr.filter((k) => k !== storybook.id);
      } else newSelectedStorybookArr = [...selectedStoryBookArr, storybook.id];
      setSelectedStorybookArr(newSelectedStorybookArr);
      if (newSelectedStorybookArr.length === 1) {
        setActiveStorybook(storybook);
        handleView(storybook.id);
      }
    }
  };

  const deselectStorybooks = (ids?: string[]) => {
    let nextSelectedScenes = [];
    if (ids) {
      nextSelectedScenes = selectedStoryBookArr?.filter(
        (id) => !ids.includes(id)
      );
    }
    setSelectedStorybookArr(nextSelectedScenes);
    setActiveStorybook({});
  };

  useEffect(() => {
    const onDisableMultipleSelect = (e) => {
      if (e.type === 'contextmenu') {
        if (
          (e.ctrlKey || e.metaKey || e.shiftKey) &&
          isMultipleSelectionEnabled
        ) {
          setIsMultipleSelectionEnabled(false);
        }
      } else if (e.type === 'blur') {
        if (isMultipleSelectionEnabled) {
          setIsMultipleSelectionEnabled(false);
        }
      }
    };

    window.addEventListener('contextmenu', onDisableMultipleSelect);
    window.addEventListener('blur', onDisableMultipleSelect);

    hotkeys(
      '*',
      { keyup: true, keydown: true, scope: `storybook-overview` },
      (e) => {
        if (isInSpecialFolder || !!onSelect) return;
        if (e.type === 'keydown') {
          if (e.ctrlKey || e.key === 'Meta' || e.key === 'Shift') {
            setIsMultipleSelectionEnabled(true);
            setMultipleSelectTrigger(e.key);
          }
        }
        if (e.type === 'keyup') {
          if (e.key === 'Control' || e.key === 'Meta' || e.key === 'Shift') {
            setIsMultipleSelectionEnabled(false);
            setMultipleSelectTrigger(undefined);
          }
        }
      }
    );

    return () => {
      hotkeys.unbind('ctrl+a,cmd+a');
      hotkeys.unbind('*');
      window.removeEventListener('contextmenu', onDisableMultipleSelect);
      window.removeEventListener('blur', onDisableMultipleSelect);
    };
  }, [isInSpecialFolder]);

  hotkeys('ctrl+a,cmd+a', `storybook-overview`, (e) => {
    if (isInSpecialFolder || !!onSelect) return;
    e.preventDefault();
    setSelectedStorybookArr(storybooks.map((s) => s.id));
  });

  return (
    <div className="SBSearch" onDragOver={(e) => e.preventDefault()}>
      {!isInsight && (
        <SearchBar
          setKeywords={setKeywords}
          placeHolder={
            isInFolder
              ? `Search in ${getFolderLabel(activeFolderKeyLabel)} folder`
              : 'Search'
          }
          loading={loading === LoadingStatus.LOADING}
          onCreate={onCreate}
          selectMode={!!onSelect}
          createTitle="Create Storybook"
          title="Storybooks"
          keywords={keywords}
          onBack={isInsight ? onBack : undefined}
        />
      )}
      {isInsight ? (
        <SBAnalytics setIsInsight={setIsInsight} />
      ) : (
        <div className="col-container">
          <div
            className={`folder-desktop content-col side-col${
              showFolderCol ? '' : '-hide'
            }`}
          >
            {showFolderCol && (
              <FolderNavigation
                folders={filteredFolderKeys}
                selectedFolder={activeFolderKey}
                onSelectFolderKey={onSelectFolder}
                expandedFolderKeys={expandedFolderKeys}
                toggleExpandFolderKey={toggleExpandFolderKey}
                title={{
                  mainTitle: 'Folders',
                  trashTitle: 'Deleted',
                  rootTitle: 'My Storybooks'
                }}
                onAddNewFolder={
                  onSelect ? undefined : () => setEditFolderPopup({ id: 'new' })
                }
                excludeFolders={onSelect ? [COLLABORATED_ITEMS] : []}
                onDrop={onDropToItem}
              />
            )}
            {showFolderCol ? (
              <div
                className="hide-folder"
                onClick={() => {
                  setShowFolderCol(!showFolderCol);
                  logClickButton('Hide folder');
                }}
              >
                <IconOutlineArrow direction="left" />
              </div>
            ) : (
              <div
                className="show-folder"
                onClick={() => {
                  setShowFolderCol(!showFolderCol);
                  logClickButton('Show folder');
                }}
              >
                <IconOutlineArrow direction="right" />
              </div>
            )}
          </div>
          <div className={`folder-mobile content-col side-col`}>
            <FolderNavigationMobile
              folders={filteredFolderKeys}
              selectedFolder={activeFolderKey}
              onSelectFolderKey={onSelectFolder}
              expandedFolderKeys={expandedFolderKeys}
              toggleExpandFolderKey={toggleExpandFolderKey}
              title={{
                trashTitle: 'Deleted',
                rootTitle: 'My Storybooks'
              }}
              onAddNewFolder={
                onSelect ? undefined : () => setEditFolderPopup({ id: 'new' })
              }
              excludeFolders={onSelect ? [COLLABORATED_ITEMS] : []}
              onDrop={onDropToItem}
            />
          </div>
          <div
            className="content-col main-col flex-grow-1"
            onClick={() => deselectStorybooks()}
          >
            {!onSelect && restoreDeleteAll}
            <div className="content-container">
              <div className="inner-content-container" ref={infiniteRef}>
                {!keywords && (
                  <FolderList
                    folderKeys={filteredFolderKeys}
                    activeFolderKey={activeFolderKey}
                    elementsInFolderKeys={elementsInFolderKeys}
                    onSelectFolderKey={(keyId) => {
                      selectFolderKey(keyId);
                      onSelectFolder(keyId);
                    }}
                    onClickMenu={(id, canDelete) =>
                      setThumbnailMenu({
                        type: 'folder',
                        id,
                        canDelete
                      })
                    }
                    selectMode={!!onSelect}
                    onDrop={onDropToItem}
                  />
                )}

                <SBSearchItems
                  userId={userId}
                  storybooks={storybooks}
                  onSelect={onSelect}
                  onView={handleView}
                  onDelete={(id) => setDeleteConfirmation([id])}
                  selectedStorybooks={
                    !isEmpty(selectedStoryBookArr)
                      ? selectedStoryBookArr
                      : !isEmpty(selectedStorybooks)
                      ? selectedStorybooks
                      : activeStorybook?.id
                      ? [activeStorybook?.id]
                      : []
                  }
                  loadingStatus={state.loadingStatus}
                  onClickMenu={(sb, canEdit) => {
                    setShareModal(null);
                    const featuredImage = getLanguaImageFromJSON(
                      sb.featuredImage,
                      'en'
                    );
                    const firstPageThumbnail = getLanguaImageFromJSON(
                      sb.pages?.[0]?.thumbnail || sb.pages?.[0]?.url,
                      'en'
                    );
                    const thumbnailUrl = featuredImage
                      ? `${storyAssetBaseUrl}/${featuredImage}`
                      : firstPageThumbnail;
                    setThumbnailMenu({
                      type: 'storybook',
                      id: sb.id,
                      payload: { ...sb, thumbnailUrl },
                      viewOnly: !canEdit
                    });
                  }}
                  onRestore={(id) => onRestore([id])}
                  trashList={trashList}
                  favoriteList={favoritesList}
                  searchMode={!!keywords}
                  isInTrashKey={isInTrashKey}
                  isBrowseStorybookInMeeting={isBrowseStorybookInMeeting}
                  selectStorybook={selectStorybook}
                  loadingFolder={loadingFolder}
                  isDraggable={!isInSpecialFolder}
                />

                <OverviewPlaceHolder
                  hasNoSearchResult={hasNoSearchResult}
                  hasNoItems={hasMoreResult ? false : hasNoStorybook}
                  keywords={keywords}
                  onCreate={onCreate}
                  loading={loading}
                  canModify={
                    !entitlement.viewOnlyMode && !entitlement.selectMode
                  }
                  onReload={loadStorybooks}
                  currentFolder={
                    activeFolderKey === '/'
                      ? activeFolderKey
                      : activeFolderKeyLabel
                  }
                  onDeleteFolder={onDeleteFolder}
                  isLastChild={isLastChild}
                  context="Storybook"
                  teamFolder={
                    folderKeys?.find((f) => f.id === activeFolderKey)?.settings
                      ?.type
                  }
                />
              </div>
            </div>
          </div>
          {activeStorybook?.id &&
            selectedStoryBookArr.length <= 1 &&
            !onSelect &&
            !isInsight && (
              <div className="content-col side-col">
                <StorybookMetaData
                  storybook={activeStorybook}
                  updateStorybook={(sb) => {
                    setActiveStorybook(sb);
                    action.save(
                      SBActionTarget.StorybookAttributes,
                      () => {
                        loadStorybooks(true);
                      },
                      {
                        id: sb.id,
                        tags: sb.tags,
                        visibility: sb.visibility,
                        visibilityScope: sb.visibilityScope,
                        createdBy: sb.createdBy
                      }
                    );
                  }}
                  onToggleFavorite={onToggleFavorite}
                  canModify={
                    activeStorybook?.createdBy === userId &&
                    !onSelect &&
                    !trashList.includes(activeStorybook?.id)
                  }
                  isFavorited={favoritesList?.includes(activeStorybook?.id)}
                  onClose={() => {
                    setActiveStorybook({});
                    logClickButton('close storybook detail');
                  }}
                />
              </div>
            )}
        </div>
      )}

      {(onSelect || (onClose && entitlement.popupMode)) && (
        <div className="action-footer">
          {onClose && entitlement.popupMode && (
            <button
              className="btn btn-round btn-outline-dark"
              onClick={onClose}
            >
              Cancel
            </button>
          )}
          {!isBrowseStorybookInMeeting && onSelect && (
            <button
              className="btn btn-round btn-dark ml-2 ok-button"
              onClick={onAddSelected}
              disabled={!selectedStorybooks?.length}
            >
              Ok
            </button>
          )}
        </div>
      )}

      {shareModal?.url && (
        <ToolTipWrapper
          onClose={() => setShareModal(null)}
          parentId={`menu-${shareModal.id}`}
          width={250}
          xPosition="left"
        >
          <div className="control-menu text-left">
            <SimpleShareModal
              show={true}
              position="bottom"
              url={shareModal.url}
              onShare={(channel) =>
                logEvent(DID_SHARE_STORYBOOK, channel, {
                  storybookId: shareModal.id,
                  shareUrl: shareModal.url,
                  message: shareModal.message,
                  organisationId:
                    brandSetup?.parentBrandId || brandId || brandSetup?.id
                })
              }
              title={shareModal.message}
              type="menu"
              qrUrl={shareModal.url}
              urlOnly={true}
            />
          </div>
        </ToolTipWrapper>
      )}
      {thumbnailMenu && (
        <>
          <Modal
            show={!!thumbnailMenu}
            onHide={() => setThumbnailMenu(null)}
            className="thumbnailMenu-mobile"
            backdropClassName="thumbnailMenu-backdrop-mobile"
            animation={false}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body>
              <div className="content-header">
                <div className="content-info">
                  {thumbnailMenu.payload?.thumbnailUrl && (
                    <img src={thumbnailMenu.payload?.thumbnailUrl} />
                  )}
                  <div className="header-info">
                    {thumbnailMenu.payload?.title || ''}
                  </div>
                </div>
                <div className="btn-close" onClick={onClose}>
                  <IconClose />
                </div>
              </div>
              <div className="actions">
                <SBPresentButton
                  storybook={thumbnailMenu.payload}
                  onPresent={(s) => {
                    setTimeout(() => {
                      window.open(s.url, '_blank');
                    });
                    onClose();
                  }}
                />
                {thumbnailMenu.payload?.stats?.isPublished && true && (
                  <SBShareButton
                    storybook={thumbnailMenu.payload as IStorybook}
                    onShare={(s) => {
                      isOnMobile ? setShareModal(s) : undefined;
                      onClose();
                    }}
                  />
                )}

                {thumbnailMenu.payload?.stats?.isPublished && (
                  <div
                    onClick={() => {
                      setShareWithOptions(thumbnailMenu.payload);
                      logClickButton('Share with options');
                    }}
                  >
                    {isOnMobile ? 'Share with Options' : 'Share'}
                  </div>
                )}
                {
                  <div
                    onClick={() => {
                      setShareWithOptions(thumbnailMenu.payload);
                      logClickButton('Share with options');
                    }}
                  >
                    Transfer
                  </div>
                }
              </div>
            </Modal.Body>
          </Modal>

          <ToolTipWrapper
            onClose={() => setThumbnailMenu(null)}
            parentId={`menu-${thumbnailMenu.id}`}
            width={thumbnailMenu.type === 'folder' ? 150 : 250}
            xPosition="left"
            className="sbMenu"
          >
            {thumbnailMenu.type === 'storybook' ? (
              <SBThumbnailMenu
                isCollaboration={isInCollaboratedKey}
                viewOnly={thumbnailMenu.viewOnly}
                storybook={thumbnailMenu.payload}
                onDelete={onTrash}
                onShare={isOnMobile ? setShareModal : undefined}
                onReload={() => loadStorybooks(true)}
                onEdit={onEdit}
                onClose={() => setThumbnailMenu(null)}
                onToggleFavorite={onToggleFavorite}
                onSettingPasscode={setPasscodeSetting}
                onShareWithOptions={setShareWithOptions}
                onView={onPreview}
                onAddToFolder={setAddToFolder}
                isFavorited={favoritesList?.includes(thumbnailMenu.id)}
                onInsight={onInsight}
                onTransferOwnership={(storybook) => {
                  setTransferOwnershipSb(storybook);
                }}
              />
            ) : (
              <FolderMenu
                canDelete={thumbnailMenu.canDelete}
                onDelete={() => onDeleteFolder(thumbnailMenu.id)}
                onClose={() => setThumbnailMenu(null)}
                onRename={() =>
                  setEditFolderPopup({ id: thumbnailMenu.id, hideMoveTo: true })
                }
                onMoveToFolder={() =>
                  setEditFolderPopup({ id: thumbnailMenu.id, hideRename: true })
                }
              />
            )}
          </ToolTipWrapper>
        </>
      )}
      {passcodeSetting && (
        <PasscodePopup
          title={passcodeSetting?.editMode ? 'Passcode Setting' : 'Publish'}
          onClose={() => setPasscodeSetting(null)}
          hasPasscode={
            passcodeSetting.type === StorybookAccessType.PASSCODE_REQUIRED
          }
          onSubmit={handlePublish}
          editMode={passcodeSetting?.editMode}
        />
      )}

      {!!shareWithOptions?.id && (
        <SBSharePopup
          storybook={shareWithOptions}
          onClose={() => {
            setShareWithOptions(null);
          }}
          className="shareWithOptions"
        />
      )}

      {addToFolder && (
        <AddToFolderPopup
          folders={folderKeys}
          activeFolderKey={activeFolderKey}
          expandedFolderKeys={expandedFolderKeys}
          onClose={() => setAddToFolder('')}
          loading={loadingFolder?.[addToFolder] === LoadingStatus.LOADING}
          onMoveTo={(target) => {
            const folderPath =
              filteredFolderKeys?.find((folder) => folder?.id === target)
                ?.key || '/';
            if (activeFolderType === SHARED_TEAM_TYPE) {
              if (elementsInFolderKeys[target].includes(addToFolder)) {
                setAddToFolder(null);
                dispatch(
                  actionHubAlertInfo(
                    `Move to Shared with me/${folderPath.replace(
                      SHARED_ITEMS,
                      ''
                    )}`
                  )
                );
                return;
              }
              if (
                folderKeys.find((f) => f.id === target).key === SHARED_ITEMS &&
                !getKeyIdFromElementId(addToFolder, elementsInFolderKeys)
              ) {
                setAddToFolder(null);
                dispatch(
                  actionHubAlertInfo(
                    `Move to Shared with me/${folderPath.replace(
                      SHARED_ITEMS,
                      ''
                    )}`
                  )
                );
                return;
              }
            }
            moveElementToKey({
              elementId: addToFolder,
              toKeyId: target,
              callback: () => {
                if (folderPath.startsWith(SHARED_ITEMS)) {
                  dispatch(
                    actionHubAlertInfo(
                      `Move to Shared with me/${folderPath.replace(
                        SHARED_ITEMS,
                        ''
                      )}`
                    )
                  );
                } else {
                  dispatch(actionHubAlertInfo(`Move to Overview${folderPath}`));
                }
                setAddToFolder('');
              }
            });
          }}
          onAddNewFolder={() => setEditFolderPopup({ id: 'new' })}
        />
      )}

      {editFolderPopup && (
        <CreateFolderPopup
          context="storybook"
          uuid={userId}
          userId={userId}
          folderToEdit={editFolderPopup.id}
          onSuccess={() => {
            setEditFolderPopup(null);
          }}
          onClose={() => setEditFolderPopup(null)}
          folders={folderKeys}
          activeFolderKey={activeFolderKey}
          expandedFolderKeys={expandedFolderKeys}
          hideRenameTo={editFolderPopup.hideRename}
          hideMoveTo={editFolderPopup.hideMoveTo}
        />
      )}

      {!!deleteConfirmation?.length && (
        <ConfirmationPopup
          title={`Are you sure want to delete ${
            deleteConfirmation.length > 1
              ? 'all these Storybooks'
              : 'this Storybook'
          } permanently?`}
          okLabel="Delete"
          onOk={() => {
            setDeleteConfirmation([]);
            onDelete(deleteConfirmation);
          }}
          onClose={() => setDeleteConfirmation([])}
        >
          <p />
        </ConfirmationPopup>
      )}

      {!!transferOwnershipSb?.id && (
        <SBTransferOwnershipPopup
          onClose={() => setTransferOwnershipSb(null)}
          storybook={transferOwnershipSb}
          onSuccess={() => {
            setStorybooks(
              filter(allStoryBooks, (storybook) => {
                return storybook.id !== transferOwnershipSb.id;
              })
            );
            // loadStorybooks(true);
            setTransferOwnershipSb(null);
            dispatch(actionHubAlertInfo('Transfer ownership successfully'));
          }}
        />
      )}
      <style jsx>{`
        .SBSearch {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-content: stretch;
        }
        .folder-mobile {
          display: none;
        }
        .col-container {
          height: 100%;
          overflow: hidden;
          display: flex;
        }
        .content-col {
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
        }
        .main-col {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-content: baseline;
          position: relative;
        }

        .side-col {
          width: 300px;
          min-width: 300px;
          transition: min-width 0.1s;
          position: relative;
        }
        .show-folder {
          padding: 8px;
          opacity: 0.5;
          cursor: pointer;
        }
        .hide-folder {
          position: absolute;
          top: 14px;
          right: 29px;
          opacity: 0.5;
          cursor: pointer;
        }
        .side-col-hide {
          min-width: 35px;
          transition: min-width 0.1s;
          border-right: 1px solid #ccc;
        }
        .side-col:first-child {
          border-right: 1px solid #ccc;
        }
        .side-col:last-child {
          border-left: 1px solid #ccc;
        }
        .content-container {
          overflow-y: scroll;
          width: 100%;
          padding: 20px;
        }
        .inner-content-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-content: baseline;
        }

        .ok-button {
          min-width: 150px;
        }
        .action-footer {
          padding-top: 15px;
          border-top: 1px solid #ccc;
          text-align: right;
        }
        .side-col :global(.SBThumbnail) {
          max-width: 244px;
          margin: 10px auto;
        }
        @media (max-width: 500px) {
          .folder-desktop {
            display: none;
          }
          .folder-mobile {
            display: block !important;
          }
          .col-container {
            display: block !important;
          }
          .side-col {
            width: 100%;
            height: auto;
          }
        }
      `}</style>
      <style jsx global>{`
        .StoryBook .content-wrapper {
          padding: 0 !important;
          background: #fff;
        }
        .thumbnail-container {
          width: 50%;
          padding: 10px;
        }
        .thumbnail-container :global(.media-container) {
          border: none !important;
          border-radius: 0;
          border-bottom: 1px solid #ccc;
        }
        .thumbnailMenu-mobile {
          display: none !important;
        }
        .thumbnailMenu-backdrop-mobile {
          display: none;
        }
        @media (min-width: 1200px) {
          .thumbnail-container {
            width: 33%;
          }
        }

        @media (min-width: 1600px) {
          .thumbnail-container {
            width: 25%;
          }
        }

        @media (min-width: 2000px) {
          .thumbnail-container {
            width: 20%;
          }
        }

        @media (min-width: 2000px) {
          .thumbnail-container {
            width: 16.666%;
          }
        }
        @media (max-width: 500px) {
          .header-container .btn-create {
            display: none;
          }
          .header-container {
            display: block !important;
            border-bottom: none !important;
            padding: 15px !important;
          }
          .header-container .SearchInput {
            width: 100%;
            margin-top: 10px;
          }
          .header-container .SearchInput input {
            font-size: 14px;
          }
          .SBThumbnail {
            position: relative;
          }
          .SBThumbnail .actions {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            border-top: none !important;
            margin-top: 0 !important;
            padding-top: 0 !important;
          }
          .SBThumbnail .actions .sb-option {
            background: transparent;
          }

          .SBThumbnail .actions .sb-option svg {
            color: #000;
            transform: rotate(90deg);
          }
          .FolderThumbnail {
            min-height: 100px !important;
          }
          .FolderThumbnail .folder-option {
            display: none;
          }
          .thumbnail-container {
            width: 100%;
            padding: 5px 0;
          }
          .SBThumbnail {
            display: flex;
            justify-content: left;
            display: flex;
            align-items: center;
            border: none !important;
            border-radius: 4px !important;
            color: #828282;
          }
          .SBThumbnail .media-container {
            width: 120px;
            padding: 5px;
          }
          .SBThumbnail .modified,
          .SBThumbnail .created {
            display: none;
          }
          .SBThumbnail .createby-mobile {
            display: block !important;
            text-align: right;
          }
          .SBThumbnail .createby-desktop {
            display: none !important;
          }
          .SBThumbnail > .content-container {
            display: flex;
            justify-content: space-between;
            width: calc(100% - 120px);
            padding-right: 25px !important;
          }
          .SBThumbnail > .content-container .title {
            color: #000;
          }
          .SBThumbnail > .content-container .info {
            margin-top: 0 !important;
            width: 70px;
            white-space: unset;
            padding-right: 5px;
          }
          .SBThumbnail > .content-container .main-info {
            width: calc(100% - 70px);
          }
          .thumbnail-container .tag-label {
            font-size: 8px;
          }
          .thumbnail-container .tag-favorite {
            display: none !important;
          }
          .thumbnail-container .tag-visibility {
            display: none !important;
          }
          .thumbnail-container .tag-label svg {
            width: 10px !important;
          }
          .dragging-elements {
            display: none;
          }
          .FolderThumbnail .content img {
            width: 30px;
          }
          .FolderThumbnail .label-container {
            margin-top: 5px;
          }
          .thumbnailMenu-mobile {
            display: block !important;
          }
          .thumbnailMenu-backdrop-mobile {
            display: block;
          }
          .sbMenu {
            display: none !important;
          }
          .thumbnailMenu-mobile {
            margin: 0;
            width: 100%;
            bottom: 0;
            top: unset;
            height: auto;
          }
          .thumbnailMenu-mobile .modal-dialog {
            margin: 0;
            max-width: 100vw;
          }

          .thumbnailMenu-mobile .modal-body {
            padding: 30px 30px 0;
          }
          .thumbnailMenu-mobile .modal-dialog .modal-content {
            border-radius: 1.25rem 1.25rem 0rem 0rem;
            border: none;
          }
          .thumbnailMenu-mobile .modal-dialog .content-header {
            display: flex;
            justify-content: space-between;
          }
          .thumbnailMenu-mobile .modal-dialog .content-info {
            display: flex;
            justify-content: space-between;
            gap: 10px;

            max-width: calc(100% - 20px);
          }
          .thumbnailMenu-mobile .modal-dialog .content-info img {
            width: 60px;
          }
          .thumbnailMenu-mobile .modal-dialog .header-info {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 500;
            line-height: 32px;
          }
          .thumbnailMenu-mobile .modal-dialog .actions div {
            font-weight: 400;
            margin: 15px 0;
          }
          .thumbnailMenu-mobile .modal-dialog .actions .list-group-item {
            padding: 0;
            border: none;
          }
          .thumbnailMenu-mobile .modal-dialog .btn-close svg {
            width: 20px;
          }
          .SBSearch .content-container {
            padding: 15px !important;
          }
          .SBPopupWrapper > .content-container {
            position: absolute !important;
            bottom: 0;
            padding: 0 !important;
          }
          .shareWithOptions {
            height: 65vh !important;
            max-width: 100vw !important;
          }
          .shareWithOptions .nav-bar {
            height: auto !important;
          }
          .shareWithOptions .nav-bar .nav-item {
            height: 100% !important;
            width: 25%;
          }
          .tab-container {
            margin: 0 !important;
          }
          .shareWithOptions .btn-close {
            top: 5px !important;
          }
          .tab-container .tab-pane {
            padding: 0 !important;
          }
          .SharePopup .contacts {
            max-height: calc(65vh - 220px);
          }
          .SharePopup .contacts .avatar {
            width: 30px !important;
            height: 30px !important;
          }
          .SharePopup .contacts .btn-rounded {
            width: 30px !important;
            height: 30px !important;
          }
          .SharePopup .contacts .name-tag .email-mobile {
            display: block !important;
            font-size: 12px;
          }
          .SharePopup .contacts .name-tag .jobtitle {
            font-size: 13px;
          }
          .SharePopup .contacts .name-tag {
            flex: 0 0 66.66666%;
            max-width: 66.66666%;
          }
          .SharePopup .contacts .email {
            display: none !important;
          }
          .SBThumbnail .content-container .main-info .subtitle {
            white-space: unset;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: unset;
          }
          .main-col {
            height: calc(100% - 45px) !important;
          }
        }
      `}</style>
    </div>
  );
};

export default SBSearch;
