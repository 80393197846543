import { IPolotnoJSON, TLanguage } from '../../mappers/polotno';
import { HISTORY_SCENES_STORED } from '../../utils/constants';

function findAndRemoveOldestScene(scenes) {
  let oldestId = null;
  let oldestTime = Infinity;

  Object.keys(scenes).forEach((sceneId) => {
    const ts = scenes[sceneId]?.ts;
    if (ts && ts < oldestTime) {
      oldestTime = ts;
      oldestId = sceneId;
    }
  });

  if (oldestId) {
    delete scenes[oldestId];
  }
  return scenes;
}

export const getScenesLocalStorage = () => {
  return localStorage.getItem(HISTORY_SCENES_STORED) || '{}';
};

export const setScenesLocalStorage = (scenes: any) => {
  if (!scenes) return;
  return localStorage.setItem(HISTORY_SCENES_STORED, JSON.stringify(scenes));
};

// export const addSceneToLocalStorage = (store: IPolotnoJSON, sceneId: string, language: TLanguage) => {
//   const key = `scene_${sceneId}`;
//   const storedData = localStorage.getItem(key);
//   const data = storedData ? JSON.parse(storedData) : { languages: [] };

//   if (!data.languages.includes(language)) {
//     data.languages.push(language);
//     data.pages = store.pages;
//     localStorage.setItem(key, JSON.stringify(data));
//   }
// };

export const addSceneToLocalStorage = (
  store: IPolotnoJSON,
  sceneId: string,
  activeLanguage: TLanguage
) => {
  if (!store || !sceneId) return;
  let scenesBackup = JSON.parse(getScenesLocalStorage());
  const key = `scene_${sceneId}`;
  const storedData = localStorage.getItem(key);
  const data = storedData ? JSON.parse(storedData) : { languages: [] };

  if (!data?.languages?.includes(activeLanguage)) {
    data?.languages?.push(activeLanguage);
    data.pages = store?.pages;
    localStorage.setItem(key, JSON.stringify(data));
  }
  if (Object.keys(scenesBackup).length >= 5) {
    scenesBackup = findAndRemoveOldestScene(scenesBackup);
  }
  if (scenesBackup[sceneId]) {
    if (scenesBackup[sceneId][activeLanguage]) {
      scenesBackup[sceneId][activeLanguage] = store;
    } else {
      scenesBackup[sceneId][activeLanguage] = store;
    }
    scenesBackup[sceneId].ts = Date.now();
  } else {
    scenesBackup[sceneId] = {
      [activeLanguage]: store,
      ts: Date.now()
    };
  }
  setScenesLocalStorage(scenesBackup);
};

export const removeSceneFromLocalStorage = (sceneId: string) => {
  if (!sceneId) return;
  const scenesBackup = JSON.parse(getScenesLocalStorage());
  if (scenesBackup && scenesBackup[sceneId]) {
    delete scenesBackup[sceneId];
    setScenesLocalStorage(scenesBackup);
  }
};

export const removeItemFromLocalStorage = (sceneId: string)=>{
  const item= `scene_${sceneId}`
  localStorage.removeItem(item)
  
}
export const removeLanguageFromLocalStorage = (
  sceneId: string,
  language: TLanguage
) => {
  const scenesBackup = JSON.parse(localStorage.getItem('scenes') || '{}');
  if (scenesBackup[sceneId] && scenesBackup[sceneId][language]) {
    delete scenesBackup[sceneId][language];
    scenesBackup[sceneId]['ts'] = Date.now();
    localStorage.setItem('scenes', JSON.stringify(scenesBackup));
  }
};
