import React from 'react';
import { TLanguage } from '../../../../mappers/polotno';
import DesignerPanel from '../PageDesigner';
import SBBuilderActions from './SBBuilderActions';
import SBContentManager from './SBContentManager';

const SBDesigner = React.memo(({
  onOpenEditor,
  onDuplicate,
}: {
  onOpenEditor?: (props: {
    sceneId?: string;
    allowEdit?: boolean;
    isImported?: boolean;
    featuredImage?: boolean;
    activeLanguage?: TLanguage;
  }) => void;
  onDuplicate?: (props: { sceneId?: string; isImported?: boolean }) => void;
}) => {
  return (
    <div className="SBDesigner d-flex h-100 flex-column">
      <SBBuilderActions />
      <div className="design-container d-flex h-100">
        <div className="col-side d-flex">
          <SBContentManager />
        </div>
        <div className="flex-grow-1 d-flex col-center">
          <DesignerPanel
            onOpenEditor={onOpenEditor}
            onDuplicate={onDuplicate}
          />
        </div>
      </div>
      <style jsx global>{`
        .StoryBook .content-wrapper {
          padding: 0 20px 20px !important;
          background: #f6f6f6;
        }
      `}</style>

      <style jsx>{`
        .design-container {
          overflow: hidden;
        }
        .design-container > div > :global(div) {
          background: #fff;
          border-radius: 10px;
          padding: 20px;
        }
        .SBDesigner :global(.col-center) {
          padding: 0 30px;
        }
        .SBDesigner :global(.panel-label) {
          font-weight: bold;
          font-size: 14px;
        }
        .SBDesigner :global(label) {
          font-weight: bold;
          font-size: 12px;
        }
        .col-side {
          position: relative;
          overflow: hidden;
        }
        .SBDesigner .col-side :global(.panel-header) {
          height: 90px;
        }
        .SBDesigner :global(.panel-header) {
          border-bottom: 1px solid #ccc;
        }
        .SBDesigner :global(.panel-content) {
          height: calc(100% - 90px);
          overflow: auto;
        }
        .SBDesigner .col-center :global(.panel-content) {
          height: calc(100% - 52px);
        }
        .SBDesigner :global(.panel-header .form-control) {
          margin-top: 7px;
        }
        .SBDesigner :global(.col-side) {
          width: 370px;
          min-width: 370px;
        }
        @media (max-width: 1530px) {
          .SBDesigner :global(.col-side) {
            width: 300px;
            min-width: 300px;
          }
        }
        @media (max-width: 1400px) {
          .SBDesigner :global(.col-side) {
            width: 230px;
            min-width: 230px;
          }
        }
      `}</style>
    </div>
  );
});

export default SBDesigner;
