import React from 'react';
import { IFolderKey } from '../../../../interfaces';
import {
  COLLABORATED_ITEMS,
  FAVORITE_FOLDER,
  getDirectSubFolder,
  getFolderLabel,
  SHARED_ITEMS,
  TRASH_FOLDER
} from '../../../utils/folders';
import FolderThumbnail from './FolderThumbnail';

const FolderList = ({
  folderKeys,
  activeFolderKey,
  onSelectFolderKey,
  onClickMenu,
  elementsInFolderKeys,
  selectMode,
  isDroppable = true,
  onDrop
}: {
  folderKeys: IFolderKey[];
  activeFolderKey: string;
  onSelectFolderKey: (id: string) => void;
  onClickMenu?: (id: string, canDelete?: boolean) => void;
  elementsInFolderKeys?: { [key: string]: string[] };
  selectMode?: boolean;
  isDroppable?: boolean;
  onDrop?: (id: string, transferData: DataTransfer) => void;
}) => {
  const handleSelectFolderKey = (id: string) => {
    onSelectFolderKey(id);
  };
  const folders = getDirectSubFolder(activeFolderKey, folderKeys);
  const filtered = folders.filter(
    (f) =>
      ![
        TRASH_FOLDER,
        FAVORITE_FOLDER,
        SHARED_ITEMS,
        COLLABORATED_ITEMS
      ].includes(f.key)
  );

  return (
    <>
      {filtered.map((folder) => {
        const itemNumber =
          (getDirectSubFolder(folder.id, folderKeys)?.length || 0) +
          (elementsInFolderKeys?.[folder.id]?.length || 0);
        return (
          <div className="thumbnail-container" key={folder.id}>
            <FolderThumbnail
              onClick={handleSelectFolderKey}
              label={getFolderLabel(folder.key)}
              id={folder.id}
              onClickMenu={selectMode ? undefined : onClickMenu}
              itemNumber={selectMode ? null : itemNumber}
              isTeamFolder={folder.settings?.type === 'team'}
              isDroppable={isDroppable}
              onDrop={onDrop}
            />
          </div>
        );
      })}
    </>
  );
};

export default FolderList;
