import { isEmpty } from '@aws-amplify/core';
import { isEqual, uniq } from 'lodash';
import React from 'react';
import { ILibraryScene } from '../../../../interfaces';
import CollaboratorSelector from '../../Common/Collaborators/CollaboratorSelector';
import FormattedDate from '../../Common/FormattedDate';
import {
  IconClose,
  IconFavorite,
  IconFavoriteFilled
} from '../../Common/HubIcons';
import UserLabel from '../../Common/UserLabel';
import SBVisibilityTagsSetting from '../../StoryBook/Common/SBVisibilityTagsSetting';

const SLMetaData = ({
  scene,
  updateScene,
  onToggleFavorite,
  canModify,
  isFavorited,
  onClose
}: {
  scene: ILibraryScene;
  updateScene?: (storybook: ILibraryScene) => void;
  onToggleFavorite?: (id: string, add: boolean) => void;
  canModify?: boolean;
  isFavorited?: boolean;
  onClose: () => void;
}) => {
  const existingScope = scene?.content?.visibilityScope || [];
  const existingTags = scene?.tags || [];

  const addTeam = (teamId: string) => {
    const visibilityScope = uniq([...existingScope, teamId]);
    if (!isEqual(visibilityScope, existingScope)) {
      updateScene({
        ...scene,
        content: {
          ...scene.content,
          visibility: isEmpty(visibilityScope) ? 'private' : 'team',
          visibilityScope
        }
      });
    }
  };

  const removeTeam = (teamId: string) => {
    const visibilityScope = existingScope.filter((id) => id !== teamId);
    if (!isEqual(visibilityScope, existingScope)) {
      updateScene({
        ...scene,
        content: {
          ...scene.content,
          visibility: isEmpty(visibilityScope) ? 'private' : 'team',
          visibilityScope: visibilityScope
        }
      });
    }
  };

  const addTag = (tag: string) => {
    const tags = uniq([...existingTags, tag]);
    if (!isEqual(tags, existingTags)) {
      updateScene({
        ...scene,
        tags,
      });
    }
  };

  const removeTag = (t: string) => {
    const tags = existingTags.filter((tag) => tag !== t);
    if (!isEqual(tags, existingTags)) {
      updateScene({
        ...scene,
        tags
      });
    }
  };
  const favoriteLabel = isFavorited
    ? 'Remove from Favorites'
    : 'Add to Favorites';

  const handleToggleFavorite = () => {
    onToggleFavorite(scene.id, !isFavorited);
  };

  if (!scene.id) return null;

  return (
    <div className="meta-data">
      <p className="title">{scene?.content?.name}</p>
      <p className="subtitle">{scene?.content?.description}</p>
      <div className="stats">
        <span>
          <UserLabel id={scene.createdBy || ''} label="By: " />
        </span>
        <br />
        <span>
          <FormattedDate dateString={scene.createdAt || ''} label="Created: " />
        </span>
        <br />
        <span>
          <FormattedDate
            dateString={scene.modifiedAt || ''}
            label="Modified: "
          />
        </span>
      </div>
      {canModify && (
        <button className="btn favorite" onClick={handleToggleFavorite}>
          {isFavorited ? (
            <IconFavoriteFilled color="#B7A56D" />
          ) : (
            <IconFavorite />
          )}
          <span>{favoriteLabel}</span>
        </button>
      )}
      <CollaboratorSelector
        itemId={scene.id}
        owner={scene.createdBy}
        context="scene"
        sidePanelMode={true}
        readOnly={!canModify}
      />

      <SBVisibilityTagsSetting
        teamIds={existingScope}
        tags={existingTags}
        addTeam={addTeam}
        removeTeam={removeTeam}
        addTag={addTag}
        removeTag={removeTag}
        canModify={canModify}
        uniqueId={scene.id}
      />
      <div className="btn-close" onClick={onClose}>
        <IconClose />
      </div>
      <style jsx>{`
        .meta-data {
          padding: 30px 40px;
        }

        .meta-data :global(.SearchInput .form-control) {
          font-size: 12px !important;
        }

        .meta-data :global(.UserTeamSelector .avatar-container) {
          font-size: 9px !important;
        }

        .title {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        .subtitle {
          margin-bottom: 27px;
        }

        .subtitle,
        .stats {
          font-size: 10px;
          font-weight: 400;
        }

        .favorite {
          margin-top: 15px;
          font-size: 12px;
          font-weight: 600;
          border: none;
          padding: 0;
          background: none;
        }
        .favorite :global(svg) {
          height: 22px;
          width: 22px;
          margin-top: -3px;
        }
        .favorite span {
          display: inline-block;
          text-decoration: underline;
          margin-left: 5px;
        }
        .actions {
          border-top: 1px solid #efefef;
          justify-content: space-between;
          padding: 15px 0;
          margin-top: 15px;
        }
        .actions .btn {
          width: 48%;
        }
        .btn-close {
          position: absolute;
          top: 5px;
          right: 15px;
        }
        .btn-close :global(svg) {
          width: 20px;
          height: auto;
          cursor: pointer;
          border: 1px solid rgba(0, 0, 0);
          border-radius: 100px;
        }
      `}</style>
    </div>
  );
};

export default SLMetaData;
