import React, { useEffect } from 'react';
import ToolTipWrapper from '../../../components/Common/ToolTipWrapper';
import { IconArrow, IconClose } from './HubIcons';

const SelectDropdown = ({
  id,
  label,
  value,
  options,
  children,
  onSelect,
  forceClose,
  className,
  hideArrow,
  childrenPosition = 'after',
  popupWidth = 150,
  showCloseButton,
  popupXPosition,
  width,
  setForceClose
}: {
  id: string;
  forceClose?: boolean;
  label?: string | React.ReactNode;
  value?: string | number;
  options?: {
    value?: string | number;
    label: string | React.ReactNode;
    onSelect?: (value?: string | number) => void;
  }[];
  children?: React.ReactNode;
  onSelect?: (value: string | number) => void;
  className?: string;
  hideArrow?: boolean;
  childrenPosition?: 'before' | 'after';
  popupWidth?: number;
  showCloseButton?: boolean;
  popupXPosition?: 'center' | 'left' | 'right';
  width?: string;
  setForceClose?: (close: boolean) => void;
}) => {
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    if(forceClose) {
      setShow(false)
    }
  }, [forceClose])
  const popup = (
    <ToolTipWrapper
      parentId={id}
      distanceFromParent={20}
      onClose={() => setShow(false)}
      width={popupWidth}
      closeButton={
        showCloseButton ? (
          <button className="btn popup-close p-0 btn-sm">
            <IconClose />
          </button>
        ) : (
          ''
        )
      }
      xPosition={popupXPosition}
    >
      <div className="list-group shadow-box text-left">
        {children && childrenPosition === 'before' && (
          <li className="list-group-item">{children}</li>
        )}
        {options?.map((option) => (
          <button
            className={`list-group-item list-group-item-action ${
              option.value === value ? 'selected' : ''
            }`}
            key={option.value}
            onClick={() => {
              setShow(false);
              option?.onSelect
                ? option.onSelect(option.value)
                : onSelect?.(option.value);
            }}
          >
            {option.label}
          </button>
        ))}
        {children && childrenPosition === 'after' && (
          <li className="list-group-item">{children}</li>
        )}
      </div>
      <style jsx>{`
        .selected {
          background: #efefef;
        }
        .list-group {
          height: auto;
          overflow: auto;
          background: #fff;
          border-radius: 10px;
        }
        .list-group-item {
          border-left: none;
          border-right: none;
        }
        .list-group-item:first-child {
          border-top: none;
        }
        .list-group-item:last-child {
          border-bottom: none;
        }
      `}</style>
    </ToolTipWrapper>
  );
  return (
    <>
      <button
        className={`btn ${className ? className : 'btn-outline-dark'}`}
        id={id}
        onClick={() => {
          setShow(!show);
          setForceClose && setForceClose(false);
        }}
      >
        {label}{' '}
        <strong>
          {options?.find((option) => option.value === value)?.label}
        </strong>
        {!hideArrow && <IconArrow direction={show ? 'up' : 'down'} />}
      </button>
      {show && popup}
      <style jsx global>{`
        #ToolTipWrapper {
          overflow: visible !important;
          display: flex;
          flex-direction: column;
        }
        .popup-close {
          border: 1px solid #000;
          border-radius: 50px;
          margin: 5px 5px 0 0;
        }
      `}</style>
      <style jsx>{`
        .btn:hover :global(path),
        .btn:active :global(path) {
          stroke: #fff !important;
        }
        .btn{
          width: ${width ? width : 'auto'};
        }
        .btn :global(svg) {
          width: 15px;
          height: auto;
          margin-left: 5px;
        }
      `}</style>
    </>
  );
};

export default SelectDropdown;
