import React, { useEffect, useState } from 'react';
import { IStorybook, LoadingStatus } from '../../../../interfaces';
import SBThumbnail from '../Common/SBThumbnail';

const SBSearchItems = ({
  userId,
  storybooks,
  onSelect,
  selectStorybook,
  loadingFolder={},
  onView,
  selectedStorybooks,
  onDelete,
  loadingStatus,
  onClickMenu,
  onRestore,
  trashList,
  favoriteList,
  searchMode,
  isInTrashKey,
  isBrowseStorybookInMeeting,
  isDraggable=false
}: {
  userId: string;
  storybooks: IStorybook[];
  onSelect?: (storybook: IStorybook) => void;
  onView: (storybookId: string) => void;
  onDelete: (storybookId: string) => void;
  onRestore: (storybookId: string) => void;
  selectedStorybooks?: string[];
  loadingStatus?: {
    [key: string]: LoadingStatus;
  };
  onClickMenu: (s: IStorybook, canEdit?: boolean) => void;
  trashList?: string[];
  favoriteList?: string[];
  searchMode?: boolean;
  isInTrashKey?: boolean;
  isBrowseStorybookInMeeting?: boolean;
  selectStorybook?: (scene: IStorybook) => void;
  loadingFolder?: object;
  isDraggable?: boolean;
}) => {
  const [data, setData] = useState<IStorybook[]>(storybooks);
  const canModify = (createdBy: string) => createdBy === userId && !onSelect;
  const isLoadingFolder =  (key: string) => loadingFolder[key] === LoadingStatus.LOADING;

  useEffect(() => {
    setData(storybooks);
  }, [storybooks]);

  return (
    <>
      {data?.map((sb) => {
        const isInTrash = trashList?.includes(sb.id);
        const deleteMark = isInTrash && searchMode && !isInTrashKey;
        return (
          <div className="thumbnail-container" key={sb.id}>
            <SBThumbnail
              storybook={sb}
              onSelect={onSelect}
              onView={onView}
              selected={!!selectedStorybooks?.includes(sb.id)}
              onDelete={onDelete}
              loading={(loadingStatus?.[sb.id] === LoadingStatus.LOADING) || isLoadingFolder(sb.id)}
              canModify={canModify(sb.createdBy)}
              onClickMenu={(canEdit) => onClickMenu(sb, canEdit)}
              onRestore={onRestore}
              isInTrash={isInTrash}
              isFavorited={favoriteList?.includes(sb.id)}
              deleteMark={deleteMark}
              isBrowseStorybookInMeeting={isBrowseStorybookInMeeting}
              selectedStorybooks={selectedStorybooks}
              selectStorybook={selectStorybook}
              isDraggable={isDraggable}
            />
          </div>
        );
      })}

      <style jsx>{`
        .stats {
          border-top: 1px solid #ccc;
          font-size: 0.8em;
          padding-top: 10px;
          margin-bottom: 10px;
          margin-top: 10px;
          line-height: 1.4;
        }
      `}</style>
    </>
  );
};

export default SBSearchItems;
