import React from 'react';
import { Spinner } from 'react-bootstrap';
import { logClickButton } from '../../../../analytics';
import { useCollaborations } from '../../../../components/hooks/collaborator';
import { IStorybook } from '../../../../interfaces';
import { StorybookAccessType } from '../../../utils/storybook';
import SBChangePasscode from './SBChangePasscode';
import SBPresentButton from './SBPresentButton';
import SBPublishButton from './SBPublishButton';
import SBShareButton from './SBShareButton';

const SBThumbnailMenu = ({
  viewOnly,
  storybook,
  onDelete,
  onEdit,
  onShare,
  onReload,
  onClose,
  onToggleFavorite,
  onSettingPasscode,
  onView,
  onAddToFolder,
  onInsight,
  isFavorited,
  isCollaboration,
  onShareWithOptions,
  onTransferOwnership
}: {
  viewOnly?: boolean;
  storybook: IStorybook;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  onShare?: (arg: { url: string; message: string; id: string }) => void;
  onReload?: (fresh?: boolean) => void;
  onClose: () => void;
  onToggleFavorite: (id: string, add: boolean) => void;
  onSettingPasscode: (arg: {
    type?: StorybookAccessType;
    storybook: IStorybook;
    editMode?: boolean;
  }) => void;
  onView: (id: string) => void;
  onAddToFolder: (id: string) => void;
  onInsight: (storybook: IStorybook, fallback?: () => void) => void;
  isFavorited?: boolean;
  isCollaboration?: boolean;
  onShareWithOptions?: (storybook: IStorybook) => void;
  onTransferOwnership?: (storybook: IStorybook) => void;
}) => {
  const { stats } = storybook;

  const { removeAllCollaborators } = useCollaborations({
    itemId: storybook.id,
    context: 'storybook',
    owner: storybook.createdBy
  });

  const [loading, setLoading] = React.useState(false);
  const [inSightLoading, setIsInsightLoading] = React.useState(false);

  const btnProps = {
    className: `list-group-item list-group-item-action`
  };

  const onClick = (
    button: string,
    closeAfterClick: boolean,
    reloadAfterClick: boolean
  ) => {
    logClickButton(button);
    closeAfterClick && onClose();
    reloadAfterClick && onReload();
  };

  const handleEdit = () => {
    onEdit(storybook.id);
    onClick('Edit', true, false);
  };

  const handleDelete = () => {
    setLoading(true);
    const fallback = () => {
      setLoading(false);
      onDelete(storybook.id);
      onClick('Delete', true, false);
      isCollaboration && onReload();
    };
    removeAllCollaborators({
      itemId: storybook.id,
      onSuccess: fallback,
      onFailed: fallback
    });
  };

  const handlePreview = () => {
    onView(storybook.id);
    onClick('Preview', true, false);
  };

  const favoriteLabel = isFavorited
    ? 'Remove from Favorites'
    : 'Add to Favorites';

  const handleToggleFavorite = () => {
    onToggleFavorite(storybook.id, !isFavorited);
    onClick(favoriteLabel, true, false);
  };

  const handleSettingPasscode = (type: StorybookAccessType) => {
    onSettingPasscode({ type, storybook, editMode: true });
    onClick('Passcode Setting', true, false);
  };

  const handleShareWithOptions = () => {
    onShareWithOptions(storybook);
    onClick('Share with options', true, false);
    onClose();
  };

  const handleInsight = () => {
    setIsInsightLoading(true);
    const fallback = () => {
      setIsInsightLoading(false);
      onClick('Insight', true, false);
    };
    onInsight(storybook, fallback);
  };
  return (
    <>
      <div className="control-menu text-left">
        {!viewOnly && (
          <button {...btnProps} onClick={handleToggleFavorite}>
            {favoriteLabel}
          </button>
        )}

        {(!viewOnly || isCollaboration) && (
          <button {...btnProps} onClick={handleEdit}>
            Edit
          </button>
        )}
        {!isCollaboration && (
          <button {...btnProps} onClick={handleInsight}>
            {inSightLoading ? (
              <Spinner size="sm" animation="border" />
            ) : (
              'Insight'
            )}
          </button>
        )}
        <button {...btnProps} onClick={handlePreview}>
          Preview
        </button>
        {!isCollaboration && (
          <button
            {...btnProps}
            onClick={() => {
              onAddToFolder(storybook.id);
              onClose();
            }}
          >
            Move to Folder
          </button>
        )}

        {stats?.isPublished && onShare && (
          <SBShareButton
            storybook={storybook}
            onShare={(s) => {
              onShare(s);
              onClose();
            }}
          />
        )}

        {stats?.isPublished && (
          <button {...btnProps} onClick={handleShareWithOptions}>
            {onShare ? 'Share with Options' : 'Share'}
          </button>
        )}

        {!viewOnly && (
          <button
            {...btnProps}
            onClick={() => {
              onTransferOwnership(storybook);
              onClose();
            }}
          >
            Transfer Ownership
          </button>
        )}
        <SBPresentButton
          storybook={storybook}
          onPresent={(s) => {
            setTimeout(() => {
              window.open(s.url, '_blank');
            });
            onClose();
          }}
        />

        {stats?.isPublished && !viewOnly && (
          <SBChangePasscode
            storybook={storybook}
            onSettingPasscode={handleSettingPasscode}
          />
        )}

        {stats?.canPublish && !viewOnly && (
          <SBPublishButton
            storybook={storybook}
            onClickPublish={() => {
              onClick('Publish', true, true);
              onSettingPasscode({ storybook, editMode: false });
            }}
            unpublishCallback={() => onClick('Unpublish', true, true)}
          />
        )}
        {!viewOnly && (
          <button {...btnProps} onClick={handleDelete}>
            {loading ? <Spinner size="sm" animation="border" /> : 'Delete'}
          </button>
        )}
      </div>
      <div></div>
    </>
  );
};

export default SBThumbnailMenu;
