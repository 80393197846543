import React, { useState } from 'react';

const PhoneMockup = ({
  children,
  landscape
}: {
  children: React.ReactNode;
  landscape?: boolean;
}) => {

  const [mockupWidth, setMockupWidth] = useState(null)

  return (
    <div
      className={`PhoneMockup ${landscape ? 'landscape' : 'portrait'}`}
      style={{ width: mockupWidth ? mockupWidth : 'fit-content' }}
    >
      {landscape ? (
        <img src="/asset/iphone-landscape.png" />
      ) : (
        <img
          onLoad={async (e) => {
            const image = e.target as HTMLImageElement;
            setMockupWidth(image.width);
          }}
          src="/asset/iphone-portrait.png"
        />
      )}
      <div className="screen">
        <div className="notch" />
        <div className="browser">{children}</div>
      </div>
      <style jsx>{`
        .PhoneMockup {
          position: relative;
          margin: auto;
          width: fit-content;
        }
        .screen {
          z-index: 1;
          overflow: hidden;
          position: absolute;
          left: 6.6%;
          top: 2.9%;
          right: 6.5%;
          height: calc(100% - 2.9% - 2.8%);
        }
        .notch {
          height: 4%;
          background: #000;
        }

        .browser {
          height: 96%;
          width: 100%;
          position: relative;
        }

        img {
          position: relative;
          pointer-events: none;
          z-index: 2;
        }

        .portrait {
          height: 100%;
          max-height: min(96%, 800px);
          position: relative;
        }

        .portrait img {
          height: 100%;
          width: auto;
        }

        .landscape {
          width: 100%;
          max-width: min(90%, 800px);
          height: fit-content;
        }

        .landscape .screen {
          top: 6.4%;
          bottom: 6.4%;
          left: 2.5%;
          right: 2.5%;
        }

        .landscape img {
          width: 100%;
          height: auto;
        }
        .landscape .notch {
          display: none;
        }
        .landscape .browser {
          height: 100%;
        }

        @-moz-document url-prefix() {
          :global(.thumbnail-container .thumbnail):last-child {
            margin-right: calc(${mockupWidth/2}px - 75px) !important;
          }
          .PhoneMockup {
            width: ${mockupWidth ? mockupWidth : 'fit-content'}px !important;
          }
        }

      `}</style>
    </div>
  );
};

export default PhoneMockup;
