
export const SAVING_SCENE = 'SAVING_SCENE';
export const SAVING_SCENE_SUCCESSFUL = 'SAVING_SCENE_SUCCESSFUL';
export const SAVING_SCENE_FAILED = 'SAVING_SCENE_FAILED';

export const UPDATING_SCENE_ATTRIBUTES = 'UPDATING_SCENE_ATTRIBUTES';
export const UPDATING_SCENE_ATTRIBUTES_SUCCESSFUL = 'UPDATING_SCENE_ATTRIBUTES_SUCCESSFUL';
export const UPDATING_SCENE_ATTRIBUTES_FAILED = 'UPDATING_SCENE_ATTRIBUTES_FAILED';


export const LOADING_SCENE = 'LOADING_SCENE';
export const LOADING_SCENE_SUCCESSFUL = 'LOADING_SCENE_SUCCESSFUL';
export const LOADING_SCENE_FAILED = 'LOADING_SCENE_FAILED';

export const LOADING_SCENES = 'LOADING_SCENES';
export const LOADING_SCENES_SUCCESSFUL = 'LOADING_SCENES_SUCCESSFUL';
export const LOADING_SCENES_FAILED = 'LOADING_SCENES_FAILED';

export const LOADING_SCENE_FILES = 'LOADING_SCENE_FILES';
export const LOADING_SCENE_FILES_SUCCESSFUL =
  'LOADING_SCENE_FILES_SUCCESSFUL';
export const LOADING_SCENE_FILES_FAILED = 'LOADING_SCENE_FILES_FAILED';

export const ACTIVATING_SCENE = 'ACTIVATING_SCENE';
export const ACTIVATING_SCENE_SUCCESSFUL =
  'ACTIVATING_SCENE_SUCCESSFUL';
export const ACTIVATING_SCENE_FAILED = 'ACTIVATING_SCENE_FAILED';

export const DELETING_SCENE = 'DELETING_SCENE';
export const DELETING_SCENE_SUCCESSFUL = 'DELETING_SCENE_SUCCESSFUL';
export const DELETING_SCENE_FAILED = 'DELETING_SCENE_FAILED';

export const DID_REMOVE_SCENE_FROM_THUMBNAIL = 'DID_REMOVE_SCENE_FROM_THUMBNAIL';
export const DID_SELECT_SCENE_FROM_THUMBNAIL = 'DID_SELECT_SCENE_FROM_THUMBNAIL';