import React from 'react';
import PageEditor from './SBPageEditor';
import { StoryBookContext } from '../SBContextContainer';
import { getSBPageSetting } from '../../../utils/storybook';
import SBPopupWrapper from '../Common/SBPopupWrapper';
import { SBActionTarget } from '../SBActionHandler';

const SBEnlargedPage = React.memo(() => {
  const { action, state, entitlement, selectedStorybookPage } =
    React.useContext(StoryBookContext);
  return (
    <SBPopupWrapper
      onClose={() => action.openStorybookPage(null)}
      width="90%"
      height="100%"
      maxWidth="1700px"
      maxHeight="80vh"
    >
      <div className="popup-container w-100 h-100 d-flex">
        <PageEditor
          page={selectedStorybookPage}
          pageNumber={state.currentPageIndex + 1}
          onDelete={() => {
            action.delete(SBActionTarget.StorybookPage, state.currentPageIndex);
            action.openStorybookPage(null);
          }}
          onUpdate={(update) =>
            action.update(SBActionTarget.StorybookPage, {
              index: state.currentPageIndex,
              page: update
            })
          }
          enlargeMode={true}
          allowEdit={entitlement.allowEdit}
          settings={getSBPageSetting(
            selectedStorybookPage.settings,
            state.storybook.settings
          )}
        />
      </div>
    </SBPopupWrapper>
  );
});

export default SBEnlargedPage;
