import { useSelector } from 'react-redux';
import {
  FileViewerLayout,
  IStorybookFileState,
  IMainState,
  MeetingRole
} from '../../interfaces';
import { TLanguage } from '../../mappers/polotno';

export const useFileViewerState = (): IStorybookFileState => {
  return useSelector((state: IMainState) => state.clientState.fileViewer || {});
};

export interface FileViewerProps extends IStorybookFileState {
  hiddenPages: number[];
  thumbnails: string[];
  pagesSetting: string[];
  viewOnly?: boolean;
  className?: string;
  pages: string[];
  children?: React.ReactNode;
  silences?: boolean[];
  portraitContent?: boolean;
  initPage?: number;
  canControl?: boolean;
  activateLanguage?: TLanguage;
}
export const useFileView = (): FileViewerProps => {
  const meeting = useSelector(
    (state: IMainState) => state.clientState.meeting || {}
  );
  const state = useSelector(
    (state: IMainState) => state.clientState.fileViewer || {}
  );
  const files = state?.files || [],
    activeFile = state?.activeFile,
    playerControl = state?.playerControl || {},
    currentPage = state?.currentPage,
    nextUrl = state?.nextUrl,
    prevUrl = state?.prevUrl,
    layoutMode = state?.layoutMode || FileViewerLayout.NORMAL,
    controller = state?.controller;
  const canControl = [
    MeetingRole.ADVISOR,
    MeetingRole.MC,
    MeetingRole.CO_ADVISOR
  ].includes(meeting.localUser?.role);
  let currentFile = files.find((file) => file.id === activeFile);
  if (!currentFile && state?.sbFromMeeting) {
    currentFile = state?.sbFromMeeting;
  }
  const pages = currentFile?.pages || [];
  const thumbnails = currentFile?.thumbnails || [];
  const hiddenPages = currentFile?.hiddenPages || [];
  const pagesSetting = currentFile?.pagesSetting || [];
  const silences = currentFile?.silences || [];
  const viewOnly = controller !== meeting.localUser?.participantId;
  return {
    activeFile,
    playerControl,
    layoutMode,
    hiddenPages,
    currentPage,
    nextUrl,
    prevUrl,
    thumbnails,
    pages,
    pagesSetting,
    silences,
    viewOnly,
    canControl
  };
};
