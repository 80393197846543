import React, { useEffect, useMemo, useState } from 'react';
import { StoryBookContext } from '../SBContextContainer';
import SBAnalyticsRough from './SBAnalyticsRough';
import { RiLayoutGridFill } from 'react-icons/ri';
import { BsChevronRight } from 'react-icons/bs';
import SBAnalyticsTabs from './SBAnalyticsTabs';
import SBAnalyticsOverview from './SBAnalyticsOverview';
import SBAnalyticsScenes from './SBAnalyticsScenes';
import SBAnalyticsInteractions from './SBAnalyticsInteractions';
import SBAnalyticsSceneDetails from './SBAnalyticsSceneDetails';
import SBAnalyticsInteractionsDetails from './SBAnalyticsInteractionsDetails';
import Dropdown from 'react-bootstrap/Dropdown';
import DateRange from '../../../../components/Common/DateRange';
import SearchInput from '../../Common/SearchInput';
import moment from 'moment';
import { getThumbnailFromPage } from './helpers';

function SBAnalytics({ setIsInsight }) {
  const { state } = React.useContext(StoryBookContext);
  const storybook = state.storybook || {};

  const [breadcrumbs, setBreadcrumbs] = useState(
    storybook?.title
      ? [
          `${storybook.title}${
            storybook.subtitle ? ` - ${storybook.subtitle}` : ''
          }`
        ]
      : []
  );

  const tabs = [
    { id: 'overview', label: 'Overview' },
    { id: 'scenes', label: 'Scenes' },
    { id: 'interactions', label: 'Interactions' }
  ];

  const campaignOptions = useMemo(() => {
    const campaigns = state.sbInsightCampaigns?.data || [];
    return [
      {
        value: '',
        label: 'All campaigns'
      },
      ...campaigns.map((c) => ({
        value: c,
        label: c
      }))
    ];
  }, [state.sbInsightCampaigns?.data]);

  const dateRangeOptions = [7, 28, 90, 365];
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [listItemTab, setListItemTab] = useState([]);
  const [dateRange, setDateRange] = useState(dateRangeOptions[0]);
  const [campaign, setCampaign] = useState(campaignOptions[0]);
  const [customStartDate, setCustomStartDate] = useState<number | null>(null);
  const [customEndDate, setCustomEndDate] = useState<number | null>(null);
  const [campaignSearch, setCampaignSearch] = useState<string>('');

  const storybookPages = useMemo(() => {
    if (!storybook?.pages) return [];
    return storybook.pages.map((page) => {
      const thumbnail = getThumbnailFromPage(page);
      return {
        name: page.name,
        scene: page.id,
        thumbnail: thumbnail,
        type: 'scene'
      };
    });
  }, [storybook.id]);

  const handleSelectItem = (item) => {
    if (!item) {
      setSelectedItem(null);
      setListItemTab([]);
      return;
    }
    if (item.type === 'scene') {
      const page = storybookPages.find((page) => page.scene === item.scene);
      setSelectedItem(page);
      setListItemTab(storybookPages);
    }
  };

  useEffect(() => {
    if (selectedItem)
      setBreadcrumbs([
        ...(storybook?.title
          ? [
              `${storybook.title}${
                storybook.subtitle ? ` - ${storybook.subtitle}` : ''
              }`
            ]
          : []),
        selectedItem.name || selectedItem.scene
      ]);
    else
      setBreadcrumbs([
        ...(storybook?.title
          ? [
              `${storybook.title}${
                storybook.subtitle ? ` - ${storybook.subtitle}` : ''
              }`
            ]
          : [])
      ]);
  }, [selectedItem]);

  if (!storybook) return <div>Empty</div>;

  const onClickBreadcrumbs = (index) => {
    switch (index) {
      case 0:
        setIsInsight(false);
        break;

      case 1:
        setSelectedItem(null);
        setListItemTab([]);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <div className="analytics">
        <div className="analytics-header">
          <div className="title">Analytics</div>
          <div className="analytics-header-bar">
            <div className="header-breadcrumbs">
              <div
                className="breadcrumbs__item"
                onClick={() => onClickBreadcrumbs(0)}
              >
                <RiLayoutGridFill style={{ width: 20, height: 20 }} />
                Storybook
              </div>
              {breadcrumbs.map((bc, index) => (
                <div
                  className="breadcrumbs__item"
                  key={index}
                  onClick={() => onClickBreadcrumbs(index + 1)}
                >
                  <BsChevronRight />
                  <div className="text-ellipsis">{bc}</div>
                </div>
              ))}
            </div>
            <div className="analytics-header__actions">
              <Dropdown className="analytics-dropdown">
                <Dropdown.Toggle id="dropdown-campaigns">
                  {campaign?.label}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <label>Show:</label>
                  <SearchInput
                    onSearch={setCampaignSearch}
                    searchOnTyping={true}
                    placeHolder={'Search'}
                    defaultKeyword={campaignSearch || ''}
                    clear={false}
                  />
                  {campaignOptions
                    .filter((opt) =>
                      opt.label
                        .trim()
                        .toLocaleLowerCase()
                        .includes(campaignSearch.trim().toLocaleLowerCase())
                    )
                    .map((campaign) => (
                      <Dropdown.Item
                        key={campaign.value}
                        onClick={() => setCampaign(campaign)}
                      >
                        {campaign.label}
                      </Dropdown.Item>
                    ))}
                </Dropdown.Menu>
              </Dropdown>
              <div
                className={`dateRange ${
                  dateRange === -1 ? 'dateRange__custom' : ''
                }`}
              >
                <Dropdown className="analytics-dropdown">
                  <Dropdown.Toggle id="dropdown-date-range">
                    {dateRange == -1 ? 'Custom' : `Last ${dateRange} Days`}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <label>Show:</label>
                    {dateRangeOptions.map((range) => {
                      return (
                        <Dropdown.Item
                          key={range}
                          onClick={() => setDateRange(range)}
                        >
                          Last {range} Days
                        </Dropdown.Item>
                      );
                    })}
                    <Dropdown.Item
                      onClick={() => {
                        setDateRange(-1);
                        const currentDate = new Date();
                        const startDate = new Date(currentDate);
                        startDate.setDate(currentDate.getDate() - 7);
                        setCustomStartDate(startDate.getTime());
                        setCustomEndDate(currentDate.getTime());
                      }}
                    >
                      Custom
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {dateRange === -1 && (
                  <DateRange
                    startDate={customStartDate}
                    endDate={customEndDate}
                    onDateChange={(start, end) => {
                      setCustomStartDate(start);
                      setCustomEndDate(end);
                    }}
                    defaultOpen={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="analytics-main">
          <div className={`content-col side-col`}>
            <SBAnalyticsRough
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              storybook={storybook}
              listItemTab={listItemTab}
              setListItemTab={setListItemTab}
            />
          </div>
          <div className="content-col main-col">
            {selectedItem ? (
              <div className="SBAnalytics h-100">
                {selectedItem?.type === 'scene' ? (
                  <SBAnalyticsSceneDetails
                    item={selectedItem}
                    campaign={campaign.value}
                    startDate={
                      customStartDate &&
                      moment(customStartDate).format('YYYY-MM-DD')
                    }
                    endDate={
                      customEndDate &&
                      moment(customEndDate).format('YYYY-MM-DD')
                    }
                    range={dateRange}
                  />
                ) : (
                  <SBAnalyticsInteractionsDetails item={selectedItem} />
                )}
              </div>
            ) : (
              <div className="SBAnalytics h-100">
                <SBAnalyticsTabs
                  active={activeTab}
                  tabs={tabs}
                  onSelectTab={setActiveTab}
                />
                <div className="SBAnalytics-content">
                  {activeTab.id === 'overview' ? (
                    <SBAnalyticsOverview
                      range={dateRange}
                      campaign={campaign.value}
                      startDate={
                        customStartDate &&
                        moment(customStartDate).format('YYYY-MM-DD')
                      }
                      endDate={
                        customEndDate &&
                        moment(customEndDate).format('YYYY-MM-DD')
                      }
                      setSelectedItem={handleSelectItem}
                      setListItemTab={setListItemTab}
                    />
                  ) : activeTab.id === 'scenes' ? (
                    <SBAnalyticsScenes
                      setSelectedItem={handleSelectItem}
                      range={dateRange}
                      startDate={
                        customStartDate &&
                        moment(customStartDate).format('YYYY-MM-DD')
                      }
                      endDate={
                        customEndDate &&
                        moment(customEndDate).format('YYYY-MM-DD')
                      }
                      campaign={campaign.value}
                    />
                  ) : activeTab.id === 'interactions' ? (
                    <SBAnalyticsInteractions
                      setSelectedItem={handleSelectItem}
                      // setListItemTab={setListItemTab}
                      range={dateRange}
                      startDate={
                        customStartDate &&
                        moment(customStartDate).format('YYYY-MM-DD')
                      }
                      endDate={
                        customEndDate &&
                        moment(customEndDate).format('YYYY-MM-DD')
                      }
                      campaign={campaign.value}
                    />
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        .analytics-main {
          display: flex;
        }

        .content-col {
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          padding: 20px;
        }

        .text-ellipsis {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 400px;
        }
        .main-col {
          height: 100%;
          width: calc(100% - 230px);
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-content: baseline;
          position: relative;
          padding: 0;
        }

        .side-col {
          width: 270px;
          min-width: 270px;
          transition: min-width 0.1s;
          position: relative;
          padding: 0;
          background: #fdfdfd;
        }

        .analytics-header {
          display: flex;
          border-bottom: 1px solid #d8d8d8;
          min-height: 60px;
          justify-content: space-between;
        }

        .analytics-header .title {
          color: var(--Black, #000);
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          width: 270px;
          padding: 15px 20px;
        }
        .analytics-header-bar {
          display: flex;
          justify-content: space-between;
          width: calc(100% - 270px);
        }

        .header-breadcrumbs {
          padding: 0 20px;
          color: var(--Black, #000);
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
        }

        .breadcrumbs__item {
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .breadcrumbs__item:hover {
          text-decoration: underline;
        }

        :global(.analytics-header-bar .breadcrumbs__item svg) {
          margin-right: 7px;
        }

        :global(.analytics-dropdown button) {
          background: transparent !important;
          color: #707070 !important;
          font-size: 12px;
          border: transparent;
          box-shadow: none !important;
          min-width: 8rem;
        }

        :global(.analytics-dropdown button::after) {
          margin-left: 15px;
        }

        :global(.analytics-dropdown) {
          display: flex;
          align-items: center;
        }

        :global(.analytics-dropdown .dropdown-menu) {
          font-size: 12px;
          color: #666;
          font-weight: 600;
          min-width: 8rem;
          border-radius: 20px;
          background: #fff;
          box-shadow: 0px 2.679px 6.698px 0px rgba(0, 0, 0, 0.25);
          overflow: hidden;
        }

        :global(.analytics-dropdown .dropdown-menu label) {
          color: #c1c1c1;
          padding: 0.25rem 1rem;
          margin: 0;
        }

        :global(.analytics-dropdown .dropdown-menu .dropdown-item) {
          padding: 0.25rem 1rem;
        }

        .action {
          display: flex;
          align-items: center;
          height: 50px;
          padding: 10px;
        }
        .btn-back {
          font-size: 14px;
        }
        :global(.SBAnalytics .h-100) {
          height: calc(100% - 15px) !important;
        }
        .action h5 {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-bottom: 0;
        }
        .SBAnalytics {
          position: relative;
          overflow: hidden;
          padding: 20px;
        }
        .SBAnalytics-content {
          overflow: auto;
          max-height: calc(100vh - 235px);
        }

        .row .col-4:first-child {
          padding-left: 15px;
        }
        .row .col-4:last-child {
          padding-right: 15px;
        }
        .row .col-4,
        .col-8 {
          padding-left: 5px;
          padding-right: 5px;
        }
        .sb-pages {
          max-width: 285px;
        }
        .sb-analytics-details {
          min-width: calc(100% - 285px);
        }
        .sb-view-count {
          background-color: #f3f3f3;
          border-radius: 5px;
        }
        .dateRange {
          align-items: center;
          display: flex;
        }

        .dateRange__custom {
          display: flex;
          gap: 10px;
        }

        .analytics-header__actions {
          display: flex;
          flex-wrap: wrap;
          min-width: 265px;
          padding: 3px 20px;
        }

        :global(.analytics-dropdown .SearchInput) {
          margin-bottom: 10px;
        }
        :global(.analytics-dropdown .SearchInput input) {
          font-size: 12px;
          border-top: none;
          border-left: none;
          border-right: none;
          border-radius: 0;
          margin: 0 16px;
          padding: 0;
          color: #a8a8a8;
        }

        :global(.analytics-dropdown .SearchInput .btn-search) {
          right: 0;
          left: unset;
          color: #a8a8a8;
        }

        @media (max-width: 768px) {
          .analytics-header {
            display: grid;
            grid-template-columns: 1fr;
            height: auto;
          }
          .title,
          .analytics-header-bar {
            width: 100% !important;
          }

          .analytics-header-bar {
            display: block;
          }

          .title {
            margin-bottom: 1rem;
          }
          .analytics-main {
            display: block;
          }
          .side-col,
          .main-col {
            width: 100%;
          }
          :global(.analytics-rough__main) {
            max-height: unset;
          }
          :global(.analytics-rough__main .cards) {
            display: flex;
            flex-wrap: wrap;
            gap: 15px;
          }
          :global(.analytics-rough__main .cards .card-basic) {
            min-width: 150px;
          }
          :global(.SBAnalytics-content) {
            max-height: unset !important;
          }
          :global(.item-list__container) {
            flex-direction: row !important;
          }
          :global(.analytics-main) {
            max-height: unset !important;
          }
        }
      `}</style>
      <style jsx global>{`
        .area-custom-tooltip {
          padding: 10px;
          font-size: 12px;
          border: 1px solid #8f6599;
          overflow: hidden;
          border-radius: 5px;
        }
        .area-custom-tooltip .header .date {
          color: #615e83;
          margin-bottom: 6px;
        }
        .area-custom-tooltip .header .tab {
          font-weight: 700;
          text-transform: capitalize;
          margin-bottom: 6px;
        }
        .area-custom-tooltip ul {
          padding: 0;
          margin: 0;
          list-style: none;
        }
        .area-custom-tooltip ul li {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 3px 0;
          gap: 20px;
        }
        .area-custom-tooltip li .tooltip-label {
          display: flex;
          align-items: center;
        }
        .area-custom-tooltip li .tooltip-label span.color {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 5px;
        }
        .area-custom-tooltip li .tooltip-value {
          font-weight: 700;
          font-size: 16px;
          color: #4c2d5a;
        }
        :global(.custom-interaction) {
          display: flex;
          gap: 5px;
        }
        :global(.custom-interaction .value) {
          display: flex;
          flex-direction: column;
        }
        :global(.custom-interaction .value .type) {
          position: relative;
          padding-left: 15px;
          text-transform: capitalize;
          font-size: 12px;
          font-weight: 600;
        }
        :global(.custom-interaction .value .type::before) {
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: currentColor;
          top: 50%;
          transform: translateY(-50%);
          left: 0px;
        }

        .analytics .scenes_order {
          display: flex;
          align-items: center;
        }
        .analytics .scenes_order span {
          display: inline-block;
          width: 20px;
        }
      `}</style>
    </>
  );
}

export default SBAnalytics;
