import React from 'react';
import { StoryBookContext } from '../SBContextContainer';
import ConfirmationPopup from '../../Common/ConfirmationPopup';
import { IconArrow } from '../../Common/HubIcons';
import {
  IStorybook,
  IStorybookVisibility,
  LoadingStatus
} from '../../../../interfaces';
import { Spinner } from 'react-bootstrap';
import SBPublishPopup from './SBPublishPopup';
import {
  getLanguageJSON,
  getSBVersionForDropdown,
  hasDesignedFeaturedImage,
  setImageAsFeaturedImage
} from '../../../utils/storybook';
import { SBActionTarget } from '../SBActionHandler';
import { logEvent } from '../../../../analytics';
import { DID_CLICK_BUTTON } from '../../../../utils/constants';
import { AiFillCheckCircle } from 'react-icons/ai';
import SBBuilderTitle from './SBBuilderTitle';
import SBTitleEditor from './SBTitleEditor';
import VersionsDropdown from '../../Common/VersionsDropdown';
import ButtonWithConfirmation from '../../Common/ButtonWithConfirmation';
import { useUploadFile } from '../../../../components/hooks/fileUpload';
import CollaboratorButton from '../../Common/Collaborators/CollaboratorButton';
import { useCollaborations } from '../../../../components/hooks/collaborator';
import { appendNewValueIntoSetting } from '../../../../mappers/storybook';

let saveTimeout;

const SBBuilderActions = React.memo(() => {
  const { clearFileUpload, uploadFileList } = useUploadFile();

  const uploadInProgress = uploadFileList.length > 0;
  const { action, state, entitlement, visibility } =
    React.useContext(StoryBookContext);

  const [loadingOnDelay, setLoadingOnDelay] = React.useState(null);
  const [loadingOnSetFeaturedImage, setLoadingOnSetFeaturedImage] =
    React.useState(false);

  const { allowSaveDraft, allowPublish } = entitlement;
  const { loadingStatus, storybook } = state;

  const loadingSaving = loadingStatus[state.storybook.id];
  const loadingActivating = loadingStatus[state.storybook.createdBy];

  const [publishPopup, setPublishPopup] = React.useState(false);
  const [confirmation, setConfirmation] = React.useState<{ onOk: () => void }>(
    null
  );
  const isNew = !storybook?.status;

  const { collaborators, checkForConflict } = useCollaborations({
    itemId: storybook.id,
    context: 'storybook',
    owner: storybook.createdBy
  });

  const isCollaborated = collaborators.length > 0;

  const [showTitlePopup, setShowTitlePopup] = React.useState(
    !storybook?.status
  );

  const logClickButton = (name: string) => {
    logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, { button: name });
  };
  const handleSelectVersion = (version: number) => {
    logClickButton(`Select version: ${version}`);
    const readVersion = () =>
      action.read(SBActionTarget.StorybookOfVersion, {
        id: storybook.id,
        version
      });
    if (entitlement.allowSaveDraft) {
      action.save(SBActionTarget.Storybook, readVersion);
    } else {
      readVersion();
    }
  };

  const shouldSaveBeforeLeave = allowSaveDraft || state.undoRedo.canRedo;

  const handleSetFeaturedImage = () => {
    setLoadingOnSetFeaturedImage(true);
    const firstPageThumbnail = getLanguageJSON(storybook.pages[0].thumbnail)[
      'en'
    ];
    setImageAsFeaturedImage(firstPageThumbnail, storybook.id)
      .then((res) => {
        action.update(SBActionTarget.Storybook, {
          data: {
            featuredImage: `${res}?ts=${Date.now()}`,
            settings: appendNewValueIntoSetting(storybook.settings, {
              featuredImage: firstPageThumbnail
            })
          },
          append: true
        });
      })
      .finally(() => {
        setPublishPopup(true);
        setLoadingOnSetFeaturedImage(false);
      });
  };

  const handleActivateStorybook = ({
    visibility,
    visibilityScope
  }: {
    visibility: IStorybookVisibility;
    visibilityScope: string[];
  }) => {
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }

    const activate = () => {
      action.save(
        SBActionTarget.StorybookAttributes,
        () => {
          setLoadingOnDelay(LoadingStatus.LOADING);
          setTimeout(() => {
            setLoadingOnDelay(LoadingStatus.LOADED);
            action.save(SBActionTarget.ActivatedStorybook);
          }, 2000);
        },
        {
          id: storybook.id,
          visibility,
          visibilityScope,
          tags: storybook.tags,
          createdBy: storybook.createdBy
        }
      );
    };

    if (shouldSaveBeforeLeave) {
      action.save(SBActionTarget.Storybook, activate);
    } else {
      activate();
    }
  };

  const handleSaveStorybook = (reloadAfterSave?: boolean) => {
    action.save(
      SBActionTarget.Storybook,
      reloadAfterSave
        ? () => action.read(SBActionTarget.Storybook, { id: storybook.id })
        : undefined
    );
  };

  const onCheckConflict = (remoteItem: IStorybook) => {
    checkForConflict({
      remoteItem,
      localItem: storybook,
      onApproveRemoteItem: () =>
        action.update(SBActionTarget.Storybook, {
          data: remoteItem,
          append: false,
          doNotFlagChange: true
        }),
      onApproveLocalItem: () => handleSaveStorybook(isNew)
    });
  };

  const handleSaveCollaboratedStorybook = () => {
    action.read(
      SBActionTarget.Storybook,
      { id: storybook.id, silent: true },
      onCheckConflict
    );
  };

  const onClickBack = (component: string) => {
    logClickButton(component);

    const navigate = () => {
      action.navigate();
      clearFileUpload();
    };
    const performBack = () => {
      if (shouldSaveBeforeLeave) {
        action.save(SBActionTarget.Storybook, () => {
          navigate();
        });
      } else {
        navigate();
      }
    };
    if (uploadInProgress) {
      setConfirmation({ onOk: performBack });
    } else {
      performBack();
    }
  };

  React.useEffect(() => {
    const clearSaveTimeout = () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
    if (allowSaveDraft) {
      clearSaveTimeout();
      saveTimeout = setTimeout(
        () => {
          if (isCollaborated) {
            handleSaveCollaboratedStorybook();
          } else {
            handleSaveStorybook(isNew);
          }
        },
        isNew ? 0 : 3000
      );
    }

    return () => {
      clearSaveTimeout();
    };
  }, [storybook, allowSaveDraft]);

  const allVersions = getSBVersionForDropdown(storybook.availableVersions);

  const hasDraftInVersion =
    allVersions.length > 1 &&
    storybook.availableVersions?.some((v) => v.status === 'draft');

  const autoSaveStatus = visibility.showPublishButton && (
    <div className={`btn text-success btn-sm btn-save`}>
      {loadingSaving === LoadingStatus.LOADING ? (
        <Spinner animation="border" size="sm" />
      ) : (
        <>
          <AiFillCheckCircle size={20} /> <strong>Auto Save</strong>
        </>
      )}
      <style jsx>{`
        .btn {
          cursor: initial;
        }
      `}</style>
    </div>
  );

  return (
    <>
      <div className="SBBuilderActions d-flex w-100">
        <div className="col-side d-flex" style={{ alignItems: 'center' }}>
          <button
            className="btn btn-sm btn-back"
            onClick={() => {
              onClickBack('Back as arrow');
            }}
          >
            <IconArrow direction="left" />
          </button>
          <h2
            onClick={() => {
              onClickBack('Back as title');
            }}
          >
            Storybook Builder
          </h2>
        </div>
        <div className="flex-grow-1 col-center">
          <div className="action-group action-title flex-grow-1">
            <SBBuilderTitle
              onUpdate={
                entitlement.allowEdit
                  ? (data) =>
                      action.update(SBActionTarget.Storybook, {
                        data,
                        append: true
                      })
                  : undefined
              }
              storybook={storybook}
            />
          </div>
        </div>
        <div className="text-right">
          <div className="action-group">
            {autoSaveStatus}{' '}
            <CollaboratorButton
              itemId={state?.storybook?.id}
              context="storybook"
              owner={state?.storybook?.createdBy}
            />
            {storybook?.version && (
              <VersionsDropdown
                versions={allVersions}
                value={storybook?.version}
                onSelect={handleSelectVersion}
              />
            )}
            <span className="action-group btn-edit-publish">
              {(storybook?.status === 'published' ||
                storybook?.status === 'activated') && (
                <ButtonWithConfirmation
                  shouldShowConfirmation={hasDraftInVersion}
                  onClick={() => {
                    handleSaveStorybook(true);
                  }}
                  minWidth="100px"
                  message="You have existing draft version of this storybook. Do you want to
                override?"
                  loading={loadingSaving === LoadingStatus.LOADING}
                  label="Edit"
                />
              )}

              {visibility.showPublishButton && (
                <button
                  className="btn btn-round btn-dark btn-sm btn-publish"
                  onClick={() => {
                    if (hasDesignedFeaturedImage(storybook)) {
                      setPublishPopup(true);
                      allowSaveDraft && handleSaveStorybook(true);
                    } else {
                      handleSetFeaturedImage();
                    }
                    logClickButton('Activate');
                  }}
                  disabled={!allowPublish}
                >
                  {loadingOnSetFeaturedImage ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    'Activate'
                  )}
                </button>
              )}
            </span>
          </div>
        </div>

        <style jsx>{`
          .text-right {
            margin-left: auto;
            width: 550px;
          }
          .btn-edit-publish {
            margin-left: 5px;
          }
          h2 {
            cursor: pointer;
          }
          .SBBuilderActions {
            padding: 20px;
          }
          .col-center {
            display: flex;
            justify-content: space-between;
            padding: 0 0px;
          }
          .action-group :global(.btn) {
            margin-left: 10px;
          }
          .action-group :global(.btn:first-child) {
            margin-left: 0;
          }
          .status {
            text-transform: capitalize;
          }
          .save-info {
            font-size: 11px;
            font-weight: normal;
            margin-left: 10px;
          }
          .btn-round {
            padding-left: 20px;
            padding-right: 20px;
          }
          .action-title {
            width: 0;
            padding-right: 0;
          }
          .btn-publish {
            min-width: 100px;
          }
        `}</style>
      </div>

      {publishPopup && (
        <SBPublishPopup
          onClose={() => {
            setPublishPopup(false);
            setLoadingOnDelay(null);
          }}
          loading={[loadingActivating, loadingSaving, loadingOnDelay].includes(
            LoadingStatus.LOADING
          )}
          onActivate={handleActivateStorybook}
          storybook={storybook}
        />
      )}

      {confirmation && (
        <ConfirmationPopup
          onClose={() => setConfirmation(null)}
          onOk={() => confirmation.onOk?.()}
        >
          <p className="text-semibold">
            File upload is still in progress.
            <br />
            Are you sure you want to leave this page?
          </p>
        </ConfirmationPopup>
      )}
      {showTitlePopup && (
        <SBTitleEditor
          onSave={(data) =>
            action.update(SBActionTarget.Storybook, { data, append: true })
          }
          title={storybook.title}
          subTitle={storybook.subtitle}
          onClose={() => setShowTitlePopup(false)}
        />
      )}
    </>
  );
});

export default SBBuilderActions;
