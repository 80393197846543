import React, { useState } from 'react';
import {
  mapStorybookSettingsObjectToString,
  mapStorybookSettingsStringToObject
} from '../../../../mappers/storybook';
import ColorInput from '../../Common/ColorInput';
import { storyAssetBaseUrl } from '../../../../config';
import { TLanguage } from '../../../../mappers/polotno';
import { getLanguaImageFromJSON } from '../../../utils/storybook';

const SBSettings = ({
  showVideoSettings,
  onUpdate,
  setting,
  label = 'Page Settings',
  children,
  onUpdateFeaturedImage,
  featuredImage
}: {
  showVideoSettings?: boolean;
  onUpdate?: (value: string) => void;
  setting?: string;
  label?: string;
  children?: React.ReactNode;
  onUpdateFeaturedImage?: (value: string) => void;
  featuredImage?: {
    onEdit: () => void;
    url: string;
  };
}) => {
  const settingObject = mapStorybookSettingsStringToObject(setting || '');
  const disabled = !onUpdate;
  const {
    color,
    duration,
    autoplay,
    videoLoop,
    muteParticipant,
    background,
    text
  } = settingObject;

  const [featureLanguage, setFeatureLanguage] = useState<TLanguage>(
    settingObject.language || 'en'
  );

  const featuredImageUrl = getLanguaImageFromJSON(
    featuredImage?.url,
    featureLanguage
  );

  const updateSetting = (key: string, value?: string | number | boolean) => {
    console.log('Title Color', key, value);
    onUpdate(
      mapStorybookSettingsObjectToString({
        ...settingObject,
        [key]: value
      })
    );
  };

  const handleRemoveFeaturedImage = () => {
    onUpdateFeaturedImage(undefined);
  };

  const imageUpload = () => (
    <>
      <div className="imageUpload">
        <label className="text-semibold">Featured Image</label>

        {featuredImage?.url && (
          <div className="preview">
            <img
              src={`${storyAssetBaseUrl}/${featuredImageUrl}`}
              alt="preview"
            />
            {onUpdate && (
              <div className="preview-action">
                <div className="btns">
                  <button
                    className="btn btn-sm btn-light btn-round w-100 btn-feature btn-change"
                    onClick={featuredImage?.onEdit}
                    disabled={disabled}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-sm btn-danger btn-round w-100 btn-feature btn-remove"
                    onClick={(e) => {
                      e.preventDefault();
                      e.nativeEvent?.stopImmediatePropagation();
                      handleRemoveFeaturedImage();
                    }}
                    disabled={disabled}
                  >
                    Remove
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        {featuredImage?.onEdit && !featuredImage.url && (
          <button
            className="btn btn-sm btn-outline-dark btn-round w-100 mb-3 btn-feature btn-design"
            onClick={featuredImage?.onEdit}
            disabled={disabled}
          >
            Design Featured Image
          </button>
        )}
      </div>
      <style jsx>{`
        .btn-feature {
          margin-top: 10px;
        }
        .imageUpload {
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          font-weight: normal;
          margin-bottom: 2px;
        }
        .dropzone {
          border: 3px dashed #d8d8d8;
          background: #efefef !important;
          height: 193px;
          opacity: ${disabled ? 0.3 : 1};
          pointer-events: ${disabled ? 'none' : 'auto'};
        }
        .dropzone.accepted {
          border-color: green;
          background: #edfff2 !important;
        }
        .dropzone.rejected {
          border-color: green;
        }
        .upload-error-message {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
        .dropzone .info {
          text-align: center;
          margin: 50px auto;
        }
        .file-upload-modal {
          width: 500px;
        }
        .preview {
          height: 193px;
          position: relative;
        }
        .preview-action {
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          flex-direction: column;
          align-items: center;
          display: none;
        }
        .preview:hover .preview-action {
          display: flex;
        }
        .btns {
          margin: auto;
          width: 50%;
        }

        .preview img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .loading {
          width: 90%;
          position: absolute;
          height: 193px;
          top: 11.5%;
          background: rgba(0, 0, 0, 0.6);
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
        }
      `}</style>
    </>
  );

  return (
    <div className="pageSettings">
      {label && <label>{label}</label>}
      {onUpdateFeaturedImage && imageUpload()}

      <div className="fieldset">
        <div className="d-flex">
          <div>
            <label>Title Color</label>
          </div>
          <div>
            <ColorInput
              onChange={(value) => {
                updateSetting('text', value || '');
              }}
              value={text}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      <div className="fieldset">
        <div className="d-flex">
          <div>
            {' '}
            <label>Backdrop</label>
          </div>
          <div>
            <ColorInput
              onChange={(value) => {
                updateSetting('color', value || '');
              }}
              value={color}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      <div className="fieldset">
        <div className="d-flex">
          <div>
            <label>Frame</label>
          </div>
          <div>
            <ColorInput
              onChange={(value) => {
                updateSetting('background', value || '');
              }}
              value={background}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
      <div className="fieldset">
        <div className="d-flex">
          <div>
            <label>Title Duration</label>
          </div>
          <div>
            <div className="duration-input">
              <input
                className="form-control form-control-sm"
                type="number"
                placeholder="Always appear"
                value={duration || ''}
                style={{ width: '100%' }}
                min={0}
                max={99}
                onChange={(e) => {
                  const val = Number(e.target.value);
                  updateSetting('duration', val >= 99 ? 99 : val);
                }}
                disabled={disabled}
              />
              {!!duration && (
                <span style={{ left: `${duration > 9 ? '30px' : '23px'}` }}>
                  second{duration > 1 && 's'}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {showVideoSettings && (
        <div className="fieldset video-settings">
          <label>Video Settings</label>
          <div className="settings-button">
            <button
              className={`btn btn-option btn-sm ${
                autoplay ? 'btn-success' : 'btn-outline-dark'
              }`}
              onClick={() => updateSetting('autoplay', !autoplay)}
              disabled={disabled}
            >
              Auto play
            </button>
            <button
              className={`btn btn-option btn-sm ${
                muteParticipant ? 'btn-success' : 'btn-outline-dark'
              }`}
              onClick={() => updateSetting('muteParticipant', !muteParticipant)}
              disabled={disabled}
            >
              Mute participant
            </button>
            <button
              className={`btn btn-option btn-sm loop-setting ${
                videoLoop ? 'btn-success' : 'btn-outline-dark'
              }`}
              onClick={() => {
                onUpdate(
                  mapStorybookSettingsObjectToString({
                    ...settingObject,
                    videoLoop: !videoLoop,
                    muteParticipant: !videoLoop ? false : muteParticipant
                  })
                );
              }}
              disabled={disabled}
            >
              Loop
            </button>
          </div>
        </div>
      )}
      {children}
      <style jsx>{`
        .imageUpload {
          height: 120px;
          border: 1px dashed red;
        }
        .fieldset {
          margin-bottom: 10px;
          display: block !important;
        }
        .btn-outline-dark:hover,
        .btn-outline-dark:active,
        .btn-outline-dark:focus {
          background: inherit !important;
          color: inherit !important;
        }
        label {
          margin-bottom: 10px;
        }
        .btn-sm {
          margin: 3px;
        }
        .fieldset label {
          font-weight: 500;
        }
        .fieldset .d-flex > div {
          width: 100px;
        }
        .fieldset .d-flex > div + div {
          flex-grow: 1;
        }
        .d-flex {
          align-items: center;
        }
        p {
          font-size: 14px;
          font-weight: normal;
          margin-bottom: 5px;
        }
        .duration-input {
          position: relative;
        }
        .duration-input span {
          position: absolute;
          top: 3px;
          z-index: 2;
          pointer-events: none;
          font-weight: 300;
        }
        .pageSettings :global(.form-control) {
          margin: 0 !important;
        }
        .btn-option {
          padding: 2px 4px;
          font-size: 0.75em;
        }
        .btn-select-asset-library {
          width: 100%;
          padding: 5px 0px 15px 0px;
          margin-bottom: 15px;
          border-bottom: 1px solid #c4c4c4;
        }
        .language-list {
          display: flex;
          margin-bottom: 10px;
        }
        .language-item {
          margin-right: 15px;
          display: flex;
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #fff;
          cursor: pointer;
        }
        .language-item--selected {
          border-color: #d8d8d8;
          background: #f3f3f3;
        }
        .language-item:hover {
          border-color: #d8d8d8;
          background: #f3f3f3;
        }
        .language-item span {
          margin: auto;
          font-weight: 500;
          text-transform: uppercase;
          font-size: 12px;
        }
        .language-item img {
          margin-right: 5px;
        }
      `}</style>
    </div>
  );
};

export default SBSettings;
