import React from 'react';
import { logClickButton } from '../../../../analytics';
import ToolTipWrapper from '../../../../components/Common/ToolTipWrapper';

const menus: { type: string; label: string }[] = [
  { type: 'layer', label: 'Design a Scene' },
  { type: 'video', label: 'Video Scene' },
];

const SLCreateSceneMenu = ({
  onClose,
  onSelectMenu
}: {
  onClose: () => void;
  onSelectMenu: (type: 'video' | 'image' | 'layer') => void;
}) => {
  const handleSelectMenu = (menu) => {
    logClickButton(menu.label);
    onSelectMenu(menu.type);
    onClose();
  };

  return (
    <ToolTipWrapper
      onClose={onClose}
      parentId={`create-scene`}
      width={180}
      xPosition="left"
      className="menu-create"
      hideCloseButton
    >
      <div className="control-menu text-left">
        {menus.map((menu) => (
          <button
            key={menu.type}
            className="list-group-item list-group-item-action"
            onClick={() => handleSelectMenu(menu)}
          >
            {menu.label}
          </button>
        ))}
      </div>
      <style jsx global>{`
        .menu-create {
          margin-right: 0px;
          margin-top: 20px;
        }
      `}</style>
    </ToolTipWrapper>
  );
};

export default SLCreateSceneMenu;
