import { logEvent } from '../../../../analytics';
import { IStorybook } from '../../../../interfaces';
import { DID_CLICK_EDIT } from '../../../../utils/constants';

const SBBuilderTitle = ({
  storybook,
  onUpdate
}: {
  storybook: IStorybook;
  onUpdate: (s: IStorybook) => void;
}) => {
  return (
    <div
      className="title-editor d-flex"
      onClick={() => {
        logEvent(DID_CLICK_EDIT, DID_CLICK_EDIT, { edit: 'Title' });
      }}
    >
      <input
        type="text"
        className={`form-control ssp-ignore title-input`}
        placeholder="Storybook Title"
        value={storybook?.title || ''}
        disabled={!onUpdate}
        onChange={(e) => {
          onUpdate({
            ...storybook,
            title: e.target.value
          });
        }}
      />
      <input
        type="text"
        className={`form-control ssp-ignore subtitle-input`}
        placeholder="Storybook Subtitle"
        value={storybook?.subtitle || ''}
        disabled={!onUpdate}
        onChange={(e) => {
          onUpdate({
            ...storybook,
            subtitle: e.target.value
          });
        }}
      />
      <style jsx>{`
        .title-input {
          width: 40%;
          margin: 0 10px 0 0;
          background: transparent;
          font-weight: bold;
        }
        .subtitle-input {
          width: 60%;
          background: transparent;
        }
        .title-editor {
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default SBBuilderTitle;
