import React from 'react';
import DesignerPanelHeader from '../PageDesigner/SBDesignerPanelHeader';
import SBSortablePage from '../PageDesigner/SBSortablePage';

const SBSorterContainer = () => {
  return (
    <div className="SBSorterContainer h-100 w-100">
      <DesignerPanelHeader />
      <SBSortablePage compactMode={true} />
      <style jsx>{`
        .SBSorterContainer :global(.panel-content) {
          padding: 10px 0;
        }
      `}</style>
    </div>
  );
};

export default SBSorterContainer;
