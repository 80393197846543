import React from 'react';
import { IStorybook } from '../../../../interfaces';
import { FaLink } from 'react-icons/fa';
import { getSimpleShortenedUrl } from '../../../../utils/shareUrl';
import { getBrandStorybookHost } from '../../../../config';
import { logEvent } from '../../../../analytics';
import {
  DID_SHARE_STORYBOOK,
  SHARE_STORYBOOK_FAILED
} from '../../../../utils/constants';
import { performNativeShare } from '../../../../utils/window';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { IoMdInformationCircleOutline } from 'react-icons/io';

function ShareAsCampaign({ storybook }: { storybook: IStorybook }) {
  const [form, setForm] = React.useState({
    utm_source: '',
    utm_medium: '',
    utm_campaign: ''
  });

  const [loading, setLoading] = React.useState(false);
  const [copied, setCopied] = React.useState(false);
  const [error, setError] = React.useState('');

  const logShare = (channel: string, url: string) => {
    logEvent(DID_SHARE_STORYBOOK, channel, {
      storybookId: storybook.id,
      type: 'campaign',
      url
    });
  };

  const handleFormInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setError('');
    setForm({ ...form, [id]: value });
  };

  const generateShareUrl = async (id: string, brandId: string) => {
    const host = getBrandStorybookHost(brandId);
    const { utm_source, utm_medium, utm_campaign } = form;
    const originalUrl = new URL(`${host}/storybook/${id}`);
    originalUrl.searchParams.append('utm_source', utm_source);
    originalUrl.searchParams.append('utm_medium', utm_medium);
    originalUrl.searchParams.append('utm_campaign', utm_campaign);
    const oUrl = originalUrl.toString();
    return getSimpleShortenedUrl(oUrl, host)
      .then((url) => ({
        original: oUrl,
        shortened: url
      }))
      .catch(() => ({
        original: oUrl,
        shortened: ''
      }));
  };

  const share = (url) => {
    return performNativeShare(
      {
        url: url.shortened
      },
      () => logShare('native', url),
      () => {
        logShare('copied', url.shortened);
        navigator.clipboard.writeText(url.shortened);
        setCopied(true);
      }
    );
  };

  const handleGetSharableLink = () => {
    const { utm_source, utm_medium, utm_campaign } = form;
    if (!utm_source || !utm_medium || !utm_campaign) {
      setError('Please fill in all fields');
      return;
    }
    setLoading(true);
    generateShareUrl(storybook.id, storybook.brand)
      .then(share)
      .catch((e) =>
        logEvent(SHARE_STORYBOOK_FAILED, SHARE_STORYBOOK_FAILED, {
          error: e
        })
      )
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    let timeoutId = null;
    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false);
      }, 5000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [copied]);

  return (
    <div className="share-campaign text-center w-100">
      <h6 className="description">
        Once you make changes to the field below, you may click on “Get Sharable
        link” to generate a URL link.
      </h6>
      <br />
      <div className="body">
        <div className="form-group">
          <label htmlFor="campaign-name">
            <span>Source</span>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="button-tooltip">
                  Used to show which site the visitors are coming from
                </Tooltip>
              }
            >
              <span>
                <IoMdInformationCircleOutline size={16} />
              </span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="utm_source"
            onChange={handleFormInput}
            value={form.utm_source}
          />
        </div>
        <div className="form-group">
          <label htmlFor="utm_medium">
            <span>Medium</span>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="button-tooltip">
                  Used to show which marketing channels are bringing the visitor
                  to your site. Examples include, but aren’t limited to, email,
                  social, or cost-per-click.
                </Tooltip>
              }
            >
              <span>
                <IoMdInformationCircleOutline size={16} />
              </span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="utm_medium"
            onChange={handleFormInput}
            value={form.utm_medium}
          ></input>
        </div>
        <div className="form-group">
          <label htmlFor="campaign-link">
            <span>Campaign</span>
            <OverlayTrigger
              placement="right"
              overlay={
                <Tooltip id="button-tooltip">
                  Used to identify which campaign the promotion is associated
                  with
                </Tooltip>
              }
            >
              <span>
                <IoMdInformationCircleOutline size={16} />
              </span>
            </OverlayTrigger>
          </label>
          <input
            type="text"
            className="form-control"
            id="utm_campaign"
            aria-describedby="utm_campaign"
            onChange={handleFormInput}
            value={form.utm_campaign}
          />
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <div className="footer d-flex">
          <button className="btn btn-light" onClick={handleGetSharableLink}>
            <span>Get sharable link</span>
            <span className="icon">
              {!loading && <FaLink size={16} />}
              {loading && <span className="spinner-border spinner-border-sm" />}
            </span>
          </button>
          {copied && <span className="success">Copied to clipboard</span>}
        </div>
      </div>
      <style jsx>{`
        .alert {
          font-size: 12px;
        }

        .footer {
          gap: 10px;
          align-items: center;
        }
        .success {
          font-size: 12px;
          color: #5da956;
          font-weight: bold;
        }
        .description {
          font-size: 12px;
          text-align: left;
        }
        .body {
          text-align: left;
        }
        .form-group label {
          font-size: 12px;
          font-weight: bold;
        }
        .btn {
          background-color: white;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          font-size: 12px;
          font-weight: bold;
        }
        label {
          display: flex;
          gap: 5px;
          align-items: center;
        }
        .icon {
          display: inline-flex;
          border-radius: 50%;
          background-color: black;
          color: white;
          width: 25px;
          height: 25px;
          justify-content: center;
          align-items: center;
        }
      `}</style>
    </div>
  );
}

export default ShareAsCampaign;
