import React from 'react';
import { FaLink } from 'react-icons/fa';
import {
  EmailIcon,
  LineIcon,
  LineShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton
} from 'react-share';
import QR from 'qrcode.react';
import { logEvent } from '../../../../analytics';
import {
  COPY_LINK_FAILED,
  COPY_LINK_SUCCESSFUL,
  DID_CLICK_COPY_LINK,
  DID_SHARE_STORYBOOK
} from '../../../../utils/constants';
import { HubContext } from '../../HubContext';

const ICON_SIZE = 25;
function SLShareAsGeneral({ url }: { url: string }) {
  const { brandId } = React.useContext(HubContext);
  const [copied, setCopied] = React.useState(false);
  const message = '';
  const title = '';
  const iconColor = '#000000';
  const onShare = (channel) => {
    logEvent(DID_SHARE_STORYBOOK, channel, {
      shareUrl: url,
      organisationId: brandId
    });
  };
  const handleCopy = () => {
    logEvent(DID_CLICK_COPY_LINK, DID_CLICK_COPY_LINK, { urlToCopy: url });
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          setCopied(true);
          logEvent(COPY_LINK_SUCCESSFUL, COPY_LINK_SUCCESSFUL, {
            urlToCopy: url
          });
        })
        .catch((e) =>
          logEvent(COPY_LINK_FAILED, COPY_LINK_FAILED, {
            urlToCopy: url,
            error: e
          })
        );
    } else {
      logEvent(COPY_LINK_FAILED, COPY_LINK_FAILED, {
        urlToCopy: url,
        error: 'Navigator clipboard unavailable'
      });
    }
  };

  React.useEffect(() => {
    let timeoutId = null;
    if (copied) {
      timeoutId = setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [copied]);

  if (!url)
    return (
      <div className="flex-center">
        <span className="spinner-border spinner-border-sm" />
      </div>
    );

  return (
    <div className="general-share">
      <div className="share-group">
        <WhatsappShareButton
          url={url}
          title={message || title}
          separator=" "
          className="btnShare"
          beforeOnClick={() => onShare('whatsapp')}
          windowWidth={920}
          windowHeight={720}
        >
          <WhatsappIcon
            bgStyle={{ fill: iconColor }}
            size={ICON_SIZE}
            round
            crossOrigin="anonymous"
          />
          <span className="btnShareSpan">WhatsApp</span>
        </WhatsappShareButton>
        <LineShareButton
          url={url}
          title={message || title}
          className="btnShare"
          beforeOnClick={() => onShare('line')}
        >
          <LineIcon
            bgStyle={{ fill: iconColor }}
            size={ICON_SIZE}
            round
            crossOrigin="anonymous"
          />
          <span className="btnShareSpan">Line</span>
        </LineShareButton>
        <a
          href={`mailto:?subject=${title}&body=${message || title} ${url}`}
          target="_blank"
          className="btnShare email"
          onClick={() => onShare('email')}
        >
          <EmailIcon
            bgStyle={{ fill: iconColor }}
            size={ICON_SIZE}
            round
            crossOrigin="anonymous"
          />
          <span className="btnShareSpan">Email</span>
        </a>
        <TelegramShareButton
          url={url}
          title={message || title}
          className="btnShare"
          beforeOnClick={() => onShare('telegram')}
        >
          <TelegramIcon
            bgStyle={{ fill: iconColor }}
            size={ICON_SIZE}
            round
            crossOrigin="anonymous"
          />
          <span className="btnShareSpan">Telegram</span>
        </TelegramShareButton>
        <button className="btnShare btn btn-light" onClick={handleCopy}>
          <span className="icon">
            <FaLink size={16} />
          </span>
          <span>
            Get sharable link
            {copied && ' (copied)'}
          </span>
        </button>
      </div>
      <div className="qr-code">
        <QR value={url} size={180} />
      </div>
      <style jsx>{`
        .general-share {
          display: flex;
          gap: 20px;
          flex-wrap: wrap;
          padding: 20px 0;
        }

        .share-group {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 20px;
        }
        span.message {
          font-size: 12px;
          display: flex;
          width: 100%;
          justify-content: center;
        }
        .share-group > ::global(*) {
          text-align: left;
          display: flex;
          gap: 10px;
          align-items: center;
          font-weight: 500;
          color: #000000;
          font-size: 15px;
          letter-spacing: 0.02em;
          padding: 10px 0 !important;
        }
        .share-group > ::global(button span) {
          font-weight: 500;
          font-size: 15px;
        }

        .qr-code {
          flex: 1;
        }
        .email:hover {
          text-decoration: none;
        }
        .btn {
          background-color: white;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          font-size: 12px;
          font-weight: bold;
          outline: none;
          padding: 10px 0;
          border: none;
        }
        .icon {
          display: inline-flex;
          border-radius: 50%;
          background-color: black;
          color: white;
          width: 25px;
          height: 25px;
          justify-content: center;
          align-items: center;
        }
        .qr-code {
          padding-top: 50px;
        }
      `}</style>
    </div>
  );
}

export default SLShareAsGeneral;
