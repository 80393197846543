import React from 'react';
import { TLanguage } from '../../../../mappers/polotno';

import { StoryBookContext } from '../SBContextContainer';

import DesignerPanelHeader from './SBDesignerPanelHeader';
import SBEnlargedPage from './SBEnlargedPage';
import SBSortablePage from './SBSortablePage';
const DesignerPanel = React.memo(({
  onOpenEditor,
  onDuplicate,
}: {
  onOpenEditor?: (props: {
    sceneId?: string;
    allowEdit?: boolean;
    isImported?: boolean;
    featuredImage?: boolean;
    activeLanguage?: TLanguage
  }) => void;
  onDuplicate?: (props: { sceneId?: string; isImported?: boolean }) => void;
}) => {
  const { state } = React.useContext(StoryBookContext);

  return (
    <div className="DesignerPanel h-100 w-100">
    <DesignerPanelHeader
      onEditFeaturedImage={() => onOpenEditor({ featuredImage: true })}
    />
    <SBSortablePage
      onOpenEditor={onOpenEditor}
      onDuplicate={onDuplicate}
    />
    {state.currentPageIndex !== null && <SBEnlargedPage />}
    <style jsx>{`
      .DesignerPanel {
        padding-top: 5px !important;
      }
    `}</style>
  </div>
  );
});

export default DesignerPanel;
