import React from 'react';

const ContentItemActions = ({
  onAdd,
  onView
}: {
  onAdd?: () => void;
  onView?: () => void;
}) => {
  return (
    <div className="actions d-flex">
      <div className="inner">
        {onView && (
          <button
            className="btn btn-sm btn-round btn-outline-light"
            onClick={onView}
          >
            View
          </button>
        )}
        {onAdd && (
          <button
            className="btn btn-sm btn-round btn-outline-light"
            onClick={onAdd}
          >
            Select
          </button>
        )}
      </div>
      <style jsx>{`
        .actions {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          background: rgba(0, 0, 0, 0.5);
          align-items: center;
          opacity: 0;
          transition: all 0.1s ease-in-out;
        }
        .actions:hover {
          opacity: 1;
        }
        .inner {
          margin: auto;
          width: 80px;
        }
        .btn {
          display: block;
          width: 100%;
          margin: 5px 0;
          border-color: #fff !important;
          padding: 0;
        }
      `}</style>
    </div>
  );
};

export default ContentItemActions;
