import React from 'react';
import { useDispatch } from 'react-redux';
import { logClickButton, logEvent } from '../../../../analytics';
import { useLibraryAction } from '../../../../components/hooks/library';
import { ILibraryScene, IStorybookPage } from '../../../../interfaces';
import { mapStorybookPageToScene } from '../../../../mappers/library';
import { mapSceneToStorybook } from '../../../../mappers/storybook';
import {
  actionHubAlertError,
  actionHubAlertInfo
} from '../../../../redux/actions';
import SBPopupWrapper from '../Common/SBPopupWrapper';
import SLSceneDetails from '../../SceneLibrary/Builder/SLSceneDetails';
import { StoryBookContext } from '../SBContextContainer';
import SBPreview from '../Preview';
import { isVideo } from '../../../../utils/file';
import { Spinner } from 'react-bootstrap';
import { useCollaborations } from '../../../../components/hooks/collaborator';
import { getIdentityId } from '../../../../utils/identity';
import { removeUnusedFontsInScene } from '../../../../utils/polotno';

const DID_ADD_STORYBOOK_PAGE_TO_LIBRARY = 'DID_ADD_STORYBOOK_PAGE_TO_LIBRARY';

const SBExportPagePopup = ({
  page,
  onClose
}: {
  page: IStorybookPage;
  onClose: (updatedPage?: ILibraryScene) => void;
}) => {
  const dispatch = useDispatch();
  const { state } = React.useContext(StoryBookContext);

  const [scene, setScene] = React.useState(
    mapStorybookPageToScene({ page, userId: state.storybook.createdBy })
  );

  const [loading, setLoading] = React.useState(false);

  const sceneName = scene.content.name;

  const { saveScene, activateScene } = useLibraryAction({
    context: 'scene',
    userId: state.storybook.createdBy
  });

  const currentUserId = getIdentityId();

  const { addCollaborator } = useCollaborations({
    itemId: scene.id,
    context: 'scene',
    owner: scene.createdBy
  });

  const onSaveError = () => {
    setLoading(false);
  };

  const onSaveSuccess = (savedPage: ILibraryScene) => {
    if (currentUserId !== scene.createdBy) {
      addCollaborator('user', currentUserId);
    }
    const updateStorybookPage = mapSceneToStorybook(savedPage).pages[0];
    setLoading(false);
    onClose(updateStorybookPage);
    logEvent(
      DID_ADD_STORYBOOK_PAGE_TO_LIBRARY,
      DID_ADD_STORYBOOK_PAGE_TO_LIBRARY,
      {
        page: {
          id: updateStorybookPage.id,
          title: updateStorybookPage.title
        }
      }
    );
    dispatch(actionHubAlertInfo('Your scene has been added to library.'));
  };

  const handleSave = () => {
    if (!sceneName.trim()) {
      dispatch(actionHubAlertError('Please enter a scene name.'));
      return;
    }

    setLoading(true);
    logClickButton('Save Exported Page');
    saveScene({
      scene: removeUnusedFontsInScene(scene),
      onSuccess: (res1) => {
        activateScene({
          id: res1.id,
          delay: 2700,
          onSuccess: (res2) => {
            onSaveSuccess(res2);
          }
        });
      },
      onError: onSaveError
    });
  };
  return (
    <SBPopupWrapper
      width="90%"
      height="100%"
      maxWidth="1700px"
      maxHeight="80vh"
    >
      <div className="popup d-flex w-100 h-100">
        <div className="content-container d-flex w-100">
          <div className="preview">
            <SBPreview
              storybook={mapSceneToStorybook(scene)}
              isForScene={true}
              hideInfo={true}
              previewMode="microsite"
              title="Add Scene to Library"
            />
          </div>
          <div className="details">
            <SLSceneDetails
              onUpdate={setScene}
              scene={scene}
              context="scene"
              isVideo={isVideo(scene.content.url)}
            />
          </div>
        </div>
        <div className="action-bar d-flex">
          <div className="note">
            If you add the scene to the library and make changes in the future,
            all connected Storybooks will automatically be updated.
          </div>
          <div className="actions d-flex">
            <button
              className="btn btn-sm btn-round btn-outline-dark"
              onClick={() => onClose()}
            >
              Cancel
            </button>
            <button
              className="btn btn-sm btn-round btn-dark"
              onClick={handleSave}
            >
              {loading ? <Spinner animation="border" size="sm" /> : 'Save'}
            </button>
          </div>
        </div>
      </div>
      <style jsx>{`
        .popup {
          flex-direction: column;
        }
        .popup :global(.collaborators) {
          display: none;
        }

        .content-container {
          height: calc(100% - 60px);
        }
        .preview {
          flex-grow: 1;
          border-right: 1px solid #ccc;
        }
        .details {
          overflow: auto;
        }
        .action-bar {
          border-top: 1px solid #ccc;
          height: 60px;
          padding: 0 20px;
          align-items: center;
          justify-content: space-between;
        }
        .note {
          font-size: 12px;
          font-weight: normal;
        }
        .btn {
          width: 120px;
          margin-left: 10px;
        }
      `}</style>
    </SBPopupWrapper>
  );
};

export default SBExportPagePopup;
