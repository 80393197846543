import { isEmpty, isEqual, uniq } from 'lodash';
import React from 'react';
import { IStorybook } from '../../../../interfaces';
import CollaboratorSelector from '../../Common/Collaborators/CollaboratorSelector';
import {
  IconClose,
  IconFavorite,
  IconFavoriteFilled
} from '../../Common/HubIcons';
import SBVisibilityTagsSetting from '../Common/SBVisibilityTagsSetting';
import StorybookStats from '../Common/StorybookStats';

const StorybookMetaData = ({
  storybook,
  updateStorybook,
  onToggleFavorite,
  canModify,
  isFavorited,
  onClose
}: {
  storybook: IStorybook;
  updateStorybook?: (storybook: IStorybook) => void;
  onToggleFavorite?: (id: string, add: boolean) => void;
  canModify?: boolean;
  isFavorited?: boolean;
  onClose: () => void;
}) => {
  const existingScope = storybook?.visibilityScope || [];
  const existingTags = storybook?.tags || [];

  const addTeam = (teamId: string) => {
    const visibilityScope = uniq([...existingScope, teamId]);
    if (!isEqual(visibilityScope, existingScope)) {
      updateStorybook({
        ...storybook,
        visibility: isEmpty(visibilityScope) ? 'private' : 'team',
        visibilityScope
      });
    }
  };

  const removeTeam = (teamId: string) => {
    const visibilityScope = existingScope.filter((id) => id !== teamId);
    if (!isEqual(visibilityScope, existingScope)) {
      updateStorybook({
        ...storybook,
        visibility: isEmpty(visibilityScope) ? 'private' : 'team',
        visibilityScope: visibilityScope
      });
    }
  };

  const addTag = (tag: string) => {
    const tags = uniq([...existingTags, tag]);
    if (!isEqual(tags, existingTags)) {
      updateStorybook({
        ...storybook,
        tags
      });
    }
  };

  const removeTag = (t: string) => {
    const tags = existingTags.filter((tag) => tag !== t);
    if (!isEqual(tags, existingTags)) {
      updateStorybook({
        ...storybook,
        tags
      });
    }
  };
  const favoriteLabel = isFavorited
    ? 'Remove from Favorites'
    : 'Add to Favorites';

  const handleToggleFavorite = () => {
    onToggleFavorite(storybook.id, !isFavorited);
  };

  if (!storybook.id) return null;

  return (
    <div className="meta-data">
      <p className="title">{storybook?.title}</p>
      <p className="subtitle">{storybook?.subtitle}</p>
      <div className="stats">
        <StorybookStats storybook={storybook} />
      </div>
      {canModify && (
        <button className="btn favorite" onClick={handleToggleFavorite}>
          {isFavorited ? (
            <IconFavoriteFilled color="#B7A56D" />
          ) : (
            <IconFavorite />
          )}
          <span>{favoriteLabel}</span>
        </button>
      )}
      <CollaboratorSelector
        itemId={storybook.id}
        owner={storybook.createdBy}
        context="storybook"
        sidePanelMode={true}
        readOnly={!canModify}
      />
      <SBVisibilityTagsSetting
        teamIds={existingScope}
        tags={existingTags}
        addTeam={addTeam}
        removeTeam={removeTeam}
        addTag={addTag}
        removeTag={removeTag}
        canModify={canModify}
        uniqueId={storybook?.id}
      />
      <div className="btn-close" onClick={onClose}>
        <IconClose />
      </div>
      <style jsx>{`
        .meta-data {
          padding: 30px 40px;
        }

        .meta-data :global(.SearchInput .form-control) {
          font-size: 12px !important;
        }

        .meta-data :global(.UserTeamSelector .avatar-container) {
          font-size: 9px !important;
        }
        .title {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        .subtitle {
          margin-bottom: 27px;
        }

        .subtitle,
        .stats {
          font-size: 10px;
          font-weight: 400;
        }

        .favorite {
          margin-top: 15px;
          font-size: 12px;
          font-weight: 600;
          border: none;
          padding: 0;
          background: none;
        }
        .favorite :global(svg) {
          height: 22px;
          width: 22px;
          margin-top: -3px;
        }
        .favorite span {
          display: inline-block;
          text-decoration: underline;
          margin-left: 5px;
        }
        .actions {
          border-top: 1px solid #efefef;
          justify-content: space-between;
          padding: 15px 0;
          margin-top: 15px;
        }
        .actions .btn {
          width: 48%;
        }
        .btn-close {
          position: absolute;
          top: 5px;
          right: 15px;
        }
        .btn-close :global(svg) {
          width: 20px;
          height: auto;
          cursor: pointer;
          border: 1px solid rgba(0, 0, 0);
          border-radius: 100px;
        }
      `}</style>
    </div>
  );
};

export default StorybookMetaData;
