import { isNumber } from 'lodash';
import { useRef } from 'react';
import { assetBaseUrl } from '../../../../config';
import { BsThreeDots } from 'react-icons/bs';

const FolderThumbnail = ({
  onClick,
  label,
  onClickMenu,
  id,
  itemNumber = 0,
  disabled,
  isTeamFolder,
  isDroppable = true,
  onDrop
}: {
  onClick: (id: String) => void;
  label: string;
  onClickMenu?: (id: string, canDelete?: boolean) => void;
  id: string;
  itemNumber?: number;
  disabled?: boolean;
  isTeamFolder?: boolean;
  isDroppable?: boolean;
  onDrop?: (id: string, transferData: DataTransfer) => void;
}) => {
  const containerRef = useRef<HTMLDivElement>();

  return (
    <div
      className={`FolderThumbnail d-flex ${isTeamFolder ? 'team-folder' : ''} ${
        disabled ? 'disabled' : ''
      }`}
      ref={containerRef}
      onDragOver={(e) => {
        e.preventDefault();
        if (!isDroppable) return;
        containerRef.current?.classList?.add('drag-active');
      }}
      onDragLeave={() => {
        if (!isDroppable) return;
        containerRef.current?.classList?.remove('drag-active');
      }}
      onDrop={(e) => {
        if (!isDroppable) return;
        onDrop?.(id, e.dataTransfer);
        containerRef.current?.classList?.remove('drag-active');
      }}
    >
      <div className="content" onClick={() => onClick(id)}>
        <img
          src={`${assetBaseUrl}/hub/${
            itemNumber ? 'folder.png' : 'folder-empty.png'
          }`}
          alt="Folder"
        />
        <div className="label-container">
          <strong>{label}</strong>
        </div>
      </div>
      {!isTeamFolder && (
        <div className="details d-flex">
          {isNumber(itemNumber) && (
            <div className="itemNumber">{itemNumber}</div>
          )}
          {onClickMenu && (
            <div
              onClick={() => onClickMenu(id, itemNumber <= 0)}
              className="folder-option"
              id={`menu-${id}`}
            >
              <BsThreeDots />
            </div>
          )}
        </div>
      )}
      <style jsx>{`
        .FolderThumbnail {
          background: rgb(230, 124, 255);
          background: linear-gradient(
            135deg,
            rgba(230, 124, 255, 1) 0%,
            rgba(143, 101, 153, 1) 100%
          );
          border-radius: 15px;
          overflow: hidden;
          font-size: 13px;
          border: 2px solid #fff;
          box-shadow: 0 0 5px #efefef;
          position: relative;
          height: 100%;
          padding: 14px 14px 14px 20px;
          min-height: 250px;
          justify-content: space-between;
        }
        .team-folder {
          background: linear-gradient(
            135deg,
            rgb(170 202 250) 0%,
            rgb(98 142 220) 100%
          );
        }
        .disabled {
          pointer-events: none;
          opacity: 0.5;
        }
        .FolderThumbnail > div {
          width: 80%;
        }
        .FolderThumbnail > div + div {
          width: auto;
        }
        img {
          width: 30%;
          height: auto;
        }
        .folder-option {
          padding: 4px;
          background: #000;
          border-radius: 100%;
        }
        .folder-option :global(svg) {
          width: 18px;
          height: auto;
          cursor: pointer;
          color: #fff;
          font-size: 10px;
        }
        .itemNumber {
          min-width: 28px;
          height: 28px;
          margin-right: 3px;
          padding: 0 10px;
          overflow: hidden;
          background: #000;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 500;
          border-radius: 100px;
        }
        .label-container {
          font-size: 1em;
          text-transform: uppercase;
          letter-spacing: 0.08em;
          margin-top: 30px;
          color: #fff;
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
        }
        .details {
          align-items: end;
          justify-content: space-between;
          flex-direction: column;
        }
        .content {
          cursor: pointer;
          text-align: left;
        }
        .drag-active {
          border: 1px solid #8f6599;
          background: rgb(157 78 177 / 58%);
        }
      `}</style>
    </div>
  );
};
export default FolderThumbnail;
