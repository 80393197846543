import React from 'react';
import { logEvent } from '../../../../analytics';
import { IStorybook } from '../../../../interfaces';
import {
  DID_CLICK_BUTTON,
  PRESENT_STORYBOOK_FAILED
} from '../../../../utils/constants';
import { getSimpleShortenedUrl } from '../../../../utils/shareUrl';
import { getPresentBrandSBHost } from '../../../../config/index';

const SBPresentButton = ({
  storybook = {},
  onPresent
}: {
  storybook: IStorybook;
  onPresent: (arg: { url: string; message: string; id: string }) => void;
}) => {
  const performPresent = (url: string, storybook: IStorybook) => {
    onPresent({
      url,
      message: storybook.title,
      id: storybook.id
    });
  };

  const generateShareUrl = async (id: string, brandId: string) => {
    const host = getPresentBrandSBHost(brandId);
    const storybookUrl = `${host}/present/${id}`;
    if (typeof getSimpleShortenedUrl === 'function') {
      return getSimpleShortenedUrl(storybookUrl, host)
        .then((url) => ({
          original: storybookUrl,
          shortened: url
        }))
        .catch(() => ({
          original: storybookUrl,
          shortened: ''
        }));
    } else {
      return {
        original: storybookUrl,
        shortened: ''
      };
    }
  };

  return (
    <div
      className="list-group-item list-group-item-action"
      id={`sb-${storybook.id}`}
      onClick={() => {
        logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
          button: 'Present Storybook',
          storybookId: storybook.id
        });
        generateShareUrl(storybook.id, storybook.brand)
          .then((url) => {
            performPresent(url.shortened || url.original, storybook);
          })
          .catch((e) =>
            logEvent(PRESENT_STORYBOOK_FAILED, PRESENT_STORYBOOK_FAILED, {
              error: e
            })
          );
      }}
    >
      Present
    </div>
  );
};

export default SBPresentButton;
