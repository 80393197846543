import React from 'react';
import PopupContainer from '../../Common/PopupContainer';

const SBPopupWrapper = (props: {
  onClose?: () => void;
  children: React.ReactNode;
  width?: string;
  maxWidth?: string;
  height?: string;
  maxHeight?: string;
  zIndex?: number;
  className?: string;
}) => {
  return (
    <PopupContainer
      {...props}
      className={`SBPopupWrapper ${props.className || ''}`}
    />
  );
};

export default SBPopupWrapper;
