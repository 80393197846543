import React from 'react';
import { useLoadingStatus } from '../../../../components/hooks/loading';
import { ILibraryScene, LoadingStatus } from '../../../../interfaces';
import SLThumbnail from '../Common/SLThumbnail';

const SLSearchItems = ({
  userId,
  scenes,
  onView,
  onDelete,
  onClickMenu,
  trashList,
  onRestore,
  favoriteList,
  searchMode,
  isInTrashKey,
  activeThumbnail,
  selectedScenes,
  selectedSceneArr,
  onSelect,
  selectScene,
  loadingFolder={},
  isDraggable=false
}: {
  userId: string;
  scenes: ILibraryScene[];
  onView: (sceneId: string) => void;
  onDelete: (sceneId: string) => void;
  onClickMenu: (s: ILibraryScene, viewOnly?: boolean) => void;
  trashList: string[];
  onRestore: (sceneId: string) => void;
  favoriteList: string[];
  searchMode: boolean;
  isInTrashKey: boolean;
  activeThumbnail?: string;
  selectedScenes?: string[];
  selectedSceneArr?: string[];
  onSelect?: (scene: ILibraryScene) => void;
  selectScene?: (scene: ILibraryScene) => void;
  loadingFolder?: object;
  isDraggable?: boolean
}) => {
  const { isLoading } = useLoadingStatus();
  const isLoadingFolder =  (key: string) => loadingFolder[key] === LoadingStatus.LOADING

  return (
    <>
      {scenes?.map((scene) => {
        const isInTrash = trashList?.includes(scene.id);
        const deleteMark = isInTrash && searchMode && !isInTrashKey;
        return (
          <div className="thumbnail-container" key={scene.id}>
            <SLThumbnail
              scene={scene}
              onClickMenu={() => onClickMenu(scene, scene.createdBy !== userId)}
              canModify={!onSelect}
              selectScene={selectScene}
              onDelete={onDelete}
              loading={isLoading(scene.id) || isLoadingFolder(scene.id)}
              isInTrash={isInTrash}
              isFavorited={favoriteList?.includes(scene.id)}
              deleteMark={deleteMark}
              onRestore={onRestore}
              selectedScenes={selectedSceneArr}
              selected={
                activeThumbnail === scene.id ||
                selectedScenes?.includes(scene.id) ||
                selectedSceneArr?.includes(scene.id)
              }
              onSelect={onSelect}
              onView={onView}
              isDraggable={isDraggable}
            />
          </div>
        );
      })}
    </>
  );
};

export default SLSearchItems;
