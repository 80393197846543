import React from 'react';
import { Spinner } from 'react-bootstrap';
import { IStorybook } from '../../../../interfaces';
import { SBActionTarget } from '../SBActionHandler';
import { StoryBookContext } from '../SBContextContainer';

const SBPublishButton = ({
  storybook,
  onClickPublish,
  unpublishCallback
}: {
  storybook: IStorybook;
  onClickPublish: () => void;
  unpublishCallback?: () => void;
}) => {
  const { action } = React.useContext(StoryBookContext);
  const [loading, setLoading] = React.useState(false);
  const isPublished = storybook.stats.isPublished;

  const handleUnpublish = () => {
    setLoading(true);
    action.delete(SBActionTarget.PublishedStorybook, storybook, () => {
      unpublishCallback?.();
      setLoading(false);
    });
  };

  return isPublished ? (
    <button
      className="list-group-item list-group-item-action"
      onClick={handleUnpublish}
    >
      {loading ? <Spinner animation="border" size="sm" /> : 'Unpublish'}
    </button>
  ) : (
    <button
      className="list-group-item list-group-item-action"
      onClick={onClickPublish}
    >
      Publish
    </button>
  );
};

export default SBPublishButton;
